import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import config from '../../config';
import { API } from '../../constants';
import { post } from '../../services/api.services';
import { SET_GIFT_CARD_INFO } from '../../store/actions';

import {
    Box,
    Container,
    Grid,
    TextField,
    Stack,
} from '@mui/material';

import Button from '../../components/common/button';
import Header from '../../components/header/logged-out';
import Footer from '../../components/footer/logged-out';

import GiftCardImg from '../../assets/images/parentswarm-gift-card.png';

function GiftCardConfirmation({}) {
    const dispatcher = useDispatch();

    const giftCard = useSelector((state) => state.giftCard);

    const sendGiftCard = async () => {
        const result = await post(
            `${API.GIFT_CARD}`,
            {
                amount: giftCard.amount * 100,
                recipient_email: giftCard.recipientEmail,
                recipient_name: giftCard.recipientName,
                message: giftCard.message,
                send_date: giftCard.sendDate,
            },
        );

        if (result.status === 201) {
            dispatcher({
                type: SET_GIFT_CARD_INFO,
                payload: {
                    ...giftCard,
                    purchaseSaved: true,
                },
            });
        }
        else {
        }
    };

    useEffect(() => {
        if (!giftCard.purchaseSaved) {
            sendGiftCard();
        }
    }, []);

    return (
        <>
            <Header />
            <Container>
                <Box pt={4} pb={4} sx={{ fontFamily: 'Nunito' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={GiftCardImg} alt="Parentswarm gift card" width={325} style={{ borderRadius: 8 }} />
                                <Box>
                                    <h1>Parentswarm Gift Card</h1>
                                    <h2>${giftCard.amount} USD</h2>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h1>Thank you for your purchase!</h1>
                            We will send this card to {giftCard.recipientName}. A copy of your receipt has been sent to your email.{' '}
                            If you have any questions, please email us at support@parentswarm.com.
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default GiftCardConfirmation;