import { get } from '../services/api.services';
import { API } from '../constants';
import {
    RESET_FOLDER_IDS,
    ADD_FOLDER_ID,
    SET_FOLDER,
} from '../store/actions';

export const fetchFolders = async (dispatcher, navigate, token) => {
    const result = await get(
        API.FOLDER,
        token,
    );

    dispatcher({
        type: RESET_FOLDER_IDS,
        payload: {},
    });

    if (result.status === 403) {
        navigate('/login/parents');
    }
    else if (result.status === 200) {
        result.data.forEach((folder) => {
            dispatcher({
                type: SET_FOLDER,
                payload: {
                    folder,
                },
            });

            dispatcher({
                type: ADD_FOLDER_ID,
                payload: {
                    folder,
                },
            });
        });
    }
}
