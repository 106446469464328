import styled from 'styled-components';
import calender from '../../assets/images/calendar.svg';

export const InputWrapperContainer = styled.div`
  width: 100%;
  grid-template-columns: auto auto;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 60px;
  font-family: 'Cabin';
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 7px;
  margin-top: 7px;
`;

export const Input = styled.input`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #bcb6c1;
  font-family: 'Nunito';
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #bcb6c1;
    opacity: 1; /* Firefox */
  }
  &::-webkit-datetime-edit {
    color: ${(props) => props.dateColor};
  }
  &::-webkit-calendar-picker-indicator {
    background-image: url(${calender});
    background-size: 20px;
  }
`;

export const ServiceOfferContainer = styled.div``;

export const ServiceOfferOptions = styled.div`
  margin-top: 10px;
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
`;

export const SelectContainer = styled.select`
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  border: 1px solid #bcb6c1;
  border-radius: 5px;
  outline: none;
  font-family: 'Cabin';
  font-size: 16px;
  &::placeholder {
    color: #bcb6c1;
    opacity: 1; /* Firefox */
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin: 0px;
  margin-top: 5px;
  font-size: 13px;
`;

export const Button = styled.button`
  width: ${(props) => props.width || '142px'};
  height: ${(props) => props.height || '55px'};
  background: ${(props) => props.background || '#5371ff'};
  border-radius: ${(props) => props.br || '0px'};
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) => props.color || 'white'};
  cursor: pointer;
  position: ${(props) => props.position || 'relative'};
  top: ${(props) => props.top || '0'};
  left: ${(props) => props.left || '0'};
  bottom: ${(props) => props.bottom || '0'};
  right: ${(props) => props.right || '0'};
  outline: none;
  border: none;
  font-size: ${(props) => props.fontSize || '20px'};
  font-weight: bold;
  margin: 10px;
`;

export const Space = styled.div`
  width: ${(props) => props.width || '142px'};
  height: ${(props) => props.height || '55px'};
`;
