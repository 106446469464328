import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
} from '@mui/material';

import { ACCOUNT_TYPES } from '../../constants';
import { displayName, calculateAge } from '../../utils/profile';
import Profile from '../profile';

import {
  MiniProfileContainer,
  MiniProfileDetail,
  MiniProfileName,
  MiniProfileNameContainer,
} from './Session.style';
import { stringToColor } from '../../utils/stringHelpers';

function MiniProfile({ profile }) {
  const [openProfile, setOpenProfile] = useState(false);

  return (
    <>
      <MiniProfileContainer onClick={() => setOpenProfile(true)}>
        <Avatar
          alt={profile.first_name}
          src={profile.profile_image_url}
          sx={{ width: 35, height: 35, bgcolor: profile.profile_image_url ? '#FFFFFF' : stringToColor(profile.business_name || profile.first_name) }}
        />
        <MiniProfileNameContainer>
          <MiniProfileName>
              {profile.business_name || displayName(profile.first_name, profile.last_name)}
          </MiniProfileName>
          {profile.profile_type === ACCOUNT_TYPES.EXPERT ? (
              <MiniProfileDetail>
                  {profile.roles?.split(',').join(', ')}
              </MiniProfileDetail>
          ) : (
              <MiniProfileDetail className='fs-exclude'>
                  {profile.birth_date ? `${calculateAge(profile.birth_date)} years old` : ''}
              </MiniProfileDetail>
          )}
        </MiniProfileNameContainer>
      </MiniProfileContainer>
      {openProfile && (
        <Profile open={openProfile} profileId={profile.display_id} onClose={() => setOpenProfile(false)} />
      )}
    </>
  );
}

MiniProfile.propTypes = {
  profile: PropTypes.any, // TODO: Replace with proper proptype
};

export default MiniProfile;