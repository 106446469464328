import React from 'react';

import { Box, Button, Typography } from '@mui/material';

export default function ProductTypeSelection({ type, title, description, onSelect, selected }) {
    return (
        <Button variant="outlined" onClick={() => onSelect(type)} color={selected ? 'primary' : 'info'} style={{ textTransform: 'none', width: '100%' }}>
            <Box sx={{ height: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box>
                    <Typography variant="h6" component="div">
                        {title}
                    </Typography>
                    <Typography color="text.secondary" gutterBottom>
                        {description}
                    </Typography>
                </Box>
            </Box>
        </Button>
    );
}