import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Card, Container } from '@mui/material';

function Invite() {
    const navigate = useNavigate();
    const params = useParams();

    const isLoggedIn = useSelector((state) => state.account.isLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`/login/parents?invite=${params.code}`);
        }
        else {
            navigate(`/dashboard?invite=${params.code}`);
        }
    }, []);

    return (<Container />);
}

export default Invite;