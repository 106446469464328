import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Typography } from '@mui/material';

import NessleLogo from '../../assets/images/parentswarm-logo.svg';
import {
  Container, LogoWrapper, Logo, GradientContainer,
} from '../setup-account/setup-account.style';
import YourDetails from '../../components/account-setup/your-details';
import ParentDetails from '../../components/account-setup/parent-details';
import { ACCOUNT_TYPES } from '../../constants';

export default function SetupParentAccount() {
  const user = useSelector((state) => state.account.user);
  const matchAnswers = useSelector((state) => state.match);

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [state, setState] = useState({
    firstName: user.profile.first_name || '',
    lastName: user.profile.last_name || '',
    dob: user.profile.birth_date || '',
    zipCode: user.profile.zip_code || (matchAnswers?.zip || ''),
    phoneNumber: user.profile.phone || '',
    pronouns: user.profile.pronouns || null,
    address: user.profile.address || '',
  });
  const [profileImageKey, setProfileImageKey] = useState(user.profile.profile_image_key || null);
  const [selectedGroups, setSelectedGroups] = useState(user.profile.parent_categories?.split(',') || (matchAnswers?.stages || []));
  const [selectedSpecialInterests, setSelectedSpecialInterests] = useState(user.profile.parent_special_interest_groups?.split(',') || (matchAnswers?.affinity || []));
  const [parentJoinedReason, setParentJoinedReason] = useState(user.profile.parent_joined_reason);
  const [tracks, setTracks] = useState(user.profile.tracks || []);

  const handleBackBtn = () => {
    if (page > 0 && page <= 2) {
      setPage((prev) => prev - 1);
    }
  };
  const handleCrossSetupLater = () => {
    navigate('/dashboard');
  };
  return (
    <GradientContainer>
      <LogoWrapper>
        <Logo src={NessleLogo} />
      </LogoWrapper>
      <Container margin="0 auto" borderRadius="14px">
        <Typography variant="h5">Set up your account</Typography>
        <Box mt={1}>
          <Typography variant="subtitle1">
            {(() => {
              switch (page) {
                case 1:
                  return (
                    'Your details'
                  );
                case 2:
                  return 'About you';
                default:
                  return null;
              }
            })()}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">({page} / 2)</Typography>
        </Box>
        {(() => {
          switch (page) {
            case 1:
              return (
                <YourDetails
                  onSuccess={() => setPage((prev) => prev + 1)}
                  handleCrossSetupLater={handleCrossSetupLater}
                  state={state}
                  setState={setState}
                  profileType={ACCOUNT_TYPES.PARENT}
                />
              );
            case 2:
              return (
                <ParentDetails
                  page={page}
                  setPage={setPage}
                  handleCrossSetupLater={handleCrossSetupLater}
                  handleBackBtn={handleBackBtn}
                  setSelectedGroups={setSelectedGroups}
                  selectedGroups={selectedGroups}
                  setSelectedSpecialInterests={setSelectedSpecialInterests}
                  selectedSpecialInterests={selectedSpecialInterests}
                  parentJoinedReason={parentJoinedReason}
                  setParentJoinedReason={setParentJoinedReason}
                />
              )
            default:
              return null;
          }
        })()}
      </Container>
    </GradientContainer>
  );
}
