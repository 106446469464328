import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
} from '@mui/material';

import {
  LoggedOutLink,
  LoggedOutHeaderContainer,
  LoggedOutHeader,
  LinkContainer,
} from './Header.style';
import Logo from '../../assets/images/parentswarm-logo.svg';
import Button from '../common/button';

function Header() {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  return (
    <>
      <LoggedOutHeaderContainer>
        <LoggedOutHeader>
          <Box>
            <a href="https://nessle.com"><img src={Logo} alt="Nessle logo" width={150} /></a>
          </Box>
          <LinkContainer>
            <Box ml={4} mr={2} sx={{ display: { xs: 'none', md: 'block' }}}>
              <LoggedOutLink href="https://nessle.com">Home</LoggedOutLink>
            </Box>
            <Box ml={4} mr={2} sx={{ display: { xs: 'none', md: 'block' }}}>
              <LoggedOutLink href="https://nessle.com/our-story">Our Story</LoggedOutLink>
            </Box>
            <Box ml={4} mr={2} sx={{ display: { xs: 'none', md: 'block' }}}>
              <LoggedOutLink href="https://www.nessle.com/list-resource">List as a Resource</LoggedOutLink>
            </Box>
            <Box ml={4} mr={2} sx={{ display: { xs: 'none', md: 'block' }}}>
              <LoggedOutLink href="https://nessle.com/blog">Blog</LoggedOutLink>
            </Box>
            <Box ml={4} mr={4}>
              <Button shade="secondary" size="large" onClick={() => navigate('/login/parents?login=1')}>Login</Button>
            </Box>
          </LinkContainer>
        </LoggedOutHeader>
      </LoggedOutHeaderContainer>
      <Box sx={{ height: '106px' }} />
    </>
  );
}

export default Header;
