// action - state management
import * as actionTypes from './actions';

export const initialState = {
    stages: [],
    affinity: [],
    zip: undefined,
    email: undefined,
    match: undefined,
};

const matchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_ANSWERS:
      return {
        ...state,
        stages: action.payload.stages,
        affinity: action.payload.affinity,
        zip: action.payload.zip,
        email: action.payload.email,
        match: action.payload.match,
      };
    default:
      return state;
  }
};

export default matchReducer;
