import { CircularProgress, Stack, Typography } from '@mui/material';

export default function LoadingScreen({
  rotatingText,
}) {
  return (
    <Stack spacing={4} sx={{ alignItems: 'center' }}>
      <Typography variant="h6">Finding your recommendations...</Typography>
      <Typography variant="body1">{rotatingText}</Typography>
      <CircularProgress size="5rem" />
    </Stack>
  );
}
