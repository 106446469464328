import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar, Box, Drawer, Grid, Modal, Rating, Tooltip, useRadioGroup } from '@mui/material';

import { Card, Question, SessionDate } from '../session/Session.style';
import { ACCOUNT_TYPES } from '../../constants';
import ParticipantCount from './participant-count';
import { RowText } from './group-session.style';
import ProductPurchase from '../product/purchase';
import MiniProfile from '../session/mini-profile';

function GroupSessionRow({ product, variant, onEdit = null }) {
  const user = useSelector((state) => state.account.user);

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <Card onClick={() => setOpenDetails(true)}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid key={product.id} item xs={12} sm={6} md={4} lg={4}>
            <Box display="flex">
              <Avatar
                variant="square"
                sx={{ width: 64, height: 64, borderRadius: 1 }}
                alt={product.title}
                src={product.image_url}
              />
              <Box ml={2} xs={{ alignItems: 'center' }}>
                <Question>
                  {product.title}
                </Question>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            {user.profile.profile_type === ACCOUNT_TYPES.EXPERT ? (
              <ParticipantCount product={product} />
            ) : (
              <MiniProfile profile={product.seller} />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <RowText>
              {moment(product.event_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}
            </RowText>
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      >
        <ProductPurchase
          preview={user.profile.id === product.seller.id}
          product={product}
          onClose={() => setOpenDetails(false)}
          onEdit={onEdit}
        />
      </Modal>
    </>
  );
}

GroupSessionRow.propTypes = {
  product: PropTypes.any, // TODO: Replace with proper proptype
  variant: PropTypes.string,
};

export default GroupSessionRow;