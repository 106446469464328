// action - state management
import * as actionTypes from './actions';

export const initialState = {
    code: undefined,
};

const couponReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_COUPON_CODE:
      return {
        ...state,
        code: action.payload.code,
      };
    default:
      return state;
  }
};

export default couponReducer;
