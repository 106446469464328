import React from 'react';
import { useSelector } from 'react-redux';

import ExpertSessions from './expert-sessions';
import ParentSessions from './parent-sessions';

import { ACCOUNT_TYPES } from '../../constants';


function Sessions() {
    const user = useSelector((state) => state.account.user);

    if (user.profile.profile_type === ACCOUNT_TYPES.EXPERT) {
        return <ExpertSessions />;
    }

    return <ParentSessions />;
}

export default Sessions;