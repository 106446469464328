/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
    background: #FFFFFF;
    width: 100%;
    font-size: 13px;
    height: 45px;
    overflow: hidden;
`;

export const FooterLink = styled.a`
    Color: #210B2B;
`;

export const LoggedOutFooterContainer = styled.div`
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    margin-top: 20px;
    @media (max-width: 1099px) {
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const LoggedOutFooter = styled.div`
    padding-top: 60px;
    @media (max-width: 1099px) {
        width: 100%;
    }
    @media (min-width: 1100px) {
        width: 1100px;
    }
`;

export const LoggedOutFooterLink = styled.a`
    color: #817d7d;
    font-size: 16px;
    line-height: 1.125em;
    font-weight: 800;
    font-family: 'Poppins';
    text-decoration: none;
    &:hover {
        color: #5371ff;
    }
`;