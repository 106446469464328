import React, { useState } from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
} from '@mui/material';

import {
    ModalFormItem,
    ModalFormItemLabel,
} from './product.style';

import {
    QUESTION_CATEGORIES_COL_1,
    QUESTION_CATEGORIES_COL_2,
} from '../../utils/groups';

export default function Category({
    title,
    setTitle,
    categories,
    setCategories,
    otherCategory,
    setOtherCategory,
}) {
    return (
        <Stack spacing={2} alignItems="center" >
            <ModalFormItem>
                <ModalFormItemLabel>
                    Title
                </ModalFormItemLabel>
                <TextField
                    id="product-title"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ width: '100%' }}
                    inputProps={{ maxLength: 128 }}
                />
            </ModalFormItem>
            <ModalFormItem>
                <ModalFormItemLabel>
                    Select Category:
                </ModalFormItemLabel>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Stack spacing={0}>
                            {QUESTION_CATEGORIES_COL_1.map((cat) => (
                                <FormControlLabel
                                    key={cat}
                                    control={
                                        <Checkbox
                                            checked={categories.includes(cat)}
                                            onChange={(e) => {
                                            if (e.target.checked) {
                                                setCategories([ ...categories, cat]);
                                            } else {
                                                setCategories(categories.filter((category) => category !== cat));
                                            }
                                            }}
                                        />
                                    }
                                    label={cat}
                                />
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack spacing={0}>
                            {QUESTION_CATEGORIES_COL_2.map((cat) => (
                              <FormControlLabel
                                key={cat}
                                control={
                                  <Checkbox
                                    checked={categories.includes(cat)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setCategories([ ...categories, cat]);
                                      } else {
                                        setCategories(categories.filter((category) => category !== cat));
                                      }
                                    }}
                                  />
                                }
                                label={cat}
                              />
                            ))}
                            {categories.includes('Other') && (
                              <TextField
                                id="other-text"
                                variant="outlined"
                                placeholder="Add your category"
                                value={otherCategory}
                                onChange={(e) => setOtherCategory(e.target.value)}
                                inputProps={{ maxLength: 64 }}
                              />
                            )}  
                        </Stack>
                    </Grid>
                </Grid>
            </ModalFormItem>
        </Stack>
    );
}
