import { useNavigate } from 'react-router';

import { Box, Button, Grid, Typography } from '@mui/material';

import InviteClientImg from '../../assets/images/invite-client.png';

interface InviteClientProps {}

export default function InviteClient({}: InviteClientProps) {
    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Invite your first client</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Bring your clients onto Parentswarm to easily book and connect<Box display="inline" ml={-1}><Button size="large" onClick={() => navigate('/clients')}>here</Button></Box>
                            </li>
                            <li>
                                Watch the Training Module on inviting clients<Box display="inline" ml={-1}><Button size="large" onClick={() => window.open('https://www.veed.io/view/e71b18c6-ca0c-4108-a30a-c36a8390d3d0?panel=share', '_blank')}>here</Button></Box>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={InviteClientImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
