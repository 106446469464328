import styled from 'styled-components';

export const GradientContainer = styled.div`
    background: linear-gradient(180deg, rgba(151, 71, 255, 0.2) 0%, rgba(179, 214, 249, 0.2) 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 768px) {
      padding-top: 50px;
    }
`;

export const Heading = styled.h2`
  text-align: center;
`;

export const DetailsTextContainer = styled.div`
  text-align: center;
`;

export const LogoWrapper = styled.div`
  padding-bottom: 20px;
  margin-top: -40px;
  @media (max-width: 768px) {
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const Logo = styled.img`
  width: 60px;
`;
export const TotalPages = styled.span`
  color: #bcb6c1;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 40px 140px;
  margin: ${(props) => props.margin || `${0}px`};
  margin-bottom: 40px;
  background: #ffffff;
  border-radius: ${(props) => props.borderRadius || 0};
  @media (max-width: 768px) {
    width: calc(90% - 40px);
    margin: 0 5%;
    margin-bottom: 20px;
    padding: 20px;
  }
`;
