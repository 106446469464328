export const NextButtonSX = {
  fontFamily: 'Arial',
  display: 'inline-block',
  width: '8rem',
  minWidth: 'min-content',
  whiteSpace: 'nowrap',
  borderRadius: '1.5rem',
  borderStyle: 'none',
  background: '#5371ff',
  padding: '1rem',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  fontWeight: '700',
  letterSpacing: '.025em',
  color: 'white',
  cursor: 'pointer',
  '&:hover': {
    fontFamily: 'Arial',
    display: 'inline-block',
    width: '8rem',
    minWidth: 'min-content',
    whiteSpace: 'nowrap',
    borderRadius: '1.5rem',
    borderStyle: 'none',
    background: '#5371ff',
    padding: '1rem',
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: '700',
    letterSpacing: '.025em',
    color: 'white',
    cursor: 'pointer',
  },
};

export const PrevButtonSX = {
  fontFamily: 'Arial',
  display: 'inline-block',
  width: '8rem',
  minWidth: 'min-content',
  whiteSpace: 'nowrap',
  borderRadius: '1.5rem',
  borderStyle: 'none',
  background: '#ffe7e7',
  padding: '1rem',
  fontSize: '.875rem',
  lineHeight: '1.25rem',
  fontWeight: '700',
  letterSpacing: '.025em',
  color: '#5371ff',
  cursor: 'pointer',
  '&:hover': {
    fontFamily: 'Arial',
    display: 'inline-block',
    width: '8rem',
    minWidth: 'min-content',
    whiteSpace: 'nowrap',
    borderRadius: '1.5rem',
    borderStyle: 'none',
    background: '#ffe7e7',
    padding: '1rem',
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: '700',
    letterSpacing: '.025em',
    color: '#5371ff',
    cursor: 'pointer',
  },
};
