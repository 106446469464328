// npm imports
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

// local imports
import { ACCOUNT_TYPES } from "../../constants";

function RegisterForm({
  loginDisplay,
  handleSubmit,
  email,
  phoneNo,
  handleEmailChange,
  emailError,
  active,
  setLoginDisplay,
  password,
  handlePasswordChange,
  passwordError,
  openForgotPassword,
  code,
  setCode,
  codeError,
  accountType,
  setAccountType,
}) {
  const SITE_KEY = "6Lc4jEgpAAAAAOlQMWkOGfc57eFiY84I_SEcOtg3";
  const [verified, setVerified] = useState(false);

  const handleVerification = async (token) => {
    if (token.length > 0) {
      setVerified(true);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!loginDisplay && (
          <Box mt={2}>
            <FormControl sx={{ width: "80%" }}>
              <InputLabel id="client-label">
                Register as a{accountType === ACCOUNT_TYPES.EXPERT ? "n" : ""}
              </InputLabel>
              <Select
                id="client"
                value={accountType}
                onChange={(e) => setAccountType(e.target.value)}
                sx={{ width: "100%" }}
                label="Register as a"
              >
                <MenuItem value="parent">Parent</MenuItem>
                <MenuItem value="expert">Supplier</MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
        <Box mt={2}>
          <FormControl sx={{ width: "80%" }}>
            <TextField
              id="email"
              type="email"
              variant="outlined"
              value={email}
              placeholder="Email"
              onChange={(e) => handleEmailChange(e.target.value)}
              inputProps={{ maxLength: 256, sx: { width: "100%" } }}
              sx={{ width: "100%" }}
              error={emailError}
              helperText={emailError || null}
            />
          </FormControl>
        </Box>
        <Box mt={2}>
          <FormControl sx={{ width: "80%" }}>
            <TextField
              id="password"
              type="password"
              variant="outlined"
              value={password}
              placeholder={loginDisplay ? "Password" : "Choose a password"}
              onChange={(e) => handlePasswordChange(e.target.value)}
              inputProps={{ maxLength: 256, sx: { width: "100%" } }}
              sx={{ width: "100%" }}
              error={passwordError}
              helperText={passwordError || null}
            />
          </FormControl>
        </Box>
        {!loginDisplay && (
          <Box mt={2}>
            <FormControl sx={{ width: "80%" }}>
              <TextField
                id="code"
                type="text"
                variant="outlined"
                value={code}
                placeholder="Joining Code (Optional)"
                onChange={(e) => setCode(e.target.value)}
                inputProps={{ maxLength: 256, sx: { width: "100%" } }}
                sx={{ width: "100%" }}
                error={codeError}
                helperText={codeError || null}
              />
            </FormControl>
          </Box>
        )}
        {!loginDisplay && (
          <Box mt={2}>
            <Typography variant="caption">
              By continuing, you agree to our{" "}
              <a
                href={
                  accountType === ACCOUNT_TYPES.PARENT
                    ? "https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f7961c65433676bb1ee50_Parentswarm%20-%20Terms%20of%20Service%20and%20User%20Agreement%20(TC%205.6.24).pdf"
                    : "https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f796175c75899e368a6ba_Parentswarm%20-%20Terms%20of%20Service%20and%20Supplier%20Agreement%20(TC%205.6.24).pdf"
                }
                target="_blank"
                rel="noreferrer"
              >
                TERMS AND CONDITIONS
              </a>{" "}
              and{" "}
              <a
                href="https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f796163c081c0c26f19eb_Parentswarm%20-%20Privacy%20Policy%20-%205.8.24.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PRIVACY POLICY
              </a>
              .
            </Typography>
          </Box>
        )}
        {!loginDisplay && (
          <Box
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ReCAPTCHA
              sitekey={SITE_KEY}
              onChange={(response) => {
                handleVerification(response);
              }}
              onExpired={() => {
                alert(
                  "Your captcha session has expired. Please refresh the page."
                );
              }}
            />
          </Box>
        )}
        <Box mt={2}>
          <Button
            type="submit"
            disabled={!loginDisplay ? !active || !verified : !active} // enable button only when form data is valid (active) AND user has verified via recaptcha
            variant="contained"
            color="primary"
          >
            {loginDisplay ? "Login" : "Create Account"}
          </Button>
        </Box>
      </form>

      {!loginDisplay ? (
        <Box mt={4}>
          <Typography variant="caption">Already have an account?</Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setLoginDisplay(true)}
            >
              Login
            </Button>
          </Box>
        </Box>
      ) : (
        <Box mt={4}>
          <Typography variant="caption">New to Parentswarm?</Typography>
          <Box mt={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setLoginDisplay(false)}
            >
              Register
            </Button>
          </Box>
          <Box mt={4}>
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={openForgotPassword}
            >
              Reset my password
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}

RegisterForm.propTypes = {
  loginDisplay: PropTypes.bool,
  handleSubmit: PropTypes.func,
  email: PropTypes.string,
  phoneNo: PropTypes.string,
  handleEmailChange: PropTypes.func,
  emailError: PropTypes.string,
  phoneError: PropTypes.string,
  setLoginDisplay: PropTypes.func,
  active: PropTypes.bool,
  openForgotPassword: PropTypes.func,
};

export default RegisterForm;
