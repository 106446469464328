import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';
import {
    Alert,
    Box,
    ButtonGroup,
    Container,
    Modal,
    Grid,
    Stack,
    CircularProgress,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import {
    PageTitle,
    NoResults,
} from '../sessions/sessions.style';
import Button from '../../components/common/button';
import Chip from '../../components/common/chip';
import ResourceCard from './document-card';
import ResourceForm from './document-form';
import ShareForm from '../../components/share/share-form';
import { get, destroy } from '../../services/api.services';
import { API } from '../../constants';
import { uploadToS3 } from '../../utils/upload';
import { displayName } from '../../utils/profile';
import { fetchResources } from '../../clients/resource';
import { ResourceActionType } from '../../store/actions';

const VIEWS = {
    ME: 'me',
    COMMUNITY: 'community',
};

function Resources({ clientView = false }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const resourcesById = useSelector((state) => state.resources.resourcesById);
    const resourceIds = useSelector((state) => state.resources.resourceIds);

    const [folder, setFolder] = useState();
    const [shares, setShares] = useState([]);
    const [sharesByResourceId, setSharesByResourceId] = useState([]);
    const [selectedResourceIds, setSelectedResourceIds] = useState([]);
    const [openResourceForm, setOpenResourceForm] = useState(false);
    const [openShareForm, setOpenShareForm] = useState(false);
    const [editResource, setEditResource] = useState(null);
    const [selectMode, setSelectMode] = useState(clientView);
    const [fileUploading, setFileUploading] = useState(false);
    const [fileKey, setFileKey] = useState('');
    const [fileKeyError, setFileKeyError] = useState();
    const [view, setView] = useState(queryParams.get('view') || VIEWS.ME);

    const toggleResourceSelect = (id) => {
        const selectionIndex = selectedResourceIds.indexOf(id);

        if (selectionIndex !== -1) {
            setSelectedResourceIds(
                [...selectedResourceIds.slice(0, selectionIndex), ...selectedResourceIds.slice(selectionIndex + 1)]
            );
        } else {
            setSelectedResourceIds([ ...selectedResourceIds, id ]);
        }
    };

    const closeResourceForm = () => {
        setOpenResourceForm(false);
        setEditResource(null);
        setFileKey(undefined);
        setFileKeyError(undefined);
    };

    const fetchFolder = async () => {
        const result = await get(
            `${API.FOLDER}${params.folder_id}`,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate(`/login/${user.profile.profile_type}`);
        }
        else if (result.status === 200) {
            setFolder(result.data);
        }
        else {
            // TODO: Handle errors
        }
    };

    const fetchShares = async () => {
        const result = await get(
            `${API.SHARE}?folder_id=${params.folder_id}&share_type=resource`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setShares(result.data);

            const fetchedSharesByResourceId = {};

            result.data.forEach((share) => {
                fetchedSharesByResourceId[share.resource.id] = share;
            });

            setSharesByResourceId(fetchedSharesByResourceId);
        }
        else {
        }
    }

    const onNewFile = async (acceptedFiles) => {
        setFileUploading(true);

        for (let i = 0; i < acceptedFiles.length; i++) {
          const path = `resources/${user.profile.id}/${moment().unix()}-${acceptedFiles[i].path}`;
          const successfulUpload = await uploadToS3(
              token,
              path,
              acceptedFiles[i],
          );
    
          if (successfulUpload) {
            setFileKey(path);
            setOpenResourceForm(true);
          }
          else {
            setFileKeyError(true);
            setOpenResourceForm(true);
          }

          setFileUploading(false);
        }
    };

    const onOpenResource = (resource) => {
        if (resource.uploaded_by === user.profile.id) {
            setEditResource(resource);
            setOpenResourceForm(true);
        } else {
            window.open(resource.file_url, '_blank');
        }
    }

    const reloadResources = async () => {
        await fetchResources(dispatcher, navigate, token, view === VIEWS.COMMUNITY)
    }

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': [],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
          'video/mp4': [],
        },
        maxFiles: 1,
        maxSize: 5 * 1000 * 1000 * 1000,
        onDrop: onNewFile,
    });

    useEffect(() => {
        setFolder(undefined);

        if (params.folder_id) {
            fetchFolder();
            fetchShares();
        }
    }, []);

    useEffect(() => {
        setFolder(undefined);
    }, [clientView]);

    useEffect(() => {
        reloadResources();
    }, [view])

    const dropzoneStyle = {
        fontFamily: 'Cabin',
        background: '#FFFFFF',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 8,
    };

    const resourceList = (
        <>
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <PageTitle>
                    Resources
                </PageTitle>
                {(!folder || folder && folder.owner.id === user.profile.id) ? (
                    <Box display="flex" flexDirection="row">
                        {!selectMode ? (
                            <>
                                {folder && (
                                    <Box mr={2}>
                                        <Button shade="secondaryLight" onClick={() => {
                                            navigate(`/clients/${folder.id}`);
                                        }}>
                                            Back
                                        </Button>
                                    </Box>
                                )}
                                {!folder && (
                                    <Button shade="secondary" onClick={() => setSelectMode(true)}>
                                        Select and Share
                                    </Button>
                                )}
                            </>
                        ) : (
                            <>
                                {!folder && (
                                    <Box mr={2}>
                                        <Button shade="secondaryLight" onClick={() => {
                                            setSelectMode(false)
                                            setSelectedResourceIds([]);
                                        }}>
                                            Cancel
                                        </Button>
                                    </Box>
                                )}
                                <Button
                                    shade="secondary"
                                    onClick={() => setOpenShareForm(true)}
                                    disabled={selectedResourceIds.length === 0}
                                >
                                    Share
                                </Button>
                            </>
                        )}
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="row">
                        <Button shade="secondaryLight" onClick={() => {
                            navigate(`/clients/${folder.id}`);
                        }}>
                            Back
                        </Button>
                    </Box>
                )}
            </Box>
            <Stack display="flex" justifyContent="left" spacing={2} direction="row" mb={2}>
                <Button
                    shade={view === VIEWS.ME ? 'secondary' : 'secondaryLight'}
                    onClick={() => setView(VIEWS.ME)}
                >
                    My Resources
                </Button>
                <Button
                    shade={view === VIEWS.COMMUNITY ? 'secondary' : 'secondaryLight'}
                    onClick={() => setView(VIEWS.COMMUNITY)}
                >
                    Nessle Resources
                </Button>
            </Stack>
            {folder && folder.owner.id === user.profile.id && (
                <Box mb={view === VIEWS.COMMUNITY ? 2 : 0}>
                    <Chip
                        color="secondaryLight"
                        label={`Client: ${displayName(folder.members[0].first_name, folder.members[0].last_name)}`}
                        onDelete={() => {
                            setFolder(null);
                            setShares([]);
                            setSharesByResourceId({});
                            navigate('/resources');
                        }}
                    />
                </Box>
            )}
            {(!folder || folder && folder.owner.id === user.profile.id) && view === VIEWS.ME && (
                <Box mt={2} mb={2}>
                    <div {...getRootProps({ style: dropzoneStyle })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <Box mt={2} mb={2}>
                                <CloudUploadOutlinedIcon sx={{ fontSize: '48px' }}/>
                                <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: '20px' }}>
                                    Drop attachments here
                                </Box>
                                <Box mt={1}>Supported file types: PNG, JPG, PDF, DOCX, MP4 up to 5GB</Box>
                            </Box>
                        ) : (
                            <>
                                {(!fileUploading || fileRejections.length > 0) ? (
                                    <Box mt={2} mb={2}>
                                        {fileRejections.length > 0 && (
                                            <Alert color="error">We were unable to process this file, please confirm that it is a compatible file type and size.</Alert>
                                        )}
                                        <CloudUploadOutlinedIcon sx={{ fontSize: '48px' }}/>
                                        <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: '20px' }}>
                                            Add new resource
                                        </Box>
                                        <Box mt={1}>Supported file types: PNG, JPG, PDF, DOCX, MP4 up to 5GB</Box>
                                    </Box>
                                ) : (
                                    <Box mt={2} mb={2}>
                                        <CircularProgress />
                                        <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600, fontSize: '20px' }}>
                                            Uploading...
                                        </Box>
                                        <Box mt={1}> </Box>
                                    </Box>
                                )}
                            </>
                        )}
                    </div>
                </Box>
            )}
            {(!folder || selectMode) ? (
                <Grid container spacing={2}>
                    {resourceIds.map((id) => (
                        <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                            <ResourceCard
                                key={id}
                                resource={resourcesById[id]}
                                selectMode={selectMode}
                                selected={selectedResourceIds.indexOf(id) !== -1}
                                onSelect={() => toggleResourceSelect(id)}
                                onOpen={() => onOpenResource(resourcesById[id])}
                                share={sharesByResourceId[id]}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    {shares.length === 0 && folder.owner.id !== user.profile.id && (
                        <Grid item xs={12}>
                            <NoResults>
                                {`${displayName(folder.owner.first_name, folder.owner.last_name)} hasn't shared any resources yet`}
                            </NoResults>
                        </Grid>
                    )}
                    {shares.map((share) => (
                        <Grid key={share.id} item xs={12} sm={6} md={4} lg={3}>
                            <ResourceCard
                                key={share.resource.id}
                                resource={share.resource}
                                selectMode={selectMode}
                                selected={selectedResourceIds.indexOf(share.resource.id) !== -1}
                                onSelect={() => toggleResourceSelect(share.resource.id)}
                                onOpen={() => onOpenResource(share.resource)}
                                share={share}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            <Modal open={openResourceForm} onClose={closeResourceForm}>
                <ResourceForm
                    resource={editResource}
                    onClose={closeResourceForm}
                    onSuccess={(resource) => {
                        if (resourceIds.indexOf(resource.id) === -1) {
                            dispatcher({
                                type: ResourceActionType.ADD_RESOURCE_ID,
                                payload: {
                                    resource,
                                },
                            });
                        }

                        dispatcher({
                            type: ResourceActionType.SET_RESOURCE,
                            payload: {
                                resource,
                            },
                        });

                        if (folder) {
                            setSelectMode(true);
                            setSelectedResourceIds([ ...selectedResourceIds, resource.id]);
                            setOpenShareForm(true);
                        }
                    }}
                    startFileKey={fileKey}
                    startFileKeyError={fileKeyError}
                    folder={folder}
                />
            </Modal>
            <Modal open={openShareForm} onClose={() => setOpenShareForm(false)}>
                <ShareForm
                    folder={folder}
                    resources={selectedResourceIds.map((id) => resourcesById[id])}
                    onClose={() => {
                        setOpenShareForm(false);

                        if (!folder) {
                            setSelectMode(false);
                        }

                        setSelectedResourceIds([]);

                        if (params.folder_id) {
                            fetchShares();
                        }
                    }}
                />
            </Modal>
        </>
    );

    return (
        <Container maxWidth="lg">
            {resourceList}
        </Container>
    );
}

export default Resources;