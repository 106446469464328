import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';

import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { State } from '../../types';
import TrainingDocumentationImg from '../../assets/images/training-documentation.png';

interface TrainingDocumentationProps {}

export default function TrainingDocumentation({}: TrainingDocumentationProps) {
    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Submit Documentation of Your Training</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                All Parentswarm Experts are required to have completed training in their roles, go<Box display="inline" ml={-1} mr={-1}><Button size="large" onClick={() => navigate('/more/documentation')}>here</Button></Box>to submit documentation of{' '}
                                or to write a paragraph describing your training(s) or apprenticeships
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={TrainingDocumentationImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
