import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Modal } from '@mui/material';
import AddAlertIcon from '@mui/icons-material/AddAlert';

import Button from "../common/button";
import AddOrEditEvent from "./add-or-edit-event";
import { SET_PAYWALL } from "../../store/actions";
import { State } from "../../types";
import { PaywallTypes } from "../../constants";

export default function AddEventButton({ folderId }: { folderId: string }) {
  const dispatcher = useDispatch();

  const featureFlags = useSelector((state: State) => state.account.user.profile.feature_flags);

  const [isOpen, setOpen] = useState(false);

  const openPaywall = () => {
    dispatcher({
      type: SET_PAYWALL,
      payload: {
        paywall: true,
        paywall_type: PaywallTypes.BASE, 
      },
    });
  };

  const clickAddEvent = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (featureFlags.workflows) {
      setOpen(true);
    } else {
      openPaywall();
    }
  }, []);

  const closeEventModal = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <React.Fragment>
      <React.Fragment>
        <Button shade="secondary" onClick={clickAddEvent}>
          <AddAlertIcon /> <Box sx={{ display: { xs: 'none', lg: 'inline' }}}>Add Important Date</Box>
        </Button>
        <Modal open={isOpen} onClose={closeEventModal}>
          <Box onClick={(e: React.SyntheticEvent) => e.stopPropagation()}>
            <AddOrEditEvent onClose={() => setOpen(false)} folderId={folderId} />
          </Box>
        </Modal>
      </React.Fragment>
    </React.Fragment>
  );
}
