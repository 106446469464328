const development = {
  currentEnv: 'development',
  API_BASE: 'http://localhost:8000/api',
  FE_BASE: 'http://localhost:3000',
};

const stage = {
  currentEnv: 'stage',
  API_BASE: 'https://api.dev.nessle.com/api',
  FE_BASE: 'https://staging.nessle.com',
};

const production = {
  currentEnv: 'production',
  API_BASE: 'https://api.prod.nessle.com/api',
  FE_BASE: 'https://app.nessle.com',
};

let config = development;

switch (process.env.REACT_APP_ENV) {
  case 'stage': {
    config = stage;
    break;
  }
  case 'production': {
    config = production;
    break;
  }
  default: {
    config = development;
    break;
  }
}

export default {
  ...config,
};
