import { useNavigate } from 'react-router';

import { Box, Button, Grid, Typography } from '@mui/material';

import BuildOneonOneImg from '../../assets/images/build-1-1.png';

interface AddSingleProductProps {}

export default function AddSingleProduct({}: AddSingleProductProps) {
    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Add Your First 1-on-1 Session</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Add your first 1-on-1 session for sale in your storefront<Box display="inline" ml={-1}><Button size="large" onClick={() => navigate('/storefront')}>here</Button></Box>
                            </li>
                            <li>
                                Watch the Training Module on adding 1-on-1 sessions<Box display="inline" ml={-1}><Button size="large" onClick={() => window.open('https://www.veed.io/view/fca9e086-592e-4c9c-870c-346b8f549348?panel=share', '_blank')}>here</Button></Box>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={BuildOneonOneImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
