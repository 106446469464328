import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import ExpertDashboard from './expert';
import ParentDashboard from './parent';

import { ACCOUNT_TYPES, API } from '../../constants';
import { patch } from '../../services/api.services';

function Dashboard() {
    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const timezone = moment.tz.guess();

    const updateTimezone = async () => {
        const result = await patch(
            `${API.PROFILE}${user.profile.id}/`,
            {
              timezone,
            },
            token,
        );
    };

    useEffect(() => {
        updateTimezone();
    }, []);

    if (user.profile.profile_type === ACCOUNT_TYPES.EXPERT) {
        return <ExpertDashboard />;
    }

    return <ParentDashboard />;
}

export default Dashboard;