import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Box, TextField } from '@mui/material';

import { SET_SESSION } from '../../store/actions';
import { API } from '../../constants';
import { patch } from '../../services/api.services';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import Button from '../common/button';
import { Card, LinkModal } from './Session.style';

function AddMeetingLink({ session, onDismiss }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    
    const [link, setLink] = useState(session.meeting_link);

    const getValidUrl = (url = "") => {
        let newUrl = window.decodeURIComponent(url);
        newUrl = newUrl.trim().replace(/\s/g, "");
    
        if (/^(:\/\/)/.test(newUrl)) {
            return `http${newUrl}`;
        }
        if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
            return `http://${newUrl}`;
        }
    
        return newUrl;
    };

    const saveMeetingLink = async () => {
        const result = await patch(
            `${API.SESSION}${session.id}/`,
            {
              meeting_link: getValidUrl(link),
            },
            token,
        );
    
        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            dispatcher({
                type: SET_SESSION,
                payload: {
                    session: result.data,
                },
            });
            onDismiss();
        }
        else {
          // TODO: Error states
        }
      }

    return (
        <LinkModal>
            <Card>
                <Box sx={{ position: 'absolute', right: 10, top: 10 }} onClick={onDismiss}>
                    <img src={DismissIcon} width={15} alt="dismiss" style={{ cursor: 'pointer' }} />
                </Box>
                <Box sx={{ mt: 1, mb: 1 }}>Add Meeting Link</Box>
                {session.session_date && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                        Your 1-on-1 session is on {moment(session.session_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}
                    </Box>   
                )}
                <Box sx={{ mt: 2, mb: 3 }}>
                    <TextField
                        id="link"
                        variant="outlined"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        placeholder="Paste meeting link here"
                        sx={{ width: '100%' }}
                    />
                </Box>
                <Button shade="secondary" onClick={saveMeetingLink}>Add Link</Button>
            </Card>
        </LinkModal>
    );
}

AddMeetingLink.propTypes = {
  session: PropTypes.any,
  onDismiss: PropTypes.func,
};

export default AddMeetingLink;