import React from 'react';
import  { useSelector } from 'react-redux';
import moment from 'moment';

import { Box } from '@mui/material';

import Attachment from '../attachment';
import { NoteBody, NoteDate, NoteTitle } from './note.style';

function Note({ note }) {
    const sessionsById = useSelector((state) => state.sessions.sessionsById);

    let relatedSession;

    if (note.session_id) {
        relatedSession = sessionsById[note.session_id];
    }

    return (
        <Box mb={1} sx={{ background: '#FFFFFF', border: '1px solid #e8e8e8', borderRadius: 2, padding: 2 }}>
            <NoteDate>
                {relatedSession ? `Session ${relatedSession.title} - ` : ''}
                {moment(note.created_at).format('MMM D, YYYY')}
            </NoteDate>
            <NoteTitle className='fs-exclude'>{note.title}</NoteTitle>
            <NoteBody className='fs-exclude'>{note.body}</NoteBody>
            {note.attachments.map((attachment) => (
                <Attachment attachment={attachment} className='fs-exclude' />
            ))}
        </Box>
    );
}

export default Note;
