import {
    Container,
    Grid,
    Paper,
    Typography,
} from '@mui/material';

import Partner from './partner';
import CarryLogo from '../../assets/images/partners/carry.jpg';
import BroodLogo from '../../assets/images/partners/brood.png';
import CofertilityLogo from '../../assets/images/partners/cofertility.png';
import DailiesPodsLogo from '../../assets/images/partners/dailiespods.png';
import FrameLogo from '../../assets/images/partners/frame.png';
import HeartfulSproutLogo from '../../assets/images/partners/heartfulsprout.png';
import MarmaLogo from '../../assets/images/partners/marma.png';
import MilkifyLogo from '../../assets/images/partners/milkify.png';
import MosieBabyLogo from '../../assets/images/partners/mosiebaby.png';
import PlusUpLogo from '../../assets/images/partners/plusup.jpg';
import ProsperaLogo from '../../assets/images/partners/prospera.png';
import PurrbookLogo from '../../assets/images/partners/purrbook.png';
import ScribbleLogo from '../../assets/images/partners/scribble.png';
import SunfishLogo from '../../assets/images/partners/sunfish.png';

function Partners() {
    return (
        <Container maxWidth="lg">
            <Grid container spacing={2} mt={4}>
                <Grid item xs={12}>
                    <Typography variant="h5">Trusted Partners</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Carry"
                            description={`Carry offers prenatal and postpartum yoga and meditation${' '}
                                for all on this journey. Via a simple and beautiful app.`}
                            url="https://www.thecarryapp.com"
                            tags={[
                                'Movement and Fitness',
                                'Labor and Birth',
                                'Prenatal Care',
                                'Mental Wellness / Life Design',
                            ]}
                            discountDetails='After downloading the app, scroll down on the pricing screen, tap "Redeem Code" and enter "Carry3Nessle" for 3 days to look around the app for free. After 3 days, you STILL have a free one week trial on the App Store when you choose a subscription! Great for pregnancy and post, whether you have yoga or meditation experience or not.'
                            logo={CarryLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Marma"
                            description={`Marma is a mobile app platform offering personalized nutritional${' '}
                            guidance and support throughout fertility, pregnancy, and postpartum. We empower${' '}
                            mothers and birthing individuals with evidence-based resources, direct access to nutrition${' '}
                            experts, and a vibrant community to enhance their maternal health and wellness.`}
                            url="https://marma.health"
                            tags={[
                                'Fertility / Conception / Adoption',
                                'Labor and Birth',
                                'Prenatal Care',
                                'Nutrition',
                                'Lactation and Feeding',
                                'Mental Wellness / Life Design',
                            ]}
                            discountDetails='Get a free month of Marma Premium with code: NESSLE'
                            logo={MarmaLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Milkify"
                            description={`What if you had the benefits of breast milk with the convenience of${' '}
                                formula? Milkify turns your breast milk into a shelf stable powder that lasts${' '}
                                for 3 years on the shelf. Our patented process retains the nutrients without ever${' '}
                                thawing or touching your milk. Since 2018 we've helped thousands of moms store, use,${' '}
                                and transport their stash in a better way.`}
                            url="https://milkify.me"
                            tags={[
                                'Lactation and Feeding',
                            ]}
                            discountDetails='10% off any order with the code NESSLE'
                            logo={MilkifyLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Frame"
                            description={`Frame offers personalized fertility care and coaching to empower you${' '}
                                on your family building journey—whether you’re planning to get pregnant, actively${' '}
                                trying, or in treatment.`}
                            url="https://bit.ly/47Jr4tS"
                            tags={[
                                'Fertility / Conception / Adoption',
                            ]}
                            discountDetails='Code: NESSLE15 is valid for 15% off any Frame plan (one-time use)'
                            logo={FrameLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Family by Co"
                            description={`Cofertility is a matching platform for individuals and couples${' '}
                                looking to grow their families through egg donation. Instead of cash compensation,${' '}
                                all Cofertility donors keep half of the eggs retrieved in their cycle while donating${' '}
                                half to intended parents.`}
                            url="https://www.cofertility.com/family"
                            tags={[
                                'Fertility / Conception / Adoption',
                            ]}
                            discountDetails='After creating a free Family by Co account, Nessle members are eligible for a free 1-1 consultation with a founding member of the Cofertility team.'
                            logo={CofertilityLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="DailiesPods"
                            description={`We provide online small-group interactive classes with certified${' '}
                                teachers for children ages 3-12. We offer academic and enrichment class${' '}
                                offerings, along with seasonal camps and 1:1 tutoring.`}
                            url="https://dailiespods.com"
                            tags={[
                                'Child Development and Behavior',
                            ]}
                            discountDetails='To claim your free preview class, just visit us at https://dailiespods.com and click "Join for Free"'
                            logo={DailiesPodsLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Goodtimer"
                            description={`For modern parents of young children who embrace positive reinforcement but are still struggling with challenging behavior like temper tantrums, we created Goodtimer, an positive reinforcement platform with a built-in allowance that helps kids learn healthy habits so that the whole can feel more peaceful and connected.`}
                            url="www.gogoodtimer.com"
                            tags={[
                                'Nutrition',
                                'Movement and Fitness',
                                'Sleep',
                                'Child Development and Behavior',
                                'Mental Wellness / Life Design',
                                'Family and Relationships',
                            ]}
                            discountDetails='Parents can save an extra 10% off their Goodtimer purchase using promo code WELCOME10 at checkout'
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Happypillar"
                            description={`Happypillar, a proven way to improve your child's mental health. 5 minutes a day is all it takes for our therapeutic tool to make a huge impact. Rooted in science and easy to use! Backed by psychologists, pediatricians, and licensed therapists, fully customized for your goals and personalized for your child.`}
                            url="https://www.happypillar.com"
                            tags={[
                                'Child Development and Behavior',
                            ]}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Kidiosity"
                            description="Kidiosity makes it easy for today's parents to engage, inspire, and spend quality time with their growing children. We offer daily conversation starters, parenting inspiration, and unique activities to enjoy with those you love most."
                            url="www.kidiosity.com"
                            tags={[
                                'Child Development and Behavior',
                                'Family and Relationships',
                                'Mental Wellness / Life Design',
                            ]}
                            discountDetails="Sign up for Kidiosity using the Nessle link and save 63%. Only $2.99 per month, or $29.99 per year if paid annually. Link: https://app.kidiosity.com/a/nessle"
                            discountLink="https://app.kidiosity.com/a/nessle"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Scribble"
                            description="Scribble makes it easy for parents to transform kids' art into lasting keepsakes. No more piles of drawings or crumpled masterpieces. Just beautiful, professionally printed books that capture and inspire childhood creativity."
                            url="https://scribble.art"
                            tags={[
                                'Child Development and Behavior',
                                'Family and Relationships',
                            ]}
                            logo={ScribbleLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Pajama Cats Media"
                            description="Pajama Cats Media transforms children's books by adding AI-powered reading co-pilot to develop early language skills."
                            url="https://www.pajamacatsmedia.com"
                            tags={[
                                'Child Development and Behavior',
                            ]}
                            discountDetails='Promo code "FRIEND15" for 15% off on all books. After registering in the mobile app or website, login to our books store "purrbook.app" input this code when checking out'
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Kahlmi"
                            description="We are the 1st baby massage brand devoted to increasing awareness and education about the science-backed benefits of baby massage."
                            url="Kahlmi.com"
                            tags={[
                                'Labor and Birth',
                                'Movement and Fitness',
                                'Sleep',
                                'Child Development and Behavior',
                                'Mental Wellness / Life Design',
                                'Family and Relationships',
                            ]}
                            discountDetails="15% off. Use code Nessle15"
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Sunfish"
                            description="Get financial support and options for IVF, surrogacy, and egg donation."
                            url="https://joinsunfish.com/"
                            tags={[
                                'Fertility / Conception / Adoption',
                            ]}
                            discountDetails="A complementary call with a Sunfish financial advocate to make a custom financial plan for your fertility journey. Visit www.joinsunfish.com/nessle"
                            discountLink="https://www.joinsunfish.com/nessle"
                            logo={SunfishLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Brood"
                            description="Brood is a next-generation health and education company, a community of in-home care workers, doulas, and parents, redefining the meaning of family care. We do this by providing inclusive, accessible education and services to families that support unique reproductive and life experiences."
                            url="https://broodcare.com/"
                            tags={[
                                'Fertility / Conception / Adoption',
                                'Prenatal Care',
                                'Labor and Birth',
                                'Postpartum',
                                'Lactation and Feeding',
                                'Sleep',
                                'Child Development and Behavior',
                                'Family and Relationships',
                            ]}
                            discountDetails='50% off our Family Membership. Click ""Buy Now"" on this page https://broodcare.com/our-family-membership/ and enter code NESSLE50'
                            discountLink="https://broodcare.com/our-family-membership/"
                            logo={BroodLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Mosie Baby"
                            description="The Mosie Baby Kit for at-home insemination is the first accessible home insemination product of its kind and has helped over 100,000 people on their path to pregnancy inseminate privately on their own."
                            url="https://mosiebaby.com/"
                            tags={[
                                'Fertility / Conception / Adoption',
                            ]}
                            discountDetails="Get 15% off when they sign up for their newsletter: https://mosiebaby.com/pages/subscribe"
                            logo={MosieBabyLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Prospera"
                            description="We provide mental health support for moms through weekly one-on-one mental health coaching sessions and our digital tools library. We help with depression, anxiety, postpartum rage, intrusive thoughts, birth trauma and more."
                            url="https://prosperamhw.com"
                            tags={[
                                'Fertility / Conception / Adoption',
                                'Prenatal Care',
                                'Labor and Birth',
                                'Postpartum',
                                'Nutrition',
                                'Lactation and Feeding',
                                'Sleep',
                                'Child Development and Behavior',
                                'Mental Wellness / Life Design',
                                'Family and Relationships',
                            ]}
                            logo={ProsperaLogo}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper elevation={0}>
                        <Partner
                            name="Heartful Sprout"
                            description="We provide nutrition and meal planning for babies."
                            url="https://www.heartfulsprout.com"
                            tags={[
                                'Postpartum',
                                'Nutrition',
                            ]}
                            discountDetails="Free subscription of premium features, which include personalized meal and nutrition planning	Simply join us during our beta period!"
                            logo={HeartfulSproutLogo}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Partners;