import { Box, Button, Card, Typography } from '@mui/material';

interface ConfirmModalProps {
    title: string;
    subtitle?: string;
    cta: string;
    ctaAction: any;
    cancelText?: string;
    cancelAction: any;
}

export default function ConfirmModal({ title, subtitle, cta, ctaAction, cancelText, cancelAction }: ConfirmModalProps) {
  return (
    <Box
        marginTop="10vh"
        marginLeft="auto"
        marginRight="auto"
        width="300px"
        maxWidth="90vw"
        maxHeight="80vh"
        display="flex"
        justifyContent="center"
    >
        <Card elevation={0} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="caption">{subtitle}</Typography>
            <Box mt={2} display="flex" justifyContent="center">
                <Button variant="contained" color="primary" onClick={ctaAction}>{cta}</Button>
                <Button variant="text" onClick={cancelAction}>{cancelText || 'Cancel'}</Button>
            </Box>
        </Card>
    </Box>
  );
};