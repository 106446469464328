import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Avatar, Box, Snackbar } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

import config from '../../config';
import Button from '../common/button';
import OneOnOneImg from '../../assets/images/OneOnOne.png'
import {
    SuccessModal,
    ProductTitle,
    ProductValue,
} from './product.style';
import { getPhotoSrcFromKey } from '../../utils/photo-bank';

export default function Success({
    product,
    isEditing = false,
    onDismiss,
}) {
    const [copied, setCopied] = useState(false);

    return (
        <SuccessModal>
            <Avatar
                src={product.image_url || OneOnOneImg}
                sx={{ width: '100%', height: 200, borderRadius: 4 }}
                variant="square"
            />
            <Box sx={{ mt: 1, mb: 1 }}>
                <ProductTitle>Your service was {isEditing ? 'edited' : 'added'} successfully!</ProductTitle>
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
                {product.title} - ${product.price.toLocaleString()}
            </Box>
            {(product.general_access.indexOf('public') !== -1 || product.general_access.indexOf('link') !== -1) && (
                <>
                    <CopyToClipboard
                        text={`${config.FE_BASE}/experts/${product.seller.display_id}/${product.id}`}
                        onCopy={() => setCopied(true)}
                    >
                        <Button shade="secondary" onClick={() => {}}>
                            <Box display="flex" alignItems="center" justifyContent="center">
                                <LinkIcon />
                                <Box ml={1}>Copy Share Link</Box>
                            </Box>
                        </Button>
                    </CopyToClipboard>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        open={copied}
                        autoHideDuration={4000}
                        onClose={() => setCopied(false)}
                        message="Share link has been copied to your clipboard"
                    />
                </>
            )}
            <Box sx={{ mt: 1}}>
                <Button shade="secondaryLight" onClick={onDismiss}>Back to My Services</Button>
            </Box>
        </SuccessModal>
    );
}
