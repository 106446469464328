import React from 'react';
import PropTypes from 'prop-types';
import { Button as MUIButton } from '@mui/material';

interface ButtonProps extends React.ComponentProps<typeof MUIButton> {
  shade?: string;
}

function Button(props: ButtonProps) {
  const colorMap = {
    primary: {
      background: '#5371ff',
      color: '#FFF',
    },
    primaryLight: {
      background: '#FFEEFF',
      color: '#5371ff',
    },
    primaryInverted: {
      background: '#FFFFFF',
      color: '#5371ff',
      border: '1px solid #5371ff',
    },
    secondary: {
      background: '#5371ff',
      color: '#FFF',
    },
    secondaryLight: {
      background: '#FFE7E7',
      color: '#5371ff',
    },
    secondaryOutline: {
      color: '#000000',
    },
    gray: {
      background: '#D9D9D9',
      color: '#000000',
    },
    black: {
      background: '#210B2B',
      color: '#FFFFFF',
    },
  };

  const variantMap = {
    text: {
      background: 'none',
      color: colorMap[props.shade || 'primary'].background,
    },
  };

  const sizeMap = {
    xsmall: {
      fontSize: '13px',
      lineHeight: '80%',
      height: 30,
    },
    small: {
      fontSize: '13px',
      lineHeight: '150%',
    },
    normal: {
      fontSize: '14px',
      lineHeight: '200%',
      paddingLeft: 30,
      paddingRight: 30,
    },
    large: {
      fontSize: '15px',
      lineHeight: '250%',
      paddingLeft: 30,
      paddingRight: 30,
    },
  };

  const style = {
    borderRadius: 8,
    border: 'none',
    fontFamily: 'Cabin',
    fontWeight: 700,
    textTransform: 'none',
    ...colorMap[props.shade || 'primary'],
    ...sizeMap[props.size || 'normal'],
    ...variantMap[props.variant || 'outlined'],
    opacity: props.disabled ? '0.7' : '1.0',
  };

  return (
    <MUIButton variant={props.variant || 'outlined'} style={style} {...props} />
  );
}

Button.propTypes = {
  shade: PropTypes.string,
};

export default Button;
