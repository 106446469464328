import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Box, Grid, TextField } from '@mui/material';

import { ACCOUNT_INITIALIZE } from '../../store/actions';
import config from '../../config';

import Button from '../common/button';
import { post } from '../../services/api.services';
import { ACCOUNT_TYPES, API } from '../../constants';
import RegisterForm from '../../components/register/register-form';
import ForgotPasswordSuccess from '../../components/register/forgot-password-success';
import ForgotPasswordForm from '../../components/register/forgot-password-form';
import {
    ScheduleTitle,
} from '../product/product.style';
import { SubPrompt, ErrorMessage } from '../../pages/login/Login.style';

function ParentRegistration({ onBack }) {
  const dispatcher = useDispatch();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pathParams = useParams();

  const matchAnswers = useSelector((state) => state.match);
  const coupon = useSelector((state) => state.coupon);

  const [code, setCode] = useState(pathParams.code?.toUpperCase() || coupon.code);
  const [codeError, setCodeError] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [email, setEmail] = useState(matchAnswers.email || '');
  const [password, setPassword] = useState('');
  const [active, setActive] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginDisplay, setLoginDisplay] = useState(params.get('login') ? true : false);
  const [showRegisterForm, setShowRegisterForm] = useState(true);

  const handleNameChange = (value) => {
    setNameError(false);
    setName(value);

    if (!name.length) {
        setNameError('Name is required');
    }
  };

  const handleEmailChange = (value) => {
    setPasswordError(false);
    setEmail(value);
    // eslint-disable-next-line no-useless-escape
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      setEmailError(false);

      if (password.length && !passwordError) {
        setActive(true);
      }
    } else {
      setEmailError('Email entered is invalid');
      setActive(false);
    }
  };

  const handlePasswordChange = (value) => {
    setEmailError(false);
    setPassword(value);

    if (!value.length) {
      setPasswordError('Password is required');
      setActive(false)
    } else if (email.length && !emailError) {
      setPasswordError(false);
      setActive(true);
    }
  }

  const openForgotPassword = () => {
    window.open(`${config.FE_BASE}/login/parent?forgot=1`, '_blank');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const nameSections = name.split(' ');
    const firstName = nameSections.length ? nameSections[0] : null;
    const lastName = nameSections.length > 1 ? nameSections[1] : null;

    const payload = {
      username: email,
      email,
      password,
      profile_type: ACCOUNT_TYPES.PARENT,
      matched_product: matchAnswers.match?.productId,
      coupon: code,
      first_name: firstName,
      last_name: lastName,
      lite_registration: true,
    };
    
    let result;

    if (loginDisplay) {
      result = await post(API.LOGIN, payload);
      if (result.status === 200) {
        dispatcher({
          type: ACCOUNT_INITIALIZE,
          payload: {
            isLoggedIn: true,
            isVerified: true,
            user: {
              accountType: ACCOUNT_TYPES.PARENT,
              ...result.data.user,
            },
            token: result.data.token,
            refresh: result.data.refresh,
          },
        });
      }
      else {
        if (result.data.email) {
          setEmailError(result.data.email[0]);
        }
        else if (result.data.username) {
          setEmailError(result.data.username[0].replace('username', 'email'));
        }

        if (result.data.password) {
          setPasswordError(result.data.password[0]);
        }

        if (result.data.detail) {
          setPasswordError(result.data.detail);
        }

        setActive(false);
      }
    } else {
      result = await post(API.REGISTER, payload);
    
      if (result.status === 201) {
        dispatcher({
          type: ACCOUNT_INITIALIZE,
          payload: {
            isLoggedIn: true,
            isVerified: false,
            user: {
              accountType: ACCOUNT_TYPES.PARENT,
              ...result.data.user,
            },
            token: result.data.token,
            refresh: result.data.refresh,
          },
        });
      }
      else {
        if (result.data.email) {
          setEmailError(result.data.email[0]);
        }
        else if (result.data.username) {
          setEmailError(result.data.username[0].replace('username', 'email'));
        }

        if (result.data.password) {
          setPasswordError(result.data.password[0]);
        }

        if (result.data.code) {
          setCodeError(result.data.code);
        }

        setActive(false);
      }
    }
  };

  return (
        <>
            {showRegisterForm && (
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} width={loginDisplay ? 300 : '80%'} margin="0 auto">
                        <Grid item xs={12}>
                            <Box textAlign="center">
                                <ScheduleTitle>
                                    {loginDisplay ? 'Login' : 'Share a few details, and let\'s book!'}
                                </ScheduleTitle>
                            </Box>
                        </Grid>
                        {!loginDisplay && (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="name"
                                    type="name"
                                    variant="outlined"
                                    value={name}
                                    placeholder="Name"
                                    onChange={(e) => handleNameChange(e.target.value)}
                                    inputProps={{ maxLength: 256, sx: { fontFamily: 'Cabin' } }}
                                    sx={{ width: '100%' }}
                                />
                                {nameError && (
                                    <Box mt={1}>
                                        <ErrorMessage>{nameError}</ErrorMessage>
                                    </Box>
                                )}   
                            </Grid>
                        )}
                        <Grid item xs={12} md={loginDisplay ? 12 : 6}>
                            <TextField
                                id="email"
                                type="email"
                                variant="outlined"
                                value={email}
                                placeholder="Email"
                                onChange={(e) => handleEmailChange(e.target.value)}
                                inputProps={{ maxLength: 256, sx: { fontFamily: 'Cabin' } }}
                                sx={{ width: '100%' }}
                            />
                            {emailError && (
                                <Box mt={1}>
                                    <ErrorMessage>{emailError}</ErrorMessage>
                                </Box>
                            )}   
                        </Grid>
                        <Grid item xs={12} md={loginDisplay ? 12 : 6}>
                            <TextField
                                id="password"
                                type="password"
                                variant="outlined"
                                value={password}
                                placeholder={loginDisplay ? 'Password' : 'Choose a password'}
                                onChange={(e) => handlePasswordChange(e.target.value)}
                                inputProps={{ maxLength: 64, sx: { fontFamily: 'Cabin' } }}
                                sx={{ width: '100%' }}
                            />
                            {passwordError && (
                                <Box mt={1}>
                                    <ErrorMessage>{passwordError}</ErrorMessage>
                                </Box>
                            )}  
                        </Grid>
                        {!loginDisplay && (
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="code"
                                    type="text"
                                    variant="outlined"
                                    value={code}
                                    placeholder="Joining Code (Optional)"
                                    onChange={(e) => setCode(e.target.value)}
                                    inputProps={{ maxLength: 64, sx: { fontFamily: 'Cabin' } }}
                                    sx={{ width: '100%' }}
                                />
                                {codeError && (
                                    <Box mt={1}>
                                        <ErrorMessage>{codeError}</ErrorMessage>
                                    </Box>
                                )}   
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Button
                                    onClick={onBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={!active}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Grid>
                        <Grid xs={12}>
                            {loginDisplay ? (
                                <Box textAlign="center" mt={4} mb={2}>
                                    <SubPrompt>New to Parentswarm?</SubPrompt>
                                    <Box mt={1}>
                                        <Button
                                            onClick={() => setLoginDisplay(false)}
                                            shade="secondaryLight"
                                        >
                                            Register
                                        </Button>
                                    </Box>
                                </Box>
                            ) : (
                                <Box textAlign="center" mt={4} mb={2}>
                                    <SubPrompt>Already have an account?</SubPrompt>
                                    <Box mt={1}>
                                        <Button
                                            onClick={() => setLoginDisplay(true)}
                                            shade="secondaryLight"
                                        >
                                            Login
                                        </Button>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
}

export default ParentRegistration;
