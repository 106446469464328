import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import Button from '../button';
import {
    ConfirmModal,
    ConfirmModalTitle,
    ConfirmModalText,
} from './confirm.style';

function Confirm({ title, text, cta, onClose }) {
  return (
    <ConfirmModal>
        <Box sx={{ mb: 2 }}>
            <ConfirmModalTitle>{title}</ConfirmModalTitle>
        </Box>
        <Box sx={{ mb: 4 }}>
            <ConfirmModalText>
                {text}
            </ConfirmModalText>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
            <Button
            shade="secondaryLight"
            size="large"
            onClick={onClose}
            >
                {cta}
            </Button>
        </Box>
    </ConfirmModal>
  );
}

Confirm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  cta: PropTypes.string,
  onClose: PropTypes.func,
};

export default Confirm;