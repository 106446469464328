import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import {
    Box,
    Container,
    Grid,
    Modal,
    Stack,
} from '@mui/material';

import {
    SET_SESSION,
    ADD_SESSION_ID,
    RESET_SESSION_IDS,
} from '../../store/actions';
import AddService from '../../components/product';
import SessionCard from '../../components/session/session-card';
import SessionRow from '../../components/session/session-row';
import GroupSessionCard from '../../components/group-session/group-session-card';
import GroupSessionRow from '../../components/group-session/group-session-row';
import { get } from '../../services/api.services';
import { ACCOUNT_TYPES, API, PRODUCT_TYPES } from '../../constants';
import {
    NoResults,
    PageTitle,
} from './sessions.style';
import { sessionIdsReadyToSchedule } from '../../utils/sessions';

function ExpertSessions() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const sessionIds = useSelector((state) => state.sessions.sessionIds);
    const sessionsById = useSelector((state) => state.sessions.sessionsById);

    const [products, setProducts] = useState([]);
    const [addNewGroupSession, setAddNewGroupSession] = useState(false);
    const [editProduct, setEditProduct] = useState();

    const fetchProducts = async () => {
        const result = await get(
          `${API.PRODUCT}?seller_id=${user.profile.id}&product_type=group&include_past_events=true`,
          token,
        );
      
        if (result.status === 403 || result.status === 401) {
          navigate('/login/experts');
        }
        else if (result.status === 200) {
          setProducts(result.data.products);
        }
        else {
            // TODO: Handle errors
        }
    };

    const fetchSessions = async () => {
        const result = await get(
            `${API.SESSION}`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            dispatcher({
                type: RESET_SESSION_IDS,
                payload: {},
            });

            result.data.forEach((session) => {
                dispatcher({
                    type: SET_SESSION,
                    payload: {
                        session,
                    },
                });
                dispatcher({
                    type: ADD_SESSION_ID,
                    payload: {
                        session,
                    },
                });
            });
        }
        else {
        }
    }

    useEffect(() => {
        fetchSessions();
        fetchProducts();
    }, []);

    const filteredSessionIds = sessionIdsReadyToSchedule(sessionIds, sessionsById);

    const sessionRequests = filteredSessionIds.filter((id) => !sessionsById[id].canceled && !sessionsById[id].session_date);
    const pendingSessions = sessionIds.filter((id) => !sessionsById[id].canceled && moment(sessionsById[id].session_date) > moment.now());
    const finishedSessions = sessionIds.filter((id) => sessionsById[id].canceled || (sessionsById[id].session_date && moment(sessionsById[id].session_date) < moment.now()));
    const upcomingGroupSessions = products.filter((product) => moment(product.event_date) >= moment().subtract(product.duration || 30, 'minutes'));
    const finishedGroupSessions = products.filter((product) => moment(product.event_date) < moment().subtract(product.duration || 30, 'minutes'));

    const onModalClose = () => {
        setAddNewGroupSession(false);
        setEditProduct(null);
    };

    const onEdit = (product) => {
        setAddNewGroupSession(true);
        setEditProduct(product);
    }

    return (
        <Container maxWidth="md">
            {sessionRequests.length > 0 && (
                <>
                    <PageTitle>
                        Session Requests
                    </PageTitle>
                    <Grid container spacing={2}>
                        {sessionRequests.map((id) => (
                            <Grid key={id} item xs={12} sm={6}>
                                <SessionCard
                                    session={sessionsById[id]}
                                    variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </>
            )}
            <PageTitle>
                Upcoming Sessions
            </PageTitle>
            <Grid container spacing={2}>
                {pendingSessions.length === 0 && upcomingGroupSessions.length === 0 && (
                    <Grid item xs={12}>
                        <NoResults>You don't have any upcoming sessions yet</NoResults>
                    </Grid>
                )}
                {pendingSessions.map((id) => (
                    <Grid key={id} item xs={12} sm={6}>
                        <SessionCard
                            session={sessionsById[id]}
                            variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                        />
                    </Grid>
                ))}
                {upcomingGroupSessions.map((product) => (
                    <Grid item xs={12} sm={6}>
                        <GroupSessionCard product={product} variant="expert" onEdit={() => onEdit(product)} />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ mb: 2 }}>
                <PageTitle>
                    Finished Sessions
                </PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {finishedSessions.length === 0 && finishedGroupSessions.length === 0 && (
                                <Grid item xs={12}>
                                    <NoResults>You don't have any finished sessions yet</NoResults>
                                </Grid>
                            )}
                            {finishedSessions.map((id) => (
                                <SessionRow
                                    key={id}
                                    session={sessionsById[id]}
                                    variant={sessionsById[id].parent_profile.id === user.profile.id ? ACCOUNT_TYPES.PARENT : ACCOUNT_TYPES.EXPERT}
                                />
                            ))}
                            {finishedGroupSessions.map((product) => (
                                <Grid item xs={12}>
                                    <GroupSessionRow
                                        product={product}
                                        variant={user.profile_type}
                                        onEdit={() => onEdit(product)}
                                    />
                                </Grid>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Modal
              open={addNewGroupSession || editProduct}
              onClose={onModalClose}
            >
              <AddService
                productId={editProduct?.id}
                productType={PRODUCT_TYPES.GROUP}
                onDismiss={() => {
                    fetchProducts();
                    setAddNewGroupSession(false);
                    setEditProduct(false);
                }}
              />
            </Modal>
        </Container>
    );
}

export default ExpertSessions;