import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import PropTypes from 'prop-types';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import { Alert, Button, Stack } from '@mui/material';

import CardSection from './card-section';
import { post } from '../../services/api.services';
import { API } from '../../constants';
import { setDefaultPaymentMethod } from '../../clients/profile';

function CheckoutForm({ onSuccess, saveText='Save Card', saveColor='secondary' }) {
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const [cardError, setCardError] = useState(false);

  const savePaymentMethod = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setCardError(false);

    const result = await post(
        `${API.PROFILE}${user.profile.id}/add_payment_method/`,
        {},
        token,
    );

    stripe.confirmCardSetup(result.data.client_secret, {
        payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {},
        },
    }).then((result) => {
        if (result.error) {
          setCardError(result.error.message);
        }
        else {
            if (user.profile.default_payment_source === null) {
              setDefaultPaymentMethod(navigate, user, token, result.setupIntent.payment_method);
            }
            onSuccess();
        }
    });
  };

  return (
    <form onSubmit={savePaymentMethod}>
      <Stack spacing={2}>
        {cardError && (
          <Alert title={cardError} color="error">{cardError}</Alert>
        )}
        <CardSection />
        <Button variant="contained" color={saveColor} type="submit" disabled={!stripe}>
          {saveText}
        </Button>
      </Stack>
    </form>
  );
}

CheckoutForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default CheckoutForm;
