import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';
import { Box, Container, IconButton, Select, MenuItem, Modal, TextField, Stack } from '@mui/material';
import {
    DataGrid,
    GridColDef,
    GridColTypeDef,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
} from '@mui/x-data-grid';
import { BarChart, XAxis, Tooltip, ResponsiveContainer, Bar } from 'recharts';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { get, destroy } from '../../../services/api.services';
import { API, PaywallTypes } from '../../../constants';
import Button from '../../../components/common/button';
import { displayName } from '../../../utils/profile';
import {
    PageTitle,
} from '../../sessions/sessions.style';
import ExpenseForm from './expense-form';
import { SET_PAYWALL } from '../../../store/actions';

const LEDGER_TYPES = {
    EXPERT_INCOME: 10,
    EXPERT_EXPENSE: 20,
};

const CHARGE_CREDIT_TYPES = {
    SESSION_FEE: 40,
    PLATFORM_FEE: 50,
    PROCESSOR_FEE: 60,
};

const XAXES = {
    MONTH: 'month',
    WEEK: 'week',
};

function Earnings({}) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const token = useSelector((state) => state.account.token);
    const featureFlags = useSelector((state) => state.account.user.profile.feature_flags);

    const [dateRange, setDateRange] = useState('YTD');
    const [start, setStart] = useState(moment().set('month', 0).set('date', 1).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
    const [end, setEnd] = useState(moment());
    const [xAxis, setXAxis] = useState(XAXES.MONTH);
    const [incomeLedgers, setIncomeLedgers] = useState([]);
    const [expenseLedgers, setExpenseLedgers] = useState([]);
    const [openExpenseTracker, setOpenExpenseTracker] = useState(false);
    const [editLedger, setEditLedger] = useState(null);

    const openPaywall = () => {
        dispatcher({
          type: SET_PAYWALL,
          payload: {
            paywall: true,
            paywall_type: PaywallTypes.BASE,
          },
        });
    };

    const closeExpenseForm = () => {
        setOpenExpenseTracker(false);
        setEditLedger(null);
    };

    const onChangeDateRange = (e) => {
        const newValue = e.target.value;
        setDateRange(newValue);

        if (newValue === 'YTD') {
            setStart(moment().startOf('year'));
            setEnd(moment());
            setXAxis(XAXES.MONTH);
        } else if (newValue === 'MTD') {
            setStart(moment().startOf('month'));
            setEnd(moment());
            setXAxis(XAXES.WEEK);
        } else if (newValue === 'LY') {
            setStart(moment().subtract(1, 'year').startOf('year'));
            setEnd(moment().subtract(1, 'year').endOf('year'));
            setXAxis(XAXES.MONTH);
        } else if (newValue === 'LM') {
            setStart(moment().subtract(1, 'month').startOf('month'));
            setEnd(moment().subtract(1, 'month').endOf('month'));
            setXAxis(XAXES.WEEK)
        }
    };

    function getDateRanges(startDate, endDate, byWeek = true) {
        // Make sure startDate and endDate are valid dates
        if (!moment(startDate).isValid() || !moment(endDate).isValid()) {
          return [];
        }
      
        // Determine the unit of time to use for the date range
        const unit = byWeek ? 'week' : 'month';
      
        // Calculate the number of units between startDate and endDate
        const startOfStartDate = moment(startDate).startOf(unit);
        const endOfEndDate = moment(endDate).endOf(unit);
        const diff = endOfEndDate.diff(startOfStartDate, unit);
        const numUnits = diff + 1;
      
        // Create an array to hold the date ranges
        const dateRanges = [];
      
        // Loop through each unit and create a date range
        for (let i = 0; i < numUnits; i++) {
          // Calculate the start date of the current unit
          const startOfUnit = moment(startDate).add(i, unit);
      
          // Calculate the end date of the current unit
          let endOfUnit;
          if (byWeek) {
            endOfUnit = moment(startOfUnit).add(6, 'days');
          } else {
            endOfUnit = moment(startOfUnit).endOf('month');
          }
      
          // Make sure the end of the unit is not after the endDate
          if (endOfUnit.isAfter(endDate)) {
            dateRanges.push([startOfUnit.toDate(), endDate]);
          } else {
            dateRanges.push([startOfUnit.toDate(), endOfUnit.toDate()]);
          }
        }
      
        return dateRanges;
    }

    const fetchLedgers = async () => {
        const result = await get(
            `${API.LEDGER}?begin_dt=${start.format('YYYY-MM-DD')}&end_dt=${end.add('day', 1).format('YYYY-MM-DD')}`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setIncomeLedgers(result.data.filter((ledger) => ledger.ledger_entry_type === LEDGER_TYPES.EXPERT_INCOME));
            setExpenseLedgers(result.data.filter((ledger) => ledger.ledger_entry_type === LEDGER_TYPES.EXPERT_EXPENSE));
        }
        else {
        }
    };

    const deleteLedger = async (id) => {
        const result = await destroy(
            `${API.LEDGER}${id}/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 204) {
            fetchLedgers();
        }
        else {
        }
    };

    useEffect(() => {
        fetchLedgers();
    }, [start, end]);

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    
    const usdPrice = {
        type: 'number',
        width: 100,
        valueFormatter: ({ value }) => value !== undefined ? currencyFormatter.format(value) : 0,
        cellClassName: 'font-tabular-nums',
    };

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            width: 90,
            type: 'date',
        },
        { field: 'description', headerName: 'Description', width: 140 },
        {
          field: 'client',
          headerName: 'Client',
          width: 100,
          type: 'string',
        },
        {
          field: 'price',
          headerName: 'Price',
          ...usdPrice,
        },
        {
            field: 'platformFee',
            headerName: 'Platform Fee',
            ...usdPrice,
        },
        {
            field: 'paymentProcessorFee',
            headerName: 'Payment Processor Fee',
            ...usdPrice,
        },
        {
            field: 'net',
            headerName: 'Net Earned',
            ...usdPrice,
        },
    ];
      
    const rows = incomeLedgers.map((ledger) => {
        const sessionCharges = ledger.ledger_details.filter((ledgerDetail) => ledgerDetail.charge_credit.charge_credit_type === CHARGE_CREDIT_TYPES.SESSION_FEE);
        const platformCharges = ledger.ledger_details.filter((ledgerDetail) => ledgerDetail.charge_credit.charge_credit_type === CHARGE_CREDIT_TYPES.PLATFORM_FEE);
        const processorCharges = ledger.ledger_details.filter((ledgerDetail) => ledgerDetail.charge_credit.charge_credit_type === CHARGE_CREDIT_TYPES.PROCESSOR_FEE);

        const price = sessionCharges.length > 0 ? parseFloat(sessionCharges[0].amount) : 0;
        const platformFee = platformCharges.length > 0 ? parseFloat(platformCharges[0].amount) : 0;
        const paymentProcessorFee = processorCharges.length > 0 ? parseFloat(processorCharges[0].amount) : 0;

        return {
            id: ledger.id,
            date: new Date(ledger.transaction_dt),
            description: ledger.description,
            client: ledger.created_by ? displayName(ledger.created_by.first_name, ledger.created_by.last_name) : '',
            price,
            platformFee,
            paymentProcessorFee,
            net: price - platformFee - paymentProcessorFee,
        };
    });

    const chartData = [];
    const dateRanges = getDateRanges(start, end, xAxis === XAXES.WEEK);

    dateRanges.forEach((dateRange) => {
        const initialValue = 0;

        const net = rows.filter((incomeRow) => {
            if (moment(incomeRow.date) >= moment(dateRange[0]) && moment(incomeRow.date) <= moment(dateRange[1])) {
                return true;
            }
            return false;
        }).reduce((accumulator, currentValue) => accumulator + currentValue.net, initialValue);

        const chartColumn = {
            'Net': net,
        };

        chartColumn[xAxis === XAXES.WEEK ? 'Week' : 'Month'] = xAxis === XAXES.WEEK ? `${moment(dateRange[0]).format('M/D/YY')}-${moment(dateRange[1]).format('M/D/YY')}` : moment(dateRange[0]).format('MMM');

        chartData.push(chartColumn);
    });

    const expenseColumns = [
        {
            field: 'date',
            headerName: 'Date',
            width: 130,
            type: 'date',
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 130,
            type: 'string',
        },
        {
          field: 'client',
          headerName: 'Client',
          width: 90,
          type: 'string',
          valueFormatter: ({ value }) => value === undefined ? '' : value,
        },
        {
          field: 'paymentType',
          headerName: 'Form of Payment',
          width: 90,
          type: 'string',
          valueFormatter: ({ value }) => value === undefined ? '' : value,
        },
        {
            field: 'amount',
            headerName: 'Amount',
            ...usdPrice,
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: 90,
            type: 'string',
            valueFormatter: ({ value }) => value === undefined ? '' : value,
        },
        {
            field: 'edit',
            headerName: '',
            width: 40,
            renderCell: (params) => (
                <IconButton onClick={() => {
                    setOpenExpenseTracker(true);
                    setEditLedger(expenseLedgers.find((ledger) => ledger.id === params.id));
                }}>
                    <EditOutlinedIcon />
                </IconButton>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            width: 40,
            renderCell: (params) => (
                <IconButton onClick={() => deleteLedger(params.id)}>
                    <DeleteOutlineOutlinedIcon />
                </IconButton>
            ),
        },
    ];


      
    const expenseRows = expenseLedgers.map((ledger) => {
        let clientName = ledger.target ? displayName(ledger.target.first_name, ledger.target.last_name) : '';

        if (ledger.folder && ledger.folder.members.length) {
            clientName = displayName(ledger.folder.members[0].first_name, ledger.folder.members[0].last_name);
        }

        return {
            id: ledger.id,
            date: new Date(ledger.transaction_dt),
            description: ledger.description,
            client: clientName,
            paymentType: ledger.payment_form,
            notes: ledger.extra_notes,
            amount: ledger.ledger_details.length ? ledger.ledger_details[0].amount : 0,
        };
    });

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer style={{ paddingTop: 10, paddingRight: 10 }}>
                <GridToolbarFilterButton style={{ color: '#000000' }} />
                <GridToolbarDensitySelector style={{ color: '#000000' }} />
                <GridToolbarExport
                    style={{ color: '#000000' }}
                    csvOptions={{
                        fileName: 'nessle-export',
                        fields: ['date', 'description', 'client', 'paymentType', 'amount', 'notes', 'price', 'platformFee', 'paymentProcessorFee', 'net'],
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <Container maxWidth="md" sx={{ background: '#FFFFFF', marginTop: 4, padding: 4, borderRadius: 2 }}>
            <Box mb={1} display="flex" flexDirection="row-reverse">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Stack spacing={1} direction="row">
                        <DesktopDatePicker
                            label="From"
                            inputFormat="M/D/YYYY"
                            value={start}
                            onChange={(newValue) => {
                                setDateRange('CUSTOM');
                                setStart(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                            <DesktopDatePicker
                            label="To"
                            inputFormat="M/D/YYYY"
                            value={end}
                            onChange={(newValue) => {
                                setDateRange('CUSTOM');
                                setEnd(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        <Select
                            labelId="dateRange"
                            id="date-range"
                            value={dateRange}
                            onChange={onChangeDateRange}
                        >
                            <MenuItem value='MTD'>This Month</MenuItem>
                            <MenuItem value='LM'>Last Month</MenuItem>
                            <MenuItem value='YTD'>Year to Date</MenuItem>
                            <MenuItem value='LY'>Last Year</MenuItem>
                            <MenuItem value='CUSTOM' disabled>Custom</MenuItem>
                        </Select>
                    </Stack>
                </LocalizationProvider>
            </Box>
            <Box>
                <PageTitle>Income</PageTitle>
            </Box>
            <Box mt={1} mb={1}>
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart width={400} height={250} data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                        <XAxis dataKey={xAxis === XAXES.MONTH ? 'Month' : 'Week'} />
                        <Tooltip formatter={(value) => currencyFormatter.format(value)} />
                        <Bar type="monotone" dataKey="Net" stroke="#5371ff" fillOpacity={1} fill="#5371ff" />
                    </BarChart>
                </ResponsiveContainer>
            </Box>
            <Box mt={2} mb={2} sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    slots={{ toolbar: CustomToolbar }}
                    sx={{
                        fontFamily: 'Cabin',
                        borderRadius: 2,
                    }}
                    componentsProps={{
                        panel: {
                          sx: {
                            '& .MuiTypography-root': {
                              color: 'dodgerblue',
                              fontSize: 20,
                            },
                            '& .MuiDataGrid-filterForm': {
                              bgcolor: 'lightblue',
                            },
                          },
                        },
                      }}
                />
            </Box>
            <Box mt={2} mb={1} display="flex" alignItems="center" justifyContent="space-between">
                <PageTitle>Expenses</PageTitle>
                <Button
                    shade="secondaryLight"
                    onClick={() => {
                        if (featureFlags.bookeeping) {
                            setOpenExpenseTracker(true);
                        } else {
                            openPaywall();
                        }
                    }}
                >
                    <AddRoundedIcon fontSize="small" />
                    Track an Expense
                </Button>
            </Box>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={expenseRows}
                    columns={expenseColumns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    slots={{ toolbar: CustomToolbar }}
                    sx={{
                        fontFamily: 'Cabin'
                    }}
                />
            </div>
            <Modal open={openExpenseTracker} onClose={closeExpenseForm}>
                <ExpenseForm
                    ledger={editLedger}
                    onClose={closeExpenseForm}
                    onSuccess={fetchLedgers}
                />
            </Modal>
        </Container>
    );
}

Earnings.propTypes = {};

export default Earnings;
