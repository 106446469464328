/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const SelectedPaymentContainer = styled.div`
    background: #5371ff;
    border-radius: 8px;
    color: #FFFFFF;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Cabin';
`;

export const PaymentContainer = styled.div`
    border: 1px solid #E8E8E8;
    border-radius: 8px;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Cabin';
`;

export const DetailText = styled.div`
    font-family: 'Cabin';
    font-size: 12px;
`;
