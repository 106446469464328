import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, Typography } from '@mui/material';

import { State } from '../../types';
import OnboardingSurveyImg from '../../assets/images/onboarding-survey.png';
import CommunityAgreementForm from './community-agreement-form';

interface OnboardingSurveyProps {
    markedComplete: boolean,
    markStepComplete: Function,
    unmarkStepComplete: Function,
}

export default function OnboardingSurvey({ markedComplete, markStepComplete, unmarkStepComplete }: OnboardingSurveyProps) {
    const [openCommunityAgreement, setOpenCommunityAgreement] = useState(false);
    
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Complete the Community Agreement (Required)</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Activate your profile to show up in the Parentswarm listing directory by reviewing and signing{' '}
                                our <Box display="inline" ml={-1}><Button size="large" onClick={() => setOpenCommunityAgreement(true)}>Community Agreement</Button></Box>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={OnboardingSurveyImg} />
                </Box>
            </Grid>
            <Modal
                open={openCommunityAgreement}
                onClose={() => setOpenCommunityAgreement(false)}
            >
                <CommunityAgreementForm onClose={() => setOpenCommunityAgreement(false)} />
            </Modal>
        </Grid>
    );
}
