import { useState } from 'react';

import { Box, Button, Snackbar, Alert } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { track } from '../../utils/analytics';
import Field from './field';

interface DynamicFormProps {
    config: any;
    initialValues?: any;
    onSubmit: (data: any) => void;
}

const DynamicForm = ({ config, initialValues, onSubmit }: DynamicFormProps) => {
    const [formData, setFormData] = useState(initialValues || {});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showError, setShowError] = useState(false);
    const [started, setStarted] = useState(false);
    const [ended, setEnded] = useState(false);
    const currentQuestion = config.questions[currentQuestionIndex];

    const handleChange = (id, value) => {
        if (showError) setShowError(false);
        setFormData({ ...formData, [id]: value });
    };

    const handleNext = () => {
        const currentQuestion = config.questions[currentQuestionIndex];
        const isAnswerRequired = currentQuestion.required;
        const hasAnswer = formData[currentQuestion.id] !== undefined && formData[currentQuestion.id] !== '';

        if (isAnswerRequired && !hasAnswer) {
            setShowError(true);
            return;
        }

        track('form step completed', {
            step: config.questions[currentQuestionIndex].label,
        });
        
        if (currentQuestionIndex < config.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            if (config.end) {
                setEnded(true);
            } else {
                onSubmit(formData);
            }
        }
    };

    const handleBack = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };
    
    const onStart = () => {
        setStarted(true);
    }

    const IntroComponent = config.intro;
    const EndComponent = config.end;

    return (
        !started && config.intro ? (
            <IntroComponent onStart={onStart} formData={formData} onSubmit={() => onSubmit(formData)} />
        ) : (
            <>
                {!ended || !config.end ? (
                    <form onSubmit={(e) => e.preventDefault()}>
                        {/* Progress Indicator */}
                        <div style={{ marginBottom: '20px' }}>
                            {currentQuestionIndex + 1} / {config.questions.length}
                        </div>
                        <Field
                            question={currentQuestion}
                            value={formData[currentQuestion.id]}
                            onChange={handleChange}
                        />
                        <Box mt={2} display="flex">
                            {currentQuestionIndex > 0 && (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleBack}
                                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, minWidth: 40, padding: 0 }}
                                >
                                    <KeyboardArrowUpIcon />
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                style={currentQuestionIndex > 0 ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : {}}
                            >
                                {(currentQuestionIndex === config.questions.length - 1 && !config.end) ? 'Submit' : 'Next'}
                            </Button>
                            {/* Error Snackbar for validation message */}
                            <Snackbar open={showError} autoHideDuration={6000} onClose={() => setShowError(false)}>
                                <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
                                    This question is required.
                                </Alert>
                            </Snackbar>
                        </Box>
                    </form>
                ) : (
                    <EndComponent formData={formData} onSubmit={() => onSubmit(formData)} />
                )}
            </>
        )
    );
};

export default DynamicForm;