import { get } from '../services/api.services';
import { API } from '../constants';

export const fetchLocations = async (token) => {
    const result = await get(
      API.LOCATION,
      token,
    );

    if (result.status === 200) {
        if (result.data.length) {
            return result.data;
        }
    }
    return false;
};
