// action - state management
import * as actionTypes from './actions';

export const initialState = {
  notificationIds: [],
  notificationsById: {},
  unreadCount: 0,
  unreadCountByFolderId: {},
};

const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATION: {
      const { notification } = action.payload;
      const notificationsByIdCopy = { ...state.notificationsById };
      notificationsByIdCopy[notification.id] = notification;

      let unreadCountByFolderId = { ...state.unreadCountByFolderId };
      if (notification.folder && notification.read !== state.unreadCountByFolderId[notification.folder.id].read) {
        unreadCountByFolderId[notification.folder.id] = unreadCountByFolderId[notification.folder.id] - 1;
      }

      const decrementUnreadCount = notification.read !== state.notificationsById[notification.id].read;

      return {
        ...state,
        notificationsById: notificationsByIdCopy,
        unreadCount: decrementUnreadCount ? state.unreadCount - 1 : state.unreadCount,
        unreadCountByFolderId,
      }
    }
    case actionTypes.SET_NOTIFICATIONS: {
      const { notifications } = action.payload;
      const byId = {};
      const unreadCountByFolderId = {};

      notifications.forEach((notification) => {
        byId[notification.id] = notification;

        if (notification.folder && !notification.read) {
          if (unreadCountByFolderId[notification.folder.id]) {
            unreadCountByFolderId[notification.folder.id] = unreadCountByFolderId[notification.folder.id] + 1;
          } else {
            unreadCountByFolderId[notification.folder.id] = 1;
          }
        }
      });

      return {
        ...state,
        notificationIds: notifications.map((notification) => notification.id),
        notificationsById: byId,
        unreadCount: notifications.filter((notification) => !notification.read).length,
        unreadCountByFolderId,
      };
    }
    default:
      return state;
  }
};

export default notificationReducer;
