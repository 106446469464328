/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  background: #FFFFFF;
  text-align: center;
  width: 260px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled.img`
  margin: .5em auto;
  margin-bottom: -.5em;
  height: 50px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LogoSm = styled.img`
  position: absolute;
  left: 10px;
  top: 10px;
  margin: 0 auto;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const LogoLoggedOut = styled.img`
  position: absolute;
  left: 10px;
  top: 10px;
  margin: 0 auto;
`;

export const Profile = styled.div`
background-color: #5371ff;
margin: 0em 1.3em;
border-radius: 5px; 
padding: 1em;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
cursor: pointer;
@media (max-width: 768px) {
  display: none;
}
`;

export const ProfileText = styled.div`
display: ${({ fontSize }) => (fontSize ? 'flex' : null)};
justify-content: space-between;
background-color: #5371ff;
margin-top: 1em;
border-radius: 5px;
font-size: ${({ fontSize }) => (fontSize ? '10px' : '13px')};
color: #FFFFFF;
@media (max-width: 768px) {
  display: none;
}
`;
