import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, Typography } from '@mui/material';


import NessleLogo from '../../assets/images/parentswarm-logo.svg';
import {
  Container, Heading, DetailsTextContainer, LogoWrapper, Logo, TotalPages, GradientContainer,
} from './setup-account.style';
import YourDetails from '../../components/account-setup/your-details';
import Checkout from '../../components/account-setup/checkout';
import { ACCOUNT_TYPES } from '../../constants';

export default function SetupAccount() {
  const user = useSelector((state) => state.account.user);

  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [state, setState] = useState({
    firstName: user.profile.first_name || '',
    lastName: user.profile.last_name || '',
    dob: user.profile.birth_date || '',
    zipCode: user.profile.zip_code || '',
    phoneNumber: user.profile.phone || '',
    pronouns: user.profile.pronouns || null,
    inPerson: user.profile.offers_in_person_services || false,
    virtual: user.profile.offers_virtual_services || false,
    radius: user.profile.in_person_radius || '',
    address: user.profile.address || '',
  });

  const handleBackBtn = () => {
    if (page > 0 && page <= 2) {
      setPage((prev) => prev - 1);
    }
  };
  const handleCrossSetupLater = () => {
    navigate('/');
  };
  return (
    <GradientContainer>
      <LogoWrapper>
        <Logo src={NessleLogo} />
      </LogoWrapper>
      <Container margin="0 auto" borderRadius="14px">
        <Typography variant="h5">Set up your account</Typography>
        <Box mt={1}>
          <Typography variant="subtitle1">
            {(() => {
              switch (page) {
                case 1:
                  return (
                    'Your details'
                  );
                case 2:
                  return 'Checkout';
                default:
                  return null;
              }
            })()}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1">({page} / 2)</Typography>
        </Box>
        {(() => {
          switch (page) {
            case 1:
              return (
                <YourDetails
                  page={page}
                  setPage={setPage}
                  onSuccess={() => {
                    user.profile.subscription ? setPage((prev) => prev + 1) : handleCrossSetupLater()
                  }}
                  handleCrossSetupLater={handleCrossSetupLater}
                  state={state}
                  setState={setState}
                  profileType={ACCOUNT_TYPES.EXPERT}
                />
              );
            case 2:
              return (
                <Checkout handleBackBtn={handleBackBtn} />
              )
            default:
              return null;
          }
        })()}
      </Container>
    </GradientContainer>
  );
}
