import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import AllRoutes from './routes/Routes';
import VersionChecker from './components/general/version-checker';

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#5371ff',
        dark: '#5371ff',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#f7b51e',
        dark: '#FFE0E0',
        contrastText: '#000000',
      },
      info: {
        main: '#210B2B',
        light: '#E8E8E8',
      },
      success: {
        main: '#04A777',
        contrastText: '#FFFFFF',
      },
      error: {
        main: '#E66069',
        contrastText:'#FFFFFF',
      },
      warning: {
        main: '#7A6F9B',
        contrastText: '#FFFFFF',
      },
      paper: {
        main: '#FFFFFF',
      },
    },
    typography: {
      fontFamily: [
        'Nunito',
        'Cabin',
        'Open Sans',
        'Arial',
        'sans-serif',
      ].join(','),
      h5: {
        fontWeight: 700,
      },
      h6: {
        fontWeight: 700,
      },
      button: {
        fontWeight: 700,
        textTransform: 'none',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            borderRadius: '6px',
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: '6px',
            border: 'none',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              color: '#FFFFFF',
              backgroundColor: '#5371ff',
              '&:hover': {
                color: '#FFFFFF',
                backgroundColor: '#5371ff',
              },
            },
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontWeight: 600,
            fontSize: 14,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: 'inherit',
            marginRight: '-12px',
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <VersionChecker />
      <AllRoutes />
    </ThemeProvider>
  );
}

export default App;
