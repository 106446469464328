// action - state management
import {
    SET_FOLDER,
    ADD_FOLDER_ID,
    RESET_FOLDER_IDS,
  } from './actions';
  
  export const initialState = {
    foldersById: {},
    folderIds: [],
  };
  
  const folderReducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_FOLDER: {
        const { folder } = action.payload;
        const newFoldersById = { ...state.foldersById };

        newFoldersById[folder.id] = folder;

        return {
          ...state,
          foldersById: newFoldersById,
        };
      }
      case ADD_FOLDER_ID: {
        const { folder } = action.payload;
        return {
          ...state,
          folderIds: [ ...state.folderIds, folder.id ],
        }
      }
      case RESET_FOLDER_IDS: {
        return {
          ...state,
          folderIds: [],
        }
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default folderReducer;
  