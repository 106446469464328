export const INSURANCE_TAGS = [
  { label: 'HSA Eligible', value: 'hsa' },
  { label: 'HRA Eligible', value: 'hra' },
  { label: 'FSA Eligible', value: 'fsa' },
  {
    label: 'Medicaid',
    value: 'medicaid',
  },
  {
    label: 'Private Insurance',
    value: 'private',
  },
];
export const INSURANCES = [
  'hsa',
  'hra',
  'fsa',
  'medicaid',
  'private',
];
