import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Container,
    FormGroup,
    FormControlLabel,
    Grid,
    Paper,
    Snackbar,
    Stack,
    Switch,
    Typography,
} from '@mui/material';

import { useAuth } from '../../../hooks';
import { API } from '../../../constants';
import { patch } from '../../../services/api.services';
import { SET_USER } from '../../../store/actions';
import NotificationCategory from './notification-category';

export default function NotificationPreferences({  }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState(false);

    const save = async (notificationKey, notificationType, value) => {
        setErrors(false);
    
        const notificationPreferencesCopy = { ...user.profile.notification_preferences };
        notificationPreferencesCopy[notificationKey][notificationType] = value;

        const result = await patch(
            `${API.PROFILE}${user.profile.id}/`,
            {
                notification_preferences: notificationPreferencesCopy
            },
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setSaved(true);
            dispatcher({
                type: SET_USER,
                payload: {
                    user: {
                        ...user,
                        profile: {
                            ...result.data,
                        },
                    },
                },
            });
        } else {
            setErrors(true);
        }
    };

    return (
        <Container maxWidth="md">
            <Box mt={4}>
                <Typography variant="h5">Notification Preferences</Typography>
            </Box>
            <Paper elevation={0}>
                <Stack spacing={4} m={2} p={2}>
                    <NotificationCategory
                        title="Account Notifications"
                        description="Reminders related to account setup, welcome series with tips for success."
                        value={user.profile.notification_preferences.account}
                        notificationKey="account"
                        save={save}
                    />
                    <NotificationCategory
                        title="New Activity Notifications"
                        description="New messages, bookings, scheduling changes, cancellations, etc."
                        value={user.profile.notification_preferences.new_activity}
                        notificationKey="new_activity"
                        save={save}
                    />
                    <NotificationCategory
                        title="Reminders"
                        description="Reminders of upcoming sessions, group classes, events, etc."
                        value={user.profile.notification_preferences.reminders}
                        notificationKey="reminders"
                        save={save}
                    />
                </Stack>
            </Paper>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={saved}
                autoHideDuration={3000}
                onClose={() => setSaved(false)}
                message="Your preferences have been saved."
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={saved}
                autoHideDuration={3000}
                onClose={() => setSaved(false)}
                message="Your preferences have been saved."
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={errors}
                autoHideDuration={3000}
                onClose={() => setErrors(false)}
                color="error"
                message="An error occurred while trying to save your preferences. Please try again."
            />
        </Container>
    );
}
