import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import ExpertSessions from '../../assets/icons/Expert-sessions.svg';
import Experts from '../../assets/icons/Experts.svg';
import GroupSessions from '../../assets/icons/Group-Sessions.svg';
import { SessionTypeText } from './Session.style';
import { ACCOUNT_TYPES, PRODUCT_TYPES } from '../../constants';

function SessionType({ productType, variant }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        {productType === PRODUCT_TYPES.SINGLE && (
            <>
                <img
                    src={variant === ACCOUNT_TYPES.EXPERT ? ExpertSessions : Experts}
                    alt="1-on-1 session"
                    width={14}
                    height={14}
                />
                <SessionTypeText>Live 1-on-1 Session</SessionTypeText>
            </>
        )}
        {productType === PRODUCT_TYPES.GROUP && (
            <>
                <img
                    src={GroupSessions}
                    alt="group icon"
                    width={16}
                    height={16}
                />
                <SessionTypeText>Live Group Session</SessionTypeText>
            </>
        )}
    </Box>
  );
}

SessionType.propTypes = {
  productType: PropTypes.string,
  variant: PropTypes.string,
};

export default SessionType;