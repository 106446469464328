import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { Box, Button, Container, Grid, Stack, Tooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'react-toastify/dist/ReactToastify.css';

import { SET_USER } from '../../store/actions';

import {
  Input,
  ServiceOfferOptions,
  SelectContainer,
  ErrorMessage,
} from './your-details.style';
import { patch } from '../../services/api.services';
import { API, ACCOUNT_TYPES } from '../../constants';

export default function YourDetails({
  onSuccess, state, setState, profileType,
}) {
  const dispatcher = useDispatch();
  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const zipcodeRegex = /^\d{5}(?:[-]\d{4})?$/;
  const firstnameRegex = /^[a-zA-Z ]{1,}$/;
  const lastNameRegex = /^[a-zA-Z]{1,}$/;
  const [maxDate, setMaxDate] = useState('');
  const [errorMsg, setErrorMsg] = useState({
    firstName: false,
    zipCode: false,
    phoneNumber: false,
    lastName: false,
    pronouns: false,
    address: false,
  });
  const [inPersonFields, setInPersonFields] = useState({
    zipCode: false,
    radius: false,
  });
  const [requiredFields, setRequiredFields] = useState({
    firstName: false,
    lastName: false,
  });
  const navigate = useNavigate();

  const updateFields = (fn, name, value) => {
    fn((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  // Checking mandatory fields
  const checkMandatoryFields = () => {
    const {
      firstName, lastName,
    } = state;
    if (firstName.length === 0) {
      updateFields(setRequiredFields, 'firstName', true);
    }
    if (lastName.length === 0) {
      updateFields(setRequiredFields, 'lastName', true);
    }
  };
  const resetErrorMsg = () => {
    setErrorMsg({
      firstName: false,
      zipCode: false,
      phoneNumber: false,
      pronouns: false,
      lastName: false,
      address: false,
    });
    setInPersonFields({
      zipCode: false,
      radius: false,
    });
  };

  const handleDisbleBtn = () => {
    checkMandatoryFields();
  };

  const handleSubmit = async () => {
    resetErrorMsg();
    const {
      firstName, lastName,
    } = state;
    // errorMsg keys are true then not calling api
    if (firstName && lastName) {
      const payloadData = state;
      // Removing empty key value pair
      for (const key in payloadData) {
        if (payloadData[key] === '') {
          delete payloadData[key];
        }
      }
      const result = await patch(
        `${API.PROFILE}${user.profile.id}/`,
        {
          first_name: state.firstName,
          last_name: state.lastName,
          zip_code: state.zipCode,
          offers_in_person_services: state.inPerson,
          offers_virtual_services: state.virtual,
          phone: state.phoneNumber,
          pronouns: state.pronouns,
          in_person_radius: state.radius,
          profile_type: profileType,
          address: state.address,
        },
        token,
      );
      if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
      }
      else if (result.status === 200) {
        onSuccess();

        dispatcher({
          type: SET_USER,
          payload: {
            user: {
              ...user,
              profile: {
                ...result.data,
              },
            },
          },
        });
      }
      else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleMaxDate = () => {
    const currentDate = new Date();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    const year = currentDate.getFullYear();
    if (month < 10) month = `0${month.toString()}`;
    if (day < 10) day = `0${day.toString()}`;
    const maxDate = `${year}-${month}-${day}`;
    setMaxDate(maxDate);
  };

  const checkValidation = (name, regexPattern, e, value) => {
    if (e.target.name === name && !regexPattern.test(value)) {
      setErrorMsg({
        ...errorMsg,
        [e.target.name]: true,
      });
    } else if (e.target.name === name && regexPattern.test(value)) {
      setErrorMsg({
        ...errorMsg,
        [e.target.name]: false,
      });
    }
  };
  const handleCheckMandatoryFields = (fn, checkName, name, value) => {
    if (checkName === name && value.length > 0) {
      updateFields(fn, name, false);
    } else if (checkName === name && value.length === 0) {
      updateFields(fn, name, true);
    }
  };
  const handleChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    // Checking validation of fields
    checkValidation('zipCode', zipcodeRegex, e, value);
    checkValidation('firstName', firstnameRegex, e, value);
    checkValidation('lastName', lastNameRegex, e, value);
  
    if (e.target.name === 'zipCode') {
      const curValue = value;
      let newValue = value;
      if (curValue.length > 5) {
        newValue = curValue.replace(/(\d)(\d)(\d)(\d)(\d)(\d)/, '$1$2$3$4$5-$6');
      }
      updateFields(setState, e.target.name, newValue);
    } else {
      updateFields(setState, e.target.name, value);
    }
    handleCheckMandatoryFields(setRequiredFields, 'firstName', e.target.name, value);
    handleCheckMandatoryFields(setRequiredFields, 'lastName', e.target.name, value);
  };
  // TO set max date according to current date.
  useEffect(() => {
    handleMaxDate();
  }, [maxDate]);

  return (
    <Container maxWidth="md">
      <ToastContainer />
      <Stack spacing={2} maxWidth="md">
        <Box>
          <Typography variant="subtitle1">
            First Name <span>*</span>
          </Typography>
          <Input
            type="text"
            pattern="[A-Za-z]+"
            name="firstName"
            value={state.firstName}
            onChange={(e) => handleChange(e)}
            placeholder="Eg. Olivia"
            tabIndex="1"
          />
          {state.firstName
          && errorMsg.firstName && <ErrorMessage> First name is invalid</ErrorMessage>}
          {requiredFields.firstName && <ErrorMessage> First name is required</ErrorMessage>}
        </Box>
        <Box>
          <Typography variant="subtitle1">
            Last Name <span>*</span>
          </Typography>
          <Input tabIndex="2" type="text" placeholder="Eg. Smith" name="lastName" value={state.lastName} onChange={(e) => handleChange(e)} />
          {state.lastName && errorMsg.lastName && <ErrorMessage>Last name is invalid</ErrorMessage>}
          {requiredFields.lastName && <ErrorMessage> Last name is required</ErrorMessage>}
        </Box>
        <Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" marginRight={1}>Phone Number</Typography>
            <Tooltip title="We'll send you texts for time-sensitive notifications. You can always turn them off in your Notification Settings.">
              <InfoOutlinedIcon fontSize="small" color="info" />
            </Tooltip>
          </Box>
          <PhoneInput
            tabIndex="4"
            placeholder="Eg. 212-456-7890"
            name="phoneNumber"
            defaultCountry="US"
            value={state.phoneNumber}
            onChange={(value) => handleChange({ target: { value: value || '', name: 'phoneNumber' }})}
            inputComponent={Input}
            displayInitialValueAsLocalNumber={true}
          />
          {state.phoneNumber
          && errorMsg.phoneNumber && <ErrorMessage> Phone number is invalid</ErrorMessage>}
        </Box>
        {profileType === ACCOUNT_TYPES.EXPERT && (
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1" marginRight={1}>Mailing Address</Typography>
              <Tooltip title="Totally optional, but occasionally we send out swag and event invitations.">
                <InfoOutlinedIcon fontSize="small" color="info" />
              </Tooltip>
            </Box>
            <Input tabIndex="5" type="text" placeholder="123 Main Street, Richmond, VA 23223" name="address" value={state.address} onChange={(e) => handleChange(e)} />
            {!!state.address && errorMsg.address && <ErrorMessage> Mailing address is invalid</ErrorMessage>}
          </Box>
        )}
        <Box>
          <Typography variant="subtitle1">
            Pronouns
          </Typography>
          <Input tabIndex="5" type="text" placeholder="she / her, he / him, they / them" name="pronouns" value={state.pronouns} onChange={(e) => handleChange(e)} />
          {state.pronouns
          && errorMsg.pronouns && <ErrorMessage> Pronouns is invalid</ErrorMessage>}
          {requiredFields.pronouns && <ErrorMessage> Pronouns is required</ErrorMessage>}
        </Box>
        {profileType === ACCOUNT_TYPES.PARENT && (
          <Box>
            <Typography variant="subtitle1">Zip Code (optional, for in-person services)</Typography>
            <Input tabIndex="8" placeholder="Eg. 23226" data-testid="zipCode-test" name="zipCode" value={state.zipCode} onChange={(e) => handleChange(e)} />
            {state.zipCode
            && errorMsg.zipCode && <ErrorMessage> Zip code is invalid</ErrorMessage>}
            {inPersonFields.zipCode && <ErrorMessage> Zip code is required</ErrorMessage>}
          </Box>
        )}
        {profileType === ACCOUNT_TYPES.EXPERT && (
          <Box mb={1}>
            <Typography variant="subtitle1">Service Offering Type</Typography>
            <ServiceOfferOptions>
              <span>
                <input
                  value={state.inPerson}
                  checked={state.inPerson}
                  data-testid="inPerson-test"
                  type="checkbox"
                  name="inPerson"
                  onChange={(e) => handleChange(e)}
                  id="inPerson"
                  tabIndex="6"
                />
                <label htmlFor="inPerson"> In-Person</label>
              </span>
              <span>
                <input
                  tabIndex="7"
                  checked={state.virtual}
                  value={state.virtual}
                  data-testid="virtual-test"
                  type="checkbox"
                  name="virtual"
                  onChange={(e) => handleChange(e)}
                  id="virtual"
                />
                <label htmlFor="virtual"> Virtual</label>
              </span>
              <span />
            </ServiceOfferOptions>
            {state.inPerson && (
                  <Stack mt={2} mb={1} spacing={2}>
                    <Box>
                      <Typography variant="subtitle1">Zip Code</Typography>
                      <Input tabIndex="8" placeholder="Eg. 23226" data-testid="zipCode-test" name="zipCode" value={state.zipCode} onChange={(e) => handleChange(e)} />
                      {state.zipCode
                      && errorMsg.zipCode && <ErrorMessage> Zip code is invalid</ErrorMessage>}
                      {inPersonFields.zipCode && <ErrorMessage> Zip code is required</ErrorMessage>}
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" htmlFor="radius-select">Radius </Typography>
                      <SelectContainer tabIndex="9" name="radius" data-testid="radius-test" value={state.radius} onChange={(e) => handleChange(e)} id="radius-select">
                        <option value={null}>Choose an option</option>
                        <option value={5}>5 mile</option>
                        <option value={10}>10 mile</option>
                        <option value={25}>25 mile</option>
                        <option value={50}>50+ mile</option>
                      </SelectContainer>
                      {inPersonFields.radius && <ErrorMessage> Radius is required</ErrorMessage>}
                    </Box>
                  </Stack>
                )}
          </Box>
        )}
        {state.firstName.length > 0 && state.lastName.length > 0 ? (
          <Box textAlign="center">
            <Button variant="contained" color="primary" size="large" onClick={() => handleSubmit()}>
              Next
            </Button>
          </Box>
        ) : (
          <Box textAlign="center">
            <Button variant="contained" color="primary" size="large" onClick={() => handleDisbleBtn()}>
              Next
            </Button>
          </Box>
        )}
      </Stack>
    </Container>
  );
}

YourDetails.propTypes = {
  setPage: PropTypes.any,
  state: PropTypes.objectOf(PropTypes.any),
  setState: PropTypes.any,
  profileType: PropTypes.string,
};
