import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { Badge, Box, Button, Container, Drawer, Grid, Modal as MUIModal } from "@mui/material";

import { get } from "../../services/api.services";
import { API, ACCOUNT_TYPES } from "../../constants";
import { fetchSessions } from "../../clients/session";
import { fetchEvents } from "../../clients/event";
import { PageTitle } from "../sessions/sessions.style";
import { FOLDER_VARIANTS } from "../../components/folder/folder-card";
import SessionCard from "../../components/session/session-card";
import EventCard from "../../components/event/event-card";
import Thread from "../../components/thread";
import NotesList from "../../components/note/list";
import Notifications from "../../components/notifications";
import Profile from "../../components/profile";
import { displayName } from "../../utils/profile";
import { NoResults } from "../questions/questions.style";
import { Card } from "../../components/session/Session.style";
import { useAuth } from "../../hooks";
import { State, Folder, Event, Payment, Purchase } from "../../types";
import AddEventButton from "../../components/event/add-event-button";
import { fetchPurchases } from "../../clients/purchase";
import { OPEN_PRODUCT } from "../../store/actions";
import { PRODUCT_TYPES } from "../../constants";
import PurchaseRow from "../../components/purchase/purchase-row";
import PaymentRow from '../../components/payment/payment-row';
import { fetchPayments } from "../../clients/payment";
import Modal from '../../components/common/modal';
import PaymentForm from "../../components/payment/payment-form";

function FolderSessions({ folderSessionIds, sessionsById }) {
  const { user } = useAuth();
  const featureFlags = useSelector((state: State) => state.account.user.profile.feature_flags);

  const filteredSessionIds = folderSessionIds.filter((sessionId) => {
    const session = sessionsById[sessionId];

    if (session.prompt_scheduling_date && moment(session.prompt_scheduling_date) > moment.utc()) {
      return false;
    }
    if (!session.prompt_scheduling_date && session.prompt_scheduling_after_session_id) {
      return false;
    }
    return true;
  });

  return (
    <React.Fragment>
      {filteredSessionIds.length === 0 && (
        <Box>
          <PageTitle>1-1 Sessions</PageTitle>
          <NoResults>No sessions booked yet.</NoResults>
        </Box>
      )}
      {filteredSessionIds.length > 0 && (
        <Box>
          <PageTitle>1-1 Sessions</PageTitle>
          <Grid container spacing={2}>
            {filteredSessionIds.map((sessionId) => (
              <Grid key={sessionId} item xs={12} sm={6}>
                <SessionCard
                  session={sessionsById[sessionId]}
                  variant={
                    sessionsById[sessionId].parent_profile.id ===
                    user.profile.id
                      ? ACCOUNT_TYPES.PARENT
                      : ACCOUNT_TYPES.EXPERT
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

interface FolderEventsProps {
  folderEventIds: string[];
  eventsById: Record<string, Event>;
  folderId: string;
}

function FolderEvents({
  folderEventIds,
  eventsById,
  folderId,
}: FolderEventsProps) {
  return (
    <React.Fragment>
      {(folderEventIds?.length ?? 0) > 0 && (
        <Box>
          <PageTitle>Important Dates</PageTitle>
          <Grid container spacing={2}>
            {folderEventIds.map((eventId) => (
              <Grid key={eventId} item xs={12} sm={6}>
                <EventCard event={eventsById[eventId]} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

interface FolderPurchasesProps {
  purchases: Purchase[];
}

function FolderPurchases({
  purchases,
}: FolderPurchasesProps) {
  return (
    <React.Fragment>
      {(purchases?.length ?? 0) > 0 && (
        <Box>
          <PageTitle>Purchase History</PageTitle>
          <Grid container spacing={2}>
            {purchases.map((purchase) => (
              <Grid key={purchase.id} item xs={12}>
                <PurchaseRow purchase={purchase} />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

interface FolderPaymentsProps {
  variant: string;
  folderId: string;
  payments: Payment[];
  onClose: () => void;
}

function FolderPayments({
  variant,
  payments,
  folderId,
  onClose,
}: FolderPaymentsProps) {
  const [openPaymentForm, setOpenPaymentForm] = useState(false);

  return (
    <Box>
      {payments?.length > 0 ? (
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <PageTitle>Payments</PageTitle>
            <Box>
              <Button variant="contained" color="secondary" style={{ marginRight: 5 }} onClick={onClose}>
                Back
              </Button>
              {variant === FOLDER_VARIANTS.OWNER && (
                <Button variant="contained" color="primary" onClick={() => setOpenPaymentForm(true)}>
                  Request Payment
                </Button>
              )}
            </Box>
          </Box>
          <Grid container spacing={2}>
            {payments.map((payment) => (
              <Grid key={payment.id} item xs={12}>
                <PaymentRow payment={payment} />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          <PageTitle>Payments</PageTitle>
          <NoResults>
            <Button variant="contained" color="primary" onClick={() => setOpenPaymentForm(true)}>
              Request Payment
            </Button>
          </NoResults>
        </Box>
      )}
      <MUIModal
        open={openPaymentForm}
        onClose={() => setOpenPaymentForm(false)}
      >
        <Modal
          title="Request Payment"
          onClose={() => setOpenPaymentForm(false)}
        >
          <PaymentForm folderId={folderId} onClose={() => setOpenPaymentForm(false)} />
        </Modal>
      </MUIModal>
    </Box>
  )
}

export default function FolderPage() {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { folder_id: folderId } = params;

  const { user, token } = useAuth();
  const unreadCountByFolderId = useSelector(
    (state: State) => state.notifications.unreadCountByFolderId
  );
  const sessionsById = useSelector(
    (state: State) => state.sessions.sessionsById
  );
  const folderSessionIds = useSelector(
    (state: State) => state.sessions.folderSessionIds
  );
  const eventsById = useSelector((state: State) => state.events.eventsById);
  const folderEventIds = useSelector(
    (state: State) => state.events.folderEventIds
  );
  const productIdsBySellerId = useSelector(
    (state: State) => state.products.productIdsBySellerId
  );
  const productsById = useSelector(
    (state: State) => state.products.productsById
  );
  const paymentsById = useSelector(
    (state: State) => state.payments.paymentsById
  );
  const paymentIds = useSelector(
    (state: State) => state.payments.paymentIdsByFolderId[folderId]
  )
  const featureFlags = useSelector((state: State) => state.account.user.profile.feature_flags);

  const [folder, setFolder] = useState<Folder>();
  const [variant, setVariant] = useState();
  const [openThread, setOpenThread] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openPayments, setOpenPayments] = useState(false);
  const [purchases, setPurchases] = useState([]);

  const products =
    folder && productIdsBySellerId[folder.owner.id]
      ? productIdsBySellerId[folder.owner.id].filter(
          (productId) =>
            productsById[productId].product_type === PRODUCT_TYPES.SINGLE ||
            (productsById[productId].product_type === PRODUCT_TYPES.GROUP &&
              moment(productsById[productId].event_date) >= moment())
        )
      : [];

  const fetchFolder = useCallback(async () => {
    const result = await get(`${API.FOLDER}${folderId}`, token);

    if (result.status === 403 || result.status === 401) {
      navigate("/login/experts");
    } else if (result.status === 200) {
      setFolder(result.data);

      if (result.data.owner.id === user.profile.id) {
        setVariant(FOLDER_VARIANTS.OWNER);
      } else {
        setVariant(FOLDER_VARIANTS.MEMBER);
      }
    } else {
      // TODO: Handle errors
    }
  }, [folderId, navigate, token, user.profile.id]);

  const fetchAndStorePurchases = async () => {
    const data = await fetchPurchases(dispatcher, navigate, token, folder.id);
    setPurchases(data);
  };

  const displayProduct = (product, bookNow) => {
    dispatcher({
      type: OPEN_PRODUCT,
      payload: {
        product,
        bookNow,
      },
    });
  };

  useEffect(() => {
    fetchFolder();
  }, [fetchFolder]);

  useEffect(() => {
    if (folder) {
      fetchSessions(dispatcher, navigate, token, folder.id);

      if (folder.owner.id === user.profile.id) {
        fetchEvents(dispatcher, navigate, token, folder.id);
      }

      fetchAndStorePurchases();
      fetchPayments(dispatcher, navigate, token, folder.id);
    }
  }, [dispatcher, navigate, folder, token]);

  return (
    <Container maxWidth="lg">
      {folder && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                {variant === FOLDER_VARIANTS.OWNER ? (
                  <PageTitle>
                    {displayName(
                      folder.members[0].first_name,
                      folder.members[0].last_name
                    )}
                  </PageTitle>
                ) : (
                  <PageTitle>
                    {displayName(
                      folder.owner.first_name,
                      folder.owner.last_name
                    )}
                  </PageTitle>
                )}
                <Button variant="contained" color="secondary" onClick={() => setOpenProfile(true)}>
                  {variant !== FOLDER_VARIANTS.OWNER ? 'View Profile & Book' : 'View Profile'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={0} sx={{ display: { xs: 'block', sm: 'none' }}}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box sx={{ fontFamily: 'Nunito' }} onClick={() => setOpenNotifications(true)}>
                    <Card variant="outlined">
                      <Box
                        textAlign="center"
                      >
                        <Badge
                          badgeContent={unreadCountByFolderId[folder.id]}
                          max={99}
                          sx={{
                            '& .MuiBadge-badge': {
                              color: '#FFFFFF',
                              backgroundColor: '#5371ff',
                            }
                          }}
                        >
                          <NotificationsNoneOutlinedIcon sx={{ fontSize: 40 }} />
                        </Badge>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <Card variant="outlined">
                      <Box
                        textAlign="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setOpenThread(true)}
                      >
                        <MessageOutlinedIcon sx={{ fontSize: 40 }} />
                      </Box>
                    </Card>
                  </Box>
                </Grid>
                {variant === FOLDER_VARIANTS.OWNER && (
                  <Grid item xs={6}>
                    <Box>
                      <Card variant="outlined">
                        <Box
                          textAlign="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setOpenNotes(true)}
                        >
                          <NoteAltOutlinedIcon sx={{ fontSize: 40 }} />
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Box>
                    <Card variant="outlined">
                      <Box
                        textAlign="center"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/clients/${folder.id}/${folder.owner.id === user.profile.id ? 'resources' : 'shares'}`)}
                      >
                        <Inventory2OutlinedIcon sx={{ fontSize: 40 }} />
                      </Box>
                    </Card>
                  </Box>
                </Grid>
                {(variant === FOLDER_VARIANTS.OWNER || paymentIds?.length > 0) && (
                  <Grid item xs={6}>
                    <Box>
                      <Card variant="outlined">
                        <Box
                          textAlign="center"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setOpenPayments(true)}
                        >
                          <AttachMoneyRoundedIcon sx={{ fontSize: 40 }} />
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={0} sm={12} sx={{ display: { xs: 'none', sm: 'block' }}}>
              <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Box sx={{ fontFamily: 'Nunito' }} onClick={() => setOpenNotifications(true)}>
                            <Card variant="outlined">
                                <Box
                                    textAlign="center"
                                >
                                    <Badge
                                        badgeContent={unreadCountByFolderId[folder.id]}
                                        max={99}
                                        sx={{
                                        '& .MuiBadge-badge': {
                                            color: '#FFFFFF',
                                            backgroundColor: '#5371ff',
                                        }
                                        }}
                                    >
                                        <NotificationsNoneOutlinedIcon sx={{ fontSize: 40 }} />
                                    </Badge>
                                    <Box mt={1} sx={{ fontWeight: 600 }}>
                                        Notifications
                                    </Box>
                                    {variant === FOLDER_VARIANTS.OWNER && (
                                      <Box mt={1}>
                                        <AddEventButton folderId={folderId} />
                                      </Box>
                                    )}
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Card variant="outlined">
                                <Box
                                    textAlign="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => setOpenThread(true)}
                                >
                                    <MessageOutlinedIcon sx={{ fontSize: 40 }} />
                                    <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>
                                        Messages
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    {variant === FOLDER_VARIANTS.OWNER && (
                        <Grid item xs={3}>
                            <Box>
                                <Card variant="outlined">
                                    <Box
                                        textAlign="center"
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => setOpenNotes(true)}
                                    >
                                        <NoteAltOutlinedIcon sx={{ fontSize: 40 }} />
                                        <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>
                                            Internal Client Notes
                                        </Box>
                                    </Box>
                                </Card>
                            </Box>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <Box>
                            <Card variant="outlined">
                                <Box
                                    textAlign="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => navigate(`/clients/${folder.id}/${folder.owner.id === user.profile.id ? 'resources' : 'shares'}`)}
                                >
                                    <Inventory2OutlinedIcon sx={{ fontSize: 40 }} />
                                    <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>
                                        Shared Resources
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box>
                            <Card variant="outlined">
                                <Box
                                    textAlign="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => setOpenPayments(true)}
                                >
                                    <AttachMoneyRoundedIcon sx={{ fontSize: 40 }} />
                                    <Box mt={1} sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>
                                        Payments
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
              {openPayments ? (
                <FolderPayments
                  variant={variant}
                  folderId={folderId}
                  payments={paymentIds ? paymentIds.map((id) => paymentsById[id]) : []}
                  onClose={() => setOpenPayments(false)}
                />
              ) : (
                <>
                  <FolderSessions
                    sessionsById={sessionsById}
                    folderSessionIds={folderSessionIds}
                  />
                  <FolderEvents
                    folderId={folderId}
                    eventsById={eventsById}
                    folderEventIds={folderEventIds}
                  />
                  <FolderPurchases purchases={purchases} />
                </>
              )}
            </Grid>
          </Grid>
          <Drawer
            anchor="right"
            open={openThread}
            onClose={() => setOpenThread(false)}
          >
            <Thread
              parentProfile={folder.members[0]}
              expertProfile={folder.owner}
              onDismiss={() => setOpenThread(false)}
            />
          </Drawer>
          <Drawer
            anchor="right"
            open={openNotes}
            onClose={() => setOpenNotes(false)}
          >
            <NotesList folder={folder} onClose={() => setOpenNotes(false)} />
          </Drawer>
          <Drawer
            anchor="right"
            open={openNotifications}
            onClose={() => setOpenNotifications(false)}
          >
            <Notifications
              folderId={folder.id}
              onDismiss={() => {
                setOpenNotifications(false);
              }}
            />
          </Drawer>
        </>
      )}
      {openProfile && (
        <Profile
          open={openProfile}
          profileId={
            variant === FOLDER_VARIANTS.OWNER
              ? folder.members[0].display_id
              : folder.owner.display_id
          }
          onClose={() => setOpenProfile(false)}
        />
      )}
    </Container>
  );
}
