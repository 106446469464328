/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const NotificationText = styled.span`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #210B2B;
`;