import { Box, Grid, Paper, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface SectionProps {
    title: string;
    children: ReactElement;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
}

export default function Section({ title, children, xs = 12, sm = 12, md = 12, lg = 12 }: SectionProps) {
    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <Paper elevation={0} style={{ border: '1px solid #e8e8e8'}}>
                <Box p={2}>
                    <Box textAlign="center">
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                    {children}
                </Box>
            </Paper>
        </Grid>
    );
}