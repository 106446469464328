import React, { useState } from 'react';
import moment from 'moment-timezone';

import { Box, Divider, Drawer, IconButton, Modal, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '../common/button';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Thread from '../thread';
import NoteForm from '../note/note-form';
import { ACCOUNT_TYPES, PRODUCT_TYPES } from '../../constants';
import { Event } from '../../types';
import { NoteBody, NoteDate, NoteTitle } from '../note/note.style.js';
import AddOrEditEvent from './add-or-edit-event';

interface EventCardProps {
  event: Event;
}

function EventCard({ event }: EventCardProps) {
  const [editEvent, setEditEvent] = useState(false);

  return (
    <>
      <Box onClick={() => setEditEvent(true)} mb={1} sx={{ background: '#FFFFFF', border: '1px solid #e8e8e8', borderRadius: 2, padding: 2, cursor: 'pointer' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
          <NoteBody>Event</NoteBody>
          <NoteDate>
              {moment(event.event_time).format('MMM D, YYYY')}
          </NoteDate>
        </Box>
        <NoteTitle className='fs-exclude'>{event.title}</NoteTitle>
        <NoteBody className='fs-exclude'>{event.description}</NoteBody>
      </Box>
      <Modal open={editEvent} onClose={() => setEditEvent(false)}>
        <AddOrEditEvent event={event} onClose={() => setEditEvent(false)} folderId={event.folder_id} />
      </Modal>
    </>
  );
}

export default EventCard;

