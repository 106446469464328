/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const RowText = styled.span`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #210B2B;
`;

export const ParticipantMax = styled.span`
    color: rgba(33, 11, 43, 0.3);
`;