import { get, patch, post } from '../services/api.services';
import { API, PaywallTypes } from '../constants';
import {
  RESET_PRODUCT_IDS,
  ADD_PRODUCT_ID,
  SET_PRODUCT,
  SET_LOADING,
  SET_PAYWALL,
  SET_HAS_NEXT_PAGE,
} from '../store/actions';
import { buildSearchQuery } from '../utils/search';

export const fetchProducts = async (dispatcher, navigate, token, filterSet) => {
  const result = await get(
    `${API.PRODUCT}${buildSearchQuery(filterSet)}`,
    token,
  );

  if (filterSet.offset === 0) {
    dispatcher({
      type: RESET_PRODUCT_IDS,
      payload: {},
    });
  }

  if (result.status === 403) {
    navigate('/login/parents');
  } else if (result.status === 200) {
    result.data.products.forEach((product) => {
      dispatcher({
        type: SET_PRODUCT,
        payload: {
          product,
        },
      });

      dispatcher({
        type: ADD_PRODUCT_ID,
        payload: {
          product,
        },
      });
    });

    if (!result.data.has_next_page) {
      dispatcher({
        type: SET_HAS_NEXT_PAGE,
        payload: {
          hasNextPage: false,
        },
      });
    }
  }
};

export const fetchProductsBySeller = async (
  dispatcher,
  navigate,
  token,
  sellerId,
  includePastEvents = false,
) => {
  dispatcher({
    type: SET_LOADING,
    payload: { loading: true },
  });

  const result = await get(
    `${API.PRODUCT}?seller_id=${sellerId}${
      includePastEvents ? '&include_past_events=true' : ''
    }`,
    token,
  );

  if (result.status === 403) {
    navigate('/login/parents');
  } else if (result.status === 200) {
    result.data.products.forEach((product) => {
      dispatcher({
        type: SET_PRODUCT,
        payload: {
          product,
        },
      });
    });

    dispatcher({
      type: SET_LOADING,
      payload: { loading: false },
    });

    return result.data.products;
  }
};

export const fetchTrackProducts = async (
  dispatcher,
  navigate,
  token,
  track,
) => {
  dispatcher({
    type: SET_LOADING,
    payload: { loading: true },
  });

  const result = await get(`${API.PRODUCT}?track=${track}`, token);

  if (result.status === 403) {
    navigate('/login/parents');
  } else if (result.status === 200) {
    result.data.products.forEach((product) => {
      dispatcher({
        type: SET_PRODUCT,
        payload: {
          product,
        },
      });
    });

    dispatcher({
      type: SET_LOADING,
      payload: { loading: false },
    });

    return result.data.products;
  }
};

export const fetchProduct = async (dispatcher, navigate, token, id) => {
  const result = await get(`${API.PRODUCT}${id}`, token);

  if (result.status === 403) {
    navigate('/login/parents');
  } else if (result.status === 200) {
    dispatcher({
      type: SET_PRODUCT,
      payload: {
        product: result.data,
      },
    });
  }
};

export const createProduct = async (dispatcher, navigate, token, data) => {
  const result = await post(`${API.PRODUCT}`, data, token);

  if (result.status === 403 || result.status === 401) {
    navigate('/login/experts');
  } else if (result.status === 201) {
    dispatcher({
      type: SET_PRODUCT,
      payload: {
        product: result.data,
      },
    });

    return result;
  }
};

export const saveProduct = async (dispatcher, navigate, token, id, data) => {
  const result = await patch(`${API.PRODUCT}${id}/`, data, token);

  if (result.status === 403 || result.status === 401) {
    navigate('/login/experts');
  } else if (result.status === 400 && result.data.usage_exceeding) {
    dispatcher({
      type: SET_PAYWALL,
      payload: {
        paywall: true,
        paywall_type: PaywallTypes.ADD_ON,
      },
    });
  } else if (result.status === 200) {
    dispatcher({
      type: SET_PRODUCT,
      payload: {
        product: result.data,
      },
    });
  }

  return result;
};

export const fetchRecommendedProducts = async (
  dispatcher,
  navigate,
  token,
  body,
) => {
  dispatcher({
    type: SET_LOADING,
    payload: { loading: true },
  });

  const result = await post(`${API.PRODUCT}recommendations/`, body, token);

  if (result.status === 403) {
    navigate('/login/parents');
  } else if (result.status === 200) {
    result.data.products.forEach((product) => {
      dispatcher({
        type: SET_PRODUCT,
        payload: {
          product,
        },
      });
    });

    dispatcher({
      type: SET_LOADING,
      payload: { loading: false },
    });

    return result.data.products;
  }
};
