export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const stringToColor = (string: string): string => {
    // Handle null case
    if (!string) {
        return '#5371ff';
    }

    // Create a hash from the name
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Generate a pastel color from the hash
    let color = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xFF;
        // Combine the value with a light color (200, for example) to make it pastel
        color[i] = Math.round((value + 200) / 2);
    }

    // Convert the color to a hex string
    let hexColor = color.map(value => {
        let hex = value.toString(16);
        return hex.length < 2 ? '0' + hex : hex;
    }).join('');

    return '#' + hexColor;
}

export const isLightColor = (hex: string): boolean => {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse r, g, b values
    const bigint: number = parseInt(hex, 16);
    const r: number = (bigint >> 16) & 255;
    const g: number = (bigint >> 8) & 255;
    const b: number = bigint & 255;

    // Calculate the luminance
    const luminance: number = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return light or dark
    return luminance > 0.5 ? true : false;
}
