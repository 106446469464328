import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Button, Box, Stack, Snackbar, Alert } from '@mui/material';
import { PARENT_REGISTRATION } from '../../../utils/forms';
import DynamicForm from '..';
import { State } from '../../../types';
import { ACCOUNT_TYPES, API } from '../../../constants';
import { ACCOUNT_INITIALIZE } from '../../../store/actions';
import { post } from '../../../services/api.services';

const MatchQuizEnd = ({ onStart, onSubmit }) => {
    const dispatcher = useDispatch();
    const { user, token } = useSelector((state: State) => state.account);

    const [showRegistration, setShowRegistration] = useState(false);
    const [error, setError] = useState<null | string>(null);

    useEffect(() => {
        if (user) {
            onSubmit();
        }
    }, [user]);

    return (
        <Box style={{ padding: '20px' }}>
            {!showRegistration ? (
                <Stack spacing={2}>
                    <Typography variant="h6">Want to save your answers for next time?</Typography>
                    <Typography variant="body1">
                        Create an account to save your answers and fast-track your next question.
                    </Typography>
                    <Stack direction="row" spacing={2} mt={2}>
                        <Button variant="contained" color="secondary" onClick={onSubmit}>No, Just See My Matches</Button>
                        <Button variant="contained" color="primary" onClick={() => setShowRegistration(true)}>Yes, Create My Account</Button>
                    </Stack>
                </Stack>
            ) : (
                <>
                    <DynamicForm
                        config={PARENT_REGISTRATION}
                        onSubmit={async (data) => {
                            setError(null);
                            
                            const payload = {
                                username: data[3],
                                email: data[3],
                                password: data[4],
                                profile_type: ACCOUNT_TYPES.PARENT,
                                coupon: data[5],
                                lite_registration: true,
                            };
                              
                            const result: any = await post(API.REGISTER, payload);
                              
                            if (result.status === 201) {
                                dispatcher({
                                    type: ACCOUNT_INITIALIZE,
                                    payload: {
                                        isLoggedIn: true,
                                        isVerified: false,
                                        user: {
                                            accountType: ACCOUNT_TYPES.PARENT,
                                            ...result.data.user,
                                        },
                                        token: result.data.token,
                                        refresh: result.data.refresh,
                                    },
                                });
                                onSubmit();
                            } else {
                                if (result.data.email) {
                                    setError(result.data.email[0]);
                                } else if (result.data.username) {
                                    setError(result.data.username[0].replace('username', 'email'));
                                }
                          
                                if (result.data.password) {
                                    setError(result.data.password[0]);
                                }
                          
                                if (result.data.code) {
                                    setError(result.data.code);
                                }
                            }
                        }}
                    />
                </>
            )}
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default MatchQuizEnd;
