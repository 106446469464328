import {
    PaymentPlanActionType
} from '../store/actions';

import { get, post, patch, destroy } from '../services/api.services';
import { API } from '../constants';
import { resourceLimits } from 'worker_threads';

export const fetchPaymentPlans = async (dispatcher, navigate, token) => {
    const result = await get(
        API.PAYMENT_PLAN,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        dispatcher({
            type: PaymentPlanActionType.RESET_PAYMENT_PLAN_IDS,
            payload: {},
        });
    
        result.data.forEach((paymentPlan) => {
            dispatcher({
                type: PaymentPlanActionType.SET_PAYMENT_PLAN,
                payload: {
                  paymentPlan,
                },
            });

            dispatcher({
                type: PaymentPlanActionType.ADD_PAYMENT_PLAN_ID,
                payload: {
                  paymentPlan,
                },
            });
        })
    }
}

export const createPaymentPlan = async (dispatcher, navigate, token, data) => {
    const result = await post(
        API.PAYMENT_PLAN,
        data,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 201) {
        dispatcher({
            type: PaymentPlanActionType.SET_PAYMENT_PLAN,
            payload: {
              paymentPlan: result.data,
            },
        });

        dispatcher({
            type: PaymentPlanActionType.ADD_PAYMENT_PLAN_ID,
            payload: {
              paymentPlan: result.data,
            },
        });
    }
}

export const updatePaymentPlan = async (dispatcher, navigate, token, paymentPlan, data) => {
    const result = await patch(
        `${API.PAYMENT_PLAN}${paymentPlan.id}/`,
        data,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 200) {
        dispatcher({
            type: PaymentPlanActionType.SET_PAYMENT_PLAN,
            payload: {
              paymentPlan: result.data,
            },
        });
    }
}

export const deletePaymentPlan = async (dispatcher, navigate, token, paymentPlan) => {
    const result = await destroy(
        `${API.PAYMENT_PLAN}${paymentPlan.id}/`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 204) {
        dispatcher({
            type: PaymentPlanActionType.REMOVE_PAYMENT_PLAN_ID,
            payload: {
                paymentPlan,
            }
        });
    }
}
