import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, Card, Container, Grid, Modal, Typography } from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { useAuth } from '../../hooks';
import Policy from "./policy";
import PaymentScheduleForm from './payment-schedule-form';
import { State } from '../../types';
import { fetchPaymentPlans, updatePaymentPlan } from '../../clients/paymentPlan';
import { updateUser } from '../../clients/profile';
import { CANCELATION_POLICIES } from '../../constants';

interface PaymentSettingsProps {}

export default function PaymentSettings({}: PaymentSettingsProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();
    const paymentPlanIds = useSelector(
        (state: State) => state.paymentPlans.paymentPlanIds,
    );
    const paymentPlansById = useSelector(
        (state: State) => state.paymentPlans.paymentPlansById,
    );
    const defaultPaymentPlanId = useSelector(
        (state: State) => state.paymentPlans.defaultPaymentPlanId,
    );

    const [openPaymentPlanForm, setOpenPaymentPlanForm] = useState(false);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState();

    const closePaymentPlanForm = () => {
        setOpenPaymentPlanForm(false);
        setSelectedPaymentPlan(null);
    }

    const onUpdatePaymentPlanDefault = async (paymentPlan, applyToExisting) => {
        if (defaultPaymentPlanId) {
            await updatePaymentPlan(
                dispatcher,
                navigate,
                token,
                paymentPlansById[defaultPaymentPlanId],
                {
                    is_default: false,
                }
            );
        }

        await updatePaymentPlan(
            dispatcher,
            navigate,
            token,
            paymentPlan,
            {
                is_default: true,
                update_services: applyToExisting,
            }
        );
    };

    const onUpdateCancelationDefault = async (cancelationPolicy, applyToExisting) => {
        await updateUser(
            dispatcher,
            navigate,
            user,
            token,
            {
                default_cancelation_policy: cancelationPolicy,
                update_services: applyToExisting,
            }
        );
    };

    useEffect(() => {
        fetchPaymentPlans(dispatcher, navigate, token);
    }, []);

    return (
        <Container maxWidth="lg">
            <Box
                mt={2}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h5">Payment Plans</Typography>
                <Button variant="contained" color="primary" onClick={() => setOpenPaymentPlanForm(true)}>
                    <AddRoundedIcon /> Add a Payment Plan
                </Button>
            </Box>
            <Card elevation={0}>
                <Box p={4}>
                    <Grid container spacing={2}>
                        {paymentPlanIds.map((id) => (
                            <Grid
                                item
                                xs={12}
                                md={4}
                            >
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        height: '100%',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#E8E8E8',
                                        borderRadius: 1,
                                    }}
                                    onClick={() => setSelectedPaymentPlan(paymentPlansById[id])}
                                >
                                    <Policy
                                        title={paymentPlansById[id].name}
                                        description={paymentPlansById[id].description}
                                        isDefault={paymentPlansById[id].is_default}
                                        onMakeDefault={(applyToExisting) => {
                                            onUpdatePaymentPlanDefault(paymentPlansById[id], applyToExisting);
                                        }}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Card>
            <Box
                mt={2}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h5">Cancellation Policy</Typography>
            </Box>
            <Card elevation={0}>
                <Box p={4}>
                    <Grid container spacing={1}>
                        {CANCELATION_POLICIES.map((policy) => (
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        height: '100%',
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#E8E8E8',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Policy
                                        title={policy.title}
                                        description={policy.description}
                                        isDefault={policy.value === user.profile.default_cancelation_policy}
                                        onMakeDefault={(applyToExisting) => onUpdateCancelationDefault(policy.value, applyToExisting)}
                                    />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Card>
            <Modal
                open={openPaymentPlanForm || !!selectedPaymentPlan}
                onClose={closePaymentPlanForm}
            >
                <PaymentScheduleForm
                    paymentPlan={selectedPaymentPlan}
                    onClose={closePaymentPlanForm}
                />
            </Modal>
        </Container>
    );
}
