import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Container, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import { API, PaywallTypes, TRACKS } from '../../constants';
import { post, get, patch } from '../../services/api.services';
import { SET_PAYWALL, SET_USER } from '../../store/actions';
import { State } from '../../types';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

interface TracksProps {}

export default function Tracks({}: TracksProps) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: State) => state.account.user);
  const token = useSelector((state: State) => state.account.token);
  const [tracks, setTracks] = useState(user.profile.tracks || []);

  const save = async () => {
    const result = await patch(
      `${API.PROFILE}${user.profile.id}/`,
      {
        tracks,
      },
      token,
    );
    if (result.status === 403 || result.status === 401) {
      navigate('/login/parents');
    }
    else if (result.status === 200) {
      dispatcher({
        type: SET_USER,
        payload: {
          user: {
            ...user,
            profile: {
              ...result.data,
            },
          },
        },
      });
    }
    else {
      toast.error('Something went wrong');
    }
  };

  const openPaywall = () => {
    dispatcher({
      type: SET_PAYWALL,
      payload: {
        paywall: true,
        paywall_type: PaywallTypes.BASE, 
      },
    });
  };

  const toggleTrack = (track: string) => {
    if (!user.profile.feature_flags.tracks) {
      openPaywall();
    } else {
      if (tracks.includes(track)) {
          setTracks(prev => prev.filter((val) => val !== track));
      } else {
          setTracks([...tracks, track]);
      }
    }
  }

  useEffect(() => {
    save();
  }, [tracks]);

  return (
    <Container maxWidth="lg">
        <Box mt={2}>
            <Box mb={2}>
                <Typography variant="h5">Tracks</Typography>
                <Typography variant="subtitle1">
                    Follow tracks for your stage(s) of parenthood to get suggestions of
                    resources, services and brands on your dashboard
                </Typography>
            </Box>
            <List sx={{ backgroundColor: '#FFFFFF' }}>
                {TRACKS.map((track, index) => (
                    <>
                        <ListItem key={index}>
                            <ListItemAvatar>
                                <Avatar
                                    alt={track.title}
                                    variant="square"
                                    src={track.image}
                                    sx={{ width: 100, height: 100, borderRadius: 1, mr: 2 }}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={track.title} secondary={track.description} />
                            <Button
                                variant={tracks.includes(track.value) ? 'contained' : 'outlined'}
                                color="primary"
                                onClick={() => toggleTrack(track.value)}
                                sx={{ ml: 1 }}
                                startIcon={tracks.includes(track.value) ? <BookmarkAddedOutlinedIcon /> : <BookmarkAddOutlinedIcon />}
                            >
                                {tracks.includes(track.value) ? 'Following' : 'Follow'}
                            </Button>
                        </ListItem>
                        {index < TRACKS.length - 1 && (
                            <Divider variant="fullWidth" component="li" />
                        )}
                    </>
                ))}
            </List>
        </Box>
    </Container>
  );
}

