/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Filters = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 10%;
    font-family: 'Cabin';
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 10px;
    margin-top: 10%;
    max-height: 80%;
    overflow-y: scroll;
`;

export const FilterTitle = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
`;

export const FilterField = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 10px;
`;

export const NoResults = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Cabin';
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
`;