// action - state management
import { PaymentActionType } from "./actions";
import { Payment } from "../types";

export const initialState = {
  paymentsById: {},
  paymentIdsByFolderId: {},
};

type PaymentAction =
  | { type: PaymentActionType.SET_PAYMENT; payload: { payment: Payment } }
  | { type: PaymentActionType.ADD_PAYMENT_ID; payload: { payment: Payment, folderId: string } }
  | { type: PaymentActionType.RESET_PAYMENT_IDS; payload: { folderId: string } }
  | { type: PaymentActionType.REMOVE_PAYMENT_ID; payload: { payment: Payment } }

const paymentReducer = (state = initialState, action: PaymentAction) => {
  switch (action.type) {
    case PaymentActionType.SET_PAYMENT: {
        const { payment } = action.payload;
        const newPaymentsById = { ...state.paymentsById };
        newPaymentsById[payment.id] = payment;
        return {
            ...state,
            paymentsById: newPaymentsById,
        };
    }
    
    case PaymentActionType.ADD_PAYMENT_ID: {
        const { payment, folderId } = action.payload;
        const newPaymentIdsByFolderId = { ...state.paymentIdsByFolderId };
        
        if (!newPaymentIdsByFolderId[folderId]) {
            newPaymentIdsByFolderId[folderId] = [payment.id];
        } else {
            newPaymentIdsByFolderId[folderId] = [ payment.id, ...newPaymentIdsByFolderId[folderId] ];
        }
        
        return {
            ...state,
            paymentIdsByFolderId: newPaymentIdsByFolderId,
        };
    }

    case PaymentActionType.RESET_PAYMENT_IDS: {
        const { folderId } = action.payload;
        const newPaymentIdsByFolderId = { ...state.paymentIdsByFolderId };
        newPaymentIdsByFolderId[folderId] = [];
        return {
            ...state,
            paymentIdsByFolderId: newPaymentIdsByFolderId,
        };
    }
    case PaymentActionType.REMOVE_PAYMENT_ID: {
        const { payment } = action.payload;
        const newPaymentIdsByFolderId = { ...state.paymentIdsByFolderId };

        Object.keys(newPaymentIdsByFolderId).forEach((key) => {
            if (newPaymentIdsByFolderId[key].includes(payment.id)) {
                newPaymentIdsByFolderId[key] = newPaymentIdsByFolderId[key].filter((id) => id !== payment.id);
            }
        });

        return {
            ...state,
            paymentIdsByFolderId: newPaymentIdsByFolderId,
        };
    }
    default: {
      return { ...state };
    }
  }
};

export default paymentReducer;
