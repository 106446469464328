import styled from 'styled-components';

export const LargeSeparator = styled.div`
  margin-bottom: 8rem;
`;

export const BaseSeparator = styled.div`
  margin-bottom: 4rem;
`;

export const SmallSeparator = styled.div`
  margin-bottom: 2rem;
`;
