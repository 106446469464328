import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';

import { Box, Button, Card, Container, LinearProgress, Typography } from '@mui/material';

import { State } from '../../types';
import { API, ORDERED_ONBOARDING_STEPS } from '../../constants';
import { SET_USER } from '../../store/actions';
import { OnboardingSteps } from '../../constants';
import { patch } from '../../services/api.services';
import { fetchUser } from '../../clients/profile';
import TrainingVideo from './training-video';
import OnboardingSurvey from './onboarding-survey';
import ConnectBank from './connect-bank';
import AddSingleProduct from './add-single-product';
import AddGroupProduct from './add-group-product';
import ListingPage from './listing-page';
import InviteClient from './invite-client';
import SocialAnnouncment from './social-announcement';
import TrainingDocumentation from './training-documentation';
import AgreedToTos from './agreed-to-tos';
import Confetti from '../../assets/images/confetti.gif';

interface OnboardingProps {}

export default function Onboarding({}: OnboardingProps) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();
    const location = useLocation();

    const params = new URLSearchParams(location.search);

    const user = useSelector((state: State) => state.account.user);
    const token = useSelector((state: State) => state.account.token);

    const [stepIndex, setStepIndex] = useState(0);
    const [stepsMarkedComplete, setStepsMarkedComplete] = useState([]);

    const steps = [];

    if (user.profile.onboarding_checklist) {
        Object.keys(user.profile.onboarding_checklist).forEach((key) => {
            if (!user.profile.onboarding_checklist[key]) {
                steps.push(key);
            }
        });
    }

    let orderedSteps: string[] = [ ...ORDERED_ONBOARDING_STEPS ];

    orderedSteps = orderedSteps.filter((e => steps.includes(e)));
    
    // If coming from a link to a specific item, add it first
    if (params.get('key')) {
        const listKeyIndex = orderedSteps.indexOf(params.get('key'));

        if (listKeyIndex !== -1) {
            orderedSteps.splice(listKeyIndex, 1);
        }
        orderedSteps.unshift(params.get('key'));
    }

    const updateUser = async () => {
        await fetchUser(dispatcher, user, token);
    };

    const markStepComplete = (stepKey) => {
        setStepsMarkedComplete([ ...stepsMarkedComplete, stepKey ]);
    };

    const unmarkStepComplete = (stepKey) => {
        setStepsMarkedComplete(stepsMarkedComplete.filter((key) => key !== stepKey));
    };

    const saveChecklist = async (update = false) => {
        const newChecklist = { ...user.profile.onboarding_checklist };

        stepsMarkedComplete.forEach((stepKey) => {
            newChecklist[stepKey] = true;
        });
    
        const result = await patch(
            `${API.PROFILE}${user.profile.id}/`,
            {
              onboarding_checklist: newChecklist,
            },
            token,
        );

        if (update) {
            updateUser();
        }
    }

    const prev = () => {
        setStepIndex(stepIndex - 1);
    };
    
    const next = () => {
        setStepIndex(stepIndex + 1);
        saveChecklist();
    };

    useEffect(() => {
        updateUser();
    }, []);

    useEffect(() => {
        if (orderedSteps.length === stepsMarkedComplete.length) {
            saveChecklist(true);
        }
    }, [stepsMarkedComplete]);

    if (orderedSteps.length === 0) {
        return (
            <Container maxWidth="lg">
                <Box
                    mt={2}
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h5">Onboarding Complete!</Typography>
                </Box>
                <Box mb={2}>
                    <LinearProgress variant="determinate" value={100} sx={{ borderRadius: 2, height: 10 }} />
                </Box>
                <Card>
                    <Box p={4} display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h5">Way to go! You've completed your onboarding checklist.</Typography>
                        <img width={200} src={Confetti} />
                    </Box>
                </Card>
            </Container>
        )
    }

    let step = null;
    const stepKey = orderedSteps[stepIndex];

    switch (stepKey) {
        case OnboardingSteps.ONBOARDING_SURVEY:
            step = (
                <OnboardingSurvey
                    markedComplete={stepsMarkedComplete.indexOf(OnboardingSteps.ONBOARDING_SURVEY) > -1}
                    markStepComplete={() => markStepComplete(OnboardingSteps.ONBOARDING_SURVEY)}
                    unmarkStepComplete={() => unmarkStepComplete(OnboardingSteps.ONBOARDING_SURVEY)}
                />
            );
            break;
        case OnboardingSteps.LISTING_PAGE:
            step = <ListingPage />;
            break;
        case OnboardingSteps.AGREED_TO_TOS:
            step = <AgreedToTos />;
            break;
        case OnboardingSteps.CONNECT_BANK:
            step = <ConnectBank />;
            break;
        case OnboardingSteps.INVITE_CLIENT:
            step = <InviteClient />;
            break;
        case OnboardingSteps.ADD_SINGLE_PRODUCT:
            step = <AddSingleProduct />;
            break;
        case OnboardingSteps.ADD_GROUP_PRODUCT:
            step = <AddGroupProduct />;
            break;
        case OnboardingSteps.ADD_RESOURCE_PRODUCT:
            step = <div />;
            break;
        case OnboardingSteps.SOCIAL_ANNOUNCEMENT:
            step = (
                <SocialAnnouncment
                    markedComplete={stepsMarkedComplete.indexOf(OnboardingSteps.SOCIAL_ANNOUNCEMENT) > -1}
                    markStepComplete={() => markStepComplete(OnboardingSteps.SOCIAL_ANNOUNCEMENT)}
                    unmarkStepComplete={() => unmarkStepComplete(OnboardingSteps.SOCIAL_ANNOUNCEMENT)}
                />
            );
            break;
        case OnboardingSteps.SHARE_PROFILE:
            step = <div />;
            break;
        case OnboardingSteps.SHARE_PRODUCT:
            step = <div />;
            break;
        case OnboardingSteps.TRAINING_DOCUMENTATION:
            step = <TrainingDocumentation />
            break;
        default:
            return null;
    };

    return (
        <Container maxWidth="lg">
            <Box
                mt={2}
                mb={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant="h5">Complete Onboarding</Typography>
            </Box>
            <Box mb={2}>
                <LinearProgress variant="determinate" value={((stepIndex + 1) / (orderedSteps.length)) * 100} sx={{ borderRadius: 2, height: 10 }} />
            </Box>
            <Card>
                <Box p={4}>
                    {step}
                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                        {stepIndex !== 0 ? (
                            <Button variant="contained" color="secondary" onClick={prev}>Back</Button>
                        ) : (
                            <Box />
                        )}
                        {stepIndex < orderedSteps.length - 1 ? (
                            <Button variant="contained" color="primary" onClick={next}>Next</Button>
                        ) : (
                            <Box />
                        )}
                    </Box>
                </Box>
            </Card>
        </Container>
  );
}
