export const displayName = (firstName, lastName) => {
  return `${firstName} ${lastName ? `${lastName.charAt(0)}.` : ''}`;
};

export const calculateAge = (birthDateString) => {
  const today = new Date();
  const birthDate = new Date(birthDateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }

  return age;
};

export const removeBlankEntries = (arr) => {
  return arr.filter((item) => item !== '');
};

const BASE_PRICES = [
  'price_1MlfCuHf16Ud2OOxyw80aSbv', // Prod Business Monthly
  'price_1NnSXHHf16Ud2OOx8mg4HOgR', // Prod Business Annually
  'price_1Nn3hzHf16Ud2OOxbmEsA5Le', // Prod Pro Monthly
  'price_1NnSWVHf16Ud2OOxbfYaXxnM', // Prod Pro Annually
  'price_1MPY0RHf16Ud2OOxAIgb3b2q', // Dev Business Monthly
  'price_1NnSViHf16Ud2OOxnjat7ISw', // Dev Business Annually
  'price_1NijrRHf16Ud2OOxI3ekOYAA', // Dev Pro Monthly
  'price_1NnSUhHf16Ud2OOxE47FNUw7', // Dev Pro Annually
  'price_1P9LHXHf16Ud2OOxbf6ZzKtA', // Dev Parent One-Time
  'price_1OG1h0Hf16Ud2OOx5bRICnft', // Dev Parent Monthly
  'price_1OG1h0Hf16Ud2OOxJezgYVme', // Dev Parent Quarterly
  'price_1OG1h0Hf16Ud2OOxiEh8XVzh', // Dev Parent Annually
  'price_1P9LBuHf16Ud2OOxooRum9aL', // Prod Parent One-Time
  'price_1OG5RzHf16Ud2OOx2BYM5mEw', // Prod Parent Monthly
  'price_1OG5RzHf16Ud2OOxLY3ZHaL6', // Prod Parent Quarterly
  'price_1OG5RzHf16Ud2OOxEFSK0BGS', // Prod Parent Annually
  'price_1PW1MZHf16Ud2OOxbwTImd9S', // Prod Parent Insider
  'price_1PW1PdHf16Ud2OOxg4V7CYuc', // Dev Parent Insider
  'price_1PfDLZHf16Ud2OOxL1TW5nfe', // Prod Pathfinder
  'price_1PfDQJHf16Ud2OOxgsUJgebo', // Dev Pathfinder
  'price_1PikOpHf16Ud2OOxo2VqgqYs', // Dev Resource
  'price_1PikPVHf16Ud2OOxrKWaxYTP', // Prod Resource
  'price_1Pjal7Hf16Ud2OOxcEuv3d5r', // Prod Supplier Insider
  'price_1PjalvHf16Ud2OOxoOSakFDx', // Dev Supplier Insider
];

const BASE_PRODUCTS = [
  'prod_NWieJmAUSBnTSS', // Prod Business
  'prod_N9rja1oC6pPuR1', // Dev Business
  'prod_OaEAlmpo6sJg60', // Prod Pro
  'prod_OVlOA7C07oTyh0', // Dev Pro
  'prod_P4DtBzz7th2Fmr', // Prod Parent
  'prod_P4A14dEASQ2Jol', // Dev Parent
  'prod_PzfWCDB2oYMRyp', // Prod Parent Insider
  'prod_PzfUsACnUZoDI1', // Dev Parent Insider
  'prod_QWFrZx971l4o38', // Prod Pathfinder
  'prod_QWFwYWeV5svJwg', // Dev Pathfinder
  'prod_QZuDK6cUE2Gqmf', // Dev Resource
  'prod_QZuDiukwpEu9v8', // Prod Resource
  'prod_QamJeSiZdDj1UV', // Prod Supplier Insider
  'prod_QamKJaAPbgyd2C', // Dev Supplier Insider
];

export const getSubscriptionBaseProduct = (user) => {
  const productId = user.profile.subscription?.plan.product;
  
  if (BASE_PRODUCTS.includes(productId)) {
    return productId;
  }

  return null;
};

export const getSubscriptionBasePrice = (user) => {
  const planId = user.profile.subscription?.plan.id;
  return planId || null;
};