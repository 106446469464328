import { get, patch, post } from '../services/api.services';
import { API } from '../constants';
import { SET_USER, SET_PROFILE } from '../store/actions';

export const fetchUser = async (dispatcher, user, token) => {
    const result = await get(
      `${API.USER}${user.id}`,
      token,
    );

    if (result.status === 200) {
      dispatcher({
        type: SET_USER,
        payload: {
          user: result.data,
        },
      });
    }
};

export const fetchProfile = async (dispatcher, user, token) => {
    const result = await get(
      `${API.PROFILE}${user.profile.id}`,
      token,
    );

    if (result.status === 200) {
      dispatcher({
        type: SET_USER,
        payload: {
          user: {
            ...user,
            profile: {
                ...result.data,
            },
          },
        },
      });
    }
};

export const fetchDisplayProfile = async (dispatcher, displayId, token) => {
    const result = await get(
        `${API.PROFILE}${displayId}/display/`,
        token,
    );

    if (result.status === 200) {
        dispatcher({
            type: SET_PROFILE,
            payload: {
              profile: result.data,
            },
        });
    }
};

export const updateUser = async (dispatcher, navigate, user, token, data) => {
    const result = await patch(
        `${API.PROFILE}${user.profile.id}/`,
        data,
        token,
    );
  
    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        dispatcher({
            type: SET_USER,
            payload: {
                user: {
                    ...user,
                    profile: {
                        ...result.data,
                    },
                },
            },
        });

        return true;
    } else {
        return false;
    }
}

export const fetchPaymentMethods = async (navigate, user, token) => {
    const result = await get(
        `${API.PROFILE}${user.profile.id}/payment_methods/`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate(`/login/${user.profile.profile_type}s`);
    }
    else if (result.status === 200) {
        return result.data.payment_methods;
    }
    else {
      return false;
    }
}

export const setDefaultPaymentMethod = async (navigate, user, token, sourceId) => {
    const result = await post(
        `${API.PROFILE}${user.profile.id}/set_default_payment_method/`,
        {
            'source_id': sourceId,
        },
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate(`/login/${user.profile.profile_type}s`);
    }

    else if (result.status === 200) {
        return true;
    }
    else {
        return false;
    }
}

export const setSubscription = async (dispatcher, navigate, user, token, priceId, payment_method, quantity=1, oneTimeCharge=null) => {
    const result = await post(
        `${API.PROFILE}${user.profile.id}/subscribe/`,
        {
            'price_id': priceId,
            'one_time_charge': oneTimeCharge,
            'payment_method': payment_method,
            'quantity': quantity,
        },
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate(`/login/${user.profile.profile_type}s`);
    }
    else if (result.status === 200) {
        await fetchProfile(dispatcher, user, token);
        return {
            success: true,
        };
    }
    else {
        return {
            success: false,
            message: result.data.message,
        };
    }
}

export const applyCoupon = async (dispatcher, navigate, user, token, couponCode) => {
    const result = await patch(
        `${API.PROFILE}${user.profile.id}/`,
        {
            coupon: couponCode,
        },
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate(`/login/${user.profile.profile_type}`);
    }

    if (result.status === 200) {
        dispatcher({
            type: SET_USER,
            payload: {
                user: {
                    ...user,
                    profile: {
                        ...result.data,
                    },
                },
            },
        });

        return true;
    } else {
        return false;
    }
}

export const fetchRecommendedProfiles = async (
    dispatcher,
    navigate,
    token,
    body,
  ) => {
    const result = await post(`${API.PROFILE}recommendations/`, body, token);
  
    if (result.status === 403) {
      navigate('/login/parents');
    } else if (result.status === 200) {
      result.data.profiles.forEach((profile) => {
        dispatcher({
          type: SET_PROFILE,
          payload: {
            profile,
          },
        });
      });
  
      return result.data.profiles;
    }
  };