import {
    ResourceActionType
} from '../store/actions';

import { get } from '../services/api.services';
import { API } from '../constants';

export const fetchPurchases = async (dispatcher, navigate, token, folderId = null) => {
    const result = await get(
        `${API.ATTENDEE}${folderId ? `?folder_id=${folderId}` : ''}`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        return result.data;
    }
}
