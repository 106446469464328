import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton } from '@mui/material';

import DismissIcon from '../../../assets/icons/Dismiss.svg';

import {
  ModalContentContainer,
  ModalHeader,
  ModalHeaderLeft,
  ModalTitle,
  ModalBody,
  ModalHeaderRight,
} from '../../profile/Profile.style';

function Modal({ title, onClose, children, headerRight }) {
    return (
        <ModalContentContainer>
            <ModalHeader>
                <ModalHeaderLeft>
                    <IconButton onClick={onClose}>
                        <img src={DismissIcon} alt="share" width={12} height={12} />
                    </IconButton>
                    <ModalTitle>
                        {title}
                    </ModalTitle>
                </ModalHeaderLeft>
                <ModalHeaderRight>
                    {headerRight}
                </ModalHeaderRight>
            </ModalHeader>
            <ModalBody>
              {children}
            </ModalBody>
        </ModalContentContainer>
  );
}

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    onClose: PropTypes.func,
    headerRight: PropTypes.any,
};

export default Modal;