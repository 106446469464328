/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Subtitle = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #210B2B;
    margin-bottom: 10px;
`;

export const Card = styled.div`
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    padding: 10px;
    cursor: pointer;
`;

export const CardTitle = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 150%;
    color: #210B2B;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 30px;
`;

export const CardSubtitle = styled.div`
    font-size: 15px;
`;

export const CardImg = styled.div`
    width: 100%;
    height: 120px;
    border-radius: 10px;
    background-image: url('${props => props.src}');
    background-size: cover;
    background-repeat: no-repeat;
`;

export const CardDetail = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 150%;
    color: #B4B4B4;
`;

export const CardLink = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #5371ff;
    margin-top: 5px;
`;