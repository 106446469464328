// action - state management
import * as actionTypes from './actions';

export const initialState = {
    amount: undefined,
    recipientName: undefined,
    recipientEmail: undefined,
    message: '',
    sendDate: undefined,
    purchaseSaved: false,
};  

const giftCardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.RESET_GIFT_CARD_INFO:
        return {
            amount: undefined,
            recipientName: undefined,
            recipientEmail: undefined,
            message: '',
            sendDate: undefined,
            purchaseSaved: false,
        };
    case actionTypes.SET_GIFT_CARD_INFO:
      return {
        ...state,
        amount: action.payload.amount,
        recipientName: action.payload.recipientName,
        recipientEmail: action.payload.recipientEmail,
        message: action.payload.message,
        sendDate: action.payload.sendDate,
        purchaseSaved: action.payload.purchaseSaved,
      };
    default:
      return state;
  }
};

export default giftCardReducer;
