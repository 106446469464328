import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Avatar, Box, Modal } from '@mui/material';

import {
    MyMessage,
    Timestamp,
    YourMessage,
    ShareMessage,
} from './thread.style';
import Button from '../common/button';
import SignatureForm from '../signature/signature-form';
import { SET_SHARE_TO_SIGN } from '../../store/actions';
import { stringToColor } from '../../utils/stringHelpers';

function Message({ message, previousMessage }) {
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);

    const openSignatureForm = (share) => {
        dispatcher({
            type: SET_SHARE_TO_SIGN,
            payload: { share },
        });
    };

    const msgSent = moment(message.created_at);
    let includeTimestamp = true;

    if (previousMessage) {
        const prevMsgSent = moment(previousMessage.created_at); 
        const minutesSinceLastMsg = msgSent.diff(prevMsgSent, 'minutes'); 

        if (minutesSinceLastMsg < 5) {
            includeTimestamp = false;
        }
    }

    let MessageContainer;
    let messageContent;
    
    if (message.signature) {
        MessageContainer = ShareMessage;
        messageContent = (
            <>
                <Box sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>{message.signature.share.resource.title}</Box>
                <Box>{message.signature.signature_name} signed on {moment(message.signature.created_at).format('M/D/YYYY')}</Box>
                <Box mt={2}>
                    <Button shade="secondary" onClick={() => window.open(message.signature.signed_file_url, '_blank')}>Review</Button>
                </Box>
            </>
        )
    } else if (message.share) {
        MessageContainer = ShareMessage;
        messageContent = (
            <>
                {message.share.resource.requires_signature && (
                    <Box sx={{ textDecoration: 'underline', fontSize: '10px' }}>
                        Requires signature
                    </Box>
                )}
                <Box sx={{ fontFamily: 'Nunito', fontWeight: 600 }}>{message.share.resource.title}</Box>
                <Box>{message.text}</Box>
                {message.share.resource.requires_signature ? (
                    <Box mt={2}>
                        <Button shade="secondary" onClick={() => openSignatureForm(message.share)}>Review + Sign</Button>
                    </Box>
                ) : (
                    <Box mt={2}>
                        <Button shade="secondary" onClick={() => window.open(message.share.resource.file_url, '_blank')}>View</Button>
                    </Box>
                )}
            </>
        );
    } else {
        MessageContainer = message.sender.id === user.profile.id ? MyMessage : YourMessage;
        messageContent = (
            <>
                {message.attachment_file_url && (
                    <>
                        {(message.attachment_file_key.split('.').pop() === 'png' || message.attachment_file_key.split('.').pop() === 'jpeg') ? (
                            <Box sx={{ mt: 1, mb: 1, background: '#FFFFFF', borderRadius: 1 }}>
                                <a href={message.attachment_file_url} download target="_blank">
                                    <img
                                        src={message.attachment_file_url}
                                        alt={message.attachment_file_key.split('/')[2]}
                                        width={150}
                                    />
                                </a>
                            </Box>
                        ) : (
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <a href={message.attachment_file_url} download target="_blank" style={{ textDecoration: 'underline', color: '#FFFFFF' }}>
                                    {message.attachment_file_key.split('/')[2]}
                                </a>
                            </Box>
                        )}
                    </>
                )}
                {message.text}
            </>
        );
    }

  return (
    <>
        {includeTimestamp && (
            <Timestamp>{ msgSent.local().calendar()}</Timestamp>
        )}
        {message.sender.id === user.profile.id ? (
            <Box
                className='fs-exclude'
                sx={{
                    width: 400,
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                }}
            >
                <MessageContainer>
                    {messageContent}
                </MessageContainer>
                <Avatar
                    alt={message.sender.first_name}
                    src={message.sender.profile_image_url}
                    sx={{ width: 25, height: 25, margin: '10px', bgcolor: stringToColor(message.sender.first_name) }}
                >
                    {!message.sender.profile_image_url ? message.sender.first_name?.charAt(0) : null}
                </Avatar>
            </Box>
        ) : (
            <Box
                className='fs-exclude'
                sx={{
                    width: 400,
                    maxWidth: '100%',
                    display: 'flex',
                    alignItems: 'flex-start'
                }}
            >
                <Avatar
                    alt={message.sender.first_name}
                    src={message.sender.profile_image_url}
                    sx={{ width: 25, height: 25, margin: '10px', bgcolor: stringToColor(message.sender.first_name) }}
                >
                    {!message.sender.profile_image_url ? message.sender.first_name?.charAt(0) : null}
                </Avatar>
                <MessageContainer>
                    {messageContent}
                </MessageContainer>
            </Box>
        )}
    </>
  );
}

Message.propTypes = {
  message: PropTypes.any, // TODO: Replace with proper proptype
  previousMessage: PropTypes.any,
};

export default Message;