import React from "react";
import { useSelector } from "react-redux";

import { Avatar, Box, Grid, Stack } from "@mui/material";

import FolderIcon from "../../assets/icons/folder.svg";
import {
  ProductCategories,
  ProductContainer,
  ProductLabel,
  ProductTitle,
  ProductValue,
} from "./product.style";
import ResourcePreview from "../resource/resource-preview";
import ProductPreview from "./product-preview";
import { PRODUCT_TYPES } from "../../constants";
import ProductDetailLine from "./product-detail-line";
import MeetingLink from "../session/meeting-link";
import { capitalizeFirstLetter } from "../../utils/stringHelpers";
import { CANCELATION_POLICIES } from "../../constants";
import ReimbursementLabel from "./components/reimbursement-label";
import { modifyReimbursementLabels } from "./utils/format-reimbursement";

export default function Preview({
  title,
  categories,
  photoPreview,
  price,
  durationHours,
  durationMins,
  description,
  maxParticipants,
  virtual,
  inPerson,
  link,
  location,
  productType,
  insuranceOptions,
  selectedResourceIds,
  selectedProductIds,
  additionalPaymentPlansOffered,
  cancelationPolicy,
  defaultPaymentPlan,
}) {
  const resourcesById = useSelector((state) => state.resources.resourcesById);
  const productsById = useSelector((state) => state.products.productsById);
  const paymentPlansById = useSelector(
    (state) => state.paymentPlans.paymentPlansById
  );

  return (
    <ProductContainer>
      <Grid container>
        <Grid item sx={12} sm={6}>
          <Box sx={{ pl: 2, pr: 2, borderRight: "1px solid #e8e8e8" }}>
            {photoPreview && (
              <Avatar
                src={photoPreview}
                sx={{ width: "100%", height: 200, borderRadius: 4 }}
                variant="square"
              />
            )}
            <Box sx={{ mt: 1, mb: 1 }}>
              <ProductTitle>{title}</ProductTitle>
            </Box>
            <Box sx={{ mt: 1, mb: 1 }}>
              <ProductCategories>
                <img src={FolderIcon} alt="categories" width={15} />
                &nbsp;
                {categories.join(", ")}
              </ProductCategories>
            </Box>
            <ProductDetailLine
              label="Price"
              value={`$${price.toLocaleString()}`}
              firstLine={true}
            />
            {additionalPaymentPlansOffered.length === 0 && price > 0 && (
              <ProductDetailLine
                label="Payment Terms"
                value={paymentPlansById[defaultPaymentPlan].description}
              />
            )}
            {(productType === PRODUCT_TYPES.SINGLE ||
              productType === PRODUCT_TYPES.GROUP) && (
              <ProductDetailLine
                label="Duration"
                value={`${
                  durationHours
                    ? `${durationHours} hour${durationHours > 1 ? "s " : " "}`
                    : ""
                }${
                  durationMins
                    ? `${durationMins} min${durationMins > 1 ? "s" : ""}`
                    : ""
                }`}
              />
            )}
            {maxParticipants && (
              <ProductDetailLine
                label="Max Participants"
                value={maxParticipants}
              />
            )}
            {link && (
              <Box sx={{ mt: 1, mb: 1, borderTop: "1px solid #E8E8E8", pb: 1 }}>
                <ProductLabel>Meeting Link</ProductLabel>
                <Box>
                  <MeetingLink link={link} />
                </Box>
              </Box>
            )}
            {location && (
              <ProductDetailLine label="In-Person Location" value={location} />
            )}
            {insuranceOptions.length > 0 && price > 0 && (
              <ProductDetailLine
                label={
                  <ReimbursementLabel
                    insuranceOptions={modifyReimbursementLabels(
                      insuranceOptions
                    )}
                  />
                }
                value={modifyReimbursementLabels(insuranceOptions).join(", ")}
              />
            )}
            {productType !== PRODUCT_TYPES.RESOURCE &&
              (virtual || inPerson) && (
                <ProductDetailLine
                  label="Location"
                  value={`${virtual ? "Virtual" : ""}${
                    inPerson ? `${virtual ? ", In Person" : "In Person"}` : ""
                  }`}
                />
              )}
          </Box>
        </Grid>
        <Grid item sx={12} sm={6}>
          <Box pl={2} pr={2}>
            <ProductLabel>About</ProductLabel>
            <Box>{description}</Box>
          </Box>
          {selectedResourceIds.length > 0 && (
            <Box mt={1} pl={2} pr={2}>
              <ProductLabel>Resources Included</ProductLabel>
              <Stack spacing={1} mt={1}>
                {selectedResourceIds.map((resourceId) => (
                  <ResourcePreview
                    key={resourceId}
                    resource={resourcesById[resourceId]}
                    showThumbnail={false}
                    interactable={false}
                  />
                ))}
              </Stack>
            </Box>
          )}
          {selectedProductIds.length > 0 && (
            <Box mt={1} pl={2} pr={2}>
              <ProductLabel>What's Included</ProductLabel>
              <Stack spacing={1} mt={1}>
                {selectedProductIds.map((productId) => (
                  <ProductPreview
                    product={productsById[productId]}
                    interactable={false}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </Grid>
      </Grid>
    </ProductContainer>
  );
}
