import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';
import {
    Alert,
    Box,
    ButtonGroup,
    Container,
    Modal,
    Grid,
    Stack,
    CircularProgress,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import {
    PageTitle,
    NoResults,
} from '../sessions/sessions.style';
import ResourceCard from '../documents/document-card';
import { fetchResources } from '../../clients/resource';
import { ResourceActionType, SET_PAYWALL } from '../../store/actions';
import { State } from '../../types';
import { PaywallTypes } from '../../constants';

function DigitalBonusKit({}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const featureFlags = useSelector((state: State) => state.account.user.profile.feature_flags);

    const user = useSelector((state: State) => state.account.user);
    const token = useSelector((state: State) => state.account.token);
    const resourcesById = useSelector((state: State) => state.resources.resourcesById);
    const resourceIds = useSelector((state: State) => state.resources.resourceIds);

    const onOpenResource = (resource) => {
        if (!featureFlags.digital_bonus_kit) {
            dispatcher({
                type: SET_PAYWALL,
                payload: {
                  paywall: true,
                  paywall_type: PaywallTypes.BASE,
                },
            });
        } else {
            window.open(resource.file_url, '_blank');
        }
    }

    const reloadResources = async () => {
        await fetchResources(dispatcher, navigate, token, true);
    };

    useEffect(() => {
        reloadResources();
    }, [])

    const resourceList = (
        <>
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <PageTitle>
                    Digital Bonus Kit
                </PageTitle>
            </Box>
            <Grid container spacing={2}>
                {resourceIds.map((id) => (
                    <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                        <ResourceCard
                            key={id}
                            resource={resourcesById[id]}
                            selectMode={false}
                            selected={false}
                            onSelect={() => {}}
                            onOpen={() => onOpenResource(resourcesById[id])}
                            share={undefined}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );

    return (
        <Container maxWidth="lg">
            {resourceList}
        </Container>
    );
}

export default DigitalBonusKit;
