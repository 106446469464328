import { get } from '../services/api.services';
import { API } from '../constants';
import {
    SET_SESSION,
    ADD_SESSION_ID,
    RESET_SESSION_IDS,
    ADD_FOLDER_SESSION_ID,
    RESET_FOLDER_SESSION_IDS,
} from '../store/actions';

export const fetchSessions = async (dispatcher, navigate, token, folderId = null) => {
    const result = await get(
        `${API.SESSION}${folderId ? `?folder_id=${folderId}` : ''}`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/parents');
    }
    else if (result.status === 200) {
        if (folderId) {
            dispatcher({
                type: RESET_FOLDER_SESSION_IDS,
                payload: {},
            });
        } else {
            dispatcher({
                type: RESET_SESSION_IDS,
                payload: {},
            });
        }
        
        result.data.forEach((session) => {
            dispatcher({
                type: SET_SESSION,
                payload: {
                    session,
                },
            });

            if (folderId) {
                dispatcher({
                    type: ADD_FOLDER_SESSION_ID,
                    payload: {
                        session,
                    },
                });
            } else {
                dispatcher({
                    type: ADD_SESSION_ID,
                    payload: {
                        session,
                    },
                });
            }
        });
    }
}
