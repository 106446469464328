import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { useAuth } from '../../hooks';
import { updateUser } from '../../clients/profile';
import Modal from '../../components/common/modal';
import Confetti from '../../assets/images/confetti.gif';

interface CommunityAgreementFormProps {
    onClose: () => void,
}

export default function CommunityAgreementForm({ onClose }: CommunityAgreementFormProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const [step, setStep] = useState(1);
    const [businessJourney, setBusinessJourney] = useState<string | null>(null);
    const [businessGoal, setBusinessGoal] = useState<string | null>(null);
    const [programmingInterests, setProgrammingInterests] = useState<string | null>(null);
    const [expertStyle, setExpertStyle] = useState<string | null>(null);
    const [source, setSource] = useState<string | null>(null);
    const [instagramHandle, setInstagramHandle] = useState<string | null>(null);
    const [comments, setComments] = useState<string | null>(null);
    const [agreement1, setAgreement1] = useState(!user.profile.under_review);
    const [agreement2, setAgreement2] = useState(!user.profile.under_review);
    const [agreement3, setAgreement3] = useState(!user.profile.under_review);
    const [agreement4, setAgreement4] = useState(!user.profile.under_review);
    const [agreement5, setAgreement5] = useState(!user.profile.under_review);

    const onAgreementComplete = async () => {
        setStep(2);
        await updateUser(
            dispatcher,
            navigate,
            user,
            token,
            {
                under_review: false,
            }
        );
    };

    const onSaveAdditionalQuestions = async () => {
        await updateUser(
            dispatcher,
            navigate,
            user,
            token,
            {
                business_journey: businessJourney,
                business_goal: businessGoal,
                programming_interests: programmingInterests,
                expert_style: expertStyle,
                source,
                instagram_handle: instagramHandle,
                intake_comments: comments,
            }
        )
    }

    useEffect(() => {
        onSaveAdditionalQuestions();
    }, [businessJourney, businessGoal, programmingInterests, expertStyle, source, instagramHandle, comments])

    return (
        <Modal
            title="Community Agreement"
            onClose={onClose}
        >
            <Box p={2}>
                {step === 1 && (
                    <>
                        <Box mb={4}>
                            <Typography variant="body1">
                                Welcome to Parentswarm! We are so excited to see you join our platform. Your profile listing will be activated as soon as you submit this form.<br /><br />
                                Agreement to these statements is required in order for us to activate your page on Parentswarm.{' '}
                                If you have questions about the particulars before agreeing, feel free to reach out to our customer{' '}
                                service team at <a href="mailto:support@parentswarm.com">support@parentswarm.com</a>.
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>
                                        At Parentswarm, we value the safety and health of all of our staff members,{' '}
                                        listed Experts, and customers, and stand firm in our support of our minoritized{' '}
                                        members. We will not tolerate incidents of racism, gender-based harassment,{' '}
                                        ageism, body shaming, ableism, or oppression of any kind. Do you agree to support{' '}
                                        this approach?
                                    </b>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreement1}
                                            onChange={(e) => setAgreement1(e.target.checked)}
                                        />
                                    }
                                    label="I agree"
                                />
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>
                                        We also recognize that sometimes it's hard to speak to something painful;{' '}
                                        we want you to know we believe you, we're on your side, and we'll take proper{' '}
                                        accountability measures. I understand that I can report an incident of bias at{' '}
                                        any time by reaching out to bias@parentswarm.com.
                                    </b>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreement2}
                                            onChange={(e) => setAgreement2(e.target.checked)}
                                        />
                                    }
                                    label="I agree"
                                />
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>
                                        I understand that the guidance I give, the liability I take on,{' '}
                                        and the equipment I use in my work are my own responsibility, and not{' '}
                                        the responsibility of Parentswarm Inc.
                                    </b>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreement3}
                                            onChange={(e) => setAgreement3(e.target.checked)}
                                        />
                                    }
                                    label="I agree"
                                />
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>
                                        I understand that although Parentswarm is non-medical and is therefore not{' '}
                                        bound by HIPAA, the company takes pride in being HIPAA-compliant, and it{' '}
                                        is my responsibility to keep confidential the personal identifying{' '}
                                        information and medical information I receive from the clients with whom{' '}
                                        I communicate. 
                                    </b>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreement4}
                                            onChange={(e) => setAgreement4(e.target.checked)}
                                        />
                                    }
                                    label="I agree"
                                />
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>
                                        Lastly, please sign our community agreement by checking the box: 
                                        <br /><br />
                                    </b>
                                </Typography>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={agreement5}
                                            onChange={(e) => setAgreement5(e.target.checked)}
                                        />
                                    }
                                    label="On my honor, I pledge that the offerings I make available on the Parentswarm platform shall be: evidence-based, inclusive, and non-medical in nature. I will strive always for a respectful tone, honoring families in all their constellations and beliefs. I will make an effort to use inclusive language. I will share accurate, evidence-based information in a clear, user-friendly way, and I will work from the presumption that the client is always telling their own truth, and that it's my job as an Expert to support them in navigating that truth. For more information, please refer to our Code of Conduct."
                                />
                            </FormControl>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                size="large"
                                disabled={!(agreement1 && agreement2 && agreement3 && agreement4 && agreement5)}
                                onClick={onAgreementComplete}
                            >
                                Activate My Profile
                            </Button>
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box mb={4} textAlign="center">
                            <Box textAlign="center"><img width={200} src={Confetti} /></Box>
                            <Typography variant="h6">
                                Your profile listing has been activated!
                            </Typography>
                            <Typography variant="body1">
                                Please continue with the following questions to help us tailor your experience on Parentswarm.
                            </Typography>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-journey">
                                    <b>How far along are you in your business journey as a parent-support expert?</b>
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="business-journey-label"
                                    name="business-journey-group"
                                    value={businessJourney}
                                    onChange={(e) => setBusinessJourney(e.target.value)}
                                >
                                    <FormControlLabel value="START" control={<Radio />} label="I'm just starting out" />
                                    <FormControlLabel value="FEW" control={<Radio />} label="I've had a few clients" />
                                    <FormControlLabel value="1TO5" control={<Radio />} label="I've been supporting families for 1-5 years" />
                                    <FormControlLabel value="5PLUS" control={<Radio />} label="I've been at this for more than 5 years" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-goal">
                                    <b>What's your TOP goal for using Parentswarm?</b>
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="business-goal-label"
                                    name="business-goal-group"
                                    value={businessGoal}
                                    onChange={(e) => setBusinessGoal(e.target.value)}
                                >
                                    <FormControlLabel value="MONEY" control={<Radio />} label="To save money by streamlining my software subscriptions" />
                                    <FormControlLabel value="TIME" control={<Radio />} label="To save time by keeping everything organized for my business" />
                                    <FormControlLabel value="PROF" control={<Radio />} label="To make my business look and feel more professional" />
                                    <FormControlLabel value="CONNECT" control={<Radio />} label="To grow, learn, and connect as a professional in this field" />
                                    <FormControlLabel value="LEADS" control={<Radio />} label="To get more inbound leads from potential clients" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-journey">
                                    <b>When it comes to the programming that Parentswarm offers to experts like you, which of the following do you find most appealing?</b>
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="programming-interest-label"
                                    name="programming-interests-group"
                                    value={programmingInterests}
                                    onChange={(e) => setProgrammingInterests(e.target.value)}
                                >
                                    <FormControlLabel value="WORKSHOPS" control={<Radio />} label="Professional development workshops about sales, marketing, and other ways to grow my business" />
                                    <FormControlLabel value="TECH" control={<Radio />} label="Learning more about technology and leveraging AI, SEO, etc." />
                                    <FormControlLabel value="CHALLENGES" control={<Radio />} label="Challenges to keep me motivated through my day-to-day in this field" />
                                    <FormControlLabel value="NETWORKING" control={<Radio />} label="Networking opportunities and changes to formge connections with colleagues and other experts in the field" />
                                    <FormControlLabel value="SUPPORT" control={<Radio />} label="Support groups with fellow experts" />
                                    <FormControlLabel value="NONE" control={<Radio />} label="I'm really not here for the programming" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-journey">
                                    <b>Complete the following sentence: If I had to describe my own expert style as one of the following, I'd say I'm most like...</b>
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="expert-style-label"
                                    name="expert-style-group"
                                    value={expertStyle}
                                    onChange={(e) => setExpertStyle(e.target.value)}
                                >
                                    <FormControlLabel value="FAIRY" control={<Radio />} label="A Fairy Godparent" />
                                    <FormControlLabel value="EARTHY" control={<Radio />} label="An Earthy Flower Child" />
                                    <FormControlLabel value="SCIENCE" control={<Radio />} label="A Science Teacher" />
                                    <FormControlLabel value="SIBLING" control={<Radio />} label="A Trusted Big Sibling" />
                                    <FormControlLabel value="FRIEND" control={<Radio />} label="A Friend Who Makes Others Laugh" />
                                    <FormControlLabel value="NONE" control={<Radio />} label="None of these / Unsure" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-journey">
                                    <b>How did you first hear about Parentswarm?</b>
                                </Typography>
                                <RadioGroup
                                    aria-labelledby="source-label"
                                    name="source-group"
                                    value={source}
                                    onChange={(e) => setSource(e.target.value)}
                                >
                                    <FormControlLabel value="FRIEND" control={<Radio />} label="A friend of colleague told me" />
                                    <FormControlLabel value="SOCIAL" control={<Radio />} label="Social media" />
                                    <FormControlLabel value="SEARCH" control={<Radio />} label="Google or other search engine" />
                                    <FormControlLabel value="MARKETING" control={<Radio />} label="I received a marketing message / email" />
                                    <FormControlLabel value="ORG" control={<Radio />} label="Through a certifying / training organization" />
                                    <FormControlLabel value="PROVIDER" control={<Radio />} label="My healthcare provider told me" />
                                    <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1" id="business-journey">
                                    <b>Do you have an Instagram handle for your business? If so, please share it here (and follow @parentswarm)!</b>
                                </Typography>
                                <Box mt={2}>
                                    <TextField
                                        id="instagram"
                                        variant="outlined"
                                        value={instagramHandle}
                                        onChange={(e) => setInstagramHandle(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AlternateEmailIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                        <Box mb={4}>
                            <FormControl>
                                <Typography variant="body1">
                                    <b>Thanks so much for your input! Please use this space to add any additional thoughts or comments for us.</b>
                                </Typography>
                                <Box mt={2}>
                                    <TextField
                                        id="comments"
                                        variant="outlined"
                                        value={comments}
                                        onChange={(e) => setComments(e.target.value)}
                                        multiline
                                        minRows={4}
                                        fullWidth
                                    />
                                </Box>
                            </FormControl>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={onClose}
                            >
                                Submit
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
}
