import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import { SET_SESSION } from '../../store/actions';
import { API } from '../../constants';
import { patch } from '../../services/api.services';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import Button from '../common/button';
import { Card, LinkModal } from './Session.style';

function Cancel({ session, onDismiss }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const cancelee = session.parent_profile.id === user.profile.id ? session.expert_profile : session.parent_profile;

    const cancelSession = async () => {
        const result = await patch(
            `${API.SESSION}${session.id}/`,
            {
              canceled: true,
            },
            token,
        );
    
        if (result.status === 403) {
            navigate(`/login/${user.profile.profile_type}`);
        }
        else if (result.status === 200) {
            dispatcher({
                type: SET_SESSION,
                payload: {
                    session: result.data,
                },
            });
            onDismiss();
        }
        else {
          // TODO: Error states
        }
    }

    return (
        <LinkModal>
            <Card>
                <Box sx={{ position: 'absolute', right: 10, top: 10 }} onClick={onDismiss}>
                    <img src={DismissIcon} width={15} alt="dismiss" style={{ cursor: 'pointer' }} />
                </Box>
                <Box sx={{ mt: 1, mb: 1 }}>Cancel Session</Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                    Are you sure you'd like to cancel this session? {cancelee.first_name} will be notified of the cancellation.
                </Box>   
                <Button shade="secondary" onClick={cancelSession}>Yes, Cancel.</Button>
            </Card>
        </LinkModal>
    );
}

Cancel.propTypes = {
  session: PropTypes.any,
  onDismiss: PropTypes.func,
};

export default Cancel;