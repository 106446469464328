import { getAllForFolder } from '../services/event.services';
import {
    EventActionType
} from '../store/actions';

export const fetchEvents = async (dispatcher, navigate, token, folderId = null) => {
    const result = await getAllForFolder(folderId, token);

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        if (folderId) {
            dispatcher({
                type: EventActionType.RESET_FOLDER_EVENT_IDS,
            });
        } else {
            dispatcher({
                type: EventActionType.RESET_EVENT_IDS,
            });
        }

        result.data.forEach((event) => {
            dispatcher({
                type: EventActionType.SET_EVENT,
                payload: {
                    event,
                },
            });

            if (folderId) {
                dispatcher({
                    type: EventActionType.ADD_FOLDER_EVENT_ID,
                    payload: {
                        event,
                    },
                });
            } else {
                dispatcher({
                    type: EventActionType.ADD_EVENT_ID,
                    payload: {
                        event,
                    },
                });
            }
        });
    }
}
