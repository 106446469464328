import { patch, post, get } from "./api.services";
import { API } from "../constants";

export function createOrSave(event, data, token) {
  if (event) {
    return patch(`${API.EVENT}${event.id}/`, data, token);
  }
  return post(`${API.EVENT}`, data, token);
}

export function getAllForFolder(folderId, token) {
  return get(`${API.EVENT}${folderId ? `?folder_id=${folderId}` : ""}`, token);
}
