import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function Attachment({ attachment }) {
    const showPreview = attachment.file_key.split('.').pop() !== 'pdf';

    return (
        <Box mt={1} mb={1}>
            {showPreview ? (
                <a href={attachment.file_url} download target="_blank">
                    <img
                        src={attachment.file_url}
                        alt={attachment.file_key.split('/')[4]}
                        width={150}
                    />
                </a>
            ) : (
                <a href={attachment.file_url} download target="_blank" style={{ textDecoration: 'underline' }}>
                    {attachment.file_key.split('/')[4]}
                </a>
            )}
        </Box>
    );
}

Attachment.propTypes = {
  url: PropTypes.string,
};

export default Attachment;