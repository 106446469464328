export const LANGUAGES = [
    'Albanian',
    'Algerian Arabic',
    'American Sign Language',
    'Amharic',
    'Arabic',
    'Assamese',
    'Bavarian',
    'Bengali',
    'Bhojpuri',
    'Burmese',
    'Cebuano',
    'Chhattisgarhi',
    'Chinese',
    'Chittagonian',
    'Czech',
    'Deccan',
    'Dutch',
    'Eastern Min',
    'Eastern Punjabi',
    'Egyptian Arabic',
    'English',
    'French',
    'Gan Chinese',
    'German',
    'Greek',
    'Guarani',
    'Gujarati',
    'Hakka',
    'Hausa',
    'Hejazi Arabic',
    'Hindi',
    'Hungarian',
    'Igbo',
    'Indonesian',
    'Iranian Persian',
    'Italian',
    'Japanese',
    'Javanese',
    'Jin Chinese',
    'Kannada',
    'Kazakh',
    'Khmer',
    'Kinyarwanda',
    'Korean',
    'Magahi',
    'Maithili',
    'Malay',
    'Malayalam',
    'Malaysian',
    'Mandarin Chinese',
    'Marathi',
    'Mesopotamian Arabic',
    'Moroccan Arabic',
    'Nepali',
    'Nigerian Fulfulde',
    'Northern Kurdish',
    'Northern Min',
    'Northern Pashto',
    'Northern Uzbek',
    'North Levantine Arabic',
    'Odia',
    'Persian',
    'Polish',
    'Portuguese',
    'Romanian',
    'Rundi',
    'Russian',
    'Sa\'idi Arabic',
    'Sanaani Spoken Arabic',
    'Saraiki',
    'Serbo-Croatian',
    'Sindhi',
    'Sinhalese',
    'Somali',
    'South Azerbaijani',
    'Southern Min',
    'Southern Pashto',
    'South Levantine Arabic',
    'Spanish',
    'Swahili',
    'Swedish',
    'Sudanese Arabic',
    'Sunda',
    'Sylheti',
    'Tagalog',
    'Ta\'izzi-Adeni Arabic',
    'Tamil',
    'Telugu',
    'Thai',
    'Tunisian Arabic',
    'Turkish',
    'Ukrainian',
    'Urdu',
    'Uyghur',
    'Vietnamese',
    'Western Punjabi',
    'Wu Chinese',
    'Xiang Chinese',
    'Yoruba',
    'Yue Chinese',
    'Zulu',
];