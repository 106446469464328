import React from 'react';

import moment from 'moment';
import { Avatar, Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';

import MiniProfile from '../../session/mini-profile';
import { CardDescription, CardPrice, CardTitle, CardDate } from '../../product/product.style';

export default function Card({
    variant,
    title,
    seller,
    description,
    tag,
    price,
    discountedPrice,
    date,
    imgUrl,
    primaryCta,
    primaryCtaAction,
    secondaryCta,
    secondaryCtaAction,
    archived,
    onUnarchive,
    miniProfile = true,
}) {
    const overridePropagation = (e, fn) => {
        e.stopPropagation();
        fn();
    };

    return variant === 'normal' ? (
        <Box
            sx={{
                borderRadius: 2,
                background: '#FFFFFF',
                overflow: 'hidden',
                position: 'relative',
                p: 2,
                border: '1px solid #e8e8e8',
                cursor: 'pointer',
                opacity: archived ? '0.7' : '1',
                display: 'flex',
                flexDirection: 'column',
            }}
            onClick={secondaryCtaAction ? () => secondaryCtaAction() : () => {}}
        >
            <Box display="flex" flexDirection="column" justifyContent="space-between" alignItems="space-between" height="100%">
                <Stack spacing={2}>
                    <Box>
                        <Avatar
                            src={imgUrl}
                            alt={title}
                            sx={{ width: 'auto', height: 175, borderRadius: 2 }}
                            variant="square"
                        />
                        <Box sx={{ position: 'absolute', top: 5, right: 5 }}>
                            <Chip label={tag} color="primary" />
                            {archived && (
                                <Tooltip title={
                                    <React.Fragment>
                                        This service is archived and not visible in your Storefront.{' '}
                                        <Box mt={1} mb={1} textAlign="center">
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                size="small"
                                                onClick={(e) => overridePropagation(e, onUnarchive)}
                                            >
                                                <UnarchiveOutlinedIcon /> <span style={{ marginLeft: 5 }}>Unarchive</span>
                                            </Button>
                                        </Box>
                                    </React.Fragment>
                                }>
                                    <Chip
                                        style={{ marginLeft: 5 }}
                                        label="Archived"
                                        color="secondary"
                                    />
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                    <CardTitle>{title}</CardTitle>
                    {seller && miniProfile && (
                        <Box mb={2} onClick={(e) => overridePropagation(e, () => {})}>
                            <MiniProfile profile={seller} />
                        </Box>
                    )}
                    <CardDescription>{description}</CardDescription>
                </Stack>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="body2">
                        {date && moment(date).tz(moment.tz.guess()).format('MMM D h:mm a')}
                    </Typography>
                    <Typography variant="h6">
                        {discountedPrice !== price ? (
                            <>
                                <span style={{ opacity: '0.7', textDecoration: 'line-through' }}>
                                    ${price ? price.toLocaleString() : 0}
                                </span> {discountedPrice === 0 ? 'Free' : `$${discountedPrice.toLocaleString()}`}
                            </>
                        ) : (
                            <span>${price ? price.toLocaleString() : 0}</span>
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    ) : (
        <Box
            sx={{
                borderRadius: 2,
                background: '#FFFFFF',
                overflow: 'hidden',
                position: 'relative',
                p: 2,
                border: '1px solid #e8e8e8',
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
            }}
            onClick={secondaryCtaAction ? () => secondaryCtaAction() : () => {}}
        >
            <Box display="flex" flexDirection="row" height="100%">
                <Box sx={{ position: 'relative' }}>
                    <Avatar
                        src={imgUrl}
                        alt={title}
                        sx={{ width: 125, height: 125, borderRadius: 2 }}
                        variant="square"
                    />
                </Box>
                <Box ml={2} display="flex" flexDirection="column" justifyContent="space-between" alignItems="space-between" sx={{ width: '100%', height: '100%' }}>
                    <Stack spacing={1}>
                        <CardTitle>{title}</CardTitle>
                        <CardDescription>{description}</CardDescription>
                    </Stack>
                    <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                        <Chip label={tag} color="primary" size="small" />
                        <CardPrice>
                            {discountedPrice !== price ? (
                                <>
                                    <span style={{ opacity: '0.7', textDecoration: 'line-through' }}>
                                        ${price ? price.toLocaleString() : 0}
                                    </span> {discountedPrice === 0 ? 'Free' : `$${discountedPrice.toLocaleString()}`}
                                </>
                            ) : (
                                <span>${price ? price.toLocaleString() : 0}</span>
                            )}
                        </CardPrice>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
