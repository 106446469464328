import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from '@mui/material';

import { PRODUCT_TYPES, PaywallTypes } from '../../constants';
import ProductTypeSelection from './product-type-selection';
import { SET_PAYWALL } from '../../store/actions';
import { State } from '../../types';

export default function ProductType({ chosenProductType, setChosenProductType }) {
    const dispatcher = useDispatch();

    const featureFlags = useSelector((state) => state.account.user.profile.feature_flags);

    const openPaywall = () => {
        dispatcher({
            type: SET_PAYWALL,
            payload: {
                paywall: true,
                paywall_type: PaywallTypes.BASE,
            },
        });
    };

    return (
        <Box textAlign="center">
            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ProductTypeSelection
                            type={PRODUCT_TYPES.SINGLE}
                            title="Live 1-on-1 Session"
                            description="A virtual or in-person meeting or call"
                            onSelect={setChosenProductType}
                            selected={chosenProductType === PRODUCT_TYPES.SINGLE}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ProductTypeSelection
                            type={PRODUCT_TYPES.GROUP}
                            title="Live Group Session"
                            description="A virtual or in-person group meeting, class, or call"
                            onSelect={setChosenProductType}
                            selected={chosenProductType === PRODUCT_TYPES.GROUP}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ProductTypeSelection
                            type={PRODUCT_TYPES.RESOURCE}
                            title="On-Demand Resource"
                            description="Downloadable files such as a guide, course, or other resource"
                            onSelect={setChosenProductType}
                            selected={chosenProductType === PRODUCT_TYPES.RESOURCE}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ProductTypeSelection
                            type={PRODUCT_TYPES.PACKAGE}
                            title="Package of Services"
                            description="Combine multiple 1-on-1, group sessions and / or on-demand resources into a package"
                            onSelect={(e) => {
                                if (featureFlags.package_services) {
                                    setChosenProductType(e);
                                  } else {
                                    openPaywall();
                                  }
                                }
                            }
                            selected={chosenProductType === PRODUCT_TYPES.PACKAGE}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
