import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useDropzone } from 'react-dropzone';
import { Alert, Box, Button, Chip, Container, Paper, TextField, Snackbar, Stack, Typography } from '@mui/material';

import { useAuth } from '../../../hooks';
import { API } from '../../../constants';
import { patch } from '../../../services/api.services';
import { SET_USER } from '../../../store/actions';
import { uploadToS3 } from '../../../utils/upload';
import { debounce } from '../../../utils/timers';

export default function Documentation({  }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const firstUpdate = useRef(true);
    const secondUpdate = useRef(true);

    const [insuranceFileKeys, setInsuranceFileKeys] = useState(user.profile.insurance_file_keys?.split(',') || []);
    const [trainingDocumentList, setTrainingDocumentList] = useState(user.profile.training_certificate_file_keys?.split(',') || []);
    const [trainingDescription, setTrainingDescription] = useState(user.profile.training_description || '');
    const [certificateError, setCertificateError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [saved, setSaved] = useState(false);
    const [errors, setErrors] = useState({});

    const dropzoneStyle = {
        fontFamily: 'Cabin',
        background: 'none',
        height: 100,
        width: '100%',
        border: `2px dashed #e8e8e8`,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 6,
    };

    const save = useCallback(async (documentList, description) => {
        let hasErrors = false;
        setErrors({});

        if (documentList.length === 0 && description.length === 0) {
            hasErrors = true;
            setCertificateError('Please upload a certificate or describe your training');
        }

        if (!hasErrors) {
            const result = await patch(
                `${API.PROFILE}${user.profile.id}/`,
                {
                    training_description: description,
                    training_certificate_file_keys: documentList.length ? documentList.join(',') : null,
                },
                token,
            );

            if (result.status === 403 || result.status === 401) {
                navigate('/login/experts');
            }
            else if (result.status === 200) {
                setSaved(true);
                dispatcher({
                    type: SET_USER,
                    payload: {
                        user: {
                            ...user,
                            profile: {
                                ...result.data,
                            },
                        },
                    },
                });
            } else {
                setErrors(result.data);
            }
        }
    }, [token, user, dispatcher, navigate]);


    const onNewImg = async (acceptedFiles) => {
        setCertificateError('');
      
        for (let i = 0; i < acceptedFiles.length; i++) {
            const path = `training-proof/${user.profile.id}/${acceptedFiles[i].path}`;
            const successfulUpload = await uploadToS3(
                token,
                path,
                acceptedFiles[i],
            );
    
            if (successfulUpload) {
                setTrainingDocumentList([ ...trainingDocumentList, path ]);
            }
            else {
                setCertificateError('We were unable to save this file, please try again.');
            }
        }
    }
    
    const onDeleteFile = (path) => {
        setTrainingDocumentList(trainingDocumentList.filter(file => file !== path))
    };
    
    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': [],
        },
        maxFiles: 10,
        maxSize: 5 * 1000 * 1000,
        onDrop: onNewImg,
    });

    const debouncedSave = useCallback(debounce(save, 1000), [save]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        if (secondUpdate.current) {
            secondUpdate.current = false;
            return;
        }
    
        debouncedSave(trainingDocumentList, trainingDescription);
    }, [trainingDocumentList, trainingDescription]);

    return (
        <Container maxWidth="md">
            <Box mt={4} mb={2}>
                <Typography variant="h5">Documentation of Training</Typography>
            </Box>
            <Paper elevation={0}>
                <Box p={4}>
                    <Stack spacing={1}>
                        <Typography variant="subtitle1">Please upload your certificate(s) or documentation of training</Typography>

                        {trainingDocumentList.map((file) => (
                            <Box>
                                <Chip key={file} variant="filled" color="secondary" label={file.split('/')[2]} onDelete={() => onDeleteFile(file)} />
                            </Box>
                        ))}

                        {errors['training_certificate_file_keys'] && (
                            <Alert color="error">{errors['training_certificate_file_keys']}</Alert>
                        )}

                        {fileRejections.map(({ file, errors }, index) => (
                            <Alert color="error" key={index}>{errors.map(error => error.message)}</Alert>
                        ))}
                        
                        <div {...getRootProps({ style: dropzoneStyle as any })}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                            <p>Drop the files here...</p>
                            ) : (
                            <p>Drag 'n' drop some files here, or click to select files</p>
                            )}
                        </div>

                        {certificateError && <Alert color="error">{certificateError}</Alert>}

                        <Box sx={{ mb: 2, textAlign: 'center' }}>
                            <Typography variant="subtitle1">OR</Typography>
                        </Box>

                        <Typography variant="subtitle1">Describe your training</Typography>
                        {errors['training_description'] && (
                            <Alert color="error">{errors['training_description']}</Alert>
                        )}

                        <TextField
                            id="training-description"
                            variant="outlined"
                            placeholder="Please provide the details of your training"
                            multiline
                            minRows={4}
                            value={trainingDescription}
                            onChange={(e) => setTrainingDescription(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 1024 }}
                        />

                        {descriptionError && <Alert color="error">{descriptionError}</Alert>}
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={saved}
                            autoHideDuration={3000}
                            onClose={() => setSaved(false)}
                            message="Your progress has been saved."
                        />
                    </Stack>
                    <Box mt={2}>
                        <Button variant="contained" color="primary" onClick={() => debouncedSave(trainingDocumentList, trainingDescription)}>
                            Save
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Container>
    );
}
