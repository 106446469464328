import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Box, Button, Chip, CircularProgress, Container, Grid, Modal, Stack } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { buildSearchQuery } from '../../utils/search';
import { PRIMARY_TOPICS } from '../../utils/groups';
import ExpertRow from '../../components/experts/expert-row';
import { get } from '../../services/api.services';
import { API } from '../../constants';
import {
    SET_PROFILE,
    ADD_EXPERT_PROFILE_ID,
    RESET_EXPERT_PROFILE_IDS,
    SET_FILTERS,
    BUMP_PAGE,
    SET_HAS_NEXT_PAGE,
    RESET_FILTERS,
} from '../../store/actions';
import { NoResults } from './experts.style';
import Filter from './filter';

function Experts() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const params = useParams();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const expertList = useSelector((state) => state.profiles.expertProfileIds);
    const profileMap = useSelector((state) => state.profiles.profilesById);
    const filterSet = useSelector((state) => state.search.filters);
    const hasNextPage = useSelector((state) => state.search.hasNextPage);
    const offset = useSelector((state) => state.search.offset);
    const limit = useSelector((state) => state.search.limit);

    const [loading, setLoading] = useState(true);
    const [openFilters, setOpenFilters] = useState(false);

    const fetchExperts = async () => {
        setLoading(true);

        const result = await get(
            `${API.PROFILE}${buildSearchQuery({ ...filterSet, offset, limit })}`,
            token,
        );

        if (offset === 0) {
            dispatcher({
                type: RESET_EXPERT_PROFILE_IDS,
                payload: {},
            });
        }

        if (result.status === 403) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            result.data.experts.forEach((expert) => {
                dispatcher({
                    type: SET_PROFILE,
                    payload: {
                        profile: expert,
                    },
                });

                dispatcher({
                    type: ADD_EXPERT_PROFILE_ID,
                    payload: {
                        expert,
                    },
                });
            });

            if (!result.data.has_next_page) {
                dispatcher({
                    type: SET_HAS_NEXT_PAGE,
                    payload: {
                        hasNextPage: false,
                    },
                });
            }

            setLoading(false);
        }
        else {
            setLoading(false);
        }
    };

    const fetchNextPage = () => {
        dispatcher({
            type: BUMP_PAGE,
            payload: {},
        });
    };

    const setTopics = (topics) => {
        dispatcher({
            type: SET_FILTERS,
            payload: {
                topics,
            },
        });
    };

    const toggleTopic = (topic) => {
        const topicIndex = filterSet.topics.indexOf(topic);
        if (topicIndex > -1) {
            const topicsCopy = [ ...filterSet.topics ];
            topicsCopy.splice(topicIndex, 1);
            setTopics(topicsCopy)
        } else {
            setTopics([ ...filterSet.topics, topic ]);
        }
    };

    const closeFilters = () => {
        fetchExperts();
        setOpenFilters(false);
    };

    useEffect(() => {
        if (!queryParams.get('reload') || queryParams.get('reload') !== 'false') {
            dispatcher({
                type: RESET_FILTERS,
                payload: {},
            });
        }

        if (queryParams.get('topic')) {
            toggleTopic(queryParams.get('topic'));
        }

        if (queryParams.get('filter')) {
            setOpenFilters(true);
        }
    }, []);

    useEffect(() => {
        fetchExperts();
    }, [filterSet, offset]);

    const expertProfiles = expertList.map((expertId) => profileMap[expertId]);

    return (
        <Container maxWidth="lg" style={!user ? { marginTop: '122px' } : {}}>
            <Box mt={2} mb={2} display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse">
                <Button color="secondary" variant="contained" onClick={() => setOpenFilters(true)}>
                    <FilterAltOutlinedIcon /> Filters
                    {filterSet.numFilters > 0 && (
                        <Chip label={filterSet.numFilters} color="primary" size="small" style={{ marginLeft: 5, zoom: '80%', marginTop: 0 }} />
                    )}
                </Button>
                <Stack direction="row" spacing={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
                    {PRIMARY_TOPICS.map((topic) => (
                        <Button
                            key={topic}
                            color={filterSet.topics.indexOf(topic) > -1 ? 'primary' : 'secondary'}
                            variant="contained"
                            onClick={() => toggleTopic(topic)}
                        >
                            {topic}
                        </Button>
                    ))}
                </Stack>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {expertProfiles.length === 0 && !loading && (
                        <Box>
                            <NoResults>
                                No resources matched your search.
                            </NoResults>
                        </Box>
                    )}
                    {(!loading || offset > 0) && (
                        <>
                            <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
                                <Grid container spacing={2}>
                                    {expertProfiles.map((profile) => (
                                        <ExpertRow
                                            key={profile.id}
                                            profile={profile}
                                        />
                                    ))}
                                </Grid>
                            </Box>
                            <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
                                <Grid container spacing={2}>
                                    {expertProfiles.map((profile) => (
                                        <ExpertRow
                                            key={profile.id}
                                            profile={profile}
                                        />
                                    ))}
                                </Grid>
                            </Box>
                        </>
                    )}
                    {!loading && hasNextPage && (
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button onClick={fetchNextPage} variant="contained" color="primary">Load More</Button>
                        </Box>
                    )}
                    {loading && (
                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                            <CircularProgress style={{ color: '#5371ff' }} />
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Modal
                open={openFilters}
                onClose={closeFilters}
            >
                <Filter onApply={fetchExperts} onClose={closeFilters} />
            </Modal>
        </Container>
    );
}

export default Experts;