import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import { fetchFile, getFileType } from '../../utils/files';
import WordIcon from '../../assets/icons/file-word-regular.svg';

interface ResourceThumbnailProps {
    url: string;
    height?: string;
}

export default function ResourceThumbnail({ url, height = '200px' }: ResourceThumbnailProps) {
    const [fileType, setFileType] = useState('');

    const getAndSetFileType = async (url) => {
        const file = await fetchFile(url);
        const fileType = await getFileType(file);
        setFileType(fileType);
    };

    const renderThumbnail = () => {
        if (fileType === 'pdf') {
            return (
                <Box sx={{ width: '100%', height: '100%', zoom: '50%' }}>
                    <iframe
                        style={{
                            border: 'none',
                            width: '100%',
                            height,
                        }}
                        src={url}
                        scrolling="no"
                    />
                </Box>
            );
        } else if (fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return (
                <Box sx={{ width: '100%', height: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={WordIcon} width="40%" />
                </Box>
            );
        } else if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            return (
                <Box sx={{ width: '100%', height: '100%', backgroundSize: 'cover', backgroundImage: `url(${url})` }} />
            );
        } else if (fileType === 'mp4') {
            return (
                <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <video height="100%">
                        <source src={url} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            )
        } else {
            return null;
        }
    };

    useEffect(() => {
        getAndSetFileType(url);
    }, []);

    return (
        <>
            {renderThumbnail()}
        </>
    );
};
