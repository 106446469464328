/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ScreenTitle = styled.span`
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0E172C; 
`;

export const ScreenSubtext = styled.span`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #0E172C;
`;