import MatchQuizIntro from "../components/dynamic-form/intro-screens/match-quiz";
import MatchQuizEnd from "../components/dynamic-form/end-screens/match-quiz";

export const QUICK_MATCH_QUIZ = {
    questions: [
        { id: 1, type: 'text', label: 'Describe your question or challenge in a sentence of two.', required: true},
        { id: 2, type: 'select', label: 'Any preference for the type of support I recommend today?', options: ['Virtual support', 'In-person support', 'Not sure / don\'t care'], multiple: false, required: true},
    ],
    intro: null,
    end: null,
};

export const MATCH_QUIZ = {
    intro: MatchQuizIntro,
    questions: [
        { id: 1, type: 'select', label: 'What stage(s) of parenthood are you in? (Select all that apply)', options: ['Trying to conceive / adopt', 'Pregnant / expecting', '4th trimester / early postpartum', 'Working parenthood', '1st year postpartum', 'Parenting toddler(s)', 'Parenting young kids', 'Parenting teens / tweens'], multiple: true, required: true },
        { id: 2, type: 'select', label: 'Which of the following, if any, describe(s) your personal experience? (Select all that apply)', options: ['First-Time Parent', 'Parent with Multiples', 'Single Parent', 'BIPOC', 'LGBTQ+', 'None of the Above'], multiple: true, required: false},
        { id: 3, type: 'select', label: 'I think of my ideal support expert as most like: (Select up to two)', options: ['A Fairy Godparent', 'An Earthy Flower Child', 'A Science Teacher', 'A Trusted Big Sibling', 'A Friend Who Makes Me Laugh', 'None of These / Unsure'], multiple: true, required: false},
        { id: 4, type: 'select', label: 'Any preference for the type of support I recommend today?', options: ['Virtual support', 'In-person support', 'Not sure / don\'t care'], multiple: false, required: true},
        { id: 5, type: 'text', label: 'Describe your question or challenge in a sentence of two.', required: true},
    ],
    end: MatchQuizEnd,
};

export const PARENT_REGISTRATION = {
    questions: [
        {
            id: 1,
            type: 'select',
            label: 'What country are you in?',
            options: ['USA', 'Canada', 'Other / None of these'],
            required: true,
            conditionalInput: {
                triggerValue: 'Other / None of these',
                type: 'text',
                label: 'Please specify your country',
            }},
        {
            id: 2,
            type: 'select',
            label: 'Select your town / city',
            options: ['San Francisco Bay Area', 'Richmond, VA', 'Boston, MA', 'Other / None of these'],
            required: true,
            conditionalInput: {
                triggerValue: 'Other / None of these',
                type: 'text',
                label: 'Please specify your town / city',
            },
        },
        { id: 3, type: 'text', label: 'Email', required: true },
        { id: 4, type: 'password', label: 'Password', required: true },
        { id: 5, type: 'text', label: 'Do you have a joining code? If so, add it here.'},
        {
            id: 6,
            type: 'checkbox',
            label: 'I agree to the Parent Terms and Conditions',
            linkText: 'Parent Terms and Conditions',
            linkHref: 'https://assets-global.website-files.com/62b6328770c172ec59ea167d/65b6f13b21b5e9f99e5120fd_Nessle%20-%20Terms%20of%20Service%20and%20User%20Agreement%20(TC%201.25.pdf',
            required: true,
        },
    ],
    intro: null,
    end: null,
};