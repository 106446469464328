import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Snackbar } from '@mui/material';

import config from '../../config';
import LinkIcon from '@mui/icons-material/Link';

import { ModalHeaderRight } from '../profile/Profile.style';
import Button from '../common/button';

export default function ProductShare({ product }) {
    const [copied, setCopied] = useState(false);
    
    return (
        <ModalHeaderRight>
            <CopyToClipboard
                text={`${config.FE_BASE}/experts/${product.seller.display_id}/${product.id}`}
                onCopy={() => setCopied(true)}
            >
                <Button onClick={() => {}} variant="text" shade="black">
                  <LinkIcon /> <Box ml={1} display="inline">Share</Box>
                </Button>
            </CopyToClipboard>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={copied}
                autoHideDuration={4000}
                onClose={() => setCopied(false)}
                message="Share link has been copied to your clipboard"
            />
        </ModalHeaderRight>
    );
}