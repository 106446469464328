import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Alert, Box, Button, InputAdornment, TextField, Typography } from '@mui/material';

import { Payment } from "../../types";
import { createPayment } from '../../clients/payment';
import { useAuth } from '../../hooks';

interface PaymentFormProps {
    folderId: string;
    onClose: () => void;
}

export default function PaymentForm({ folderId, onClose }: PaymentFormProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const { token } = useAuth();

    const [paymentAmount, setPaymentAmount]: [string, Function] = useState();
    const [paymentDescription, setPaymentDescription] = useState('');
    const [processing, setProcessing] = useState(false);

    const onSendRequest = async () => {
        setProcessing(true);

        await createPayment(
            dispatcher,
            navigate,
            token,
            {
                folder_id: folderId,
                description: paymentDescription,
                card_amount: parseFloat(paymentAmount) * 100,
                wallet_amount: 0,
                transfer_amount: parseFloat(paymentAmount) * 100,
            },
        );

        setProcessing(false);
        onClose();
    };  

    return (
        <Box>
            <Box mb={2}>
                <Typography variant="subtitle1">
                    Amount
                </Typography>
            </Box>
            {(paymentAmount !== undefined && parseFloat(paymentAmount) <= 0) && (
                <Box mb={2}>
                    <Alert color="error">Please input a positive value.</Alert>
                </Box>
            )}
            <Box mb={2}>
                <TextField
                    id="payment-amount"
                    type="number"
                    variant="outlined"
                    value={paymentAmount}
                    onChange={(e) => setPaymentAmount(e.target.value)}
                    sx={{ width: 150 }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <span>$</span>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Box mb={2}>
                <Typography variant="subtitle1">
                    Description
                </Typography>
            </Box>
            <Box mb={2}>
                <TextField
                    id="payment-description"
                    variant="outlined"
                    value={paymentDescription}
                    onChange={(e) => setPaymentDescription(e.target.value)}
                    sx={{ width: '100%' }}
                />
            </Box>
            <Box>
                <Button
                    variant="contained"
                    disabled={parseFloat(paymentAmount) <= 0 || processing}
                    onClick={onSendRequest}
                >
                    Send Request
                </Button>
            </Box>
        </Box>
    );
};
