import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Alert,
    Box,
    Snackbar,
    Stack,
    TextField,
} from '@mui/material';
import { post } from '../../services/api.services';
import { API } from '../../constants';
import config from '../../config';
import Button from '../../components/common/button';
import NessleModal from '../../components/common/modal';

function Invite({ onClose }) {
    const navigate = useNavigate();

    const token = useSelector((state) => state.account.token);

    const [inviteeName, setInviteeName] = useState('');
    const [inviteeNameError, setInviteeNameError] = useState();
    const [inviteeEmail, setInviteeEmail] = useState('');
    const [inviteeEmailError, setInviteeEmailError] = useState();
    const [inviteMsg, setInviteMsg] = useState('');
    const [inviteMsgError, setInviteMsgError] = useState();
    const [hasErrors, setHasErrors] = useState(false);
    const [copied, setCopied] = useState(false);
    const [sent, setSent] = useState(false);

    const resetErrors = () => {
        setInviteeNameError(null);
        setInviteeEmailError(null);
        setInviteMsgError(null);
        setHasErrors(false);
    }

    const createInvite = async (send = true, copy = false) => {
        resetErrors();
    
        const result = await post(
            API.INVITE,
            {
                invitee_name: inviteeName,
                invitee_email: inviteeEmail,
                message: inviteMsg,
            },
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 400) {
            setHasErrors(true);
            if (result.data.invitee_email) {
                setInviteeEmailError(result.data.invitee_email);
            }

            if (result.data.invitee_name) {
                setInviteeNameError(result.data.invitee_name);
            }

            if (result.data.invite_msg) {
                setInviteMsgError(result.data.invite_msg);
            }
        }
        else if (result.status === 201) {
            if (send) {
                const sendResult = await post(
                    `${API.INVITE}${result.data.invite.id}/send/`,
                    {},
                    token,
                );
                   
                if (result.status === 403 || result.status === 401) {
                    navigate('/login/experts');
                }

                setSent(true);
                setInviteeName('');
                setInviteeEmail('');
                setInviteMsg('');
                onClose();
            }

            if (copy) {
                await navigator.clipboard.writeText(`${config.FE_BASE}/invite/${result.data.invite.code}`);
                setCopied(true);
            }
        }
        else {
            setHasErrors(true);
        }
    }

    return (
        <NessleModal title="Invite a Client" onClose={() => onClose()}>
            {hasErrors && (
                <Box mb={2}>
                    <Alert color="error">Please review the errors below and try again.</Alert>
                </Box>
            )}
            <Stack spacing={2}>
                <TextField
                    id="inviteeName"
                    label="Name"
                    variant="outlined"
                    value={inviteeName}
                    onChange={(e) => setInviteeName(e.target.value)}
                    inputProps={{ maxLength: 64, sx: { fontFamily: 'Cabin' } }}
                    error={inviteeNameError}
                    helperText={inviteeNameError || null}
                />
                <TextField
                    id="inviteeEmail"
                    label="Email"
                    variant="outlined"
                    value={inviteeEmail}
                    onChange={(e) => setInviteeEmail(e.target.value)}
                    inputProps={{ maxLength: 256, sx: { fontFamily: 'Cabin' } }}
                    error={inviteeEmailError}
                    helperText={inviteeEmailError || null}
                />
                <TextField
                    id="inviteMsg"
                    label="Message"
                    variant="outlined"
                    value={inviteMsg}
                    multiline
                    minRows={4}
                    onChange={(e) => setInviteMsg(e.target.value)}
                    inputProps={{ maxLength: 256, sx: { fontFamily: 'Cabin' } }}
                    error={inviteMsgError}
                    helperText={inviteMsgError || null}
                />
                <Box display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondaryLight" onClick={() => createInvite(false, true)}>Copy Invite Link</Button>
                    <Button shade="secondary" onClick={createInvite}>Send Invite</Button>
                </Box>
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={copied}
                autoHideDuration={4000}
                onClose={() => setCopied(false)}
                message="Invite link has been copied to your clipboard"
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={sent}
                autoHideDuration={4000}
                onClose={() => setSent(false)}
                message="Invite sent!"
            />
        </NessleModal>
    );
}

export default Invite;