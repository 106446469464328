import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import moment from 'moment';
import {
    Alert,
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { API } from '../../constants';
import Modal from '../../components/common/modal';
import { get, post } from '../../services/api.services';
import Button from '../../components/common/button';
import { displayName } from '../../utils/profile';

function ShareForm({ folder, resources, onClose }) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [folders, setFolders] = useState([]);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState();
    const [hasErrors, setHasErrors] = useState(false);
    const [client, setClient] = useState(folder ? folder.id : '');
    const [clientError, setClientError] = useState();

    const resetErrors = () => {
        setClientError(null);
        setMessageError(null);
        setHasErrors(false);
    };

    const onSave = async () => {
        resetErrors();
        let result;
    
        result = await post(
            `${API.SHARE}`,
            {
                folder_id: client,
                resource_ids: resources.map((resource) => resource.id),
                message,
            },
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 400) {
            setHasErrors(true);
            
            if (result.data.message) {
                setMessageError(result.data.message);
            }
        }
        else if (result.status === 201 || result.status === 200) {
            onClose();
        }
        else {
        }
    };

    const fetchFolders = async () => {
        const result = await get(
            API.FOLDER,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setFolders(result.data);
        }
    };

    useEffect(() => {
        fetchFolders();
    }, []);

    return (
        <Modal
            title={
                folder ?
                `Share with ${displayName(folder.members[0].first_name, folder.members[0].last_name)}` :
                'Share'
            }
            onClose={onClose}
        >
            {hasErrors && (
                <Box mb={2}>
                    <Alert color="error">Please review the errors below and try again.</Alert>
                </Box>
            )}
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ fontFamily: 'Cabin', fontSize: '14px'}}>Resources to Share</Box>
                        {resources.map((resource) => (
                            <Box mt={1} sx={{ fontFamily: 'Cabin', fontSize: '18px'}}>
                                &bull; {resource.title}
                            </Box>
                        ))}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box mb={1} sx={{ fontFamily: 'Cabin', fontSize: '14px'}}>Share with</Box>
                        <FormControl fullWidth>
                            <Select
                                id="client"
                                value={client}
                                onChange={(e) => setClient(e.target.value)}
                                error={clientError}
                                helperText={clientError || null}
                                sx={{ width: '100%', color: 'black' }}
                            >
                                {folders.filter((folder) => folder.members.length > 0).map((folder) => (
                                    <MenuItem value={folder.id}>
                                        {displayName(folder.members[0].first_name, folder.members[0].last_name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={1} sx={{ fontFamily: 'Cabin', fontSize: '14px'}}>Note</Box>
                        <TextField
                            id="share-message"
                            variant="outlined"
                            value={message}
                            multiline
                            minRows={4}
                            onChange={(e) => setMessage(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 1024 }}
                            error={messageError}
                            helperText={messageError || null}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                    <Button shade="secondary" onClick={onSave}>Share</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default ShareForm;
