import moment from 'moment';

export const sessionIdsReadyToSchedule = (sessionIds, sessionsById) => {
    return sessionIds.filter((sessionId) => {
        const session = sessionsById[sessionId];
        
        if (session.prompt_scheduling_date && moment(session.prompt_scheduling_date) > moment.utc()) {
            return false;
        }
        if (!session.prompt_scheduling_date && session.prompt_scheduling_after_session_id) {
            return false;
        }
        return true;
    });
};