import styled from 'styled-components';

export const RegistrationText = styled.p`
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  line-height: 1.75rem;
  color: #2f1160;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: col;
  align-items: center;
  gap: 1.5rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
