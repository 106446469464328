// action - state management
import {
    SET_PROFILE,
    ADD_EXPERT_PROFILE_ID,
    RESET_EXPERT_PROFILE_IDS,
  } from './actions';
  
  export const initialState = {
    profilesById: {},
    profilesByDisplayId: {},
    expertProfileIds: [],
  };
  
  const profileReducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_PROFILE: {
        const { profile } = action.payload;
        const newProfilesById = { ...state.profilesById };
        const newProfilesByDisplayId = { ...state.profilesByDisplayId };
        newProfilesById[profile.id] = profile;
        newProfilesByDisplayId[profile.display_id] = profile;
        return {
          ...state,
          profilesById: newProfilesById,
          profilesByDisplayId: newProfilesByDisplayId,
        };
      }
      case ADD_EXPERT_PROFILE_ID: {
        const { expert } = action.payload;
        return {
          ...state,
          expertProfileIds: [ ...state.expertProfileIds, expert.id ],
        }
      }
      case RESET_EXPERT_PROFILE_IDS: {
        return {
          ...state,
          expertProfileIds: [],
        }
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default profileReducer;
  