import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';

import { SET_SESSION } from '../../store/actions';
import { API } from '../../constants';
import { patch } from '../../services/api.services';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import {
    Range,
    ScheduleTitle,
    Disclaimer,
} from '../product/product.style';
import {
    ModalContentContainer,
    ModalHeader,
    ModalHeaderLeft,
    ModalTitle,
    ModalBody,
} from '../profile/Profile.style';
import Button from '../common/button';

export default function Schedule({
    session,
    onClose,
}) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    let defaultDaySelection = null;

    if (session.date_ranges?.days) {
        defaultDaySelection = Object.keys(session.date_ranges.days)[0];
    }

    const [dateSelected, setDateSelected] = useState(defaultDaySelection);
    const [rangeSelected, setRangeSelected] = useState(null);
    const [hour, setHour] = useState('08');
    const [minute, setMinute] = useState('00');
    const [period, setPeriod] = useState('am');

    const timezone = session.date_ranges?.timezone || moment.tz.guess();

    const ranges = [
        {
            start: 6,
            end: 8,
            display: '6:00 am - 8:00 am',
        },
        {
            start: 8,
            end: 10,
            display: '8:00 am - 10:00 am',
        },
        {
            start: 10,
            end: 12,
            display: '10:00 am - 12:00 pm',
        },
        {
            start: 12,
            end: 14,
            display: '12:00 pm - 2:00 pm',
        },
        {
            start: 14,
            end: 16,
            display: '2:00 pm - 4:00 pm',
        },
        {
            start: 16,
            end: 18,
            display: '4:00 pm - 6:00 pm',
        },
        {
            start: 18,
            end: 20,
            display: '6:00 pm - 8:00 pm',
        },
    ];

    const schedule = async () => {
        let militaryHour;

        if (period === 'am') {
            if (hour == '12') {
                militaryHour = '00';
            } else {
                militaryHour = parseInt(hour);
            }
        } else {
            if (hour == '12') {
                militaryHour = parseInt(hour);
            } else {
                militaryHour = parseInt(hour) + 12;
            }
        }

        const hourStr = militaryHour < 10 ? `0${militaryHour}` : `${militaryHour}`;
        const sessionDate = moment.tz(`${dateSelected} ${hourStr}:${minute}:00`, timezone).tz('UTC').format('YYYY-MM-DD HH:mm');
        
        const result = await patch(
          `${API.SESSION}${session.id}/`,
          {
            session_date: sessionDate,
          },
          token,
        );

        if (result.status === 403 || result.status === 401) {
          navigate('/login/parents');
        }
        else if (result.status === 200) {
            onClose();

            dispatcher({
                type: SET_SESSION,
                payload: {
                    session: result.data,
                },
            });
        }
        else {
            
        }
    };

    return (
        <ModalContentContainer>
            <ModalHeader>
                <ModalHeaderLeft>
                    <IconButton onClick={onClose}>
                        <img src={DismissIcon} alt="share" width={12} height={12} />
                    </IconButton>
                    <ModalTitle>
                        Session Request
                    </ModalTitle>
                </ModalHeaderLeft>
            </ModalHeader>
            <ModalBody>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box sx={{ textAlign: 'center' }}>
                            <ScheduleTitle>
                                Here are the days and times that {session.parent_profile.first_name} selected.<br />
                                Select a date and time that works with your schedule.
                            </ScheduleTitle>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ textAlign: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <StaticDatePicker
                                    displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    views={['day']}
                                    minDate={moment.now()}
                                    disableHighlightToday
                                    onChange={(selection) => {
                                        const dateStr = selection.format('YYYY-MM-DD');
                                        setRangeSelected(null);
                                        setDateSelected(dateStr);
                                    }}
                                    showDaysOutsideCurrentMonth
                                    renderInput={(params) => {
                                        return <TextField {...params} />;
                                    }}
                                    renderDay={(day, _value, DayComponentProps) => {
                                        const dateStr = day.format('YYYY-MM-DD');

                                        if (dateStr === dateSelected) {
                                            return (
                                                <PickersDay {...DayComponentProps} style={{ background: '#5371ff', color: '#FFFFFF' }}/>
                                            );
                                        } else if (session.date_ranges?.days[dateStr] && session.date_ranges?.days[dateStr].length) {
                                            return (
                                                <Box sx={{ borderBottom: '2px solid #5371ff' }}>
                                                    <PickersDay {...DayComponentProps} />
                                                </Box>
                                            ); 
                                        } else {
                                            return (
                                                <PickersDay {...DayComponentProps} />
                                            );
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                            </Box>
                        </Box>
                    </Grid>
                    {dateSelected && (
                        <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ mb: 1 }}>
                                    <ScheduleTitle style={{ fontSize: '14px' }}>
                                        Time ranges for {moment(dateSelected).format('MMMM Do')} (Time in {moment.tz.zone(timezone).abbr(moment(dateSelected).format('x'))})
                                    </ScheduleTitle>
                                </Box>
                                {dateSelected === moment(new Date()).format('YYYY-MM-DD') && (
                                    <Box mb={1}>
                                        <Disclaimer>
                                            For day-of sessions, we suggest choosing times at least 30 minutes out, so the{' '}
                                            parent has time to prepare.
                                        </Disclaimer>
                                    </Box>
                                )}
                                {session.date_ranges?.days[dateSelected]?.map((range, index) => (
                                    <Range
                                        key={range.display}
                                        onClick={() => {
                                            setRangeSelected(index);

                                            if (session.date_ranges.days[dateSelected][index].start > 12) {
                                                setHour(session.date_ranges.days[dateSelected][index].start % 12);
                                                setPeriod('pm');
                                            } else if (session.date_ranges.days[dateSelected][index].start === 12) {
                                                setHour(session.date_ranges.days[dateSelected][index].start);
                                                setPeriod('pm');
                                            } else {
                                                setHour(session.date_ranges.days[dateSelected][index].start);
                                                setPeriod('am');
                                            }
                                        }}
                                        style={rangeSelected === index ? { background: '#FFE7E7', borderColor: '#5371ff' } : {}}
                                    >
                                        {range.display}
                                    </Range>
                                ))}
                                <Range
                                    onClick={() => setRangeSelected('other')}
                                    style={rangeSelected === 'other' ? { background: '#FFE7E7', borderColor: '#5371ff' } : {}}
                                >
                                    Other
                                </Range>
                                {rangeSelected === 'other' && (
                                    <Box>
                                        <Disclaimer>
                                            When selecting a time outside of the suggested time ranges,{' '}
                                            please use the chat feature to confirm the time with the parent.
                                        </Disclaimer>
                                    </Box>
                                )}
                                {dateSelected && rangeSelected !== null && (
                                    <>
                                        <Box sx={{
                                            fontFamily: 'Cabin',
                                            fontSize: '15px',
                                            color: '#210B2B',
                                            m: 2,
                                        }}>
                                            Enter time
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ width: '50px', marginRight: 1 }}>
                                                <TextField
                                                    id="hour"
                                                    variant="outlined"
                                                    value={hour}
                                                    onChange={(e) => setHour(e.target.value)}
                                                    inputProps={{ maxLength: 2, sx: { fontFamily: 'Cabin' } }}
                                                />
                                            </Box>
                                            <Box sx={{ width: '50px', marginRight: 1 }}>
                                                <TextField
                                                    id="minute"
                                                    variant="outlined"
                                                    value={minute}
                                                    onChange={(e) => setMinute(e.target.value)}
                                                    inputProps={{ maxLength: 2, sx: { fontFamily: 'Cabin' } }}
                                                />
                                            </Box>
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                border: '1px solid #DADCE0',
                                                borderRadius: 1,
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                justifyContent: 'space-between',
                                                cursor: 'pointer',
                                            }}>
                                                <Box
                                                    sx={{
                                                        borderBottom: '1px solid #DADCE0',
                                                        padding: '8px',
                                                        background: `${period === 'am' ? '#F2E7FE' : '#FFFFFF'}`,
                                                        color: `${period === 'am' ? '#6200EE' : '#555555'}`,
                                                    }}
                                                    onClick={() => setPeriod('am')}
                                                >
                                                    AM
                                                </Box>
                                                <Box
                                                    sx={{
                                                        padding: '8px',
                                                        background: `${period === 'pm' ? '#F2E7FE' : '#FFFFFF'}`,
                                                        color: `${period === 'pm' ? '#6200EE' : '#555555'}`,
                                                    }}
                                                    onClick={() => setPeriod('pm')}
                                                >
                                                    PM
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                )}
                                <Box sx={{ mt: 2 }}>
                                    <Button shade="primary" onClick={schedule}>Schedule</Button>
                                </Box>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </ModalBody>
        </ModalContentContainer>
    );
}
