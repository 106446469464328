import {
    PaymentActionType
} from '../store/actions';

import { get, post, patch, destroy } from '../services/api.services';
import { API } from '../constants';

export const fetchPayments = async (dispatcher, navigate, token, folderId) => {
    const result = await get(
        `${API.PAYMENT}?folder_id=${folderId}`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        dispatcher({
            type: PaymentActionType.RESET_PAYMENT_IDS,
            payload: {
                folderId,
            },
        });
    
        result.data.forEach((payment) => {
            dispatcher({
                type: PaymentActionType.SET_PAYMENT,
                payload: {
                  payment,
                },
            });

            dispatcher({
                type: PaymentActionType.ADD_PAYMENT_ID,
                payload: {
                  payment,
                  folderId,
                },
            });
        })
    }
}

export const createPayment = async (dispatcher, navigate, token, data) => {
    const result = await post(
        API.PAYMENT,
        data,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 201) {
        dispatcher({
            type: PaymentActionType.SET_PAYMENT,
            payload: {
              payment: result.data,
            },
        });

        dispatcher({
            type: PaymentActionType.ADD_PAYMENT_ID,
            payload: {
              payment: result.data,
              folderId: result.data.folder?.id,
            },
        });
    }
}

export const updatePayment = async (dispatcher, navigate, token, payment, data) => {
    const result = await patch(
        `${API.PAYMENT}${payment.id}/`,
        data,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 200) {
        dispatcher({
            type: PaymentActionType.SET_PAYMENT,
            payload: {
              payment: result.data,
            },
        });
    }
}

export const refundPayment = async (dispatcher, navigate, token, payment, amount) => {
    const result = await patch(
        `${API.PAYMENT}${payment.id}/refund/`,
        { amount },
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 200) {
        dispatcher({
            type: PaymentActionType.SET_PAYMENT,
            payload: {
              payment: result.data,
            },
        });
    }
}

export const deletePayment = async (dispatcher, navigate, token, payment) => {
    const result = await destroy(
        `${API.PAYMENT}${payment.id}/`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 204) {
        dispatcher({
            type: PaymentActionType.REMOVE_PAYMENT_ID,
            payload: {
                payment,
            }
        });
    }
}
