import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Grid } from '@mui/material';

import { ACCOUNT_INITIALIZE, SET_VERIFIED } from '../../store/actions';

import config from '../../config';
import { Col, Row } from '../../styled_components/grid/Grid.style';
import Logo from '../../assets/images/parentswarm-logo.svg';
import ExpertCaraousalStill from '../../assets/images/caraousal1.png';
import ParentCaraousalStill from '../../assets/images/caraousal4.png';
import {
  Container,
  ImageDiv,
  RegisterBody,
  TopDot,
  BottomDot,
} from './Login.style';
import Carousel from '../../components/register/caraousal';
import { post, patch } from '../../services/api.services';
import { API, ACCOUNT_TYPES } from '../../constants';
import RegisterForm from '../../components/register/register-form';
import RegisterOtp from '../../components/register/register-otp';
import RegisterSuccess from '../../components/register/register-success';
import ForgotPasswordSuccess from '../../components/register/forgot-password-success';
import ForgotPasswordForm from '../../components/register/forgot-password-form';

function Login({ accountType }) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pathParams = useParams();

  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);
  const matchAnswers = useSelector((state) => state.match);
  const coupon = useSelector((state) => state.coupon);

  const [code, setCode] = useState(pathParams.code?.toUpperCase() || coupon.code || params.get('code'));
  const [priceId, setPriceId] = useState(params.get('price') || null);

  const [profileType, setProfileType] = useState(accountType);
  const [codeError, setCodeError] = useState(false);
  const [email, setEmail] = useState(matchAnswers.email || '');
  const [password, setPassword] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [active, setActive] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [loginDisplay, setLoginDisplay] = useState(params.get('register') ? false : true /*params.get('login') || params.get('forgot') ? true : false*/);
  const [showRegisterForm, setShowRegisterForm] = useState(params.get('forgot') ? false : true);
  const [showRegisterPin, setShowRegisterPin] = useState(false);
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(params.get('forgot') ? true : false);
  const [showForgotPasswordSuccess, setShowForgotPasswordSuccess] = useState(false);
  const [otp1, setOtp1] = useState();
  const [otp2, setOtp2] = useState();
  const [otp3, setOtp3] = useState();
  const [otp4, setOtp4] = useState();
  const [count, setCount] = useState(0);
  const [timer, setTimer] = useState(5);
  const [showRegisterSuccess, setShowRegisterSuccess] = useState(false);

  const switchDisplay = (loggingIn) => {
    setLoginDisplay(loggingIn);
  };

  const handleEmailChange = (value) => {
    setPasswordError(false);
    setEmail(value);
    // eslint-disable-next-line no-useless-escape
    if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      setEmailError(false);

      if (password.length && !passwordError) {
        setActive(true);
      }
    } else {
      setEmailError('Email entered is invalid');
      setActive(false);
    }
  };

  const handlePasswordChange = (value) => {
    setEmailError(false);
    setPassword(value);

    if (!value.length) {
      setPasswordError('Password is required');
      setActive(false)
    } else if (email.length && !emailError) {
      setPasswordError(false);
      setActive(true);
    }
  }

  const openForgotPassword = () => {
    setShowRegisterForm(false);
    setShowForgotPasswordForm(true);
  };

  const openForgotPasswordSuccess = () => {
    setShowForgotPasswordForm(false);
    setShowForgotPasswordSuccess(true);
  };

  const returnFromForgotPassword = () => {
    setShowRegisterForm(true);
    setShowForgotPasswordForm(false);
    setShowForgotPasswordSuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      username: email,
      email,
      password,
      profile_type: profileType,
      matched_product: matchAnswers.match?.productId,
      coupon: code,
      price_id: priceId,
    };
    
    let result;

    if (loginDisplay) {
      result = await post(API.LOGIN, payload);
      if (result.status === 200) {
        // Comments below are for having OTP check on login
        //setShowRegisterForm(false);
        //setShowRegisterPin(true);

        dispatcher({
          type: ACCOUNT_INITIALIZE,
          payload: {
            isLoggedIn: true,
            isVerified: true, // false
            user: {
              accountType: profileType,
              ...result.data.user,
            },
            token: result.data.token,
            refresh: result.data.refresh,
          },
        });
        //handleResend();
        navigate(`/dashboard${params.get('invite') ? `?invite=${params.get('invite')}` : ''}`); // Remove for otp check
      }
      else {
        if (result.data.email) {
          setEmailError(result.data.email[0]);
        }
        else if (result.data.username) {
          setEmailError(result.data.username[0].replace('username', 'email'));
        }

        if (result.data.password) {
          setPasswordError(result.data.password[0]);
        }

        if (result.data.detail) {
          setPasswordError(result.data.detail);
        }

        setActive(false);
      }
    } else {
      result = await post(API.REGISTER, payload);
      if (result.status === 201) {
        // setShowRegisterForm(false);
        // setShowRegisterPin(true);

        dispatcher({
          type: ACCOUNT_INITIALIZE,
          payload: {
            isLoggedIn: true,
            isVerified: true, // false for email verification
            user: {
              accountType: profileType,
              ...result.data.user,
            },
            token: result.data.token,
            refresh: result.data.refresh,
          },
        });
        
        // If coming from an invite, accept it
        if (params.get('invite')) {
          await post(
            `${API.INVITE}${params.get('invite')}/accept/`,
            {},
            result.data.token,
          );
        }

        navigate(`/dashboard${params.get('invite') ? `?invite=${params.get('invite')}` : ''}`); // Remove for otp check

        /*if (config.currentEnv === 'production') {
          FS.identify(result.data.user.id, {
            email: result.data.user.email,
          });
        }*/
      }
      else {
        if (result.data.email) {
          setEmailError(result.data.email[0]);
        }
        else if (result.data.username) {
          setEmailError(result.data.username[0].replace('username', 'email'));
        }

        if (result.data.password) {
          setPasswordError(result.data.password[0]);
        }

        if (result.data.code) {
          setCodeError(result.data.code);
        }

        setActive(false);
      }
    }
  };

  const handleOtp = (e) => {
    let focus;
    if (e.target.value && !/^[0-9]$/.test(e.target.value)) {
      return;
    }
    if (e.target.name === 'otp-1') {
      if (e.target.value) {
        setOtp1(e.target.value);
        focus = document.querySelector('input[name=otp-2]');
        focus.focus();
      } else {
        setOtp1(e.target.value);
      }
    } else if (e.target.name === 'otp-2') {
      if (e.target.value) {
        setOtp2(e.target.value);
        focus = document.querySelector('input[name=otp-3]');
        focus.focus();
      } else {
        setOtp2(e.target.value);
      }
    } else if (e.target.name === 'otp-3') {
      if (e.target.value) {
        setOtp3(e.target.value);
        focus = document.querySelector('input[name=otp-4]');
        focus.focus();
      } else {
        setOtp3(e.target.value);
      }
    } else {
      setOtp4(e.target.value);
    }
  };

  const sendOtp = async () => {
    if (otp1 && otp2 && otp3 && otp4) {
      const otp = `${otp1}${otp2}${otp3}${otp4}`;
      const result = await patch(`${API.PROFILE}${user.profile.id}/submit_otp_code/`, { otp_code: otp }, token);
      if (result.status === 200) {
        if (loginDisplay) {
          navigate(`/dashboard${params.get('invite') ? `?invite=${params.get('invite')}` : ''}`);
        }
        else {
          setShowRegisterSuccess(true);
          setShowRegisterPin(false);
        }

        dispatcher({
          type: SET_VERIFIED,
        });
      }
    }
  };

  const handleResend = async () => {
    const result = await patch(`${API.PROFILE}${user.profile.id}/request_otp_code/`, {}, token);
    setOtp1(null);
    setOtp2(null);
    setOtp3(null);
    setOtp4(null);

    if (result.status === 200) {
      setTimer(5);
    }
  }

  useEffect(() => {
    if (showRegisterPin && timer > 0) {
      setTimeout(() => setTimer(timer - 1), 60000);
    }
  });

  useEffect(() => {
    const numImages = 3;
    const counter = setInterval(() => {
      setCount((count + 1) % numImages);
    }, 3000);

    return () => clearInterval(counter);
  });

  return (
    <Container>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ display: { xs: 'none', md: 'block' }, height: '100vh', backgroundColor: '#5371FF' }} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ backgroundColor: '#FFFFFF' }}>
          <RegisterBody>
            <img src={Logo} alt="parenting" width={200} />
            <br />
            {showRegisterForm && (
              <RegisterForm
                loginDisplay={loginDisplay}
                handleSubmit={handleSubmit}
                handleEmailChange={handleEmailChange}
                handlePasswordChange={handlePasswordChange}
                setLoginDisplay={switchDisplay}
                email={email}
                password={password}
                phoneNo={phoneNo}
                emailError={emailError}
                passwordError={passwordError}
                phoneError={phoneError}
                active={active}
                openForgotPassword={openForgotPassword}
                code={code}
                codeError={codeError}
                setCode={setCode}
                accountType={profileType}
                setAccountType={setProfileType}
              />
            )}
            {showRegisterPin && (
              <RegisterOtp
                handleOtp={handleOtp}
                sendOtp={sendOtp}
                setTimer={setTimer}
                handleResend={handleResend}
                timer={timer}
                otp1={otp1}
                otp2={otp2}
                otp3={otp3}
                otp4={otp4}
                loggingIn={loginDisplay}
              />
            )}
            {showRegisterSuccess && (
              <RegisterSuccess
                accountType={profileType}
              />
            )}
            {showForgotPasswordForm && (
              <ForgotPasswordForm onSuccess={openForgotPasswordSuccess} onBack={returnFromForgotPassword} />
            )}
            {showForgotPasswordSuccess && (
              <ForgotPasswordSuccess onBack={returnFromForgotPassword} />
            )}
          </RegisterBody>
        </Grid>
      </Grid>
    </Container>
  );
}

Login.propTypes = {
  accountType: PropTypes.string,
};

export default Login;
