import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Drawer, IconButton, Modal, Rating, Tooltip, Stack } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import { ACCOUNT_TYPES } from '../../constants';
import ClockIcon from '../../assets/icons/Clock.svg';
import EditIcon from '../../assets/icons/Edit.svg';
import FolderIcon from '../../assets/icons/folder-light.svg';
import DismissIcon from '../../assets/icons/DismissLight.svg';
import MeetingLink from './meeting-link';
import MiniProfile from './mini-profile';
import KeyTakeaways from './key-takeaways';
import Thread from '../thread';
import Review from '../review';
import Button from '../common/button';
import PickNewTimeRanges from '../questions/schedule';
import Schedule from './schedule';
import AddMeetingLink from './add-meeting-link';
import Cancel from './cancel';
import SuggestedTimes from './suggested-times';
import AddToCalendar from '../product/add-to-calendar';
import NoteForm from '../note/note-form';
import Note from '../note';
import { fetchSessionNotes } from '../../clients/note';

import {
    DrawerBodyDetail,
    DrawerBodyHeading,
    DrawerTitle,
    SessionDetailsBody,
    SessionDetailsHeader,
    SessionTitle,
    SpacedItem,
} from './Session.style';

function SessionDetails({ session, variant, onDismiss }) {
  const token = useSelector((state) => state.account.token);

  const [openKeyTakeaways, setOpenKeyTakeaways] = useState(false);
  const [openThread, setOpenThread] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [openAddMeetingLink, setOpenAddMeetingLink] = useState(false);
  const [suggestNewTimes, setSuggestNewTimes] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [openNewNote, setOpenNewNote] = useState(false);
  const [notes, setNotes] = useState([]);

  const fetchAndSetNotes = async () => {
    const fetchedNotes = await fetchSessionNotes(token, session.id);

    if (fetchedNotes) {
      setNotes(fetchedNotes);
    }
  };

  const closeNotes = () => {
    setOpenNewNote(false);
    fetchAndSetNotes();
  };

  useEffect(() => {
    fetchAndSetNotes();
  }, []);
  
  return (
    <Box sx={{ width: 400, maxWidth: '100%' }}>
        <SessionDetailsHeader>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <DrawerTitle>
                  {variant === ACCOUNT_TYPES.EXPERT ? 'Session Details' : '1-on-1 Session'}
                </DrawerTitle>
                <IconButton onClick={onDismiss}>
                  <img src={DismissIcon} alt="close" width={12} height={12} />
                </IconButton>
            </Box>
            <Box sx={{ mb: 2 }}>
              <SessionTitle>{session.title}</SessionTitle>
              {session.categories && (
                <Box sx={{ mt: 1, fontSize: 12, display: 'flex', alignItems: 'center' }}>
                  <img src={FolderIcon} />
                  <Box sx={{ ml: 1 }}>
                    {session.categories.split(',').join(', ')}
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {variant === ACCOUNT_TYPES.EXPERT ? (
                  <MiniProfile profile={session.parent_profile} />
                ) : (
                  <MiniProfile profile={session.expert_profile} />
                )}
                <IconButton onClick={(e) => setOpenThread(true)} style={{ color: '#FFFFFF' }}>
                  <MessageOutlinedIcon />
                </IconButton>
            </Box>
        </SessionDetailsHeader>
        <SessionDetailsBody>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <DrawerBodyHeading>When?</DrawerBodyHeading>
          </Box>
          <Box sx={{ mt: 1, mb: 2, fontFamily: 'Cabin', fontWeight: 500 }}>
            <DrawerBodyDetail>
              {!session.canceled ? (
                <>
                  {session.session_date ? (
                    <>
                      <span>{moment(session.session_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}</span>
                    </>
                  ) : (
                    <>
                      {variant === ACCOUNT_TYPES.EXPERT ? (
                        <Button size="xsmall" onClick={() => setOpenScheduler(true)}>
                          Schedule&nbsp;<img src={ClockIcon} alt="schedule" width={16} />
                        </Button>
                      ) : (
                        <span>Scheduling in progress</span>
                      )}
                      <Box mt={2}>
                        <SuggestedTimes session={session} />
                      </Box>
                    </>
                  )}
                  <Stack mt={2} spacing={1} direction="row">
                    {session.session_date && session.calendar_links &&  (
                      <AddToCalendar calendarLinks={session.calendar_links} />
                    )}
                    {variant === ACCOUNT_TYPES.EXPERT && session.session_date && (
                      <Button size="xsmall" shade="primaryLight" onClick={() => setOpenScheduler(true)}>
                        Reschedule
                      </Button>
                    )}
                    {variant === ACCOUNT_TYPES.PARENT && (
                      <Button size="xsmall" shade="primaryLight" onClick={() => setSuggestNewTimes(true)}>
                        {session.session_date ? 'Reschedule' : (
                          <>
                            {session.date_ranges ? 'Suggest New Times' : 'Schedule'}
                          </>
                        )}
                      </Button>
                    )}
                    {!session.key_takeaway_1 && (
                      <Button size="xsmall" shade="secondaryLight" onClick={() => setOpenCancel(true)}>
                        Cancel
                      </Button>
                    )}
                  </Stack>
                </>
              ) : (
                <span>This session has been canceled</span>
              )}
            </DrawerBodyDetail>
          </Box>
          {!session.canceled && (
            <>
              <Box sx={{ mb: 2, height: '1px', width: '100%', background: '#E8E8E8' }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DrawerBodyHeading>Meeting Details</DrawerBodyHeading>
                  {variant === ACCOUNT_TYPES.EXPERT && (
                    <IconButton onClick={() => setOpenAddMeetingLink(true)}>
                      <img src={EditIcon} alt="meeting-link" width={20} height={20} />
                    </IconButton>
                  )}
                </Box>
                {session.meeting_link ? (
                  <MeetingLink link={session.meeting_link} />
                ) : (
                  <Box sx={{ mt: 1, mb: 2 }}><DrawerBodyDetail>Not submitted yet</DrawerBodyDetail></Box>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <DrawerBodyHeading>Key Takeaways</DrawerBodyHeading>
                  {variant === ACCOUNT_TYPES.EXPERT && (
                    <>
                      <IconButton onClick={() => setOpenKeyTakeaways(true)}>
                        <img src={EditIcon} alt="messages" width={20} height={20} />
                      </IconButton>
                      <Drawer
                        anchor="right"
                        open={openKeyTakeaways}
                        onClose={() => setOpenKeyTakeaways(false)}
                      >
                        <KeyTakeaways
                          session={session}
                          onDismiss={() => setOpenKeyTakeaways(false)}
                        />
                      </Drawer>
                    </>
                  )}
                </Box>
                {!session.key_takeaway_1 ? (
                  <Box sx={{ mt: 1, mb: 1 }}><DrawerBodyDetail>Not submitted yet</DrawerBodyDetail></Box>
                ) : (
                  <>
                    <DrawerBodyDetail>
                      <ul style={{ paddingInlineStart: 10, marginBlockStart: 0 }}>
                        <SpacedItem>{session.key_takeaway_1}</SpacedItem>
                        {session.key_takeaway_2 && (
                          <SpacedItem>{session.key_takeaway_2}</SpacedItem>
                        )}
                        {session.key_takeaway_3 && (
                          <SpacedItem>{session.key_takeaway_3}</SpacedItem>
                        )}
                      </ul>
                    </DrawerBodyDetail>
                  </>
                )}
                {variant === ACCOUNT_TYPES.PARENT && moment(session.session_date) < moment.now() && !session.review && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                      <Button onClick={() => setOpenReview(true)}>Rate your session</Button>
                    </Box>
                    <a
                      href="mailto:help@parentswarm.com"
                      style={{
                        fontFamily: 'Cabin',
                        fontWeight: 600,
                        fontSize: 14,
                        color: '#272727',
                      }}
                    >Problem with your session?</a>
                  </Box>
                )}
                {variant === ACCOUNT_TYPES.PARENT && session.review && !session.review.direct_comments && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ mb: 2 }}>
                      <Button onClick={() => setOpenReview(true)}>Finish your review</Button>
                    </Box>
                  </Box>
                )}
                {session.review && session.review.direct_comments && (
                  <Box>
                    <Box mb={1}>
                      <DrawerBodyHeading>Review</DrawerBodyHeading>
                    </Box>
                    <Tooltip title={session.review.direct_comments || `${session.review.rating} stars`}>
                      <Box sx={{ display: 'inline' }}>
                        <Rating name="rating" value={session.review.rating} precision={1} readOnly />
                      </Box>
                    </Tooltip>
                  </Box>
                )}
            </>
          )}
          {variant === ACCOUNT_TYPES.EXPERT && notes.length > 0 && (
            <>
              <Box mt={3} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DrawerBodyHeading>Notes</DrawerBodyHeading>
                <IconButton onClick={() => setOpenNewNote(true)} style={{ color: '#5371ff' }}>
                  <AddRoundedIcon fontSize="small" />
                </IconButton>
                <Modal
                  open={openNewNote}
                  onClose={closeNotes}
                >
                  <NoteForm
                    folderId={session.folder_id}
                    sessionId={session.id}
                    onClose={closeNotes}
                  />
                </Modal>
              </Box>
              <Box sx={{ mt: 1, mb: 2, fontFamily: 'Cabin', fontWeight: 500 }}>
                {notes.map((note) => (
                  <Note note={note} />
                ))}
              </Box>
            </>
          )}
        </SessionDetailsBody>
        <Drawer
          anchor="right"
          open={openThread}
          onClose={() => setOpenThread(false)}
        >
          <Thread
            parentProfile={session.parent_profile}
            expertProfile={session.expert_profile}
            onDismiss={() => setOpenThread(false)}
          />
        </Drawer>
        <Modal
          open={openReview}
          onClose={() => setOpenReview(false)}
        >
          <Review session={session} onClose={() => setOpenReview(false)} />
        </Modal>
        <Modal
          open={openScheduler}
          onClose={() => setOpenScheduler(false)}
        >
          <Schedule
            session={session}
            onClose={() => setOpenScheduler(false)}
          />
        </Modal>
        <Modal
          open={openAddMeetingLink}
          onClose={() => setOpenAddMeetingLink(false)}
        >
          <AddMeetingLink
            session={session}
            onDismiss={() => setOpenAddMeetingLink(false)}
          />
        </Modal>
        <Modal
          open={suggestNewTimes}
          onClose={() => setSuggestNewTimes(false)}
        >
          <PickNewTimeRanges session={session} onClose={() => setSuggestNewTimes(false)} showConfirm={true} />
        </Modal>
        <Modal
          open={openCancel}
          onClose={() => setOpenCancel(false)}
        >
          <Cancel session={session} onDismiss={() => setOpenCancel(false)} />
        </Modal>
    </Box>
  );
}

SessionDetails.propTypes = {
  session: PropTypes.any, // TODO: Replace with proper proptype
  variant: PropTypes.string,
  onDismiss: PropTypes.func,
};

export default SessionDetails;