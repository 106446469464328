import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useDropzone } from 'react-dropzone';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
    Alert,
    Autocomplete,
    Avatar,
    Box,
    Button,
    Chip,
    Container,
    Fab,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    SnackbarContent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import OpenInNewRounded from '@mui/icons-material/OpenInNewRounded';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import LinkIcon from '@mui/icons-material/Link';

import ColorPicker from '../../components/common/color-picker';
import EditProfile from '../more/edit-profile';
import { useAuth } from '../../hooks';
import { ACCOUNT_TYPES } from '../../constants';
import { updateUser } from '../../clients/profile';
import { fetchLocations } from '../../clients/location';
import { displayName } from '../../utils/profile';
import { patch } from '../../services/api.services';
import { API } from '../../constants';
import { uploadToS3 } from '../../utils/upload';
import { stringToColor } from '../../utils/stringHelpers';
import BasicDatePicker from '../../components/common/date-picker';
import config from '../../config';
import { SET_PAYWALL } from "../../store/actions";
import { PaywallTypes } from "../../constants";
import { State } from '../../types';


const DEFAULT_COVER_COLOR = '#5371FF';
const DEFAULT_BACKGROUND_COLOR = '#FFFFFF';

interface ProfileSettingsProps {

}

const REIMBURSEMENT_MAP = {
    'hsa': 'HSA',
    'fsa': 'FSA',
    'medicaid': 'Medicaid',
    'private_ins': 'Private Insurance',
}

export default function ProfileSettings({}: ProfileSettingsProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();
    const featureFlags = useSelector((state: State) => state.account.user.profile.feature_flags);
    
    const [coverColor, setCoverColor] = useState(user.profile.page_settings?.cover_color || DEFAULT_COVER_COLOR);
    const [backgroundColor, setBackgroundColor] = useState(user.profile.page_settings?.background_color || DEFAULT_BACKGROUND_COLOR);
    const [businessName, setBusinessName] = useState(user.profile.business_name || displayName(user.profile.first_name, user.profile.last_name) || '');
    const [businessType, setBusinessType] = useState(user.profile.business_type);
    const [businessTypeOther, setBusinessTypeOther] = useState(user.profile.business_type_other);
    const [resourceCategory, setResourceCategory] = useState(user.profile.resource_category);
    const [resourceCategoryOther, setResourceCategoryOther] = useState(user.profile.resource_category_other);
    const [oneLiner, setOneLiner] = useState(user.profile.one_liner || '');
    const [bio, setBio] = useState(user.profile.bio || '');
    const [firstName, setFirstName] = useState(user.profile.first_name || '');
    const [lastName, setLastName] = useState(user.profile.last_name || '');
    const [pronouns, setPronouns] = useState(user.profile.pronouns || '');
    const [phone, setPhone] = useState(user.profile.phone);
    const [email, setEmail] = useState(user.email);
    const [birthDate, setBirthDate] = useState(user.profile.birth_date);
    const [pricing, setPricing] = useState(user.profile.pricing);
    const [bookingTarget, setBookingTarget] = useState(user.profile.booking_target);
    const [bookingTargetType, setBookingTargetType] = useState(user.profile.booking_target_type);
    const [specialOffer, setSpecialOffer] = useState(user.profile.special_offer);
    const [profileImageKey, setProfileImageKey] = useState(user.profile.profile_image_key);
    const [offersInPersonServices, setOffersInPersonServices] = useState(user.profile.offers_in_person_services);
    const [offersVirtualServices, setOffersVirtualServices] = useState(user.profile.offers_virtual_services);
    const [locations, setLocations] = useState([]);
    const [locationsServed, setLocationsServed] = useState(user.profile.locations_served.map((location) => location.name))
    const [reimbursementsAccepted, setReimbursementsAccepted] = useState(user.profile.reimbursements_accepted || []);
    const [availability, setAvailability] = useState(user.profile.availability);
    const [offerings, setOfferings] = useState(user.profile.offerings);
    const [personality, setPersonality] = useState(user.profile.personality || []);
    const [disableAccountSave, setDisableAccountSave] = useState(false);
    const [trainingDescriptors, setTrainingDescriptors] = useState(user.profile.training_descriptors);
    const [topics, setTopics] = useState(user.profile.topics || []);
    const [uploadError, setUploadError] = useState(false);
    const [copied, setCopied] = useState(false);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(false);

    const saveChanges = async () => {
        setError(false);
    
        const pageSettingsCopy = user.profile.page_settings || {};

        const success = await updateUser(dispatcher, navigate, user, token, {
            page_settings: {
                ...pageSettingsCopy,
                cover_color: coverColor,
                background_color: backgroundColor,
            },
        });

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    };

    const saveAccountDetails = async () => {
        setError(false);
        let success = false;

        const userResult = await patch(
            `${API.USER}${user.id}/`,
            {
              username: email,
              email: email,
            },
            token,
        );

        if (userResult.status === 200) {
            success = await updateUser(dispatcher, navigate, user, token, {
                first_name: firstName,
                last_name: lastName,
                pronouns: pronouns,
                phone,
                profile_image_key: profileImageKey,
                birth_date: birthDate,
            });
        }

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    }

    const saveBusinessDetails = async () => {
        setError(false);

        const success = await updateUser(dispatcher, navigate, user, token, {
            business_name: businessName,
            business_type: businessType,
            business_type_other: businessTypeOther,
            resource_category: resourceCategory,
            resource_category_other: resourceCategoryOther,
            one_liner: oneLiner,
            pricing,
            bio,
            offers_in_person_services: offersInPersonServices,
            offers_virtual_services: offersVirtualServices,
            locations_served: locationsServed,
            reimbursements_accepted: reimbursementsAccepted,
            availability: availability,
            topics,
        });

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    };

    const saveLeadsSettings = async () => {
        setError(false);

        const success = await updateUser(dispatcher, navigate, user, token, {
            booking_target: bookingTarget,
            booking_target_type: bookingTargetType,
            special_offer: specialOffer,
        });

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    };

    const saveRecommendationTraining = async () => {
        setError(false);

        const success = await updateUser(dispatcher, navigate, user, token, {
            offerings,
            training_descriptors: trainingDescriptors,
            personality,
        });

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    };

    const publish = async () => {
        setError(false);

        if (featureFlags.listing || user.id < 661) { // TODO: HARDCODED ID number of folks we don't want to force to subscribe
            const success = await updateUser(dispatcher, navigate, user, token, {
                unlisted: false,
                under_review: false,
            });

            if (success) {
                setSaved(true);
            } else {
                setError(true);
            }
        } else {
            dispatcher({
                type: SET_PAYWALL,
                payload: {
                  paywall: true,
                  paywall_type: PaywallTypes.BASE, 
                },
            });
        }
    };

    const unpublish = async () => {
        setError(false);

        const success = await updateUser(dispatcher, navigate, user, token, {
            unlisted: true,
        });

        if (success) {
            setSaved(true);
        } else {
            setError(true);
        }
    };

    /*
    'accepts_nessle_cash', \
            'reimbursements_accepted',
    */

    const onNewImg = async (acceptedFiles) => {
        if (acceptedFiles.length) {
            setUploadError(false);
            setDisableAccountSave(true);
    
            const path = `profile-pictures/${user.profile.id}/${acceptedFiles[acceptedFiles.length - 1].path}`;
            const successfulUpload = await uploadToS3(
                token,
                path,
                acceptedFiles[acceptedFiles.length - 1],
            );
    
            if (successfulUpload) {
                setProfileImageKey(path);
                setDisableAccountSave(false);
            }
            else {
                setDisableAccountSave(false);
                setUploadError(true);
            }
        }
    }

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
        },
        maxFiles: 1,
        multiple: false,
        maxSize: 5 * 1000 * 1000,
        onDrop: onNewImg,
    });

    const fetchAndSetLocations = async () => {
        const locations = await fetchLocations(token);

        if (locations) {
            setLocations(locations);
        }
    };

    useEffect(() => {
        fetchAndSetLocations();
    }, []);

    return (
        <Container maxWidth="lg">
            {/*<EditProfile />*/}
            <Stack spacing={2} mt={2}>
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                    <Stack spacing={2} direction="row-reverse">
                        <CopyToClipboard
                            text={`${config.FE_BASE}/r/${user.profile.display_id}/`}
                            onCopy={() => setCopied(true)}
                        >
                            <Fab variant="extended">
                                <LinkIcon sx={{ mr: 1 }} /> Share
                            </Fab>
                        </CopyToClipboard>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={copied}
                            autoHideDuration={4000}
                            onClose={() => setCopied(false)}
                            message="Share link has been copied to your clipboard"
                        />
                        <Fab variant="extended" onClick={() => window.open(`/r/${user.profile.display_id}`)}>
                            <OpenInNewRounded sx={{ mr: 1 }} />
                            View Profile
                        </Fab>
                        {(user.profile.unlisted || user.profile.under_review) ? (
                            <Fab variant="extended" onClick={publish}>
                                {featureFlags.listing ? <PublishRoundedIcon sx={{ mr: 1 }} /> : <PaidOutlinedIcon sx={{ mr: 1 }} />}
                                Publish
                            </Fab>
                        ) : (
                            <Fab variant="extended" onClick={unpublish}>
                                <UnpublishedOutlinedIcon sx={{ mr: 1 }} />
                                Unpublish
                            </Fab>
                        )}
                    </Stack>
                )}
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (user.profile.unlisted || user.profile.under_review) && (
                    <Alert
                        severity="info"
                        color="error"
                    >
                        Your page isn't published! When you're ready, publish it{' '}
                        to be findable and suggested to parents.
                    </Alert>
                )}
                <Paper elevation={0}>
                    <Box p={4}>
                        <Typography variant="h6">Account Details</Typography>
                        <Stack spacing={2} mt={2}>
                            <Typography variant="subtitle1">Profile Picture:</Typography>
                            <Box>
                                <div {...getRootProps({ style: {} })}>
                                    <input {...getInputProps()} />
                                    <Box mb={1} sx={{ height: 100, cursor: 'pointer', position: 'relative' }}>
                                        <Avatar
                                            src={(acceptedFiles.length && !uploadError) ? URL.createObjectURL(acceptedFiles[acceptedFiles.length - 1]) : user.profile.profile_image_url}
                                            alt={user.profile.first_name}
                                            sx={{ width: 100, height: 100, bgcolor: user.profile.profile_image_url ? '#FFFFFF' : stringToColor(user.profile.business_name || user.profile.first_name) }}
                                        />
                                        {uploadError && (
                                            <Typography variant="caption" style={{ color: "red" }}>
                                                We were unable to upload your file. Please ensure it is less than 5MB.
                                            </Typography>
                                        )}
                                    </Box>
                                </div>
                            </Box>
                            <Typography variant="subtitle1">First Name:</Typography>
                            <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            <Typography variant="subtitle1">Last Name:</Typography>
                            <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            <Typography variant="subtitle1">Pronouns (optional):</Typography>
                            <TextField value={pronouns} onChange={(e) => setPronouns(e.target.value)} />
                            <Typography variant="subtitle1">Email:</Typography>
                            <TextField value={email} onChange={(e) => setEmail(e.target.value)} />
                            <Typography variant="subtitle1">Phone Number:</Typography>
                            <PhoneInput
                                tabIndex="4"
                                placeholder="Eg. 212-456-7890"
                                name="phoneNumber"
                                defaultCountry="US"
                                value={phone}
                                onChange={setPhone}
                                inputComponent={TextField}
                                displayInitialValueAsLocalNumber={true}
                            />
                            <Typography variant="subtitle1">Birth Date:</Typography>
                            <BasicDatePicker
                                value={birthDate}
                                onChange={(val) => setBirthDate(val)}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={saveAccountDetails}
                                disabled={disableAccountSave}
                            >
                                Save
                            </Button>
                        </Stack>
                    </Box>
                </Paper>
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                    <Paper elevation={0}>
                        <Box p={4}>
                            <Typography variant="h6">Business Details</Typography>
                            <Stack spacing={2} mt={2}>
                                <Typography variant="subtitle1">Display Name:</Typography>
                                <TextField value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                                <Typography variant="subtitle1">Business Type:</Typography>
                                <Select
                                    value={businessType}
                                    onChange={(e) => setBusinessType(e.target.value)}
                                >
                                    <MenuItem value="coach">Independent birth worker or parent coach</MenuItem>
                                    <MenuItem value="creator">Creator / influencer</MenuItem>
                                    <MenuItem value="company">For-profit company</MenuItem>
                                    <MenuItem value="nonprofit">Nonprofit company</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                                {businessType === 'other' && (
                                    <TextField placeholder="Add your business type" value={businessTypeOther} onChange={(e) => setBusinessTypeOther(e.target.value)} />
                                )}
                                <Typography variant="subtitle1">Category of resources you offer:</Typography>
                                <Select
                                    value={resourceCategory}
                                    onChange={(e) => setResourceCategory(e.target.value)}
                                >
                                    <MenuItem value="products">Physical goods / products</MenuItem>
                                    <MenuItem value="tech">Tech solution</MenuItem>
                                    <MenuItem value="services">Services</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </Select>
                                {resourceCategory === 'other' && (
                                    <TextField placeholder="Add your category" value={resourceCategoryOther} onChange={(e) => setResourceCategoryOther(e.target.value)} />
                                )}
                                <Typography variant="subtitle1">What phases of parenthood do you serve?</Typography>
                                <Autocomplete
                                    multiple
                                    id="topics"
                                    options={[
                                        "Fertility / Conception / Adoption",
                                        "Prenatal Care",
                                        "Labor and Birth",
                                        "Nutrition",
                                        "Movement and Fitness",
                                        "Lactation and Feeding",
                                        "Sleep",
                                        "Child Development, Learning, and Behavior",
                                        "Mental Health",
                                        "Personal Wellness / Life Design",
                                        "Family, Social, and Relationships",
                                        "Activities and Entertainment",
                                    ]}
                                    value={topics}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={option} color="secondary" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    sx={{
                                        width: '100%',
                                        mt: 2,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setTopics(newValue);
                                    }}
                                />
                                <Typography variant="subtitle1">One-liner description:</Typography>
                                <TextField value={oneLiner} onChange={(e) => setOneLiner(e.target.value)} />
                                <Typography variant="subtitle1">Full bio or elevator pitch</Typography>
                                <TextField
                                    value={bio}
                                    onChange={(e) => setBio(e.target.value)}
                                    multiline
                                    minRows={4}
                                    inputProps={{ maxLength: 2048 }}
                                />
                                <Typography variant="subtitle1">Pricing:</Typography>
                                <Select
                                    value={pricing}
                                    onChange={(e) => setPricing(e.target.value)}
                                >
                                    <MenuItem value="free">Free</MenuItem>
                                    <MenuItem value="budget">Budget</MenuItem>
                                    <MenuItem value="mid">Mid-market</MenuItem>
                                    <MenuItem value="premium">Premium</MenuItem>
                                </Select>
                                <Typography variant="subtitle1">Payment Methods Accepted:</Typography>
                                <Autocomplete
                                    multiple
                                    id="reimbursements-accepted"
                                    options={[
                                        'hsa',
                                        'fsa',
                                        'medicaid',
                                        'private_ins'
                                    ]}
                                    value={reimbursementsAccepted}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={option} color="secondary" label={REIMBURSEMENT_MAP[option]} {...getTagProps({ index })} />
                                        ))
                                    }
                                    sx={{
                                        width: '100%',
                                        mt: 2,
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props}>{REIMBURSEMENT_MAP[option]}</li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select the payment methods you accept"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setReimbursementsAccepted(newValue);
                                    }}
                                />
                                <Typography variant="subtitle1">Locations Served:</Typography>
                                <Autocomplete
                                    multiple
                                    id="locations-served"
                                    options={locations.map((l) => l.name)}
                                    value={locationsServed}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={option} color="secondary" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    sx={{
                                        width: '100%',
                                        mt: 2,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select or add locations you serve"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setLocationsServed(newValue);
                                    }}
                                />
                                <Typography variant="subtitle1">My Availability (if applicable):</Typography>
                                <TextField value={availability} onChange={(e) => setAvailability(e.target.value)} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveBusinessDetails}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                )}
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                    <Paper elevation={0} id="leads-settings">
                        <Box p={4}>
                            <Typography variant="h6">Leads Settings</Typography>
                            <Stack spacing={2} mt={2}>
                                <Typography variant="subtitle1">Send my leads to my:</Typography>
                                <Select
                                    value={bookingTargetType}
                                    onChange={(e) => {
                                        setBookingTargetType(e.target.value);
                                        setBookingTarget('');
                                    }}
                                >
                                    <MenuItem value="link">Website</MenuItem>
                                    <MenuItem value="email">Email Address</MenuItem>
                                    <MenuItem value="phone">Phone Number</MenuItem>
                                </Select>
                                {bookingTargetType && (
                                    <>
                                        {bookingTargetType === "phone" ? (
                                            <PhoneInput
                                                tabIndex="4"
                                                placeholder="Eg. 212-456-7890"
                                                name="phoneTarget"
                                                defaultCountry="US"
                                                value={bookingTarget}
                                                onChange={setBookingTarget}
                                                inputComponent={TextField}
                                                displayInitialValueAsLocalNumber={true}
                                            />
                                        ) : (
                                            <TextField
                                                placeholder={bookingTargetType === "link" ? "https://yourwebsite.com" : bookingTargetType === "email" ? "abc@xyz.com" : "+11234567890"}
                                                value={bookingTarget}
                                                onChange={(e) => setBookingTarget(e.target.value)}
                                            />
                                        )}
                                    </>
                                )}
                                <Box>
                                    <Typography variant="subtitle1">Special offer for Parentswarm parents:</Typography>
                                    <Typography variant="body2">Example: "Use code PARENTSWARM when checking out on my site for 10% off!"</Typography>
                                </Box>
                                <TextField value={specialOffer} onChange={(e) => setSpecialOffer(e.target.value)} />
                                
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveLeadsSettings}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                )}
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                    <Paper elevation={0}>
                        <Box p={4}>
                            <Typography variant="h6">Search and AI Training</Typography>
                            <Typography variant="caption">
                                Answer the following questions to help tailor our search and AI to surface{' '}
                                your profile to parents most likely to need your support. These answers are{' '}
                                not displayed publically. 
                            </Typography>
                            <Stack spacing={2} mt={2}>
                                <Typography variant="subtitle1">Which of the following best captures your company's vibe?</Typography>
                                <Autocomplete
                                    multiple
                                    id="personaility"
                                    options={[
                                        "A Fairy Godparent",
                                        "An Earthy Flower Child",
                                        "A Science Teacher",
                                        "A Trusted Big Sibling",
                                        "A Friend Who Makes You Laugh",
                                        "None of these / Unsure",
                                    ]}
                                    value={personality}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={option} color="secondary" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    sx={{
                                        width: '100%',
                                        mt: 2,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder="Select"
                                        />
                                    )}
                                    onChange={(event, newValue) => {
                                        setPersonality(newValue);
                                    }}
                                />
                                <Typography variant="subtitle1">
                                    Share 3-10+ adjectives or phrases that describe your brand,{' '}
                                    your approach, your style, unexpected use cases, or how you stand out.
                                </Typography>
                                <TextField
                                    value={trainingDescriptors}
                                    onChange={(e) => setTrainingDescriptors(e.target.value)}
                                    multiline
                                    minRows={4}
                                    inputProps={{ maxLength: 2048 }}
                                />
                                <Typography variant="subtitle1">
                                    Describe your top products, services, or packages.
                                </Typography>
                                <TextField
                                    value={offerings}
                                    onChange={(e) => setOfferings(e.target.value)}
                                    multiline
                                    minRows={4}
                                    inputProps={{ maxLength: 2048 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveRecommendationTraining}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                )}
                {user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                    <Paper elevation={0}>
                        <Box p={4}>
                            <Typography variant="h6">Customize Profile</Typography>
                            <Stack spacing={2} mt={2}>
                                <Typography variant="subtitle1">Choose a cover color:</Typography>
                                <ColorPicker selectedColor={coverColor} setSelectedColor={setCoverColor} />
                                <Typography variant="subtitle1">Choose a background color:</Typography>
                                <ColorPicker selectedColor={backgroundColor} setSelectedColor={setBackgroundColor} />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={saveChanges}
                                >
                                    Save
                                </Button>
                            </Stack>
                        </Box>
                    </Paper>
                )}
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={saved}
                autoHideDuration={3000}
                onClose={() => setSaved(false)}
            >
                <SnackbarContent
                    message={<Typography variant="body1">Your changes have been saved!</Typography>}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error}
                autoHideDuration={3000}
                onClose={() => setError(false)}
                message="We were unable to save your changes. Please try again."
            />
        </Container>
    );
}
