import React from 'react';

import { Box, Stack, TextField } from '@mui/material';

import Button from '../common/button';
import { ScreenTitle, ScreenSubtext } from './review.style';

export default function PrivateComment({ privateComment, setPrivateComment, next, back }) {
    return (
        <Stack spacing={2}>
            <Box display="flex" flexDirection="column" justifyContent="center" sx={{ textAlign: 'center' }}>
                <ScreenTitle>What went wrong?</ScreenTitle>
                <ScreenSubtext>This feedback comes directly to Parentswarm</ScreenSubtext>
            </Box>
            <TextField
                id="private-comment"
                variant="outlined"
                placeholder="We use this information to make Parentswarm better."
                multiline
                minRows={4}
                value={privateComment}
                onChange={(e) => setPrivateComment(e.target.value)}
                sx={{ width: '100%' }}
            />
            <Box display="flex" justifyContent="space-around">
                <Button onClick={back} shade="secondaryLight">Back</Button>
                <Button onClick={next}>Next</Button>
            </Box>
        </Stack>
    );
}
