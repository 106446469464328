import { Box, Tooltip, Typography } from '@mui/material';

interface BadgeProps {
    badgeKey: 'verified' | 'founding' | 'certified' | 'thrive' | 'telesupport' | 'dei',
    size: 'small' | 'normal',
}

const badgesByKey = {
    verified: {
        tooltip: "This Resource's profile and testimonials have been reviewed and verified by our team",
        title: 'Verified',
        image: require('../../assets/images/badges/verified.png'),
    },
    founding: {
        tooltip: 'This Resource was one of the first 100 Resources on Parentswarm!',
        title: 'Founding',
        image: require('../../assets/images/badges/founding.png'),
    },
    certified: {
        tooltip: 'This Resource has received a certification in their role by a Parentswarm-approved certifying organization.',
        title: 'Certified',
        image: require('../../assets/images/badges/certified.png'),
    },
    thrive: {
        tooltip: 'This Resource has completed the Parentswarm Thrive program.',
        title: 'Thrive',
        image: require('../../assets/images/badges/thrive.png'),
    },
    telesupport: {
        tooltip: "This Resource has completed Parentswarm's training module on Telesupport.",
        title: 'Telesupport Training',
        image: require('../../assets/images/badges/telesupport.png'),
    },
    dei: {
        tooltip: "This Resource has completed Parentswarm's training module on DEI.",
        title: 'DEI Training',
        image: require('../../assets/images/badges/dei.png'),
    },
};

export default function Badge({badgeKey, size}: BadgeProps) {
    const badge = badgesByKey[badgeKey] || badgesByKey['verified'];  // default to 'verified' if key is missing
    
    const imageSize = size === 'small' ? 45 : 65;
    const textBoxWidth = size === 'small' ? 75 : 95;
    const containerHeight = size === 'small' ? 75 : 100;
    
    return (
        <Tooltip title={badge.tooltip}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                height={containerHeight}
            >
                <img src={badge.image} alt={badge.title} width={imageSize} />
                <Box width={textBoxWidth} textAlign="center">
                    <Typography variant="caption"><b>{badge.title}</b></Typography>
                </Box>
            </Box>
        </Tooltip>
    );
}
