import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Box, Link, Stack } from '@mui/material';
import { State } from '../../../types';

const MatchQuizIntro = ({ onStart, formData, onSubmit }) => {
    const { user, token } = useSelector((state: State) => state.account);

    useEffect(() => {
        if (!formData[4] || formData[4].length === 0) {
            onStart();
        }
    }, []);

    return (
        <Stack p={2} spacing={2}>
            <Typography variant="h6" component="h1" gutterBottom>
                More information = more accurate matches!
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                The more context you provide, the more tailored your matches will be.
                Take a moment to share a bit more about your preferences and needs.
            </Typography>
            {!user && (
                <Typography variant="body1" gutterBottom>
                    If you’ve done this before, <Link href="/signin" underline="hover">sign on in!</Link>
                </Typography>
            )}
            <Stack direction="row" spacing={1}>
                <Button variant="contained" color="primary" onClick={onStart} size="large">
                    Enhance my matches
                </Button>
                <Button color="primary" onClick={onSubmit} size="large">
                    Or just browse relevant services.
                </Button>
            </Stack>
        </Stack>
    );
};

export default MatchQuizIntro;
