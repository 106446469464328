import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
    Box,
    CircularProgress,
    Container,
} from '@mui/material';

import Button from '../../components/common/button';
import {
    PageTitle,
} from '../sessions/sessions.style';
import { get } from '../../services/api.services';
import { API } from '../../constants';
import FilePreview from './file-preview';

function Resource({}) {
    const navigate = useNavigate();
    const params = useParams();

    const token = useSelector((state) => state.account.token);

    const [resource, setResource] = useState();

    const fetchResource = async () => {
        const result = await get(
            `${API.RESOURCE}${params.resource_id}/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setResource(result.data);
        }
        else {
            navigate('/resources');
        }
    };


    useEffect(() => {
        fetchResource();
    }, []);

    return (
        <Container maxWidth="lg">
            {resource ? (
                <>
                    <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <PageTitle>
                            {resource.title}
                        </PageTitle>
                        <Box mr={2}>
                            <Button shade="secondaryLight" onClick={() => {
                                navigate('/resources');
                            }}>
                                Back to Resources
                            </Button>
                        </Box>
                    </Box>
                    <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
                        <FilePreview url={resource.file_url} height='800px' />
                    </Box>
                </>
            ) : (
                <Box mt={8} display="flex" justifyContent="center">
                    <CircularProgress style={{ color: '#5371ff' }} />
                </Box>
            )}
        </Container>
    );
}

export default Resource;