import React from 'react';
import { Box, Stack } from '@mui/material';

import RainbowIcon from '../../assets/icons/Rainbow.svg';
import { ScreenTitle } from './review.style';
import Button from '../common/button';

export default function Confirm({ rating, contactMe, onClose }) {
    return (
        <>
            {rating < 3 ? (
                <Stack spacing={2}>
                    <Box sx={{ textAlign: 'center' }}>
                        {contactMe ? (
                            <ScreenTitle>
                                We will be in touch shortly. Thank you for letting us know - we hope to make this right.
                            </ScreenTitle>
                        ) : (
                            <ScreenTitle>
                                If you do ever want to share comments, you can email us here: hello@parentswarm.com
                            </ScreenTitle>
                        )}
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                </Stack>
            ) : (
                <Stack spacing={2}>
                    <Box display="flex" justifyContent="center">
                        <ScreenTitle>Thank you!</ScreenTitle>
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <img src={RainbowIcon} alt="thanks" width={100} />
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Button onClick={onClose}>Close</Button>
                    </Box>
                </Stack>
            )}
        </>
    );
}
