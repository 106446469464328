// npm imports
import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, Typography } from "@mui/material";

// local imports
import { ReimbursementDetailLine } from "../product.style";

export default function ReimbursementLabel({ insuranceOptions }) {
  return (
    <ReimbursementDetailLine>
      <>{"Reimbursment"}</>
      <Tooltip
        title={
          <React.Fragment>
            <Box mb={1}>
              <Typography variant="subtitle1">
                {"Insurance / Reimbursement Accepted"}
              </Typography>
              <Typography variant="caption">
                {`This Expert accepts reimbursment in the following plans: ${insuranceOptions?.join(
                  ", "
                )}. Please contact Expert to ensure eligible reimbursement plans.`}
              </Typography>
            </Box>
          </React.Fragment>
        }
      >
        <InfoOutlinedIcon
          fontSize="small"
          style={{
            color: "black",
            marginLeft: 5,
          }}
        />
      </Tooltip>
    </ReimbursementDetailLine>
  );
}
