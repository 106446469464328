import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Avatar,
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { track } from '../../utils/analytics';
import { SET_PAYWALL } from '../../store/actions';
import { PaywallTypes } from '../../constants';
import { State } from '../../types';

interface PartnerProps {
    name: string,
    description: string,
    discountDetails?: string,
    discountLink?: string,
    tags?: string[],
    url: string,
    logo?: string,
}

function Partner({ name, description, discountDetails, tags, url, logo, discountLink }: PartnerProps) {
    const dispatcher = useDispatch();
    const user = useSelector((state: State) => state.account.user);

    const [showCouponCode, setShowCouponCode] = useState(false);

    const openPaywall = () => {
        dispatcher({
            type: SET_PAYWALL,
            payload: {
                paywall: true,
                paywall_type: PaywallTypes.BASE, 
            },
        });
    };

    return (
        <Box p={2} display="flex" height="100%" alignItems="flex-start" sx={{ flexDirection: { xs: 'column', sm: 'row' }}}>
            <Avatar
                variant="rounded"
                sx={{ height: 100, width: 100, backgroundSize: 'contain' }}
                src={logo || ""}
            />
            <Box ml={2} flexGrow={1}>
                <Typography variant="h6">{name}</Typography>
                <Typography variant="body2">
                    {description}
                </Typography>
                {tags && (
                    <Grid container spacing={1} mt={1}>
                        {tags?.map((tag) => (
                            <Grid item xs={6} sm={4} md={3}>
                                <Chip label={tag} color="secondary" style={{ width: '100%' }} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            <Box
                display="flex"
                sx={{
                    flexDirection: { xs: 'row-reverse', sm: 'column' },
                    alignItems: { xs: 'space-between', sm: 'flex-end'},
                    mt: { xs: 2, sm: 0 },
                    justifyContent: { xs: 'space-between', sm: 'flex-start' },
                    width: { xs: '100%', sm: 'auto'},
                    ml: { xs: 0, sm: 2 }
                }}>
                <IconButton
                    onClick={() => {
                        track('partner click', { partner: name, click_type: 'website' })
                        window.open(url, '_blank');
                    }}
                >
                    <OpenInNewOutlinedIcon />
                </IconButton>
                {discountDetails && (
                    <Button
                        variant="contained"
                        sx={{ width: 150 }}
                        onClick={() => {
                            if (user.profile.feature_flags.partner_library) {
                                setShowCouponCode(true);
                                track('get partner coupon click', { partner: name, click_type: 'promo' })
                            } else {
                                openPaywall();
                            }
                        }}
                    >
                        Get Coupon Code
                    </Button>
                )}
            </Box>
            <Dialog
                open={showCouponCode}
                onClose={() => setShowCouponCode(false)}
                aria-labelledby="coupon-code-dialog-title"
                aria-describedby="coupon-code-dialog-description"
            >
                <DialogTitle id="coupon-code-dialog-title">
                    {`${name} Coupon Details`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="coupon-code-dialog-description">
                        {discountDetails}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowCouponCode(false)}>Close</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            track('partner click', { partner: name, click_type: 'promo' })
                            window.open(discountLink || url, '_blank');
                        }}
                        autoFocus
                        startIcon={<OpenInNewOutlinedIcon />}
                    >
                        Get {name}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Partner;