import React from 'react';

import { Box, Card, CardContent, Typography } from '@mui/material';

import ResourceThumbnail from './resource-thumbnail';
import { Resource } from "../../types";

interface ResourcePreviewProps {
    resource: Resource;
    showThumbnail?: boolean;
    interactable?: boolean;
}

export default function ResourcePreview({ resource, showThumbnail = true, interactable = true }: ResourcePreviewProps) {
    return (
        <Card
            sx={{ display: 'flex', flexGrow: 1, cursor: interactable ? 'pointer' : 'default', minHeight: '100px', borderRadius: 2 }}
            variant="outlined"
        >
            {showThumbnail && (
                <Box
                    m={1}
                    sx={{
                        width: '100px',
                        minWidth: '100px',
                        height: '84px',
                        overflow: 'hidden',
                        borderRadius: 2,
                        display: { xs: 'none', sm: 'block' },
                    }}
                >
                    <ResourceThumbnail url={resource.file_url} />
                </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ textAlign: 'left' }}>
                    <Typography component="div" variant="h6">
                    {resource.title}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                    {resource.description}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
};
