/**
 *
 * @param {string[]} insuranceOptions - An array of strings containing the insurance / reimbursment options the Expert has selected
 * @return {string[]} - Formatted version of the insuranceOptions input
 */

const modifyReimbursementLabels = (insuranceOptions) => {
  const plans = ["hsa", "fsa", "hra"];
  const modifiedOptions = insuranceOptions.map((label, index) => {
    if (plans.includes(label)) {
      return label.toUpperCase();
    } else if (label === "private") {
      return "Private Insurance";
    } else {
      return toTitleCase(label);
    }
  });

  return modifiedOptions;
};

/**
 * @param {string} str - String representing insurance and reimbursement policies, will generally be lowercased
 * - Examples: medicare, medicaid, private insurance
 * @return {string} - Titled version of the input
 */

const toTitleCase = (str) => {
  if (str.length === 0) {
    return str;
  }

  let words = str.split(" ");
  const capitalizedWords = words.map(
    (word) => word[0].toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(" ");
};

export { modifyReimbursementLabels };
