import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import moment from 'moment';
import { Alert, Box, Button, Card, Chip, InputAdornment, Grid, Modal as MUIModal, Stack, TextField, Typography } from '@mui/material';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';

import ConfirmModal from '../common/modal/confirm';
import { Payment } from "../../types";
import { deletePayment, refundPayment, updatePayment } from '../../clients/payment';
import { useAuth } from '../../hooks';
import { userInfo } from 'os';
import { fetchPaymentMethods } from '../../clients/profile';
import Modal from '../common/modal';
import DateTimePicker from '../common/date-time-picker';

interface PaymentRowProps {
    payment: Payment;
}

export default function PaymentRow({ payment }: PaymentRowProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const { token, user } = useAuth();

    const paymentAmount = (payment.wallet_amount || payment.card_amount) ? (payment.wallet_amount + payment.card_amount) / 100 : payment.transfer_amount / 100;

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showPayConfirm, setShowPayConfirm] = useState(false);
    const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
    const [showRefundForm, setShowRefundForm] = useState(false);
    const [showScheduleForm, setShowScheduleForm] = useState(false);
    const [refundAmount, setRefundAmount] = useState(paymentAmount.toFixed(2));
    const [newScheduleDate, setNewScheduleDate] = useState(payment.scheduled_at);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);

    const timezone = moment.tz.guess();

    // Format the price above to USD using the locale, style, and currency.
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const onDelete = async () => {
        await deletePayment(dispatcher, navigate, token, payment);
        setShowDeleteConfirm(false);
    }

    const onPay = async () => {
        await updatePayment(dispatcher, navigate, token, payment, { scheduled_at: moment.utc().format('YYYY-MM-DD HH:mm') });
        setShowPayConfirm(false);
    }

    const onSchedule = async () => {
        await updatePayment(dispatcher, navigate, token, payment, {
            scheduled_at: newScheduleDate,
        });
        setShowScheduleForm(false);
    }

    const onRefund = async () => {
        await refundPayment(dispatcher, navigate, token, payment, parseFloat(refundAmount) * 100);
        setShowRefundForm(false);
    }

    const paymentIsAboutToOccure = () => {
        const now = moment();
        const end = moment(now);
        const start = moment(now).subtract(15, 'minutes');

        return moment(payment.scheduled_at).isBetween(start, end, null, '[]');
    };

    const userIsPayee = () => {
        if (payment.purchase?.product.seller_id === user.profile.id) {
            return true;
        }

        if (payment.folder?.owner.id === user.profile.id) {
            return true;
        }

        return false;
    };

    const userIsPayer = () => {
        if (payment.purchase?.profile_id === user.profile.id) {
            return true;
        }

        if (payment.folder?.members.filter((member) => member.id === user.profile.id).length > 0) {
            return true;
        }

        return false;
    }

    const getPaymentMethods = async () => {
        const cards = await fetchPaymentMethods(navigate, user, token);
        
        if (cards.length > 0) {
            setHasPaymentMethod(true);
        }
    }

    useEffect(() => {
        getPaymentMethods();
    }, []);

    return (
        <Stack spacing={2}>
            <Card elevation={0}>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">
                                {payment.payment_number !== null ? (
                                    <span>Payment #{payment.payment_number}</span>
                                ) : (
                                    <span>Request</span>
                                )}
                            </Typography><br />
                            <Typography variant="h6">{payment.description || payment.purchase?.product.title}</Typography>
                            <Stack spacing={1} direction="row" mt={1}>
                                {payment.status === 'scheduled' && !paymentIsAboutToOccure() && (
                                    <Chip label={`Scheduled: ${moment(payment.scheduled_at).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}`} />
                                )}
                                {payment.status === 'scheduled' && paymentIsAboutToOccure() && (
                                    <Chip label={`Processing Now`} />
                                )}
                                {payment.status === 'not_scheduled' && payment.capture_occurrence !== 'manual' && (
                                    <Chip label="Scheduling Pending" />
                                )}
                                {payment.status === 'not_scheduled' && payment.capture_occurrence === 'manual' && (
                                    <Chip label="Not Scheduled" />
                                )}
                                {payment.status === 'paid' && (
                                    <Chip label="Paid" color="success" />
                                )}
                                {payment.status === 'failed' && (
                                    <Chip label="Failed" color="error" />
                                )}
                                {payment.status === 'refunded' && (
                                    <Chip label="Refunded" color="warning" />
                                )}
                                {payment.status === 'partially_refunded' && (
                                    <Chip label="Partially Refunded" color="warning" />
                                )}
                                {payment.transferred && (
                                    <Chip label="Transferred to Bank" color="success" />
                                )}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Amount</Typography><br />
                            <Typography variant="h6">{USDollar.format(paymentAmount)}</Typography>
                            {payment.refunded_amount > 0 && (
                                <Box>
                                    <Typography variant="caption">Refunded Amount</Typography><br />
                                    <Typography variant="subtitle2">{USDollar.format((payment.refunded_amount) / 100)}</Typography>
                                </Box>
                            )}
                            <Stack mt={1} direction="row" spacing={2}>
                                {payment.status === 'paid' && !payment.transferred && userIsPayee() && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() => setShowRefundForm(true)}
                                    >
                                        <ReplayRoundedIcon /> <span style={{ marginLeft: 5 }}>Refund</span>
                                    </Button>
                                )}
                                {payment.status === 'not_scheduled' && payment.purchase && userIsPayee() && !paymentIsAboutToOccure() && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() => setShowScheduleForm(true)}
                                    >
                                        <ScheduleRoundedIcon /> <span style={{ marginLeft: 5 }}>Schedule</span>
                                    </Button>
                                )}
                                {payment.status === 'scheduled' && payment.purchase && userIsPayee() && !paymentIsAboutToOccure() && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() => setShowScheduleForm(true)}
                                    >
                                        <ScheduleRoundedIcon /> <span style={{ marginLeft: 5 }}>Reschedule</span>
                                    </Button>
                                )}
                                {(payment.status === 'not_scheduled' || payment.status === 'scheduled') && (
                                    <>
                                        {userIsPayer() && (!payment.scheduled_at || !paymentIsAboutToOccure()) && (
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={() => hasPaymentMethod ? setShowPayConfirm(true) : setShowAddPaymentMethod(true)}
                                            >
                                                <PaymentRoundedIcon /> <span style={{ marginLeft: 5, marginRight: 5 }}>Pay Now</span>
                                            </Button>
                                        )}
                                        {userIsPayee() && (
                                            <Button variant="contained" color="error" size="small" onClick={() => setShowDeleteConfirm(true)}>
                                                <DeleteOutlineRoundedIcon /> <span style={{ marginLeft: 5, marginRight: 5 }}>Delete</span>
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            <MUIModal open={showDeleteConfirm} onClose={() => setShowDeleteConfirm(false)}>
                <ConfirmModal
                    title="Are you sure you want to delete this payment?"
                    subtitle="This will remove the payment and no charge will occur."
                    cta="Confirm Delete"
                    ctaAction={onDelete}
                    cancelAction={() => setShowDeleteConfirm(false)}
                />
            </MUIModal>
            <MUIModal open={showPayConfirm} onClose={() => setShowPayConfirm(false)}>
                <ConfirmModal
                    title={`Send ${USDollar.format(paymentAmount)}`} 
                    subtitle="The payment will be scheduled now and confirmed shortly thereafter."
                    cta="Confirm Payment"
                    ctaAction={onPay}
                    cancelAction={() => setShowPayConfirm(false)}
                />
            </MUIModal>
            <MUIModal open={showAddPaymentMethod} onClose={() => setShowAddPaymentMethod(false)}>
                <ConfirmModal
                    title="Add a payment method"
                    subtitle="To continue paying this request, go to your Billing page and add a payment method."
                    cta="Go to Billing"
                    ctaAction={() => navigate('/more/billing/')}
                    cancelAction={() => setShowAddPaymentMethod(false)}
                />
            </MUIModal>
            <MUIModal open={showRefundForm} onClose={() => setShowRefundForm(false)}>
                <Modal
                    title="Refund Payment"
                    onClose={() => setShowRefundForm(false)}
                >
                    <Box mb={2}>
                        <Typography variant="subtitle1">
                            How much of this payment would you like to refund?
                        </Typography>
                    </Box>
                    {parseFloat(refundAmount) > paymentAmount && (
                        <Box mb={2}>
                            <Alert color="error">This value is greater than the payment amount.</Alert>
                        </Box>
                    )}
                    {parseFloat(refundAmount) <= 0 && (
                        <Box mb={2}>
                            <Alert color="error">Please input a positive value.</Alert>
                        </Box>
                    )}
                    <Box mb={2}>
                        <TextField
                            id="details-text"
                            type="number"
                            variant="outlined"
                            value={refundAmount}
                            onChange={(e) => setRefundAmount(e.target.value)}
                            sx={{ width: 150 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span>$</span>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            disabled={parseFloat(refundAmount) > paymentAmount || parseFloat(refundAmount) <= 0}
                            onClick={onRefund}
                        >
                            Process Refund
                        </Button>
                    </Box>
                </Modal>
            </MUIModal>
            <MUIModal open={showScheduleForm} onClose={() => setShowScheduleForm(false)}>
                <Modal
                    title="Schedule Payment"
                    onClose={() => setShowScheduleForm(false)}
                >
                    <Box mb={2}>
                        <Typography variant="subtitle1">
                            When should this payment occur? (Time in {moment.tz.zone(timezone).abbr(Number(moment().format('x')))})
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <DateTimePicker value={newScheduleDate} onChange={(e) => setNewScheduleDate(e)} />
                    </Box>
                    <Box>
                        <Button
                            variant="contained"
                            onClick={onSchedule}
                        >
                            Schedule
                        </Button>
                    </Box>
                </Modal>
            </MUIModal>
        </Stack>
    );
};
