// action - state management
import { EventActionType } from "./actions";
import { Event } from "../types";

export const initialState = {
  eventsById: {},
  eventIds: [],
  folderEventIds: [],
};

type EventAction =
  | { type: EventActionType.SET_EVENT; payload: { event: Event } }
  | { type: EventActionType.ADD_EVENT_ID; payload: { event: Event } }
  | { type: EventActionType.RESET_EVENT_IDS }
  | { type: EventActionType.ADD_FOLDER_EVENT_ID; payload: { event: Event } }
  | { type: EventActionType.RESET_FOLDER_EVENT_IDS };

const eventReducer = (state = initialState, action: EventAction) => {
  switch (action.type) {
    case EventActionType.SET_EVENT: {
      const { event } = action.payload;
      const newEventsById = { ...state.eventsById };
      newEventsById[event.id] = event;
      return {
        ...state,
        eventsById: newEventsById,
      };
    }
    case EventActionType.ADD_EVENT_ID: {
      const { event } = action.payload;
      return {
        ...state,
        eventIds: [...state.eventIds, event.id],
      };
    }
    case EventActionType.RESET_EVENT_IDS: {
      return {
        ...state,
        eventIds: [],
      };
    }
    case EventActionType.ADD_FOLDER_EVENT_ID: {
      const { event } = action.payload;
      return {
        ...state,
        folderEventIds: [...state.folderEventIds, event.id],
      };
    }
    case EventActionType.RESET_FOLDER_EVENT_IDS: {
      return {
        ...state,
        folderEventIds: [],
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default eventReducer;
