// action - state management
import { ResourceActionType } from './actions';
  
  export const initialState = {
    resourcesById: {},
    resourceIds: [],
  };
  
  const resourceReducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case ResourceActionType.SET_RESOURCE: {
        const { resource } = action.payload;
        const newResourcesById = { ...state.resourcesById };

        newResourcesById[resource.id] = resource;

        return {
          ...state,
          resourcesById: newResourcesById,
        };
      }
      case ResourceActionType.ADD_RESOURCE_ID: {
        const { resource } = action.payload;
        return {
          ...state,
          resourceIds: [ ...state.resourceIds, resource.id ],
        }
      }
      case ResourceActionType.REMOVE_RESOURCE_ID: {
        const { resource } = action.payload;
        return {
          ...state,
          resourceIds: state.resourceIds.filter((id) => id !== resource.id),
        }
      }
      case ResourceActionType.RESET_RESOURCE_IDS: {
        return {
          ...state,
          resourceIds: [],
        }
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default resourceReducer;
  