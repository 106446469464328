import React from 'react';
import moment from 'moment';

import { Box, TextField } from '@mui/material';

interface TimeFieldProps {
    prompt: React.ReactNode;
    timezone?: string;
    hour: string;
    setHour: (value: string) => void;
    minute: string;
    setMinute: (value: string) => void;
    period: string;
    setPeriod: (value: string) => void;
};

function TimeField({ prompt, timezone, hour, setHour, minute, setMinute, period, setPeriod }: TimeFieldProps) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ fontFamily: 'Cabin', fontSize: '15px', color: '#210B2B', mb: 2, mt: 2, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box mr={1}>{prompt}</Box>
                {timezone && (
                    <Box> (Time in {moment.tz.zone(timezone).abbr(Number(moment().format('x')))})</Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '50px', marginRight: 1 }}>
                    <TextField
                        id="hour"
                        variant="outlined"
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                        inputProps={{ maxLength: 2, sx: { fontFamily: 'Cabin' } }}
                    />
                </Box>
                <Box mr={1}>:</Box>
                <Box sx={{ width: '50px', marginRight: 1 }}>
                    <TextField
                        id="minute"
                        variant="outlined"
                        value={minute}
                        onChange={(e) => setMinute(e.target.value)}
                        inputProps={{ maxLength: 2, sx: { fontFamily: 'Cabin' } }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #DADCE0',
                    borderRadius: 1,
                    fontSize: '12px',
                    fontWeight: 500,
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}>
                    <Box
                        sx={{
                            borderBottom: '1px solid #DADCE0',
                            padding: '8px',
                            background: `${period === 'am' ? '#F2E7FE' : '#FFFFFF'}`,
                            color: `${period === 'am' ? '#6200EE' : '#555555'}`,
                        }}
                        onClick={() => setPeriod('am')}
                    >
                        AM
                    </Box>
                    <Box
                        sx={{
                            padding: '8px',
                            background: `${period === 'pm' ? '#F2E7FE' : '#FFFFFF'}`,
                            color: `${period === 'pm' ? '#6200EE' : '#555555'}`,
                        }}
                        onClick={() => setPeriod('pm')}
                    >
                        PM
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default TimeField;
