/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const LinkModal = styled.div`
  width: 350px;
  height: 200px;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 5% auto;
  text-align: center;
  position: relative;
  &:active {
    border: none;
  }
`;

export const Card = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Cabin';
    font-weight: 600;
    line-height: 150%;
    cursor: pointer;
`;

export const Question = styled.div`
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    cursor: pointer;
    height: 45px;
`;

export const BottomDetail = styled.div`
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const TopDetail = styled.div`
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const SessionDate = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    cursor: pointer;
`;

export const MiniProfileContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const MiniProfileNameContainer = styled.div`
    padding-left: 10px;
`;

export const MiniProfileName = styled.div`
    font-family: 'Cabin';
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
`;

export const MiniProfileDetail = styled.div`
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    opacity: 0.4;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
`;

export const SessionDetailsHeader = styled.div`
    background: #5371ff;
    padding: 20px;
    color: #FFFFFF;
    font-family: 'Cabin';
    font-weight: 500;
`;

export const SessionDetailsBody = styled.div`
    background: #FFFFFF;
    padding: 20px;
    color: #210B2B;
`;

export const DrawerTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    font-family: 'Cabin';
`;

export const DrawerSubtitle = styled.div`
    font-family: 'Cabin';
    font-style: italic;
`;

export const DrawerBodyHeading = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(33, 11, 43, 0.5);
`;

export const DrawerBodyDetail = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`;

export const SessionTitle = styled.div`
    font-size: 15px;
    font-weight: 600;
`;

export const StatusBarDanger = styled.div`
    width: 3px;
    height: 35px;
    background: #FF5252;
    margin-right: 10px;
    border-radius: 3px;
`;

export const StatusBarSuccess = styled.div`
    width: 3px;
    height: 35px;
    background: #39BF6F;
    margin-right: 10px;
    border-radius: 3px;
`;

export const VerticalDivider = styled.div`
    width: 3px;
    height: 35px;
    background: #E8E8E8;
    margin-right: 10px;
    border-radius: 3px;
`;

export const Posted = styled.span`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #9C9C9C;
`;

export const Reject = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FF5252;
    width: 50%;
    text-align: center;
    cursor: pointer;
`;

export const Accept = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #39BF6F;
    width: 50%;
    text-align: center;
    cursor: pointer;
`;

export const SpacedItem = styled.li`
  margin-bottom: 10px;
`;

export const LinkContainer = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 150%;
    color: #5371ff;
    border: 1px solid #5371ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    margin: 10px auto;
`;

export const Link = styled.a`
    font-family: 'Cabin';
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-decoration: underline;
    color: #210B2B;
`;

export const LinkDetails = styled.span`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #210B2B;
`;

export const SessionTypeText = styled.span`
  font-family: 'Cabin';
  font-weight: 400;
  font-size: 13px;
  color: #210B2B;
  margin-left: 5px;
`;