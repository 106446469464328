import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/common/button';
import Chip from '../../components/common/chip';
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
} from '@mui/material';
import { LANGUAGES } from '../../utils/languages';
import { MINORITY_GROUPS, TOPICS } from '../../utils/groups';
import {
  ACCOUNT_TYPES,
  PRODUCT_TYPES,
  PRODUCT_TYPES_FRIENDLY,
} from '../../constants';
import SearchIcon from '../../assets/icons/Search.svg';
import { Filters, FilterField, FilterTitle } from './experts.style';
import { SET_FILTERS, RESET_FILTERS } from '../../store/actions';
import { INSURANCES } from '../../utils/insurance';
import { PRICING, lookupPricingLabels } from '../../utils/pricing';
import { modifyReimbursementLabels } from '../../components/product/utils/format-reimbursement';
import Modal from '../../components/common/modal';
import { fetchLocations } from '../../clients/location';

function Filter({ onApply, onClose }) {
  const dispatcher = useDispatch();

  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const keyTerm = useSelector((state) => state.search.filters.keyTerm);
  const languages = useSelector((state) => state.search.filters.languages);
  const minorityGroups = useSelector(
    (state) => state.search.filters.minorityGroups,
  );
  const topics = useSelector((state) => state.search.filters.topics);
  const productTypes = useSelector(
    (state) => state.search.filters.productTypes,
  );
  const virtual = useSelector((state) => state.search.filters.virtual);
  const inPerson = useSelector((state) => state.search.filters.inPerson);
  const radius = useSelector((state) => state.search.filters.radius);
  const zip = useSelector((state) => state.search.filters.zip);
  const insurances = useSelector((state) => state.search.filters.insurances);
  const pricing = useSelector((state) => state.search.filters.pricing);
  const locationsServed = useSelector((state) => state.search.filters.locationsServed);
  const [locations, setLocations] = useState([]);

  const setFilters = (filters) => {
    dispatcher({
      type: SET_FILTERS,
      payload: filters,
    });

    onApply();
  };

  const resetFilters = () => {
    dispatcher({
      type: RESET_FILTERS,
      payload: {},
    });

    onApply();
  };

  const fetchAndSetLocations = async () => {
    const locations = await fetchLocations(token);

    if (locations) {
        setLocations(locations);
    }
  };

  useEffect(() => {
      fetchAndSetLocations();
  }, []);

  return (
    <Modal title="Search for Support" onClose={onClose}>
      <Box p={2}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              id="search-key-term"
              placeholder="Keyword..."
              variant="outlined"
              onChange={(e) => setFilters({ keyTerm: e.target.value })}
              value={keyTerm}
              size="small"
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={SearchIcon}
                      alt="search"
                      width={20}
                      style={{ marginLeft: -5 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterField>Topics:</FilterField>
            <Grid container spacing={0}>
              {TOPICS.map((topic) => (
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    key={topic}
                    control={
                      <Checkbox
                        checked={topics.includes(topic)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilters({ topics: [...topics, topic] });
                          } else {
                            setFilters({
                              topics: topics.filter((t) => t !== topic),
                            });
                          }
                        }}
                      />
                    }
                    label={topic}
                  />
                </Grid>
              ))}
              {user && user.profile.profile_type === ACCOUNT_TYPES.EXPERT && (
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    key="Experts for Experts"
                    control={
                      <Checkbox
                        checked={topics.includes('Experts for Experts')}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilters({
                              topics: [...topics, 'Experts for Experts'],
                            });
                          } else {
                            setFilters({
                              topics: topics.filter(
                                (t) => t !== ['Experts for Experts'],
                              ),
                            });
                          }
                        }}
                      />
                    }
                    label="Experts for Experts"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FilterField>Pricing:</FilterField>
            <Grid container spacing={0}>
              {PRICING.map((p) => (
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    key={p}
                    control={
                      <Checkbox
                        checked={pricing.includes(p)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilters({
                              pricing: [...pricing, p],
                            });
                          } else {
                            setFilters({
                              pricing: pricing.filter(
                                (t) => t !== p,
                              ),
                            });
                          }
                        }}
                      />
                    }
                    label={lookupPricingLabels(p)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FilterField>Serves Parents:</FilterField>
            <Box sx={{ mt: 1 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!virtual}
                      onChange={(e) =>
                        setFilters({ virtual: e.target.checked })
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#5371ff',
                        },
                      }}
                    />
                  }
                  label="Virtually"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!inPerson}
                      onChange={(e) =>
                        setFilters({ inPerson: e.target.checked })
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#5371ff',
                        },
                      }}
                    />
                  }
                  label="In Person"
                />
              </FormGroup>
            </Box>
          </Grid>
          {inPerson && (
            <Grid item xs={12}>
              <FilterField>Areas Served In-Person:</FilterField>
              <Box sx={{ mt: 1 }}>
                <Autocomplete
                  multiple
                  id="locations_served"
                  options={locations.map((option) => option.name)}
                  value={locationsServed}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option}
                        color="primaryLight"
                        label={option}
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    ))
                  }
                  sx={{
                    width: '100%',
                    mt: 2,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" />
                  )}
                  onChange={(event, newValue) => {
                    setFilters({ locationsServed: newValue });
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <FilterField>Do you plan to pay with any of the following?</FilterField>
            <Grid container spacing={0}>
              {INSURANCES.map((insurance) => (
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    key={insurance}
                    control={
                      <Checkbox
                        checked={insurances.includes(insurance)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFilters({
                              insurances: [...insurances, insurance],
                            });
                          } else {
                            setFilters({
                              insurances: insurances.filter(
                                (t) => t !== insurance,
                              ),
                            });
                          }
                        }}
                      />
                    }
                    label={modifyReimbursementLabels([insurance])}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FilterField>Experienced with these groups:</FilterField>
            <Autocomplete
              multiple
              id="minority-identification"
              options={MINORITY_GROUPS.map((option) => option)}
              value={minorityGroups}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    color="primaryLight"
                    label={option}
                    tagProps={{ ...getTagProps({ index }) }}
                  />
                ))
              }
              sx={{
                width: '100%',
                mt: 2,
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              onChange={(event, newValue) => {
                setFilters({ minorityGroups: newValue });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FilterField>Languages Spoken:</FilterField>
            <Autocomplete
              multiple
              id="tags-filled"
              options={LANGUAGES.map((option) => option)}
              value={languages}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option}
                    color="primaryLight"
                    label={option}
                    tagProps={{ ...getTagProps({ index }) }}
                  />
                ))
              }
              sx={{
                width: '100%',
                mt: 2,
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              onChange={(event, newValue) => {
                setFilters({ languages: newValue });
              }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <Button shade="secondaryLight" onClick={resetFilters} size="large">
              Reset
            </Button>
          </Box>
          <Box sx={{ ml: 1 }}>
            <Button shade="secondary" onClick={onClose} size="large">
              See Results
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default Filter;
