import { TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default function BasicDateTimePicker({ value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
            label="Choose a date and time"
            onChange={onChange}
            value={value}
            renderInput={(params) => <TextField {...params} />}
        />
    </LocalizationProvider>
  );
}