import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { Avatar, Box, Card, Chip, Grid, Typography } from '@mui/material';

import { Purchase, State } from '../../types';
import ResourcePreview from '../resource/resource-preview';
import SessionCard from '../session/session-card';
import GroupSessionCard from '../group-session/group-session-card';
import { PRODUCT_TYPES } from '../../constants';

interface PurchaseRowProps {
  purchase: Purchase;
  showMiniProfile?: boolean;
}

function PurchaseRow({ purchase, showMiniProfile = false }: PurchaseRowProps) {

    const user = useSelector((state: State) => state.account.user);

    const [expand, setExpand] = useState(false);
    let tag = '';

    switch (purchase.product.product_type) {
        case PRODUCT_TYPES.SINGLE:
            tag = '1-on-1';
            break;
        case PRODUCT_TYPES.GROUP:
            tag = 'Group';
            break;
        case PRODUCT_TYPES.RESOURCE:
            tag = 'Resource';
            break;
        case PRODUCT_TYPES.PACKAGE:
            tag = 'Package';
            break;
    }

    return (
        <Card variant="outlined" onClick={() => setExpand(!expand)} style={{ cursor: 'pointer' }}>
            <Grid container spacing={1} p={2}>
                <Grid item xs={6}>
                    <Box display="flex" alignItems="center" height="100%">
                        {/*<Avatar
                            src={purchase.product.image_url}
                            alt={purchase.product.title}
                            sx={{ width: 'auto', height: 75, borderRadius: 2, marginRight: 2 }}
                            variant="square"
                        />*/}
                        <Typography variant="h5">{purchase.title || purchase.product.title}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box display="flex" alignItems="center" height={75}>
                        <Typography variant="subtitle1">Purchased: {moment(purchase.created_at).format('M/D/YYYY')}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box display="flex" alignItems="center" height={75}>
                        <Chip color="primary" label={tag} />
                    </Box>
                </Grid>
                {expand && (
                    <>
                        {purchase.resources.length > 0 && (
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Typography variant="h6">Resources Included</Typography>
                                </Box>
                            </Grid>
                        )}
                        {purchase.resources.map((resource) => (
                            <Grid key={resource.id} item xs={12} onClick={(e) => {
                                e.stopPropagation();
                                window.open(resource.file_url, '_target');
                            }}>
                                <ResourcePreview resource={resource} />
                            </Grid>
                        ))}
                        {purchase.sessions.length > 0 && (
                            <Grid item xs={12}>
                                <Box mt={2}>
                                    <Typography variant="h6">Sessions Included</Typography>
                                </Box>
                            </Grid>
                        )}
                        {purchase.sessions.map((session) => (
                            <Grid item xs={12} md={6} onClick={(e) => e.stopPropagation()}>
                                <SessionCard
                                    session={session}
                                    variant={user.profile.id === session.parent_profile.id ? 'parent' : 'expert'}
                                />
                            </Grid>
                        ))}
                        {purchase.group_sessions.map((groupSession) => (
                            <Grid item xs={12} md={6} onClick={(e) => e.stopPropagation()}>
                                <GroupSessionCard
                                    product={groupSession}
                                    variant={user.profile.id === purchase.profile ? 'parent' : 'expert'}
                                    purchased={true}
                                />
                            </Grid>
                        ))}
                    </>
                )}
            </Grid>
        </Card>
    );
}

export default PurchaseRow;

