import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import Dashboard from '../pages/dashboard';
import More from '../pages/more/index';
import Sessions from '../pages/sessions/index';
import SetupAccount from '../pages/setup-account/index';
import SetupParentAccount from '../pages/setup-parent-account/index';
import Clients from '../pages/clients/index';
import Folder from '../pages/folder/index';
import Earnings from '../pages/more/earnings';
import Resources from '../pages/documents';
import Resource from '../pages/documents/resource';
import ShareList from '../components/share/share-list';
import Storefront from '../pages/storefront';
import Onboarding from '../pages/onboarding';
import PaymentSettings from '../pages/payment-settings';
import Admin from '../pages/admin';
import NotificationPreferences from '../pages/more/notification-preferences';
import Documentation from '../pages/more/documentation';
import Billing from '../pages/more/billing';
import EditProfile from '../pages/more/edit-profile';
import ProfileSettings from '../pages/expert/settings';
import Partners from '../pages/partners';
import Tracks from '../pages/tracks';
import DigitalBonusKit from '../pages/digital-bonus-kit';

function PrivateRoutes() {
  const account = useSelector((state) => state.account);
  const { isLoggedIn } = account;

  if (!isLoggedIn) {
    return <Navigate replace to="/login/parents" />;
  }

  return (
    <Routes>
      <Route path="setup-account" element={<SetupAccount />} />
      <Route path="setup-parent-account" element={<SetupParentAccount />} />
      <Route path="/" element={<Layout />}>
        <Route path="" element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="clients" element={<Clients />} />
        <Route path="/clients/:folder_id/shares" element={<ShareList />} />
        <Route path="/clients/:folder_id/resources" element={<Resources clientView={true} />} />
        <Route path="/clients/:folder_id" element={<Folder />} />
        <Route path="get-started" element={<Onboarding />} />
        <Route path="sessions" element={<Sessions />} />
        <Route path="earnings" element={<Earnings />} />
        <Route path="digital-bonus-kit" element={<DigitalBonusKit />} />
        <Route path="resources" element={<Resources clientView={false} />} />
        <Route path="/resources/:resource_id" element={<Resource />} />
        <Route path="shares" element={<ShareList />} />
        <Route path="admin" element={<Admin />} />
        <Route path="/profile" element={<ProfileSettings />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/tracks" element={<Tracks />} />
        <Route path="more">
          <Route path="" element={<Navigate to="profile" replace />} />
          <Route path="profile" element={<ProfileSettings />} />
          <Route path="billing" element={<Billing />} />
          <Route path="documentation" element={<Documentation />} />
          <Route path="notifications" element={<NotificationPreferences />} />
        </Route>
        <Route path="storefront" element={<Storefront />} />
        <Route path="payment-settings" element={<PaymentSettings />} />
      </Route>
    </Routes>
  );
}

export default PrivateRoutes;
