import { useSelector } from "react-redux";
import { State } from "../types";

// must be called within redux context
export function useAuth() {
  const user = useSelector((state: State) => state.account.user);
  const token = useSelector((state: State) => state.account.token);

  return { user, token };
}
