/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ConfirmModal = styled.div`
    width: 250px;
    max-height: 80vh;
    margin-top: 10vh;
    background: #FFFFFF;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    &:active {
        border: none;
    }
`;

export const ConfirmModalTitle = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
`;

export const ConfirmModalText = styled.div`
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    text-align: center;
`;