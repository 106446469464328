/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  width: 100%;
  height: 55px;
  box-shadow: 0px 4px 2px -2px #E8E8E8;
`;

export const HelloText = styled.p`
margin: 1em 3em;
font-size: ${({ fontSize }) => (fontSize ? '12px' : '18px')};
@media (max-width: 768px) {
  display: none;
}
`;

export const SearchBox = styled.input`
font-family: 'Cabin';
width: 20vw;
border-radius: 2em;
height: 0.5em;
padding: .9em;
padding-left: 2.5em;
margin: 0.8em 1em;
color: #808080;
border: 1px solid #d3d3d3;
font-size: 12px;
&::placeholder {
    color: #bcb6c1;
    opacity: 1; /* Firefox */
  }
`;

export const SearchIconContainer = styled.img`
  position: absolute;
  margin-left: 1em;
`;

export const GroupButton = styled.button`
border-radius: 2em;
// height: 0.5em;
padding: .6em 1.2em;
margin: 0.875em 1.4em;
background-color: #5371ff;
color: #FFFFFF;
border: 1px solid #d3d3d3;
font-size: 12px;
`;

export const NotificationBtn = styled.span`
cursor: pointer;
background-color: #f4ebeb;
color: #210B2B;
font-size: 13px;
`;

export const IconSpanBorder = styled.span`
padding: .9em 0.8em .6em;
margin: 1em 1em;
color: #FFFFFF;
border-left: 1px solid #d3d3d3;
font-size: 12px;
`;

export const IconSpan = styled.span`
padding: .9em 0em .6em;
color: #FFFFFF;
font-size: 12px;
font-family: 'Cabin';
margin-right: 1em;
`;

export const MenuLink = styled.a`
  font-family: 'Cabin';
  font-size: 13px;
  color: #210B2B;
  text-decoration: none;
`;

export const MenuButton = styled.div`
  font-family: 'Cabin';
  font-size: 13px;
  color: #210B2B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const QuestionModal = styled.div`
  width: 60%;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 5% auto;
  padding: 20px;
  @media (max-width: 768px) {
    width: 80%;
    padding: 10px;
  }
  &:active {
    border: none;
  }
`;

export const QuestionModalTitle = styled.div`
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #210B2B;
`;

export const SuccessModal = styled.div`
  width: 250px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 5% auto;
  padding: 40px;
  &:active {
    border: none;
  }
`;

export const SuccessModalTitle = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
`;

export const SuccessModalText = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
`;

export const LoggedOutHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  background: #FFFFFF;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 2;
  box-shadow: 0px 1px 10px #E8E8E8;
`;

export const LoggedOutHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 106px;
  max-width: 100%;
  @media (max-width: 1099px) {
    width: 100%;
  }
  @media (min-width: 1100px) {
    width: 1100px;
  }
`;

export const LoggedOutLink = styled.a`
  font-family: 'Nunito';
  font-weight: 900;
  font-size: 17px;
  color: #000000;
  text-decoration: none;
  &:hover {
    color: #5371ff;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;