import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { Avatar, Box, Grid, IconButton, Modal, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { SET_SESSION } from '../../store/actions';
import { API } from '../../constants';
import { patch } from '../../services/api.services';
import config from '../../config';
import ShareIcon from '../../assets/icons/Share.svg';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import FolderIcon from '../../assets/icons/folder.svg';
import Security from '../../assets/images/Security.png';
import {
    ProductCategories,
    ProductContainer,
    ProductLabel,
    ProductTitle,
    ProductValue,
    Range,
    ScheduleTitle,
} from '../product/product.style';
import {
    ModalContentContainer,
    ModalHeader,
    ModalHeaderLeft,
    ModalHeaderRight,
    ModalTitle,
    ModalBody,
} from '../profile/Profile.style';
import MiniProfile from '../session/mini-profile';
import Button from '../common/button';
import Confirm from '../common/confirm';
import {
    SuccessModalTitle,
    SuccessModalText,
} from '../header/Header.style';

export default function Schedule({
    session,
    onClose,
    showConfirm = false,
}) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [dateRanges, setDateRanges] = useState({});
    const [dateSelected, setDateSelected] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const ranges = [
        {
            start: 6,
            end: 8,
            display: '6:00 am - 8:00 am',
        },
        {
            start: 8,
            end: 10,
            display: '8:00 am - 10:00 am',
        },
        {
            start: 10,
            end: 12,
            display: '10:00 am - 12:00 pm',
        },
        {
            start: 12,
            end: 14,
            display: '12:00 pm - 2:00 pm',
        },
        {
            start: 14,
            end: 16,
            display: '2:00 pm - 4:00 pm',
        },
        {
            start: 16,
            end: 18,
            display: '4:00 pm - 6:00 pm',
        },
        {
            start: 18,
            end: 20,
            display: '6:00 pm - 8:00 pm',
        },
    ];

    const timezone = moment.tz.guess();

    const addRange = (index) => {
        setDateRanges((prev) => {
            const dateRangeCopy = { ...prev };
            dateRangeCopy[dateSelected].push(index);
            return dateRangeCopy;
        });
    };

    const removeRange = (index) => {
        setDateRanges((prev) => {
            let dateRangeCopy = { ...prev };
            dateRangeCopy[dateSelected].splice(dateRangeCopy[dateSelected].indexOf(index), 1);
            return dateRangeCopy;
        });
    };

    const formatDateRanges = () => {
        const dateRangesFormatted = {
            timezone,
            days: {},
        };

        for (const [key, value] of Object.entries(dateRanges)) {
            dateRangesFormatted.days[key] = [];

            for (let i = 0; i < value.length; i++) {
                dateRangesFormatted.days[key].push(ranges[value[i]]);
            }
        }

        return dateRangesFormatted;
    };

    const addDateRanges = async () => {
        const result = await patch(
          `${API.SESSION}${session.id}/`,
          {
            date_ranges: formatDateRanges(),
          },
          token,
        );

        if (result.status === 403 || result.status === 401) {
          navigate('/login/parents');
        }
        else if (result.status === 200) {
            dispatcher({
                type: SET_SESSION,
                payload: {
                    session: result.data,
                },
            });

            if (showConfirm) {
                setShowConfirmModal(true);
            } else {
                onClose();
            }
        }
        else {
            
        }
    };

    return (
        <>
            <ModalContentContainer>
                <ModalHeader>
                    <ModalHeaderLeft>
                        <IconButton onClick={onClose}>
                            <img src={DismissIcon} alt="share" width={12} height={12} />
                        </IconButton>
                        <ModalTitle>
                            Schedule Session
                        </ModalTitle>
                    </ModalHeaderLeft>
                </ModalHeader>
                <ModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box sx={{ textAlign: 'center' }}>
                                <ScheduleTitle>Pick unlimited time ranges and dates that work for you.</ScheduleTitle>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <StaticDatePicker
                                        displayStaticWrapperAs="desktop"
                                        openTo="day"
                                        views={['day']}
                                        minDate={moment.now()}
                                        disableHighlightToday
                                        onChange={(selection) => {
                                            const dateStr = selection.format('YYYY-MM-DD');

                                            if (!dateRanges[dateStr]) {
                                                setDateRanges((prev) => {
                                                    const dateRangesCopy = { ...prev };
                                                    dateRangesCopy[dateStr] = [];
                                                    return dateRangesCopy;
                                                });
                                            }
                                            setDateSelected(dateStr);
                                        }}
                                        showDaysOutsideCurrentMonth
                                        renderInput={(params) => {
                                            return <TextField {...params} />;
                                        }}
                                        renderDay={(day, _value, DayComponentProps) => {
                                            const dateStr = day.format('YYYY-MM-DD');

                                            if (dateStr === dateSelected) {
                                                return (
                                                    <PickersDay {...DayComponentProps} style={{ background: '#5371ff', color: '#FFFFFF' }}/>
                                                );
                                            } else if (dateRanges[dateStr] && dateRanges[dateStr].length) {
                                                return (
                                                    <Box sx={{ borderBottom: '2px solid #5371ff' }}>
                                                        <PickersDay {...DayComponentProps} />
                                                    </Box>
                                                ); 
                                            } else {
                                                return (
                                                    <PickersDay {...DayComponentProps} />
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <Box sx={{ display: { xs: 'none', md: 'block' }, mt: 1 }}>
                                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                                </Box>
                            </Box>
                        </Grid>
                        {dateSelected && (
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box sx={{ mb: 1 }}>
                                        <ScheduleTitle style={{ fontSize: '14px' }}>
                                            Time ranges for {moment(dateSelected).format('MMMM Do')} (Time in {moment.tz.zone(timezone).abbr(moment(dateSelected).format('x'))})
                                        </ScheduleTitle>
                                    </Box>
                                    {ranges.map((range, index) => (
                                        <Range
                                            key={range.display}
                                            onClick={() => {
                                                if (dateRanges[dateSelected].includes(index)) {
                                                    removeRange(index);
                                                } else {
                                                    addRange(index);
                                                }
                                            }}
                                            style={dateRanges[dateSelected].includes(index) ? { background: '#FFE7E7', borderColor: '#5371ff' } : {}}
                                        >
                                            {range.display}
                                        </Range>
                                    ))}
                                    <Box sx={{ mt: 1 }}>
                                        <Button shade="primary" onClick={addDateRanges}>Send Time Suggestions</Button>
                                    </Box>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </ModalBody>
            </ModalContentContainer>
            <Modal
                open={showConfirmModal}
                onClose={() => {
                    setShowConfirmModal(false);
                    onClose();
                }}
            >
                <Confirm
                    title="Your time suggestions have been sent!"
                    text="We will let your expert know about the new time suggestions and you'll be notified when they pick a time."
                    cta="Close"
                    onClose={onClose}
                />
            </Modal>
        </>
    );
}
