import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Alert, Box, Container, Grid, Paper, Stack, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Button from '../../../components/common/button';
import Subscribe from '../../../components/payment/subscribe';
import CheckoutForm from '../../../components/payment/checkout-form';
import {
    PaymentContainer,
} from '../../../components/payment/payment.style';
import { ProfileHeading } from '../edit-profile/edit-profile.style';
import config from '../../../config';
import { fetchPaymentMethods, setDefaultPaymentMethod } from '../../../clients/profile';
import { capitalizeFirstLetter } from '../../../utils/stringHelpers';
import { ACCOUNT_TYPES, PaywallTypes } from '../../../constants';
import { getFinalPrice, getCouponDescription } from '../../../utils/coupons';
import Payout from '../earnings/payout';

const stripePromise = loadStripe(
    config.currentEnv === 'production' ?
    'pk_live_51LPUdOHf16Ud2OOxIA2TxiTEluSTEgonownCZgfuFp1TPeQVRWTL9nyRyhwTUukLBFxOkDKCZ1fCTxXegZqPVB6g00UWCQeR8N' :
    'pk_test_51LPUdOHf16Ud2OOxGrqOrYIX4jQX7gOjT3Xop4J8jSQPTG2gb1qqXdCMPm7T3gpZ9zPub7s28XYnjFgAIjOBox2200IoGhQhWk'
);

function Billing({}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    
    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [paymentMethods, setPaymentMethods] = useState([]);
    const [addNewMethod, setAddNewMethod] = useState(false);
    const [defaultCard, setDefaultCard] = useState(user.profile.default_payment_method);

    const PRICE = user.profile.subscription?.plan.amount / 100 || 0;
    const finalPrice = getFinalPrice(PRICE, user.profile.discount?.coupon);
    const hasDiscount = finalPrice !== PRICE;

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        appearance,
    };

    const getPaymentMethods = async () => {
        const cards = await fetchPaymentMethods(navigate, user, token);
        setPaymentMethods(cards);

        if (!defaultCard && cards.length) {
            setDefaultCard(cards[0].id);
        }
    };

    const setDefault = async (sourceId) => {
        const success = await setDefaultPaymentMethod(navigate, user, token, sourceId);
        
        if (success) {
            setDefaultCard(sourceId);
        }
    };

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    useEffect(() => {
        getPaymentMethods();
    }, []);

    return (
        <Container maxWidth="lg">
            <Box mt={4} mb={2}>
                <Typography variant="h5">Billing</Typography>
            </Box>
            <Paper elevation={0}>
                <Box p={4}>
                    <Grid container spacing={2}>
                        {user.profile?.profile_type === ACCOUNT_TYPES.EXPERT && (
                            <Grid item xs={12}>
                            
                                <Typography variant="h6">Payouts</Typography>
                                <Payout />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h6">My Plan</Typography>
                            <Stack spacing={1} p={2} mb={2}>
                                {user.profile.subscription?.cancel_at_period_end && (
                                    <Alert color="error" sx={{ fontFamily: 'Open Sans' }} mb={2}>
                                        Cancels On: {moment.unix(user.profile.subscription.current_period_end).format('MMM Do')}
                                    </Alert>
                                )}
                                {!user.profile.subscription?.cancel_at_period_end && user.profile.subscription?.plan.amount > 0 && (
                                    <Alert color="success" sx={{ fontFamily: 'Open Sans' }} mb={2}>
                                        Next Payment:{' '}
                                        <span style={{ textDecoration: hasDiscount ? 'line-through' : 'none' }}>
                                            ${PRICE}.00
                                        </span>
                                        {hasDiscount && (
                                            <span>{' '}{USDollar.format(finalPrice)}</span>
                                        )} on {moment.unix(user.profile.subscription.current_period_end).format('MMM Do, YYYY')}
                                    </Alert>
                                )}
                                {user.profile.discount?.coupon && !user.profile.subscription?.cancel_at_period_end && (
                                    <Alert color="success" sx={{ fontFamily: 'Open Sans' }} mb={2}>
                                        Applied Coupon: {user.profile?.discount?.coupon?.id} ({getCouponDescription(user.profile.discount.coupon)})
                                    </Alert>
                                )}
                                <Subscribe cancelInProgress={user.profile.subscription?.cancel_at_period_end} paywall_type={PaywallTypes.BASE} />
                            </Stack>
                        </Grid>
                        {(user.profile?.available_balance < 0 || paymentMethods) && (
                            <Grid item xs={12}>
                                <Typography variant="h6">My Payment Methods</Typography>
                                <Stack p={2} spacing={1}>
                                    {user.profile?.available_balance < 0 && (
                                        <Box key="wallet">
                                            <PaymentContainer style={{ cursor: 'default' }}>
                                                Available Wallet Balance: ${(user.profile.available_balance / -100).toLocaleString()}
                                            </PaymentContainer>
                                        </Box>
                                    )}
                                    {paymentMethods.map((paymentMethod) => (
                                        <Box key={paymentMethod.id}>
                                            <PaymentContainer style={{ cursor: 'default' }}>
                                                {capitalizeFirstLetter(paymentMethod.card.brand) || 'Card'} ending in &bull;&bull;&bull;&bull; {paymentMethod.card.last4}
                                                <Box ml={2}>
                                                    {defaultCard === paymentMethod.id ? (
                                                        <Button
                                                            shade="secondaryLight"
                                                            size="small"
                                                            disabled
                                                        >
                                                            Default
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            shade="primaryLight"
                                                            size="small"
                                                            onClick={() => setDefault(paymentMethod.id)}
                                                        >
                                                            Make Default
                                                        </Button>
                                                    )}
                                                </Box>
                                            </PaymentContainer>
                                        </Box>
                                    ))}
                                    {paymentMethods.length > 0 && !addNewMethod && (
                                        <Button shade="secondaryLight" onClick={() => setAddNewMethod(true)}>+ Add Payment Method</Button>
                                    )}
                                    {(paymentMethods.length === 0 || addNewMethod) && (
                                        <Elements
                                            stripe={stripePromise}
                                            options={options}
                                        >   
                                            <CheckoutForm
                                                onSuccess={() => {
                                                    setAddNewMethod(false);
                                                    getPaymentMethods();
                                                }}
                                            />
                                        </Elements>
                                    )}
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Paper>
        </Container>
    );
}

Billing.propTypes = {};

export default Billing;
