// action - state management
import {
    SET_SHARE_TO_SIGN,
} from './actions';
  
export const initialState = {
    shareToSign: undefined,
};
  
const shareReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SET_SHARE_TO_SIGN: {
            const { share } = action.payload;
            return {
                ...state,
                shareToSign: share,
            }
        }
        default: {
            return { ...state };
        }
    }
};
  
export default shareReducer;
