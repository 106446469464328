import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

function SuggestedTimes({ session }) {
    if (!session.date_ranges) {
        return null;
    }

    const rows = [];

    for (let key in session.date_ranges?.days) {
        const day = key;
        const ranges = [];

        session.date_ranges.days[day].forEach((r) => {
            ranges.push(r.display);
        });

        rows.push(
            <Box>
                {moment(day).format('MMM D')}: {ranges.join(', ')}
            </Box>
        )
    }

    return (
        <Box>
            <Box mb={1}>
                Suggested Times (in {moment.tz.zone(session.date_ranges?.timezone).abbr(moment().format('x'))}):
            </Box>
            {rows}
        </Box>
    );
}

SuggestedTimes.propTypes = {
  session: PropTypes.any,
};

export default SuggestedTimes;