import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, CircularProgress, Container, Grid, Modal, Paper, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import AddService from '../../components/product';
import Success from '../../components/product/success';
import ProductCard from '../../components/product/product-card';
import ProductPurchase from '../../components/product/purchase';
import ProductActionBar from '../../components/product/action-bar';
import { PaywallTypes } from '../../constants';
import { State } from '../../types';
import { fetchProductsBySeller } from '../../clients/product';
import { SET_PAYWALL } from '../../store/actions';

interface StorefrontProps {}

export default function Storefront({}: StorefrontProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state: State) => state.account.user);
    const token = useSelector((state: State) => state.account.token);
    const productIdsBySellerId = useSelector((state: State) => state.products.productIdsBySellerId);
    const productsById = useSelector((state: State) => state.products.productsById);
    const productsLoading = useSelector((state: State) => state.products.loading);

    const [editProduct, setEditProduct] = useState(null);
    const [previewProduct, setPreviewProduct] = useState();
    const [addNew, setAddNew] = useState(false);
    const [successProduct, setSuccessProduct] = useState();
    const [isEditing, setIsEditing] = useState(false);

    const fetchProducts = async () => {
        const products = await fetchProductsBySeller(dispatcher, navigate, token, user.profile.id, true);
    }

    const onNewService = (product = null, isEditing = false) => {
      onModalClose();
      fetchProducts();

      if (product) {
        setSuccessProduct(product);
        setIsEditing(isEditing);
      }
    };

    const onModalClose = () => {
      setAddNew(false);
      setEditProduct(null);
    };

    const onAddNew = () => {
        const numActiveProducts = productIdsBySellerId[user.profile.id].filter((productId) => !productsById[productId].archived).length
        
        if (numActiveProducts >= user.profile.feature_flags['services_listed']) {
            dispatcher({
                type: SET_PAYWALL,
                payload: {
                    paywall: true,
                    paywall_type: PaywallTypes.ADD_ON,
                },
            });
        } else {
            setAddNew(true);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

  return (
    <Container maxWidth="lg">
        <Box
            mt={2}
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            <Typography variant="h5">My Storefront</Typography>
            {!productsLoading && productIdsBySellerId[user.profile.id]?.length > 0 && (
                <Button variant="contained" color="primary" onClick={() => setAddNew(true)}>
                    <AddRoundedIcon /> Add new
                </Button>
            )}
        </Box>
        {productsLoading && (
            <Box m={2} display="flex" justifyContent="center"><CircularProgress /></Box>
        )}
        <Grid container spacing={2}>
            {productIdsBySellerId[user.profile.id]?.map((productId) => (
                <Grid key={productId} item xs={12} sm={6} md={4} height={'calc(100% + 40px)'}>
                    <ProductCard
                        product={productsById[productId]}
                        secondaryCtaAction={() => setPreviewProduct(productsById[productId])}
                        primaryCtaAction={() => {
                            setPreviewProduct(null);
                            setEditProduct(productsById[productId]);
                        }}
                        primaryCta="Edit"
                        miniProfile={false}
                    />
                    <ProductActionBar
                        product={productsById[productId]}
                        edit={(id) => setEditProduct(productsById[id])}
                    />
                </Grid>
            ))}
        </Grid>
        {!productsLoading && (!productIdsBySellerId[user.profile.id] || productIdsBySellerId[user.profile.id].length === 0) && (
            <Box mt={2}>
                <Paper elevation={0}>
                    <Box p={4} textAlign="center">
                        <Box mb={2}>
                            <Typography variant="subtitle1">
                                Start setting up your storefront by adding your first service or resource for sale!
                            </Typography>
                        </Box>
                        <Button variant="contained" color="primary" onClick={() => setAddNew(true)}>
                            <AddRoundedIcon /> Add a service
                        </Button>
                    </Box>
                </Paper>
            </Box>
        )}
        <Modal
            open={previewProduct}
            onClose={() => setPreviewProduct(null)}
        >
            <ProductPurchase
            product={previewProduct}
            onClose={() => setPreviewProduct(null)}
            preview
            onEdit={() => {
                setPreviewProduct(null);
                setEditProduct(previewProduct);
            }}
            />
        </Modal>
        <Modal
            open={addNew || editProduct}
            onClose={onModalClose}
        >
            <AddService
                productId={editProduct?.id}
                productType={editProduct?.product_type}
                onDismiss={onNewService}
            />
        </Modal>
        <Modal
            open={successProduct}
            onClose={() => setSuccessProduct(null)}
        >
            <Success
                product={successProduct}
                onDismiss={() => setSuccessProduct(null)}
                isEditing={isEditing}
            />
        </Modal>
    </Container>
  );
}
