// action - state management
import {
    SET_SESSION,
    ADD_SESSION_ID,
    RESET_SESSION_IDS,
    ADD_FOLDER_SESSION_ID,
    RESET_FOLDER_SESSION_IDS,
  } from './actions';
  
  export const initialState = {
    sessionsById: {},
    sessionIds: [],
    folderSessionIds: [],
  };
  
  const sessionReducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_SESSION: {
        const { session } = action.payload;
        const newSessionsById = { ...state.sessionsById };
        newSessionsById[session.id] = session;
        return {
          ...state,
          sessionsById: newSessionsById,
        };
      }
      case ADD_SESSION_ID: {
        const { session } = action.payload;
        return {
          ...state,
          sessionIds: [ ...state.sessionIds, session.id ],
        }
      }
      case RESET_SESSION_IDS: {
        return {
          ...state,
          sessionIds: [],
        }
      }
      case ADD_FOLDER_SESSION_ID: {
        const { session } = action.payload;
        return {
          ...state,
          folderSessionIds: [ ...state.folderSessionIds, session.id ],
        }
      }
      case RESET_FOLDER_SESSION_IDS: {
        return {
          ...state,
          folderSessionIds: [],
        }
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default sessionReducer;
  