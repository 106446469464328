// action - state management
import {
    SET_LOADING,
    OPEN_PRODUCT,
    CLOSE_PRODUCT,
    SET_PRODUCT,
    ADD_PRODUCT_ID,
    RESET_PRODUCT_IDS,
    RESET_PRODUCT_IDS_FOR_SELLER,
  } from './actions';
  
  export const initialState = {
    loading: false,
    openProduct: null,
    bookNow: false,
    productsById: {},
    productIds: [],
    productIdsByCategory: {},
    productIdsBySellerId: {},
  };
  
  const productReducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case SET_LOADING: {
        const { loading } = action.payload;
        return {
          ...state,
          loading,
        }
      }
      case OPEN_PRODUCT: {
        const { product, bookNow } = action.payload;
          return {
            ...state,
            openProduct: product.id,
            bookNow,
          }
      }
      case CLOSE_PRODUCT: {
        return {
          ...state,
          openProduct: null,
          bookNow: false,
        }
      }
      case SET_PRODUCT: {
        const { product } = action.payload;
        const newProductsById = { ...state.productsById };
        const newProductIdsByCategory = { ...state.productIdsByCategory };
        const newProductIdsBySellerId = { ...state.productIdsBySellerId };

        newProductsById[product.id] = product;

        product.categories.split(',').forEach((cat) => {
            if (cat in newProductIdsByCategory && !(product.id in newProductIdsByCategory[cat])) {
              newProductIdsByCategory[cat] = [ ...newProductIdsByCategory[cat], product.id];
            } else if (!(cat in newProductIdsByCategory)) {
              newProductIdsByCategory[cat] = [product.id];
            }
        });

        if (newProductIdsBySellerId[product.seller.id] && newProductIdsBySellerId[product.seller.id].indexOf(product.id) === -1) {
          newProductIdsBySellerId[product.seller.id] = [ ...newProductIdsBySellerId[product.seller.id], product.id];
        } else if (!newProductIdsBySellerId[product.seller.id]) {
          newProductIdsBySellerId[product.seller.id] = [product.id];
        }

        return {
          ...state,
          productsById: newProductsById,
          productIdsByCategory: newProductIdsByCategory,
          productIdsBySellerId: newProductIdsBySellerId,
        };
      }
      case ADD_PRODUCT_ID: {
        const { product } = action.payload;
        return {
          ...state,
          productIds: [ ...state.productIds, product.id ],
        }
      }
      case RESET_PRODUCT_IDS: {
        return {
          ...state,
          productIds: [],
          productIdsByCategory: {},
        }
      }
      case RESET_PRODUCT_IDS_FOR_SELLER: {
        const { sellerId } = action.payload;
        const copyProductIdsBySellerId = { ...state.productIdsBySellerId };

        copyProductIdsBySellerId[sellerId] = [];

        return {
          ...state,
          productIdsBySellerId: copyProductIdsBySellerId,
        }
      }
      default: {
        return { ...state };
      }
    }
  };
  
  export default productReducer;
  