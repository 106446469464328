import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

interface SidebarItemProps {
    action: any;
    active: boolean;
    Icon: any;
    text: string;
    children: any;
    sx: any;
}

export default function SidebarItem({ action, active, Icon, text, children, sx }: SidebarItemProps) {
    return (
        <ListItemButton
          onClick={action}
          selected={active}
          sx={{ pl: 3, mb: 1, ...sx }}
          color="info"
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={text} />
          {children}
        </ListItemButton>
    );
};
