import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box, Snackbar } from '@mui/material';

import linkIcon from '../../assets/icons/Link.svg';

import {
  LinkContainer,
  LinkDetails,
  Link,
} from './Session.style';

function MeetingLink({ link }) {
  const [copied, setCopied] = useState(false);

  let appNotice = null;

  if (link.includes('google')) {
    appNotice = `Your session will take place in Google Meet. If you plan to join from a mobile${' '}
      device please download the Google Meet app.`;
  }

  if (link.includes('zoom')) {
    appNotice = `Your session will take place in Zoom. If you plan to join from a mobile${' '}
      device please download the Zoom app.`;
  }

  return (
    <Box mb={2}>
      <CopyToClipboard
        text={link}
        onCopy={() => setCopied(true)}
      >
        <LinkContainer>
          Tap here to copy meeting link
          <img src={linkIcon} alt="copy link" width={28} />
        </LinkContainer>
      </CopyToClipboard>
      <Box>
        <Link href={link} target="_blank">{link}</Link>
      </Box>
      {appNotice && (
        <Box mt={1}>
          <LinkDetails>{appNotice}</LinkDetails>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={copied}
        autoHideDuration={4000}
        onClose={() => setCopied(false)}
        message="Meeting link has been copied to your clipboard"
      />
    </Box>
  );
}

MeetingLink.propTypes = {
  link: PropTypes.string,
};

export default MeetingLink;