/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const NoResults = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Cabin';
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
`;

export const SuccessModal = styled.div`
  width: 250px;
  height: 300px;
  background: #FFFFFF;
  border-radius: 10px;
  margin: 5% auto;
  padding: 40px;
  &:active {
    border: none;
  }
`;

export const SuccessModalTitle = styled.div`
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
`;

export const SuccessModalText = styled.div`
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  text-align: center;
`;