import React from 'react';

import { Box, Stack } from '@mui/material';

import Button from '../common/button';
import { ScreenTitle, ScreenSubtext } from './review.style';

export default function ContactForm({ contactMe, setContactMe, next, back }) {
    return (
        <Stack spacing={2}>
            <Box display="flex" flexDirection="column" justifyContent="center" sx={{ textAlign: 'center' }}>
                <ScreenTitle>Would you like to be contacted about your experience?</ScreenTitle>
                <ScreenSubtext>We will reach out and do our best to make this situation right.</ScreenSubtext>
            </Box>
            <Stack spacing={1}>
                <Box display="flex" justifyContent="center">
                    <Button
                        onClick={() => setContactMe(true)}
                        shade={contactMe === true ? "secondary" : "secondaryLight"}
                    >
                        Yes
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button
                        onClick={() => setContactMe(false)}
                        shade={contactMe === false ? "secondary" : "secondaryLight"}
                    >
                        No
                    </Button>
                </Box>
            </Stack>
            <Box display="flex" justifyContent="space-around">
                <Button onClick={back} shade="secondaryLight">Back</Button>
                <Button onClick={next}>Done</Button>
            </Box>
        </Stack>
    );
}
