import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';

import { get, post } from '../../../services/api.services';
import { API } from '../../../constants';
import { PayoutContainer, PayoutHeader, Balance } from './earnings.style';

function Payout({}) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [availableBalance, setAvailableBalance] = useState();
    const [pendingBalance, setPendingBalance] = useState();
    const [accountStatus, setAccountStatus] = useState();
    const [payoutInProgress, setPayoutInProgress] = useState(false);
    const [fetchingConnectLink, setFetchingConnectLink] = useState(false);
    const [fetchingDashboardLink, setFetchingDashboardLink] = useState(false);

    const fetchBalance = async () => {
        const result = await get(
            `${API.PROFILE}${user.profile.id}/balance/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setAvailableBalance(result.data.available_balance);
            setPendingBalance(result.data.pending_balance);
            setPayoutInProgress(false);
        }
        else {
            setPayoutInProgress(false);
        }
    }

    const fetchOnboardingLink = async () => {
        setFetchingConnectLink(true);
    
        const result = await get(
            `${API.PROFILE}${user.profile.id}/get_stripe_onboarding_link/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            window.location.href = result.data.url;
        }
        else {
        }
    }

    const fetchDashboardLink = async () => {
        setFetchingDashboardLink(true);

        const result = await get(
            `${API.PROFILE}${user.profile.id}/get_stripe_connect_dashboard/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            if (result.data.status === 'success') {
                window.location.href = result.data.url;
            }
        }
    }

    const fetchAccountStatus = async () => {
        const result = await get(
            `${API.PROFILE}${user.profile.id}/get_stripe_connect_status/`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setAccountStatus(result.data.status);
        }
        else {
        }
    }

    const payout = async () => {
        setPayoutInProgress(true);

        const result = await post(
            `${API.PROFILE}${user.profile.id}/payout/`,
            {},
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            fetchBalance();
            // TODO: Show modal?
        }
        else {
            setPayoutInProgress(false);
        }
    }

    useEffect(() => {
        fetchBalance();
        fetchAccountStatus();
    }, []);

    return (
        <Stack spacing={1}>
            <PayoutContainer>
                {pendingBalance && availableBalance === 0 ? (
                    <Stack spacing={1} sx={{ width: '50%'}}>
                        <PayoutHeader>Pending Balance</PayoutHeader>
                        <Balance>${parseFloat(pendingBalance * .01).toFixed(2)}</Balance>
                    </Stack>
                ) : (
                    <Stack spacing={1} sx={{ width: '50%'}}>
                        <PayoutHeader>Available Balance</PayoutHeader>
                        {availableBalance !== undefined ? (
                            <Balance>${parseFloat(availableBalance * .01).toFixed(2)}</Balance>
                        ) : (
                            <CircularProgress />
                        )}
                    </Stack>
                )}
                {accountStatus === 'not_started' && (
                    <Button variant="contained" color="secondary" onClick={fetchOnboardingLink}>
                        {fetchingConnectLink ? (
                            <CircularProgress />
                        ) : (
                            <span>Connect Bank</span>
                        )}
                    </Button>
                )}
                {accountStatus === 'payouts_enabled' && (
                    <Stack spacing={1} display="flex" flexDirection="column" justifyContent="flex-end">
                        {availableBalance > 0 && (
                            <Button variant="contained" color="secondary" disabled={payoutInProgress} onClick={payout}>
                                Move To Bank
                            </Button>
                        )}
                    </Stack>
                )}
                {accountStatus === 'details_submitted' && (
                    <Button variant="contained" color="secondary" onClick={fetchOnboardingLink}>
                        {fetchingConnectLink ? (
                            <CircularProgress />
                        ) : (
                            <span>Finish Bank Connection</span>
                        )}
                    </Button>
                )}
            </PayoutContainer>
            <Typography variant="caption">
                {pendingBalance ?
                    `You have payments worth $${parseFloat(pendingBalance * .01).toFixed(2)} pending. Payments take ~7 days to become available and ~2 days to transfer to your bank account once you payout.` :
                    'Payments take ~7 days to become available and ~2 days to transfer to your bank account once you payout.'
                }
            </Typography>
            {accountStatus === 'payouts_enabled' && (
                <Typography variant="subtitle1">
                    To update your bank information and view payout history:{' '}
                    <Button variant="outlined" onClick={fetchDashboardLink}>
                        {fetchingDashboardLink ? (
                            <CircularProgress />
                        ) : (
                            <span>Visit Your Stripe dashboard</span>
                        )}
                    </Button>
                </Typography>
            )}
        </Stack>
    );
}

Payout.propTypes = {};

export default Payout;
