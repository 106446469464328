import { useSelector } from 'react-redux';

import { Alert, Button } from '@mui/material';

import { State } from '../../types';

export default function VersionAlert() {
    const latestVersion = useSelector((state: State) => state.version.latestVersion);
    const currentVersion = useSelector((state: State) => state.version.currentVersion);

    if (currentVersion !== null && latestVersion !== null && latestVersion !== currentVersion) {
        return (
            <Alert
                severity="error"
                action={
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => window.location.reload()}
                    >
                        Reload Now
                    </Button>
                }
            >
                A new version of Nessle is available, please reload the page for the best experience.
            </Alert>
        );
    }

    return null;
}
