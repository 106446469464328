// npm imports
import React from 'react';
import { Box, Button, Link } from '@mui/material';
import { Widget } from '@typeform/embed-react';

export default function Feedback({ feedback, setFeedback, previous, next }) {
  return (
    <Box height={400} textAlign="center">
      <Widget id="truuXnp6" style={{ width: '100%', height: 400 }} className="my-form" />
      <Link href="/support">
        <Button variant="contained" color="secondary">Close</Button>
      </Link>
    </Box>
  );
}
