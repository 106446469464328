// action - state management
import { VersionActionType } from "./actions";

export const initialState = {
    currentVersion: null,
    latestVersion: null,
};

type VersionAction =
  | { type: VersionActionType.SET_LATEST_VERSION; payload: { version: string } }
  | { type: VersionActionType.SET_CURRENT_VERSION; payload: { version: string } }

const versionReducer = (state = initialState, action: VersionAction) => {
  switch (action.type) {
    case VersionActionType.SET_LATEST_VERSION: {
        const { version } = action.payload;
        
        return {
            ...state,
            latestVersion: version,
            currentVersion: state.currentVersion ? state.currentVersion : version, // If current version hasn't been set yet, set it
        };
    }
    case VersionActionType.SET_CURRENT_VERSION: {
        const { version } = action.payload;
        
        return {
            ...state,
            currentVersion: version,
        };
    }
    default: {
      return { ...state };
    }
  }
};

export default versionReducer;
