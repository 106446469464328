import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import moment from 'moment';
import { Box, Drawer, IconButton } from '@mui/material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';

import Thread from '../thread';

import {
  NotificationText,
} from './notifications.style.js';

import SessionCard from '../session/session-card';
import ShareCard from '../share/share-card';
import PaymentRow from '../payment/payment-row';

function Notification({ notification, textOnly = false }) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const sessionsById = useSelector((state) => state.sessions.sessionsById);

    const [openThread, setOpenThread] = useState(null);

    const buildNotification = (notification, textOnly) => {
        switch(notification.notification_type) {
          case 'question_grabbed':
            return (
              <>
                <NotificationText>Your question has been 'Grabbed'! Let the expert know whether you'd like to accept or reject.</NotificationText>
              </>
            )
            break;
          case 'session_scheduled':
            return (
              <>
                <NotificationText>Your session has been scheduled.</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'session_reschedule_request':
            return (
              <>
                <NotificationText>Your session has new time suggestions.</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'session_canceled':
            return (
              <>
                <NotificationText>Your session has been canceled.</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant={notification.session.parent_profile.id === notification.recipient.id ? 'parent' : 'expert'}
                  />
                )}
              </>
            )
            break;
          case 'submit_rating':
            return (
              <>
                <NotificationText>
                  Your session has ended. Tell us how it went by rating your session.
                </NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="parent"
                  />
                )}
              </>
            )
            break;
          case 'key_takeaways_received':
            return (
              <>
                <NotificationText>Review your key takeaways.</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="parent"
                  />
                )}
              </>
            )
            break;
          case 'grab_accepted':
            return (
              <>
                <NotificationText>Your 'Grab' has been accepted!</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'grab_declined':
            return (
              <>
                <NotificationText>Your 'Grab' has been declined.</NotificationText>
                {!textOnly && (<span>{notification.question.question}</span>)}
              </>
            )
            break;
          case 'session_requested':
            return (
              <>
                <NotificationText>You received a session request!</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'write_key_takeaways':
            return (
              <>
                <NotificationText>
                  Your session has ended. Send your client a few key takeaways.
                </NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'rating_received':
            return (
              <>
                <NotificationText>You received a new rating.</NotificationText>
                {!textOnly && (
                  <SessionCard
                      session={sessionsById[notification.session.id] || notification.session}
                      variant="expert"
                  />
                )}
              </>
            )
            break;
          case 'message_received':
            return (
              <>
                <NotificationText>
                  {notification.thread.parent_profile.id === user.profile.id ? notification.thread.expert_profile.first_name : notification.thread.parent_profile.first_name} sent you a message!
                </NotificationText>
                {!textOnly && (
                  <>
                    <IconButton
                      onClick={(e) => setOpenThread(notification.id)}
                    >
                      <MessageOutlinedIcon />
                    </IconButton>
                    <Drawer
                      anchor="right"
                      open={openThread === notification.id}
                      onClose={() => setOpenThread(null)}
                    >
                      <Thread
                        parentProfile={notification.thread.parent_profile}
                        expertProfile={notification.thread.expert_profile}
                        onDismiss={() => setOpenThread(null)}
                      />
                    </Drawer>
                  </>
                )}
              </>
            )
            break;
          case 'share_received':
            return (
              <>
                <NotificationText>{notification.folder.owner.first_name} shared a resource with you</NotificationText>
                <ShareCard
                    key={notification.share.id}
                    share={notification.share}
                    hideSignaturePrompt={true}
                />
              </>
            )
            break;
          case 'signature_received':
            return (
              <>
                <NotificationText>{notification.signature.signature_name} signed "{notification.signature.share.resource.title}"</NotificationText>
                <ShareCard
                    key={notification.signature.share.id}
                    share={notification.signature.share}
                    signatures={[notification.signature]}
                />
              </>
            )
            break;
          case 'event_reminder':
            return (
              <>
                <NotificationText>
                  Your event "{notification.event.title}" for {notification.folder.name} is happening on {moment(notification.event.event_time).format('M/D/YYYY')}
                </NotificationText>
              </>
            )
            break;
          case 'payment_request':
            return (
              <>
                <NotificationText>New payment request</NotificationText>
                <PaymentRow payment={notification.payment} />
              </>
            )
          default:
            break;
        }
    };

    return <Box mt={textOnly ? 1 : 0}>{buildNotification(notification, textOnly)}</Box>;
}

export default Notification;
