import React, { useState } from 'react';

import { Box, Button, Chip, Modal, Typography } from '@mui/material';

import ConfirmModal from '../../components/common/modal/confirm';

interface PolicyProps {
    title: string;
    description: string;
    isDefault: boolean;
    onMakeDefault: (applyToExisting: boolean) => void;
}

export default function Policy({ title, description, isDefault, onMakeDefault }: PolicyProps) {
    const [openPolicyModal, setOpenPolicyModal] = useState(false);

    return (
        <>
            <Box borderColor="info.light" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                p: 2
            }}>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
                <Box mt={1}>
                    <Typography color="text.secondary" gutterBottom>
                        {description}
                    </Typography>
                </Box>
                <Box mt={2}>
                    {isDefault ? (
                        <Chip color="primary" label="Default" />
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenPolicyModal(true);
                            }}
                        >
                            Make Default
                        </Button>
                    )}
                </Box>
            </Box>
            <Modal
                open={openPolicyModal}
                onClose={() => setOpenPolicyModal(false)}
            >
                <ConfirmModal
                    title="Would you like your existing services updated?"
                    subtitle="This will become the new default for newly created services. Would you also like it to apply to existing services?"
                    cta="Yes, update them"
                    ctaAction={(e) => {
                        e.stopPropagation();
                        onMakeDefault(true)
                        setOpenPolicyModal(false);
                    }}
                    cancelText="Only apply to new services"
                    cancelAction={(e) => {
                        e.stopPropagation();
                        onMakeDefault(false);
                        setOpenPolicyModal(false);
                    }}
                />
            </Modal>
        </>
    );
}