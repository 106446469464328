import { useNavigate } from 'react-router-dom';

import { Box, Grid, Stack } from '@mui/material';

import Logo from '../../assets/images/parentswarm-logo.svg';
import { LoggedOutFooterContainer, LoggedOutFooter, LoggedOutFooterLink } from './Footer.style';

function Footer() {
  const navigate = useNavigate();

  return (
    <LoggedOutFooterContainer>
        <LoggedOutFooter>
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Box mt={-2}>
                        <img src={Logo} alt="parentswarm logo" width={150} />
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Stack spacing={4}>
                        <LoggedOutFooterLink href="https://parentswarm.com/blog">Blog</LoggedOutFooterLink>
                        <LoggedOutFooterLink href="https://www.parentswarm.com/list-resource">Onboard as a Resource</LoggedOutFooterLink>
                        <LoggedOutFooterLink href="https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f7961c65433676bb1ee50_Parentswarm%20-%20Terms%20of%20Service%20and%20User%20Agreement%20(TC%205.6.24).pdf">
                            Parent Terms and Conditions
                        </LoggedOutFooterLink>
                        <LoggedOutFooterLink href="https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f796175c75899e368a6ba_Parentswarm%20-%20Terms%20of%20Service%20and%20Supplier%20Agreement%20(TC%205.6.24).pdf">
                            Supplier Terms and Conditions
                        </LoggedOutFooterLink>
                        <LoggedOutFooterLink href="https://cdn.prod.website-files.com/62b6328770c172ec59ea167d/663f796163c081c0c26f19eb_Parentswarm%20-%20Privacy%20Policy%20-%205.8.24.pdf">
                            Privacy Policy
                        </LoggedOutFooterLink>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>

                </Grid>
            </Grid>
            <Box sx={{ borderTop: '1px solid #e0dbdb', color: '#817d7d', fontFamily: 'Poppins', fontWeight: 500, lineHeight: '1.9em', marginTop: '30px', paddingTop: '20px', paddingBottom: '20px' }}>
                &copy; 2024 by Parentswarm
            </Box>
        </LoggedOutFooter>
    </LoggedOutFooterContainer>
  );
}

export default Footer;
