import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import axios from 'axios';
import moment from 'moment';
import {
    Alert,
    Box,
    Grid,
    TextField,
} from '@mui/material';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import { API } from '../../constants';
import Modal from '../../components/common/modal';
import { post } from '../../services/api.services';
import Button from '../../components/common/button';
import FilePreview from '../../pages/documents/file-preview';
import { uploadToS3 } from '../../utils/upload';
import { fetchFile, getFileType } from '../../utils/files';

function SignatureForm({ share, onClose }) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [signature, setSignature] = useState(`${user.first_name ? `${user.first_name} ` : ''}${user.last_name || ''}`);
    const [signatureError, setSignatureError] = useState();
    const [processing, setProcessing] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);

    const resetErrors = () => {
        setSignatureError(null);
        setHasErrors(false);
    };

    const storeSignedPdf = async () => {
        setProcessing(true);
    
        // Convert the original file to PDF using an external library
        let pdfDoc;
        const file = await fetchFile(share.resource.file_url);
        const fileType = await getFileType(file);

        if (fileType === 'pdf') {
            const response = await axios.get(share.resource.file_url, { responseType: 'arraybuffer' });
            pdfDoc = await PDFDocument.load(response.data);
        } else if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            const imageBuffer = await axios.get(share.resource.file_url, { responseType: 'arraybuffer' });
            pdfDoc = await PDFDocument.create();
            const image = await pdfDoc.embedPng(imageBuffer.data);
            const page = pdfDoc.addPage();
            page.drawImage(image, { x: 0, y: 0, width: page.getWidth(), height: page.getHeight() });
        }

        // Create a new page with the text using pdf-lib
        const [font] = await Promise.all([
            pdfDoc.embedFont(StandardFonts.Helvetica),
        ]);
        const [signatureFont] = await Promise.all([
            pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique),
        ]);
        const pages = pdfDoc.getPages();
        const lastPage = pages[pages.length - 1];
        const { width, height } = lastPage.getSize();

        // Add a new blank page to the document
        const newPage = pdfDoc.addPage();

        newPage.drawText('I agree and electronically sign this document on:', {
            x: 50,
            y: height - 50,
            size: 14,
            font,
            color: rgb(0, 0, 0),
            lineHeight: 20,
            textAlign: 'left',
            verticalAlign: 'top',
        });

        newPage.drawText(moment().format('MM/DD/YYYY'), {
            x: 50,
            y: height - 70,
            size: 14,
            font,
            color: rgb(0, 0, 0),
            lineHeight: 20,
            textAlign: 'left',
            verticalAlign: 'top',
        });

        newPage.drawText(signature, {
            x: 50,
            y: height - 90,
            size: 14,
            font: signatureFont,
            color: rgb(0, 0, 0),
            lineHeight: 20,
            textAlign: 'left',
            verticalAlign: 'top',
        });

        newPage.drawText(user.email, {
            x: 50,
            y: height - 110,
            size: 14,
            font,
            color: rgb(0, 0, 0),
            lineHeight: 20,
            textAlign: 'left',
            verticalAlign: 'top',
        });

        // Save the modified PDF document to an ArrayBuffer using pdf-lib
        const pdfData = await pdfDoc.save();
    
        // Create a blob containing the new PDF file
        const blob = new Blob([pdfData], { type: 'application/pdf' });

        const path = `signature/${share.id}/${user.profile.id}-${moment().unix()}/`;
        const successfulUpload = await uploadToS3(
              token,
              path,
              blob,
              'application/pdf',
        );

        setProcessing(false);

        if (successfulUpload) {
            return path;
        }

        return false;
    }

    const onSign = async () => {
        resetErrors();
        let result;

        if (signature.length === 0) {
            setSignatureError('This field is required.');
            return;
        }

        const signedFileKey = await storeSignedPdf();

        if (!signedFileKey) {
            setHasErrors(true);
            return;
        }
    
        result = await post(
            `${API.SIGNATURE}`,
            {
                share_id: share.id,
                signed_file_key: signedFileKey,
                signature_name: signature,
            },
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 400) {
            setHasErrors(true);
        }
        else if (result.status === 201 || result.status === 200) {
            onClose();
        }
        else {
        }
    };

    return (
        <Modal
            title="Review + Sign"
            onClose={onClose}
        >
            {hasErrors && (
                <Box mb={2}>
                    <Alert color="error">Something went wrong, please try again.</Alert>
                </Box>
            )}
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box mb={1} sx={{ fontFamily: 'Cabin', fontSize: '14px'}}>Title</Box>
                         <Box mt={1}>
                            <span>{share.resource.title}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box mb={1} sx={{ fontFamily: 'Cabin', fontSize: '14px'}}>Description</Box>
                         <Box mt={1}>
                            <span>{share.resource.description}</span>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ border: '2px solid #000000', borderRadius: 2 }}>
                            <FilePreview url={share.resource.file_url} />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="signature"
                            label="Type your full name"
                            variant="outlined"
                            value={signature}
                            onChange={(e) => setSignature(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 64, sx: { fontWeight: 600, fontStyle: 'italic' } }}
                            error={signatureError}
                            helperText={signatureError || null}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondary" disabled={processing} onClick={onSign}>I agree and sign electronically</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default SignatureForm;
