import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import moment from 'moment';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Chip,
    CircularProgress,
    Container,
    Grid,
    Rating,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { get } from '../../services/api.services';
import { useAuth } from '../../hooks';
import { API } from '../../constants';
import MiniProfile from '../../components/session/mini-profile';

interface AdminProps {}


interface SessionProps {
    session: any,
}

function SessionRow({
    session,
}: SessionProps) {
    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">{session.title}</Typography>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    target="_blank"
                    href={`https://api.prod.nessle.com/admin/api/session/${session.id}/`}
                >
                    View in Admin
                </Button>
            </Box>
            {session.session_date ? (
                <Box mt={1}>
                    <Typography variant="caption">
                        Scheduled
                    </Typography>
                    <Box>
                        <Typography variant="body2">
                            {moment(session.session_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Chip variant="outlined" size="small" color="error" label="Not Scheduled" />
            )}
            {session.review && (
                <Box mt={1}>
                    <Typography variant="caption">
                        Review
                    </Typography>
                    <Box>
                        <Rating name="rating" value={session.review.rating} precision={1} readOnly />
                    </Box>
                    <Box>
                        <Typography variant="body2">"{session.review.direct_comments}"</Typography>
                    </Box>
                </Box>
            )}
            <Box mt={1}>
                <Typography variant="caption">
                    Parent
                </Typography>
                <MiniProfile profile={session.parent_profile} />
                <Typography variant="caption">
                    Expert
                </Typography>
                <MiniProfile profile={session.expert_profile} />
            </Box>
        </Box>
    );
};

interface ExpertRowProps {
    profile: any,
    sessions: any[],
}

function ExpertRow({
    profile,
    sessions,
}: ExpertRowProps) {

    const mySessions = sessions.filter((session) => session.expert_profile.id === profile.id);

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <MiniProfile profile={profile} />
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    target="_blank"
                    href={`https://api.prod.nessle.com/admin/api/profile/${profile.id}/`}
                >
                    View in Admin
                </Button>
            </Box>
            <Stack spacing={1} direction="row" mt={1} mb={1} flexWrap="wrap">
                {profile.onboarding_checklist?.training_documentation ? (
                    <Chip variant="outlined" size="small" color="success" label="Training Documentation" />
                ) : (
                    <Chip variant="outlined" size="small" color="error" label="Training Documentation" />
                )}

                {profile.onboarding_checklist?.listing_page ? (
                    <Chip variant="outlined" size="small" color="success" label="Listing Page" />
                ) : (
                    <Chip variant="outlined" size="small" color="error" label="Listing Page" />
                )}
            </Stack>
            {mySessions.length > 0 && (
                <Box m={2}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="expert-sessions-1-content"
                            id="expert-sessions-1-header"
                        >
                            <Box mr={1}><Typography>Sessions</Typography></Box>
                            <Chip size="small" label={mySessions.length} color="primary" />
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                {mySessions.map((session) => (
                                    <SessionRow session={session} />
                                ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            )}
        </Box>
    );
};

interface OrganizationRowProps {
    organization: any,
    activeExperts: any[],
    inactiveExperts: any[],
    sessions: any[],
}

function OrganizationRow({
    organization,
    activeExperts,
    inactiveExperts,
    sessions,
}: OrganizationRowProps) {
    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">{organization.name}</Typography>
                <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    target="_blank"
                    href={`https://api.prod.nessle.com/admin/api/organization/${organization.id}/`}
                >
                    View in Admin
                </Button>
            </Box>
            <Box>
                Referral Code: {organization.referral_code}
            </Box>
            <Box mt={2}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="organization-1-content"
                        id="organization-1-header"
                    >
                        <Box mr={1}><Typography>Active Experts</Typography></Box>
                        <Chip size="small" label={activeExperts.length} color="primary" />
                    </AccordionSummary>
                    <AccordionDetails>
                        {activeExperts.map((expert) => (
                            <ExpertRow profile={expert} sessions={sessions} />
                        ))}
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="organization-2-content"
                        id="organization-2-header"
                    >
                        <Box mr={1}><Typography>Inactive Experts</Typography></Box>
                        <Chip size="small" label={inactiveExperts.length} color="primary" />
                    </AccordionSummary>
                    <AccordionDetails>
                        {inactiveExperts.map((expert) => (
                            <ExpertRow profile={expert} sessions={sessions} />
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box>
    );
};

export default function Admin({}: AdminProps) {
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const [loading, setLoading] = useState(true);
    const [totalExperts, setTotalExperts] = useState();
    const [totalParents, setTotalParents] = useState();
    const [activeExperts, setActiveExperts] = useState([]);
    const [inactiveExperts, setInactiveExperts] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [sessions, setSessions] = useState([]);

    const fetchProfiles = async () => {
        setLoading(true);
        const result = await get(
            `${API.PROFILE}admin_dashboard/`,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
            setLoading(false);
        }
        else if (result.status === 200) {
            setTotalExperts(result.data.total_experts);
            setTotalParents(result.data.total_parents);
            setActiveExperts(result.data.active_experts);
            setInactiveExperts(result.data.inactive_experts);
            setOrganizations(result.data.organizations);
            setSessions(result.data.sessions);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfiles();
    }, []);

    return (
        <Container maxWidth="lg">
            <Box mt={2} mb={2}>
                <Typography variant="h5">Admin Dashboard</Typography>
            </Box>
            <Stack spacing={2}>   
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={2}>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Total Experts</Typography>
                                        <Typography variant="h4">{totalExperts}</Typography>
                                    </Box>
                                </Card>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Active Experts</Typography>
                                        <Typography variant="h4">{activeExperts.length}</Typography>
                                        <Stack spacing={1}>
                                            {activeExperts.map((expert) => (
                                                <ExpertRow profile={expert} sessions={sessions} />
                                            ))}
                                        </Stack>
                                    </Box>
                                </Card>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Inactive Experts</Typography>
                                        <Typography variant="h4">{inactiveExperts.length}</Typography>
                                        <Stack spacing={1}>
                                            {inactiveExperts.map((expert) => (
                                                <ExpertRow profile={expert} sessions={sessions} />
                                            ))}
                                        </Stack>
                                    </Box>
                                </Card>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Stack spacing={2}>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Total Parents</Typography>
                                        <Typography variant="h4">{totalParents}</Typography>
                                    </Box>
                                </Card>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Sessions</Typography>
                                        <Typography variant="h4">{sessions.length}</Typography>
                                    </Box>
                                    <Box m={2}>
                                        <Stack spacing={2}>
                                            {sessions.filter((session) => !session.key_takeaway_1).map((session) => (
                                                <SessionRow session={session} />
                                            ))}
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="session-2-content"
                                                    id="session-2-header"
                                                >
                                                    <Typography>Completed Sessions</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Stack spacing={2}>
                                                        {sessions.filter((session) => session.key_takeaway_1).map((session) => (
                                                            <SessionRow session={session} />
                                                        ))}
                                                    </Stack>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Stack>
                                    </Box>
                                </Card>
                                <Card elevation={0}>
                                    <Box m={2}>
                                        <Typography variant="h6">Partner Organizations</Typography>
                                        <Typography variant="h4">{organizations.length}</Typography>
                                    </Box>
                                    <Box m={2}>
                                        <Stack spacing={2}>
                                            {organizations.map((organization) => (
                                                <OrganizationRow
                                                    organization={organization}
                                                    activeExperts={activeExperts.filter((expert) => expert.organization === organization.id)}
                                                    inactiveExperts={inactiveExperts.filter((expert) => expert.organization === organization.id)}
                                                    sessions={sessions}
                                                />
                                            ))}
                                        </Stack>
                                    </Box>
                                </Card>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
            </Stack>
        </Container>
    );
}
