import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, FormControl, TextField, Typography } from '@mui/material';

import { post } from '../../services/api.services';
import { API } from '../../constants';

function ForgotPasswordForm({ onSuccess, onBack }) {
    const [email, setEmail] = useState('');
    const [error, setError] = useState();

    const sendResetLink = async () => {
        setError(undefined);
        const result = await post(
            API.PASSWORD_RESET,
            { email },
            null,
        );

        if (result.status === 200) {
            onSuccess();
        }
        else {
            if ('email' in result.data) {
                setError(result.data.email);
            }
        }
    };

    return (
        <Box mt={2}>
            <Typography variant="h6">Forgot Password</Typography>
            <Box mt={2}>
                <FormControl sx={{ width: '80%' }}>
                    <TextField
                        id="email"
                        type="email"
                        variant="outlined"
                        value={email}
                        placeholder="Email"
                        onChange={(e) => setEmail(e.target.value)}
                        inputProps={{ maxLength: 256, sx: { width: '100%' } }}
                        sx={{ width: '100%' }}
                        error={error}
                        helperText={error || null}
                    />
                </FormControl>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                <Button variant="contained" color="secondary" onClick={onBack}>Back</Button>
                <Box ml={2}>
                    <Button
                        type="submit"
                        disabled={email.length === 0}
                        onClick={sendResetLink}
                        variant="contained"
                    >
                        Request reset link
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

ForgotPasswordForm.propTypes = {
    onSuccess: PropTypes.func,
    onBack: PropTypes.func,
};

export default ForgotPasswordForm;
