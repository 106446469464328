import React from 'react';

import { Box, Stack, TextField } from '@mui/material';

import Button from '../common/button';
import { ScreenTitle } from './review.style';

export default function PlatformComment({ platformComment, setPlatformComment, next, back }) {
    return (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="center" sx={{ textAlign: 'center' }}>
                <ScreenTitle>Any comments or concerns about Parentswarm?</ScreenTitle>
            </Box>
            <TextField
                id="platform-comment"
                variant="outlined"
                placeholder={`Tell us your thoughts about Parentswarm and how we can do better!`}
                multiline
                minRows={4}
                value={platformComment}
                onChange={(e) => setPlatformComment(e.target.value)}
                sx={{ width: '100%' }}
            />
            <Box display="flex" justifyContent="space-around">
                <Button onClick={back} shade="secondaryLight">Back</Button>
                <Button onClick={next}>Done</Button>
            </Box>
        </Stack>
    );
}
