import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducer import
import accountReducer from "./accountReducer";
import profileReducer from "./profileReducer";
import searchReducer from "./searchReducer";
import sessionReducer from "./sessionReducer";
import notificationReducer from "./notificationReducer";
import matchReducer from "./matchReducer";
import couponReducer from "./couponReducer";
import giftCardReducer from "./giftCardReducer";
import productReducer from "./productReducer";
import folderReducer from "./folderReducer";
import shareReducer from "./shareReducer";
import eventReducer from "./eventReducer";
import resourceReducer from "./resourceReducer";
import paymentPlanReducer from "./paymentPlanReducer";
import paymentReducer from "./paymentReducer";
import versionReducer from "./versionReducer";

//-----------------------|| COMBINE REDUCER ||-----------------------//

const reducer = combineReducers({
  account: persistReducer(
    {
      key: "account",
      storage,
      keyPrefix: "nessle-",
    },
    accountReducer
  ),
  profiles: profileReducer,
  products: productReducer,
  search: searchReducer,
  sessions: sessionReducer,
  events: eventReducer,
  notifications: notificationReducer,
  folders: folderReducer,
  match: matchReducer,
  shares: shareReducer,
  coupon: couponReducer,
  resources: resourceReducer,
  version: versionReducer,
  paymentPlans: paymentPlanReducer,
  payments: paymentReducer,
  giftCard: persistReducer(
    {
      key: "gift-card",
      storage,
      keyPrefix: "nessle-",
    },
    giftCardReducer
  ),
});

export default reducer;
