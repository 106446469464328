import React, { useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';

import { Box } from '@mui/material';

import { fetchFile, getFileType } from '../../utils/files';

export default function FilePreview({ url, height = '300px' }) {
    const [fileType, setFileType] = useState('');

    const getAndSetFileType = async (url) => {
        const file = await fetchFile(url);
        const fileType = await getFileType(file);
        setFileType(fileType);
    };

    const renderPreview = () => {
        if (fileType === 'pdf') {
            return (
                <iframe
                    style={{
                        height: height,
                        border: 'none',
                        width: '100%',
                        borderRadius: '8px',
                    }}
                    src={url}
                />
            );
        } else if (fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return (
                <Box sx={{ width: '100%', height: height, border: '2px solid #000000', borderRadius: '8px', overflow: 'hidden' }}>
                    <FileViewer
                        fileType='docx'
                        filePath={url}
                        errorComponent={<span>ERROR</span>}
                        onError={(e) => console.log('File viewer error: ', e)}
                    />
                </Box>
            );
        } else if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            return (
                <img src={url} alt="preview" style={{ width: '100%', borderRadius: '8px' }} />
            );
        } else if (fileType === 'mp4') {
            return (
                <video width="100%" controls>
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )
        } else {
            return <p>Unsupported file type</p>;
        }
    };

    useEffect(() => {
        getAndSetFileType(url);
    }, []);

    return (
        <div>
            {renderPreview()}
        </div>
    );
};
