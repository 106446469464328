import React from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import Button from '../../components/common/button';
import { SET_SHARE_TO_SIGN } from '../../store/actions';
import ResourceThumbnail from '../resource/resource-thumbnail';

export default function ShareCard({ share, signatures = [], hideSignaturePrompt = false}) {
    const dispatcher = useDispatch();

    const openSignatureForm = (e, share) => {
        e.stopPropagation();
        dispatcher({
            type: SET_SHARE_TO_SIGN,
            payload: { share },
        });
    };
    
    return (
        <Box 
            borderRadius={2}
            p={2}
            textAlign="center"
            sx={{
                cursor: 'pointer',
                background: '#FFFFFF',
                fontFamily: 'Nunito',
                fontWeight: 600,
                fontSize: '20px',
                border: '3px solid #FFFFFF',
                boxShadow: 'none',
                position: 'relative',
            }}
            onClick={() => window.open(share.resource.file_url, '_blank')}
        >
            <Box height={200}><ResourceThumbnail url={share.resource.file_url} height="100%" /></Box>
            <Box mt={2}>
                {share.resource.title}
            </Box>
            {share.resource.requires_signature && !hideSignaturePrompt && (
                <Box mt={2}>
                    {signatures.length ? (
                        <Button
                            shade="secondaryLight"
                            onClick={() => window.open(signatures[signatures.length - 1].signed_file_url, '_blank')}
                        >
                            Signed
                        </Button>
                    ) : (
                        <Button shade="secondary" onClick={(e) => openSignatureForm(e, share)}>Review + Sign</Button>
                    )}
                </Box>
            )}
        </Box>
    );
}
