import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, CircularProgress, IconButton } from '@mui/material';

import { get } from '../../services/api.services';
import { API } from '../../constants';
import { SET_NOTIFICATIONS } from '../../store/actions';
import { markNotificationRead } from '../../clients/notification';
import DismissIcon from '../../assets/icons/Dismiss.svg';

import {
  DrawerTitle,
} from '../session/Session.style';

import {
  NotificationText,
} from './notifications.style.js';

import Notification from './notification';

function Notifications({ folderId, textOnly, onDismiss }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const token = useSelector((state) => state.account.token);
    const notificationIds = useSelector((state) => state.notifications.notificationIds);
    const notificationsById = useSelector((state) => state.notifications.notificationsById);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const fetchNotifications = async () => {
      const result = await get(
          API.NOTIFICATION,
          token,
    );

      if (result.status === 200) {
          if (result.data.length) {
              dispatcher({
                type: SET_NOTIFICATIONS,
                payload: {
                  notifications: result.data,
                },
              });
          }
          setLoading(false);
      }
      else {
          setError(true);
          setLoading(false);
      }
  };

  const notificationsByDate = {};
  const filteredNotificationIds = notificationIds.filter((id) => (!folderId || (folderId && notificationsById[id].folder?.id === folderId)));

  if (filteredNotificationIds) {
    for (let i = 0; i < filteredNotificationIds.length; i++) {
      const formattedDate = moment(notificationsById[filteredNotificationIds[i]].created_at).local().format('MMM Do, YYYY');
      if (!notificationsByDate[formattedDate]) {
        notificationsByDate[formattedDate] = [];
      }
      notificationsByDate[formattedDate].push(notificationsById[filteredNotificationIds[i]]);
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    notificationIds.filter((id) => !notificationsById[id].read).forEach((id) => {
      markNotificationRead(dispatcher, token, notificationsById[id]);
    });
  }, [notificationIds]);

  return (
    <Box sx={{ width: 350, maxWidth: '100%', background: '#F4F2F2', p: 3, height: '100%', overflowY: 'scroll', }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}>
            <DrawerTitle>
              Notifications
            </DrawerTitle>
            {onDismiss && (
              <IconButton onClick={onDismiss}>
                <img src={DismissIcon} alt="close" width={12} height={12} />
              </IconButton>
            )}
        </Box>
        <Box>
          {filteredNotificationIds.length === 0 && loading && (
            <Box sx={{ mt: 4, textAlign: 'center', height: '100%' }}>
                <CircularProgress style={{ color: '#5371ff' }} />
            </Box>
          )}
          {filteredNotificationIds.length > 0 && (
            <>
              {Object.keys(notificationsByDate).map((key) => (
                <Box key={key}>
                  <Box sx={{ mb: 1 }}>
                    <NotificationText style={{ fontWeight: 500 }}>
                      {key}
                    </NotificationText>
                  </Box>
                  {notificationsByDate[key].map((notification) => (
                    <Box
                      key={notification.id}
                      sx={{
                        background: '#FFFFFF',
                        borderRadius: 2,
                        p: 2,
                        mb: 1,
                      }}
                      onClick={() => markNotificationRead(dispatcher, token, notification)}
                    >
                      <Notification notification={notification} />
                    </Box>
                  ))}
                </Box>
              ))}
            </>
          )}
          {!loading && filteredNotificationIds.length === 0 && (
            <Box p={2} mb={1} borderRadius={2} textAlign="center" sx={{ background: '#FFFFFF' }}>
              <NotificationText style={{ fontWeight: 500 }}>
                You're all set!
              </NotificationText>
            </Box>
          )}
        </Box>
    </Box>
  );
}

export default Notifications;
