import axios from 'axios';

import { API } from '../constants';

export const uploadToS3 = async (token, path, file, fileType = null) => {
    try {
        const presignedUrlResponse = await axios.get(
            API.S3 + `/?key=${encodeURIComponent(path)}&fileType=${encodeURIComponent(file.type)}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        let response = await axios.put(
            presignedUrlResponse.data.presignedUrl,
            file,
            {
                headers: {
                    'Content-Type': fileType || file.type,
                },
            },
        );

        return true;
    }
    catch (err) {
        return false;
    }
}