import React from 'react';
import { Box, Button, Card, Chip, CardContent, Stack, Typography } from '@mui/material';

function Subscription({ subscription, isCurrentSubscription, onSelectPlan, cancelInProgress, onCancelPlan, selectedCadence }) {
    return (
        <Card variant="outlined">
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: 16, fontFamily: 'Nunito' }} color="text.secondary" gutterBottom>
                    {subscription.title}
                </Typography>
                <Typography variant="h5" component="div">
                    ${subscription.priceMap[selectedCadence].amount}{selectedCadence !== 'default' ? ` / ${selectedCadence}` : ''}
                </Typography>
                <Box mt={2} mb={2} sx={{ textAlign: 'center' }}>
                    {isCurrentSubscription ? (
                        <Box display="flex" justifyContent="center">
                            <Box mr={1}><Button variant="contained" color="primary" disabled="true">{!cancelInProgress ? 'Current Plan' : 'Canceling Soon'}</Button></Box>
                            {/*!cancelInProgress && (<Button variant="text" color="primary" onClick={onCancelPlan}>Cancel</Button>)*/}
                            {cancelInProgress && (<Button variant="contained" color="secondary" onClick={onSelectPlan}>Reactivate Plan</Button>)}
                        </Box>
                    ) : (
                        <>
                            {subscription.priceMap[selectedCadence].map === 0 && cancelInProgress ? (
                                <Button variant="contained" color="primary" disabled="true">New Plan After Current Billing Cycle</Button>
                            ) : (
                                <>
                                    {subscription.ctaHref ? (
                                        <Button variant="contained" color="primary" href={subscription.ctaHref}>{subscription.ctaText}</Button>
                                    ) : (
                                        <Button variant="contained" color="primary" onClick={onSelectPlan}>{subscription.ctaText}</Button>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Box>
                <Box sx={{ textAlign: 'left', fontFamily: 'Open Sans' }}>
                    {subscription.listingFeatures?.length > 0 && (
                        <Box>
                            <Stack spacing={1}>
                                <Typography variant="subtitle1"><b>{/*Listing*/}</b></Typography>
                                {subscription.listingFeatures.map((feature) => (
                                    <Typography variant="body2">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box mr={1}>{feature.renderIcon()}</Box>
                                            {feature.title}
                                        </Box>
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    )}
                    {subscription.fulfillmentFeatures?.length > 0 && (
                        <Box mt={2}>
                            <Stack spacing={1}>
                                <Typography variant="subtitle1"><b>Features</b></Typography>
                                {subscription.fulfillmentFeatures.map((feature) => (
                                    <Typography variant="body2">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box mr={1}>{feature.renderIcon()}</Box>
                                            {feature.title}
                                        </Box>
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    )}
                    {subscription.developmentFeatures?.length > 0 && (
                        <Box mt={2}>
                            <Stack spacing={1}>
                                <Typography variant="subtitle1"><b>Professional Development</b></Typography>
                                {subscription.developmentFeatures.map((feature) => (
                                    <Typography variant="body2">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box mr={1}>{feature.renderIcon()}</Box>
                                            {feature.title}
                                        </Box>
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    )}
                    {subscription.parentFeatures?.length > 0 && (
                        <Box mt={2}>
                            <Stack spacing={1}>
                                {subscription.parentFeatures.map((feature) => (
                                    <Typography variant="body2">
                                        <Box display="flex" alignItems="flex-start">
                                            <Box mr={1}>{feature.renderIcon()}</Box>
                                            {feature.title}
                                        </Box>
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};

export default Subscription;