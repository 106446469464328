import { useDispatch } from 'react-redux';

// npm imports
import { Box, Button, Grid, Stack } from '@mui/material';

// local imports
import LogoFilled from '../../assets/images/parentswarm-logo.svg';
import { QuizQuestion } from './styled_components/match.style';
import { BaseSeparator } from './styled_components/separator.style';
import {
  RegistrationText,
  ButtonContainer,
} from './styled_components/response.style';
import { PrevButtonSX, NextButtonSX } from './utils/buttonSX';
import ProductCard from '../../components/product/product-card';
import { OPEN_PRODUCT } from '../../store/actions';
import ExpertRow from '../../components/experts/expert-row';

export default function Response({ matches, listings, next }) {
  const dispatcher = useDispatch();

  const displayProduct = (product, bookNow) => {
    dispatcher({
        type: OPEN_PRODUCT,
        payload: {
            product,
            bookNow,
        },
      });
  };

  let resultCount = 0;

  if (matches?.length) {
    resultCount += matches.length;
  }

  if (listings?.length) {
    resultCount += listings?.length;
  }

  return (
    <Stack spacing={2} sx={{ alignItems: 'center' }}>
      <QuizQuestion>
        We have {matches.length + listings.length} recommendations for you today.
      </QuizQuestion>
      <RegistrationText>
      </RegistrationText>
      <BaseSeparator />
      <Grid container spacing={1}>
        {listings?.map((listing) => (
          <ExpertRow profile={listing} sm={4} md={4} lg={4} xl={4} />
        ))}
        {matches?.sort((a, b) => a.price - b.price).map((match) => (
          <Grid key={match.id} item xs={12} sm={4}>
            <ProductCard
              variant="normal"
              product={match}
              secondaryCtaAction={() => window.open(`/r/${match.seller.display_id}/`, '_blank')}
              primaryCtaAction={() => window.open(`/r/${match.seller.display_id}/`, '_blank')}
            />
          </Grid>
        ))}
      </Grid>
      <ButtonContainer>
        <Button onClick={next} sx={PrevButtonSX}>
          End Quiz
        </Button>
      </ButtonContainer>
    </Stack>
  );
}
