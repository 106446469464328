import { Box, Typography } from '@mui/material';

import {
    ProductLabel,
    ProductValue,
} from './product.style';

interface ProductDetailLineProps {
    label: string;
    value: any;
    firstLine?: boolean;
    description?: string;
}

export default function ProductDetailLine({ label, value, description, firstLine }: ProductDetailLineProps) {
    return (
        <Box>
            <Box
                sx={{
                    mt: 1,
                    mb: 1,
                    pt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: firstLine ? 'none' : '1px solid #E8E8E8',
                }}
            >
                <ProductLabel>{label}</ProductLabel>
                <ProductValue>{value}</ProductValue>
            </Box>
            <Box>
                <Typography variant="caption">{description}</Typography>
            </Box>
        </Box>
    );
}