/** @format */
/*eslint-disable */

import axios from 'axios';

export const patch = async(url, payload, token = null) => {
    try {
        return await axios.patch(url, payload, {
            headers: token ? { Authorization: `Bearer ${token}`} : {},
        });
    } catch (error) {
        return error.response;
    }
};

export const post = async(url, payload, token = null) => {
    try {
        return await axios.post(url, payload, {
            headers: token ? { Authorization: `Bearer ${token}`} : {},
        });
    } catch (error) {
        return error.response;
    }
};

export const get = async(url, token = null) => {
    try {
        return await axios.get(url, {
            headers: token ? { Authorization: `Bearer ${token}`} : {},
        });
    } catch (error) {
        return error.response;
    }
};

export const destroy = async(url, token = null) => {
    try {
        return await axios.delete(url, {
            headers: token ? { Authorization: `Bearer ${token}`} : {},
        });
    } catch (error) {
        return error.response;
    }
};