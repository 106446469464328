import React from 'react';

import { RowText, ParticipantMax } from './group-session.style';

function ParticipantCount({ product }) {
  return (
        <RowText>
            {product.attendees.length}{product.max_sales ? <ParticipantMax>/{product.max_sales}</ParticipantMax> : ''} Participants
        </RowText>
    );
}

export default ParticipantCount;