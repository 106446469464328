import {
    ResourceActionType
} from '../store/actions';

import { get, destroy } from '../services/api.services';
import { API } from '../constants';

export const fetchResources = async (dispatcher, navigate, token, publicResources = false) => {
    const result = await get(
        `${API.RESOURCE}?is_public=${publicResources}`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        dispatcher({
            type: ResourceActionType.RESET_RESOURCE_IDS,
        });

        result.data.forEach((resource) => {
            dispatcher({
                type: ResourceActionType.SET_RESOURCE,
                payload: {
                    resource,
                },
            });

            dispatcher({
                type: ResourceActionType.ADD_RESOURCE_ID,
                payload: {
                    resource,
                },
            });
        });
    }
}

export const deleteResource = async (dispatcher, navigate, token, resource) => {
    const result = await destroy(
        `${API.RESOURCE}${resource.id}`,
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    } else if (result.status === 204) {
        dispatcher({
            type: ResourceActionType.REMOVE_RESOURCE_ID,
            payload: {
                resource,
            }
        });
    }
}
