import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import TosImg from '../../assets/images/agreed-to-tos.png';
import { updateUser } from '../../clients/profile';
import { useAuth } from '../../hooks';

interface AgreedToTosProps {}

export default function AgreedToTos({}: AgreedToTosProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const [agreed, setAgreed] = useState(false);

    const markAgreed = () => {
        const now = new Date();
        updateUser(dispatcher, navigate, user, token, { date_agreed_to_tos: `${now.getFullYear()}-${(`0${now.getMonth() + 1}`).slice(-2)}-${now.getDate()}` });
    };

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Review our Terms of Service</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Review the Parentswarm Supplier Terms of Service
                                <Box
                                    display="inline"
                                    ml={-1}
                                    mr={-1}
                                >
                                    <Button
                                        size="large"
                                        onClick={() => window.open('https://assets-global.website-files.com/62b6328770c172ec59ea167d/6539e9a2f1b0c2065c190748_Nessle%20-%20Terms%20of%20Service%20and%20Consultant%20Agreement%20(TC%2010.24.23).docx.pdf', '_blank')}
                                    >
                                        here
                                    </Button>
                                </Box>
                            </li>
                        </ul>
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agreed}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setAgreed(true);
                                        markAgreed();
                                    }
                                }}
                            />
                        }
                        label="I agree to the Terms of Service"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%', border: '2px solid #5371ff', borderRadius: '8px', paddingBottom: '20px' }} src={TosImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
