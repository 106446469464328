/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const HeroTitle = styled.div`
    color: #110229;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 60px;
`;

export const HeroText = styled.p`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #585981;
`;