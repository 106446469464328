import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

import { State } from '../../types';
import SocialPostImg from '../../assets/images/social-post.png';

interface SocialAnnouncementProps {
    markedComplete: boolean,
    markStepComplete: Function,
    unmarkStepComplete: Function,
}

export default function SocialAnnouncment({ markedComplete, markStepComplete, unmarkStepComplete }: SocialAnnouncementProps) {
    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Announce Your Listing Page</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Download your
                                <Button variant="text" onClick={() => window.open('https://app.nessle.com/resources/17', '_blank')}>
                                    Parentswarm Digital Business Kit
                                </Button>{' '}
                                for Instagram templates and other assets you can use to announce your page.
                            </li>
                        </ul>
                    </Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={markedComplete}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        markStepComplete();
                                    } else {
                                        unmarkStepComplete();
                                    }
                                }}
                            />
                        }
                        label="I have announced my listing"
                    />
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={SocialPostImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
