// action - state management
import { PaymentPlanActionType } from "./actions";
import { PaymentPlan } from "../types";
import { string } from "prop-types";

export const initialState = {
  defaultPaymentPlanId: undefined,
  paymentPlansById: {},
  paymentPlanIds: [],
};

type PaymentPlanAction =
  | { type: PaymentPlanActionType.SET_PAYMENT_PLAN; payload: { paymentPlan: PaymentPlan } }
  | { type: PaymentPlanActionType.ADD_PAYMENT_PLAN_ID; payload: { paymentPlan: PaymentPlan } }
  | { type: PaymentPlanActionType.RESET_PAYMENT_PLAN_IDS }
  | { type: PaymentPlanActionType.REMOVE_PAYMENT_PLAN_ID; payload: { paymentPlan: PaymentPlan } }

const paymentPlanReducer = (state = initialState, action: PaymentPlanAction) => {
  switch (action.type) {
    case PaymentPlanActionType.SET_PAYMENT_PLAN: {
      const { paymentPlan } = action.payload;
      const newPaymentPlansById = { ...state.paymentPlansById };
      newPaymentPlansById[paymentPlan.id] = paymentPlan;
      return {
        ...state,
        paymentPlansById: newPaymentPlansById,
        defaultPaymentPlanId: paymentPlan.is_default ? paymentPlan.id : state.defaultPaymentPlanId,
      };
    }
    case PaymentPlanActionType.ADD_PAYMENT_PLAN_ID: {
      const { paymentPlan } = action.payload;
      return {
        ...state,
        paymentPlanIds: [...state.paymentPlanIds, paymentPlan.id],
      };
    }
    case PaymentPlanActionType.RESET_PAYMENT_PLAN_IDS: {
      return {
        ...state,
        paymentPlanIds: [],
      };
    }
    case PaymentPlanActionType.REMOVE_PAYMENT_PLAN_ID: {
        const { paymentPlan } = action.payload;
        return {
            ...state,
            paymentPlanIds: [ ...state.paymentPlanIds ].filter((id) => id !== paymentPlan.id),
        }
    }
    default: {
      return { ...state };
    }
  }
};

export default paymentPlanReducer;
