// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const SET_USER = 'SET_USER';
export const SET_VERIFIED = 'SET_VERIFIED';
export const SET_PAYWALL = 'SET_PAYWALL';

// action - search reducer
export const BUMP_PAGE = 'BUMP_PAGE';
export const SET_HAS_NEXT_PAGE = 'SET_HAS_NEXT_PAGE';
export const SET_FILTERS = 'SET_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

// action - profile reducer
export const SET_PROFILE = 'SET_PROFILE';
export const ADD_EXPERT_PROFILE_ID = 'ADD_EXPERT_PROFILE_ID';
export const RESET_EXPERT_PROFILE_IDS = 'RESET_EXPERT_PROFILE_IDS';

// action - session reducer
export const SET_SESSION = 'SET_SESSION';
export const ADD_SESSION_ID = 'ADD_SESSION_ID';
export const RESET_SESSION_IDS = 'RESET_SESSION_IDS';
export const ADD_FOLDER_SESSION_ID = 'ADD_FOLDER_SESSION_ID';
export const RESET_FOLDER_SESSION_IDS = 'RESET_FOLDER_SESSION_IDS';

// action - event reducer
export enum EventActionType {
  SET_EVENT = "SET_EVENT",
  ADD_EVENT_ID = "ADD_EVENT_ID",
  RESET_EVENT_IDS = "RESET_EVENT_IDS",
  ADD_FOLDER_EVENT_ID = "ADD_FOLDER_EVENT_ID",
  RESET_FOLDER_EVENT_IDS = "RESET_FOLDER_EVENT_IDS",
}
// export const SET_EVENT = 'SET_EVENT';
// export const ADD_EVENT_ID = 'ADD_EVENT_ID';
// export const RESET_EVENT_IDS = 'RESET_EVENT_IDS';
// export const ADD_FOLDER_EVENT_ID = 'ADD_FOLDER_EVENT_ID';
// export const RESET_FOLDER_EVENT_IDS = 'RESET_FOLDER_EVENT_IDS';

// action - notification reducer
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

// action - match reducer
export const SET_ANSWERS = 'SET_ANSWERS';

// action - coupon reducer
export const SET_COUPON_CODE = 'SET_COUPON_CODE';

// action - gift card reducer
export const SET_GIFT_CARD_INFO = 'SET_GIFT_CARD_INFO';
export const RESET_GIFT_CARD_INFO = 'RESET_GIFT_CARD_INFO';

// action - product reducer
export const SET_LOADING = 'SET_LOADING';
export const OPEN_PRODUCT = 'OPEN_PRODUCT';
export const CLOSE_PRODUCT = 'CLOSE_PRODUCT';
export const SET_PRODUCT = 'SET_PRODUCT';
export const ADD_PRODUCT_ID = 'ADD_PRODUCT_ID';
export const RESET_PRODUCT_IDS = 'RESET_PRODUCT_IDS';
export const RESET_PRODUCT_IDS_FOR_SELLER = 'RESET_PRODUCT_IDS_FOR_SELLER';

// action - folder reducer
export const SET_FOLDER = 'SET_FOLDER';
export const ADD_FOLDER_ID = 'ADD_FOLDER_ID';
export const RESET_FOLDER_IDS = 'RESET_FOLDER_IDS';

// action - share reducer
export const SET_SHARE_TO_SIGN = 'SET_SHARE_TO_SIGN';

// action - resource reducer
export enum ResourceActionType {
  SET_RESOURCE = "SET_RESOURCE",
  ADD_RESOURCE_ID = "ADD_RESOURCE_ID",
  REMOVE_RESOURCE_ID = "REMOVE_RESOURCE_ID",
  RESET_RESOURCE_IDS = "RESET_RESOURCE_IDS",
}

// action - payemnt plan reducer
export enum PaymentPlanActionType {
  SET_PAYMENT_PLAN = "SET_PAYMENT_PLAN",
  ADD_PAYMENT_PLAN_ID = "ADD_PAYMENT_PLAN_ID",
  RESET_PAYMENT_PLAN_IDS = "RESET_PAYMENT_PLAN_IDS",
  REMOVE_PAYMENT_PLAN_ID = "REMOVE_PAYMENT_PLAN_ID",
}

// action - payment reducer
export enum PaymentActionType {
  SET_PAYMENT = "SET_PAYMENT",
  ADD_PAYMENT_ID = "ADD_PAYMENT_ID",
  RESET_PAYMENT_IDS = "RESET_PAYMENT_IDS",
  REMOVE_PAYMENT_ID = "REMOVE_PAYMENT_ID",
}

// action - version reducer
export enum VersionActionType {
  SET_LATEST_VERSION = "SET_LATEST_VERSION",
  SET_CURRENT_VERSION = "SET_CURRENT_VERSION",
}