import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { INSURANCE_TAGS } from '../../utils/insurance.js';
import Chip from '../../components/common/chip';
import { ModalFormItem, ModalFormItemLabel } from './product.style';
import { PRODUCT_TYPES, CANCELATION_POLICIES } from '../../constants';
import PaymentScheduleForm from '../../pages/payment-settings/payment-schedule-form';

export default function Details({
  price,
  setPrice,
  durationHours,
  setDurationHours,
  durationMins,
  setDurationMins,
  maxParticipants,
  setMaxParticipants,
  productType,
  inPerson,
  setInPerson,
  virtual,
  setVirtual,
  link,
  setLink,
  location,
  setLocation,
  generalAccess,
  setGeneralAccess,
  sharedWithFolderIds,
  setSharedWithFolderIds,
  generateMeetingLink,
  setGenerateMeetingLink,
  folders,
  defaultPaymentPlan,
  setDefaultPaymentPlan,
  additionalPaymentPlansOffered,
  setAdditionalPaymentPlansOffered,
  cancelationPolicy,
  setCancelationPolicy,
  insuranceOptions,
  setInsuranceOptions,
}) {
  const user = useSelector((state) => state.account.user);
  const foldersById = useSelector((state) => state.folders.foldersById);
  const paymentPlanIds = useSelector(
    (state) => state.paymentPlans.paymentPlanIds,
  );
  const paymentPlansById = useSelector(
    (state) => state.paymentPlans.paymentPlansById,
  );

  const [addPaymentPlans, setAddPaymentPlans] = useState(false);
  const [openPaymentPlanForm, setOpenPaymentPlanForm] = useState(false);

  const initialPaymentIsGreaterThanPrice = (paymentPlan) => {
    return (
      paymentPlansById[paymentPlan].first_payment_value_type === 'fixed' &&
      paymentPlansById[paymentPlan].first_payment_amount > price
    );
  };

  const paymentPlanExceedsPrice = () => {
    if (initialPaymentIsGreaterThanPrice(defaultPaymentPlan)) {
      return true;
    }

    additionalPaymentPlansOffered.forEach((paymentPlan) => {
      if (initialPaymentIsGreaterThanPrice(paymentPlan)) {
        return true;
      }
    });

    return false;
  };

  return (
    <Stack spacing={2} alignItems="center">
      <ModalFormItem>
        <ModalFormItemLabel>Price</ModalFormItemLabel>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Slider
            value={price}
            aria-label="price"
            onChange={(e) => setPrice(e.target.value)}
            step={5}
            min={0}
            max={250}
            sx={{ width: '50%', color: '#5371ff' }}
          />
          <TextField
            id="details-text"
            variant="outlined"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            sx={{ width: 80, ml: 4 }}
            InputProps={{
              maxLength: 10,
              startAdornment: (
                <InputAdornment position="start">
                  <span>$</span>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </ModalFormItem>
      <ModalFormItem>
        <ModalFormItemLabel>Default Payment Plan</ModalFormItemLabel>
        <Box>
          <FormControl fullWidth>
            <Select
              id="default-payment-plan"
              value={defaultPaymentPlan}
              onChange={(e) => {
                setDefaultPaymentPlan(e.target.value);
                setAdditionalPaymentPlansOffered(
                  [...additionalPaymentPlansOffered].filter(
                    (id) => id !== e.target.value,
                  ),
                );
              }}
              sx={{ width: '100%', color: 'black' }}
            >
              {paymentPlanIds.map((id) => (
                <MenuItem value={id}>{paymentPlansById[id].name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!addPaymentPlans && additionalPaymentPlansOffered.length === 0 && (
          <Box display="flex" justifyContent="flex-start">
            <Button onClick={() => setAddPaymentPlans(true)} size="small">
              + Offer additional payment plans
            </Button>
          </Box>
        )}
      </ModalFormItem>
      {(addPaymentPlans || additionalPaymentPlansOffered.length > 0) && (
        <ModalFormItem>
          <ModalFormItemLabel>
            <Box display="flex" alignItems="center">
              Additional Payment Plans Offered
              <IconButton onClick={() => setOpenPaymentPlanForm(true)}>
                <AddCircleOutlineRoundedIcon fontSize="small" />
              </IconButton>
            </Box>
          </ModalFormItemLabel>
          <Box>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="payment-plans-offered"
                options={paymentPlanIds.filter(
                  (id) => id !== defaultPaymentPlan,
                )}
                value={additionalPaymentPlansOffered}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option}
                      color="secondaryLight"
                      label={paymentPlansById[option].name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                getOptionLabel={(option) => paymentPlansById[option].name}
                sx={{
                  width: '100%',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Choose one or more payment plans to offer"
                  />
                )}
                onChange={(event, newValue) => {
                  setAdditionalPaymentPlansOffered(newValue);
                }}
              />
            </FormControl>
          </Box>
          <Modal
            open={openPaymentPlanForm}
            onClose={() => setOpenPaymentPlanForm(false)}
          >
            <PaymentScheduleForm
              onClose={() => setOpenPaymentPlanForm(false)}
            />
          </Modal>
        </ModalFormItem>
      )}
      {paymentPlanExceedsPrice() && (
        <Box width="100%" pl={4} pr={4}>
          <Alert color="error">
            A payment plan you've chosen exceeds the price of this service
          </Alert>
        </Box>
      )}
      <ModalFormItem>
        <ModalFormItemLabel>
          <Box display="flex" alignItems="center">
            Cancellation Policy
            <Tooltip
              title={
                <React.Fragment>
                  {CANCELATION_POLICIES.map((policy) => (
                    <Box mb={1}>
                      <Typography variant="subtitle1">
                        {policy.title}
                      </Typography>
                      <Typography variant="caption">
                        {policy.description}
                      </Typography>
                    </Box>
                  ))}
                </React.Fragment>
              }
            >
              <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5 }} />
            </Tooltip>
          </Box>
        </ModalFormItemLabel>
        <Box>
          <FormControl fullWidth>
            <Select
              id="cancelation-policy"
              value={cancelationPolicy}
              onChange={(e) => setCancelationPolicy(e.target.value)}
              sx={{ width: '100%', color: 'black' }}
            >
              <MenuItem value="strict">Strict</MenuItem>
              <MenuItem value="moderate">Moderate</MenuItem>
              <MenuItem value="flexible">Flexible</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </ModalFormItem>
      {productType === PRODUCT_TYPES.GROUP && (
        <ModalFormItem>
          <ModalFormItemLabel>Max participants</ModalFormItemLabel>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Slider
              value={maxParticipants}
              aria-label="price"
              onChange={(e) => setMaxParticipants(e.target.value)}
              step={5}
              min={0}
              max={250}
              sx={{ width: '50%', color: '#5371ff' }}
            />
            <TextField
              id="participants-text"
              placeholder="Unlimited"
              variant="outlined"
              value={maxParticipants}
              onChange={(e) => setMaxParticipants(e.target.value)}
              sx={{ width: 80, ml: 4 }}
              inputProps={{ maxLength: 3 }}
            />
          </Box>
        </ModalFormItem>
      )}
      {productType !== PRODUCT_TYPES.RESOURCE &&
        productType !== PRODUCT_TYPES.PACKAGE && (
          <ModalFormItem>
            <ModalFormItemLabel>Session Duration</ModalFormItemLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                id="hours-text"
                variant="outlined"
                value={durationHours}
                onChange={(e) => setDurationHours(e.target.value)}
                sx={{ width: 48, mr: 2 }}
                inputProps={{ maxLength: 2 }}
              />
              <Box sx={{ mr: 2 }}>Hours : </Box>
              <TextField
                id="mins-text"
                variant="outlined"
                value={durationMins}
                onChange={(e) => setDurationMins(e.target.value)}
                sx={{ width: 48, mr: 2 }}
                inputProps={{ maxLength: 2 }}
              />
              <Box>Mins</Box>
            </Box>
          </ModalFormItem>
        )}
      {productType !== PRODUCT_TYPES.RESOURCE && (
        <ModalFormItem>
          <ModalFormItemLabel>Session Location</ModalFormItemLabel>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={virtual}
                  onChange={(e) => setVirtual(e.target.checked)}
                />
              }
              label="Virtual"
            />
            <Box ml={4}>
              {(productType === PRODUCT_TYPES.SINGLE ||
                productType === PRODUCT_TYPES.GROUP) &&
                virtual && (
                  <ModalFormItem>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={generateMeetingLink}
                            onChange={(e) =>
                              setGenerateMeetingLink(e.target.checked)
                            }
                          />
                        }
                        label="Automatically generate meeting link"
                      />
                    </Box>
                  </ModalFormItem>
                )}
              {productType === PRODUCT_TYPES.GROUP &&
                !generateMeetingLink &&
                virtual && (
                  <ModalFormItem>
                    <ModalFormItemLabel>
                      Provide my own meeting link (add virtual Google Meet, Zoom
                      or Agora link)
                    </ModalFormItemLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        id="meeting-link"
                        variant="outlined"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                        sx={{ width: '100%' }}
                        inputProps={{ maxLength: 128 }}
                      />
                    </Box>
                  </ModalFormItem>
                )}
            </Box>
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={inPerson}
                  onChange={(e) => setInPerson(e.target.checked)}
                />
              }
              label="In Person"
            />
            <Box ml={4}>
              {(productType === PRODUCT_TYPES.GROUP ||
                productType === PRODUCT_TYPES.SINGLE) &&
                inPerson && (
                  <ModalFormItem>
                    <ModalFormItemLabel>Location</ModalFormItemLabel>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <TextField
                        id="location"
                        variant="outlined"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        sx={{ width: '100%' }}
                        inputProps={{ maxLength: 128 }}
                      />
                    </Box>
                  </ModalFormItem>
                )}
            </Box>
          </Box>
        </ModalFormItem>
      )}
      <ModalFormItem>
        <ModalFormItemLabel>General Access</ModalFormItemLabel>
        <Box>
          <FormControl fullWidth>
            <Select
              id="general-access"
              value={generalAccess === 'public' ? 'public' : 'private'}
              onChange={(e) => setGeneralAccess(e.target.value)}
              sx={{ width: '100%', color: 'black' }}
            >
              <MenuItem value="public">
                Public (Searchable, visible on your profile and shareable via
                link)
              </MenuItem>
              <MenuItem value="private">Private</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </ModalFormItem>
      {generalAccess !== 'public' && (
        <ModalFormItem>
          <ModalFormItemLabel>Grant Access To</ModalFormItemLabel>
          <Box mt={-2}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="shared-with"
                options={[
                  {
                    label: 'My clients',
                    value: 'clients',
                  },
                  {
                    label: 'Anyone with link',
                    value: 'link',
                  },
                  ...folders.map((folder) => ({
                    label: folder.name,
                    value: folder.id,
                  })),
                ].filter(
                  (opt) => sharedWithFolderIds.indexOf(opt.value) === -1,
                )}
                value={sharedWithFolderIds}
                renderTags={(value, getTagProps) => {
                  return value.map((val, index) => {
                    if (val === 'clients') {
                      return (
                        <Chip
                          key={val}
                          color="primaryLight"
                          label="My clients"
                          tagProps={{ ...getTagProps({ index }) }}
                        />
                      );
                    } else if (val === 'link') {
                      return (
                        <Chip
                          key={val}
                          color="primaryLight"
                          label="Anyone with link"
                          tagProps={{ ...getTagProps({ index }) }}
                        />
                      );
                    } else {
                      return (
                        <Chip
                          key={val}
                          color="primaryLight"
                          label={foldersById[val].name}
                          tagProps={{ ...getTagProps({ index }) }}
                        />
                      );
                    }
                  });
                }}
                sx={{
                  width: '100%',
                  mt: 2,
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
                onChange={(event, newValue) => {
                  setSharedWithFolderIds(
                    newValue.map((opt) => {
                      console.log(opt);
                      if (opt.value) {
                        return opt.value; // Most recently selected comes in as the option dictionary
                      }
                      return opt; // Previous selections are ids
                    }),
                  );
                }}
              />
            </FormControl>
          </Box>
        </ModalFormItem>
      )}
      <ModalFormItem>
        <Box display="flex">
          <ModalFormItemLabel>Reimbursement Accepted</ModalFormItemLabel>

          <a
            href="https://docs.google.com/document/d/1XRtTX8e0ZdLNTbcE-JkMmBjxZzkAZtzHvnFBiWv5Flg/edit?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            <InfoOutlinedIcon fontSize="small" style={{ marginLeft: 5 }} />
          </a>
        </Box>
        <Box>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="insurance"
              options={INSURANCE_TAGS.filter(
                (opt) => insuranceOptions.indexOf(opt.value) === -1,
              )} // if Expert hasn't selected it as an insurance option, display the options in the drop down menu
              getOptionLabel={(option) => option.label}
              value={insuranceOptions}
              sx={{ width: '100%' }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
              onChange={(event, newValue) => {
                setInsuranceOptions(
                  newValue.map((opt) => {
                    if (opt.value) {
                      return opt.value;
                    }
                    return opt;
                  }),
                );
              }}
              renderTags={(value, getTagProps) => {
                return value.map((val, index) => {
                  if (val === 'hsa') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="HSA Eligible"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  } else if (val === 'hra') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="HRA Eligible"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  } else if (val === 'fsa') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="FSA Eligible"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  } else if (val === 'medicare') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="Medicare"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  } else if (val === 'medicaid') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="Medicaid"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  } else if (val === 'private') {
                    return (
                      <Chip
                        key={val}
                        color="primaryLight"
                        label="Private Insurance"
                        tagProps={{ ...getTagProps({ index }) }}
                      />
                    );
                  }
                });
              }}
            />
          </FormControl>
        </Box>
      </ModalFormItem>
    </Stack>
  );
}
