/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const NoteDate = styled.div`
  text-align: right;
  font-family: 'Nunito';
  font-weight: 500;
  font-size: 14px;
`;

export const NoteTitle = styled.div`
    font-family: 'Nunito';
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
`;

export const NoteBody = styled.div`
  font-family: 'Nunito';
  font-weight: 500;
  font-size: 14px;
`;