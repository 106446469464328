import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import config from '../../config';

import {
    Box,
    Container,
    Grid,
    TextField,
    Stack,
} from '@mui/material';

import Button from '../../components/common/button';
import Header from '../../components/header/logged-out';
import Footer from '../../components/footer/logged-out';
import Scheduler from '../../components/common/scheduler';

import GiftCardImg from '../../assets/images/parentswarm-gift-card.png';
import { SET_GIFT_CARD_INFO, RESET_GIFT_CARD_INFO } from '../../store/actions';

function GiftCardPurchase({}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const price = params.price;
    const [recipientName, setRecipientName] = useState('');
    const [recipientEmail, setRecipientEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [scheduledAt, setScheduledAt] = useState();

    const devPriceMap = {
        '25': 'https://buy.stripe.com/test_28oeV28aj1GveHK004',
        '50': 'https://buy.stripe.com/test_5kA8wEduDetharufZ1',
        '100': 'https://buy.stripe.com/test_dR6fZ6gGPad14365kp',
        '150': 'https://buy.stripe.com/test_dR6aEM2PZ3OD8jmdQW',
    };

    const prodPriceMap = {
        '25': 'https://buy.stripe.com/9AQ4jvfqC8LmgGk5kk',
        '50': 'https://buy.stripe.com/9AQ5nz2DQaTugGk9AB',
        '100': 'https://buy.stripe.com/8wM7vH5Q28Lm9dSeUW',
        '150': 'https://buy.stripe.com/aEU5nz1zM0eQ2Pu28b',
    };

    const priceMap = config.currentEnv === 'production' ? prodPriceMap : devPriceMap;

    const onCheckout = () => {
        dispatcher({
            type: SET_GIFT_CARD_INFO,
            payload: {
                amount: price,
                recipientName,
                recipientEmail,
                message,
                sendDate: scheduledAt,
            },
        });
    
        window.location = priceMap[price];
    };

    const reset = () => {
        dispatcher({
            type: RESET_GIFT_CARD_INFO,
            payload: {},
        });
    };

    useEffect(() => {
        reset();
    }, []);

    return (
        <>
            <Header />
            <Container>
                <Box pt={4} pb={4} sx={{ fontFamily: 'Nunito' }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={GiftCardImg} alt="Parentswarm gift card" width={325} style={{ borderRadius: 8 }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <h1>Parentswarm Gift Card</h1>
                            <h2>${price} USD</h2>
                            <Stack spacing={2}>
                                <TextField
                                    id="recipient-name"
                                    variant="outlined"
                                    value={recipientName}
                                    onChange={(e) => setRecipientName(e.target.value)}
                                    inputProps={{ maxLength: 32, sx: { background: '#FFFFFF' } }}
                                    label="Recipient's Name"
                                />
                                <TextField
                                    id="recipient-email"
                                    variant="outlined"
                                    value={recipientEmail}
                                    onChange={(e) => setRecipientEmail(e.target.value)}
                                    inputProps={{ maxLength: 256, sx: { background: '#FFFFFF' } }}
                                    label="Recipient's Email"
                                />
                                <TextField
                                    id="message"
                                    variant="outlined"
                                    multiline
                                    minRows={4}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    sx={{ width: '100%', background: '#FFFFFF' }}
                                    inputProps={{ maxLength: 512 }}
                                    label="Message for recipient"
                                />
                                <Box>
                                    <Box mb={2}>When should we send it to them?</Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box mr={2}><Button onClick={() => setShowCalendar(false)} shade={showCalendar ? 'primaryLight' : 'primary'}>Right Now</Button></Box>
                                        <Button onClick={() => setShowCalendar(true)} shade={showCalendar ? 'primary' : 'primaryLight'}>Schedule</Button>
                                    </Box>
                                    {showCalendar && (
                                        <Box p={2} mt={2} sx={{ background: '#FFFFFF', borderRadius: 1, border: '1px solid #CCCCCC' }}>
                                            <Scheduler scheduledAt={scheduledAt} setScheduledAt={setScheduledAt} />
                                        </Box>
                                    )}
                                </Box>
                                <Button
                                    onClick={onCheckout}
                                    size="large"
                                    shade="secondary"
                                    disabled={!recipientEmail || !recipientName || (showCalendar && !scheduledAt)}
                                >
                                    Checkout
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
        </>
    );
}

export default GiftCardPurchase;