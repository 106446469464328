/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MyMessage = styled.div`
    background: #5371ff;
    border-radius: 16.4119px 0px 16.4119px 16.4119px;
    padding: 10px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    margin-right: 5px;
    margin-top: 10px;
    max-width: 200px;
`;

export const YourMessage = styled.div`
    background: #FDEDED;
    border-radius: 16.4119px 0px 16.4119px 16.4119px;
    padding: 10px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    margin-left: 5px;
    margin-top: 10px;
    max-width: 200px;
`;

export const ShareMessage = styled.div`
    background: #FFFFFF;
    border-radius: 16.4119px;
    padding: 10px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    margin-left: 4px;
    margin-top: 10px;
    max-width: 200px;
    border: 2px solid #000000;
    margin-right: 4px;
`;

export const Timestamp = styled.div`
    text-align: center;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #929292;
    margin-top: 20px;
    margin-bottom: -5px;
`;