// npm imports
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Modal, Paper, Stack, LinearProgress, CircularProgress } from '@mui/material';
import { CLOSE_PRODUCT, SET_COUPON_CODE, SET_USER } from '../../store/actions';
import { patch } from '../../services/api.services';
import { API } from '../../constants';
import axios from 'axios';

// local imports
import LogoFilled from '../../assets/images/parentswarm-logo.svg';
import Header from '../../components/header';
import LoggedOutHeader from '../../components/header/logged-out';
import LoadingScreen from './loading';
import Response from './response';
import Feedback from './feedback';
import {
  LinearProgressContainer,
  MatchingContainer,
} from './styled_components/match.style';
import { track } from '../../utils/analytics';
import { expertMatches } from './archive/expertmatches';
import { fetchRecommendedProducts } from '../../clients/product';
import { fetchRecommendedProfiles } from '../../clients/profile';
import { updateUser } from '../../clients/profile';
import ProductPurchase from '../../components/product/purchase';
import DynamicForm from '../../components/dynamic-form';
import { MATCH_QUIZ, PARENT_REGISTRATION } from '../../utils/forms';

export default function Match({}) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { user, token } = useSelector((state) => state.account);

  const [step, setStep] = useState(0);
  const [matches, setMatches] = useState([]);
  const [listings, setListings] = useState([]);
  const [feedback, setFeedback] = useState('');
  const [rotatingText, setRotatingText] = useState("We're finding suggestions for you!");

  const openProduct = useSelector((state) => state.products.openProduct);
  const productMap = useSelector((state) => state.products.productsById);

  const match = async (data) => {
    const rotatingText = [
      "Hang tight, this may take a moment while we find suggestions for you",
      "Our Digital Concierge is...",
      "Understanding your question or challenge",
      "Searching our database of experts, resources and products",
      "Finding ideal matches for your personality",
      "Refining to match your preferences",
      "Your next step: select suggestions you'd like to learn more about",
      "Still thinking, just a little bit longer",
      "This may take up to 20 seconds more",
      "This may take up to 15 seconds more",
      "This may take up to 10 seconds more",
      "This may take up to 5 seconds more",
      "Your results are about to load",
    ];

    let rotatingTextIndex = 0;
    const interval = setInterval(() => {
      if (rotatingTextIndex < rotatingText.length - 1) {
        rotatingTextIndex += 1;
        setRotatingText(rotatingText[rotatingTextIndex]);
      }
    }, 5000);

    const products = await fetchRecommendedProducts(dispatcher, navigate, token, {
        stages: data[1],
        special: data[2],
        personality: data[3],
        delivery: [data[4]],
        parentNeed: data[5],
    });

    const profiles = await fetchRecommendedProfiles(dispatcher, navigate, token, {
      stages: data[1],
      special: data[2],
      personality: data[3],
      delivery: [data[4]],
      parentNeed: data[5],
    });

    setMatches(products);
    setListings(profiles);
    setStep(2);
    clearInterval(interval);
  };

  const saveAnswers = async (data) => {
      const stages = data[1];
      const special = data[2];
      const personality = data[3];

      await updateUser(
        dispatcher,
        navigate,
        user,
        token,
        {
          parent_categories: stages.join(','),
          parent_special_interest_groups: special.join(','),
          preferred_personality: personality
        },
      );
  };

  const closeProduct = () => {
    dispatcher({
      type: CLOSE_PRODUCT,
      payload: {},
    });
  };

  return (
    <>
      <div>
        {user ? (
          <Header />
        ) : (
          <LoggedOutHeader />
        )}
      </div>
      <MatchingContainer>
        <Box sx={{ width: '825px', maxWidth: '95%', m: 2 }}>
          <Paper elevation={0} sx={{ p: 8, borderRadius: 8 }}>
            {step === 0 && (
              <DynamicForm
                config={MATCH_QUIZ}
                onSubmit={(data) => {
                  setStep(1);

                  if (user) {
                    saveAnswers(data);
                  }

                  match(data);
                }}
                initialValues={{
                  1: user?.profile?.parent_categories?.length > 0 ? user.profile.parent_categories.split(',') : [],
                  2: user?.profile?.parent_special_interest_groups?.length > 0 ? user.profile.parent_special_interest_groups.split(',') : [],
                  3: user?.profile?.preferred_personality?.length > 0 ? user?.profile?.preferred_personality : [],
                  5: queryParams.get('challenge') || '',
                }}
              />
            )}
            {step === 1 && (
              <LoadingScreen
                rotatingText={rotatingText}
              />
            )}
            {step === 2 && (
              <Response matches={matches} listings={listings} next={() => setStep(3)} />
            )}
            {step === 3 && (
              <Feedback
                feedback={feedback}
                setFeedback={setFeedback}
                previous={() => setStep(2)}
                next={() => navigate('/match')}
              />
            )}
          </Paper>
        </Box>
        <Modal
          open={openProduct}
          onClose={closeProduct}
        >
          <ProductPurchase
              product={productMap[openProduct]}
              onClose={closeProduct}
              skipPreview={false}
          />
        </Modal>
      </MatchingContainer>
    </>
  );
}
