export const PERSONALITIES = [
  'A Fairy Godmother',
  'A Trusted Big Sibling',
  'An Earth Mother',
  'A Science Teacher',
  'A Friend Who Makes Me Laugh',
  'None of These / Unsure',
];

export const CARE_DELIVERY = [
  'On-Demand Resource',
  'Live Virtual Support',
  'In-Person Support',
  'I Have No Strong Preference',
];

export const SUPPORT_GROUPS = [
  'Fertility',
  'Nutrition',
  'Child Development and Behavior',
  'Prenatal Care',
  'Movement and Fitness',
  'Mental Wellness / Life Design',
  'Labor and Birth',
  'Lactation and Feeding',
  'Family and Relationships',
  'Postpartum',
  'Sleep',
  'Other',
];

export const MINORITY_GROUPS = [
  'BIPOC',
  'LGBTQ+',
  'Persons with disabilities',
  'Single parent',
];

export const QUESTION_CATEGORIES_COL_1 = [
  'Fertility',
  'Prenatal Care',
  'Labor and Birth',
  'Postpartum',
  'Nutrition',
  'Movement and Fitness',
];

export const QUESTION_CATEGORIES_COL_2 = [
  'Lactation and Feeding',
  'Sleep',
  'Child Development and Behavior',
  'Mental Wellness and Relationships',
  'Experts for Experts',
  'Other',
];

export const PARENT_CATEGORIES = [
  'Trying to get Pregnant / Adopt',
  '1st Trimester',
  '2nd Trimester',
  '3rd Trimester',
  '4th Trimester / Early Postpartum',
  '1st Year of Parenting',
  'Parenting Toddler(s)',
  'Parenting Beyond the Toddler Years',
];

export const PARENT_SPECIAL_INTEREST_GROUPS = [
  'BIPOC',
  'First-Time Parent',
  'LGBTQ+',
  'Rural',
  'Single Parent',
  'Parent with Multiples',
  'Person with Disability',
];

export const TOPICS = [
  'Fertility',
  'Prenatal Care',
  'Labor and Birth',
  'Postpartum',
  'Lactation and Feeding',
  'Sleep',
  'Mental Wellness and Relationships',
  'Movement and Fitness',
  'Child Development and Behavior',
  'Nutrition',
];

export const PRIMARY_TOPICS = [
  'Labor and Birth',
  'Postpartum',
  'Lactation and Feeding',
  'Sleep',
];
