import React from 'react';
import { Avatar, Grid } from '@mui/material';

import { displayName, calculateAge } from '../../utils/profile';
import {
    ModalBody,
    ProfileLeft,
    ProfileName,
    Pronouns,
    Age,
    SectionTitle,
    Section,
    SectionContent,
} from './Profile.style';

export default function Parent({ profile }) {
  return (
    <ModalBody>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProfileLeft>
                <Avatar src={''} alt={profile.first_name} sx={{ width: 75, height: 75 }} />
                <ProfileName>
                    {`${displayName(profile.first_name, profile.last_name)} `}
                    <Pronouns>({profile.pronouns})</Pronouns>
                </ProfileName>
                {profile.birth_date && (
                  <Age className='fs-exclude'>{calculateAge(profile.birth_date)} years old</Age>
                )}
              </ProfileLeft>
            </Grid>
            <Grid item xs={12} md={6}>
              <Section>
                <SectionTitle>About Me</SectionTitle>
                <SectionContent>{profile.bio}</SectionContent>
              </Section>
            </Grid>
        </Grid>
    </ModalBody>
  );
}
