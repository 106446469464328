import moment from 'moment';
import { Avatar, Box, Card, Chip, CardContent, Stack, Typography } from '@mui/material';

import { Product } from "../../types";
import { PRODUCT_TYPES, PRODUCT_TYPES_ABBREVIATED } from '../../constants';

interface ProductPreviewProps {
    product: Product;
    interactable?: boolean;
}

export default function ProductPreview({ product, interactable = true }: ProductPreviewProps) {
    return (
        <Card
            sx={{ display: 'flex', flexGrow: 1, alignItems: 'flex-start', cursor: interactable ? 'pointer' : 'default', minHeight: '100px', borderRadius: 2 }}
            variant="outlined"
        >
            <CardContent sx={{ textAlign: 'left', p: 0, pl: 2 }}>
                <Stack spacing={1}>
                    <Typography component="div" variant="h6">
                    {product.title}
                    </Typography>
                    <Stack direction="row" flexWrap="wrap">
                        <Chip
                            color="primary"
                            label={PRODUCT_TYPES_ABBREVIATED[product.product_type]}
                            sx={{ marginBottom: 1, marginRight: 1 }}
                        />

                        {product.event_date && (
                            <Chip
                                color="secondary"
                                label={moment(product.event_date).tz(moment.tz.guess()).format('MMM D, h:mm a z')}
                                sx={{ marginBottom: 1, marginRight: 1 }}
                            />
                        )}

                        {!!product.duration && product.product_type !== PRODUCT_TYPES.RESOURCE && (
                            <Chip
                                color="secondary"
                                label={`${product.duration} min${product.duration > 1 ? 's' : ''}`}
                                sx={{ marginBottom: 1, marginRight: 1 }}
                            />
                        )}

                        {product.virtual && (
                            <Chip color="secondary" label="Virtual" sx={{ marginBottom: 1, marginRight: 1 }} />
                        )}

                        {product.in_person && (
                            <Chip color="secondary" label="In-Person" sx={{ marginBottom: 1, marginRight: 1 }} />
                        )}
                    </Stack>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                    {product.description}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};
