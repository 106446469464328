import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, IconButton, TextField } from '@mui/material';

import { API } from '../../constants';
import { SET_SESSION } from '../../store/actions';
import { patch } from '../../services/api.services';
import Button from '../common/button';

import DismissIcon from '../../assets/icons/Dismiss.svg';

import {
    DrawerTitle,
    DrawerSubtitle,
    SessionDetailsBody,
} from './Session.style';

function KeyTakeaways({ session, onDismiss }) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const [keyTakeaway1, setKeyTakeaway1] = useState(session.key_takeaway_1);
  const [keyTakeaway2, setKeyTakeaway2] = useState(session.key_takeaway_2);
  const [keyTakeaway3, setKeyTakeaway3] = useState(session.key_takeaway_3);
  const [saving, setSaving] = useState(false);

  const saveKeyTakeaways = async () => {
    setSaving(true);
    const result = await patch(
        `${API.SESSION}${session.id}/`,
        {
          key_takeaway_1: keyTakeaway1,
          key_takeaway_2: keyTakeaway2,
          key_takeaway_3: keyTakeaway3,
        },
        token,
    );

    if (result.status === 403 || result.status === 401) {
        navigate('/login/experts');
    }
    else if (result.status === 200) {
        dispatcher({
          type: SET_SESSION,
          payload: {
              session: result.data,
          },
        });
        onDismiss();
        setSaving(false);
    }
    else {
      // TODO: Error states
      setSaving(false);
    }
  }

  return (
    <Box sx={{ width: 400, maxWidth: '100%' }}>
        <SessionDetailsBody>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <DrawerTitle>
                  Write Key Takeaways
                </DrawerTitle>
                <IconButton onClick={onDismiss}>
                  <img src={DismissIcon} alt="close" width={12} height={12} />
                </IconButton>
            </Box>
            <Box sx={{ mb: 2 }}>
              <DrawerSubtitle>{session.title}</DrawerSubtitle>
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="key-takeaway-1"
                variant="outlined"
                placeholder="Write key takeaway 1"
                multiline
                minRows={4}
                value={keyTakeaway1}
                onChange={(e) => setKeyTakeaway1(e.target.value)}
                sx={{ width: '100%' }}
                inputProps={{
                  maxLength: 1024,
                }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="key-takeaway-2"
                variant="outlined"
                placeholder="Write key takeaway 2"
                multiline
                minRows={4}
                value={keyTakeaway2}
                onChange={(e) => setKeyTakeaway2(e.target.value)}
                sx={{ width: '100%' }}
                inputProps={{
                  maxLength: 1024,
                }}
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <TextField
                id="key-takeaway-3"
                variant="outlined"
                placeholder="Write key takeaway 3"
                multiline
                minRows={4}
                value={keyTakeaway3}
                onChange={(e) => setKeyTakeaway3(e.target.value)}
                sx={{ width: '100%' }}
                inputProps={{
                  maxLength: 1024,
                }}
              />
            </Box>
            <Box>
              <Button disabled={saving} shade="primary" size="large" onClick={saveKeyTakeaways}>Submit</Button>
            </Box>
        </SessionDetailsBody>
    </Box>
  );
}

KeyTakeaways.propTypes = {
  session: PropTypes.any, // TODO: Replace with proper proptype
  onDismiss: PropTypes.func,
};

export default KeyTakeaways;