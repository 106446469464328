import {
    Box,
    FormGroup,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
} from '@mui/material';

interface NotificationCategoryProps {
    notificationKey: string;
    value: {
        emails: boolean;
        texts: boolean;
    };
    title: string;
    description: string;
    save: Function;
}

export default function NotificationCategory({ notificationKey, value, title, description, save }: NotificationCategoryProps) {
    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                    <b>{title}</b>
                </Typography>
                <Typography variant="body2">
                    {description}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormGroup>
                    <Box display="flex" alignItems="center" justifyContent="space-around">
                        <FormControlLabel
                            control={
                                <Switch checked={value.emails} onChange={(e) => save(notificationKey, 'emails', e.target.checked)} />
                            }
                            label="Email"
                        />
                        <FormControlLabel
                            control={
                                <Switch checked={value.texts} onChange={(e) => save(notificationKey, 'texts', e.target.checked)} />
                            }
                            label="Text"
                        />
                    </Box>
                </FormGroup>
            </Grid>
        </Grid>
    );
}
