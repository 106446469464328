import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Box, Button, Chip, CircularProgress, Container, Grid, Modal, Pagination, Stack } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { PRIMARY_TOPICS } from '../../utils/groups';
import { fetchProducts } from '../../clients/product';
import ProductCard from '../../components/product/product-card';
import { OPEN_PRODUCT, SET_FILTERS, BUMP_PAGE, RESET_FILTERS } from '../../store/actions';
import { NoResults } from '../experts/experts.style';
import Filter from '../experts/filter';

function Support() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const productIds = useSelector((state) => state.products.productIds);
    const productMap = useSelector((state) => state.products.productsById);
    const filterSet = useSelector((state) => state.search.filters);
    const hasNextPage = useSelector((state) => state.search.hasNextPage);
    const offset = useSelector((state) => state.search.offset);
    const limit = useSelector((state) => state.search.limit);

    const [openFilters, setOpenFilters] = useState(false);
    const [loading, setLoading] = useState(true);

    const loadProducts = async () => {
        setLoading(true);
        await fetchProducts(dispatcher, navigate, token, { ...filterSet, offset, limit });
        setLoading(false);
    };

    const setTopics = (topics) => {
        dispatcher({
            type: SET_FILTERS,
            payload: {
                topics,
            },
        });
    };

    const fetchNextPage = () => {
        dispatcher({
            type: BUMP_PAGE,
            payload: {},
        });
    };

    const toggleTopic = (topic) => {
        const topicIndex = filterSet.topics.indexOf(topic);
        if (topicIndex > -1) {
            const topicsCopy = [ ...filterSet.topics ];
            topicsCopy.splice(topicIndex, 1);
            setTopics(topicsCopy)
        } else {
            setTopics([ ...filterSet.topics, topic ]);
        }
    };

    const openProduct = (product, bookNow) => {
        dispatcher({
            type: OPEN_PRODUCT,
            payload: {
                product,
                bookNow,
            },
          });
    };

    const closeFilters = () => {
        loadProducts();
        setOpenFilters(false);
    };

    useEffect(() => {
        dispatcher({
            type: RESET_FILTERS,
            payload: {},
        });

        const topic = queryParams.get('topic');

        if (topic) {
            setTopics([topic]);
        } else {
            loadProducts();
        }
    }, []);

    useEffect(() => {
        loadProducts();
    }, [filterSet, offset]);

    useEffect(() => {
        if (productIds.length) {
            setLoading(false);
        }
    }, [productIds])

    return (
        <Container maxWidth="lg" style={!user ? { marginTop: '122px' } : {}}>
            <Box mt={2} mb={2} display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse">
                <Button variant="contained" color="secondary" onClick={() => setOpenFilters(true)}>

                    <FilterAltOutlinedIcon /> Filters
                    {filterSet.numFilters > 0 && (
                        <Chip
                            label={filterSet.numFilters}
                            color="primary"
                            size="small"
                            style={{ marginLeft: 5, zoom: '80%', marginTop: 0 }}
                        />
                    )}
                </Button>
                <Stack direction="row" spacing={1} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
                    {PRIMARY_TOPICS.map((topic) => (
                        <Button
                            color={filterSet.topics.indexOf(topic) > -1 ? 'primary' : 'secondary'}
                            variant="contained"
                            onClick={() => toggleTopic(topic)}
                        >
                            {topic}
                        </Button>
                    ))}
                </Stack>
            </Box>
            {(!loading || offset > 0) && (
                <Grid container spacing={2}>
                    {productIds.map((id, index) => (
                        <>
                            {!productMap[id].archived && (
                                <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                                    <ProductCard
                                        product={productMap[id]}
                                        secondaryCtaAction={() => openProduct(productMap[id], false)}
                                        primaryCtaAction={() => openProduct(productMap[id], true)}
                                        key={id}
                                    />
                                </Grid>
                            )}
                        </>
                    ))}
                </Grid>
            )}
            {!loading && hasNextPage && (
                <Box display="flex" justifyContent="center" mt={2}>
                    <Button onClick={fetchNextPage} variant="contained" color="primary">Load More</Button>
                </Box>
            )}
            {loading && (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <CircularProgress style={{ color: '#5371ff' }} />
                </Box>
            )}
            {!productIds.length && !loading && (
                <Box mt={8}>
                    <NoResults>
                        No results matched your search.
                    </NoResults>
                </Box>
            )}
            <Modal
                open={openFilters}
                onClose={closeFilters}
            >
                <Filter onApply={loadProducts} onClose={closeFilters} />
            </Modal>
        </Container>
    );
}

export default Support;