import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Alert,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
} from '@mui/material';

import { LOGOUT, SET_USER } from '../../store/actions';

import {
  Container,
  HelloText,
  IconSpan,
  MenuButton,
  MenuLink,
} from './Header.style';
import LogoutIcon from '../../assets/icons/Logout.svg';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ACCOUNT_TYPES, API } from '../../constants';
import { SET_NOTIFICATIONS } from '../../store/actions';
import { get, post } from '../../services/api.services';
import Notifications from '../notifications';
import { LogoSm } from '../sidebar/Sidebar.style';
import NessleLogoSm from '../../assets/images/parentswarm-logo.svg';
import CommunityAgreementForm from '../../pages/onboarding/community-agreement-form';
import Sidebar from '../sidebar';

function Header({ sidebarToggle = false }) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const { user, token } = useSelector((state) => state.account);
  const unreadNotificationCount = useSelector((state) => state.notifications.unreadCount);

  const [openNotifications, setOpenNotifications] = useState(false);
  const [openMore, setOpenMore] = useState(null);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openCommunityAgreement, setOpenCommunityAgreement] = useState(params.get('community-agreement', false));

  const closeMoreMenu = () => {
    setOpenMore(null);
  };

  const fetchNotifications = async () => {
    const result = await get(
        `${API.NOTIFICATION}`,
        token,
    );

    if (result.status === 200) {
        if (result.data.length) {
            dispatcher({
              type: SET_NOTIFICATIONS,
              payload: {
                notifications: result.data,
              },
            });
        }
    }
  };

  const fetchUser = async () => {
    const result = await get(
      `${API.USER}${user.id}`,
      token,
    );

    if (result.status === 200) {
      dispatcher({
        type: SET_USER,
        payload: {
          user: result.data,
        },
      });
    }
  };

  useEffect(() => {
    fetchUser();

    setTimeout(()=>{
      fetchNotifications();
    }, 1000);
  }, []);

  const logout = () => {
    dispatcher({
      type: LOGOUT,
      payload: {},
    });
    closeMoreMenu();
    navigate(`/login/${user.profile.profile_type}s`);
  };

  return (
    <>
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconSpan>
            <IconButton onClick={() => setOpenNotifications(true)} sx={{ ml: 1 }}>
              <Badge
                badgeContent={unreadNotificationCount}
                max={99}
                sx={{
                  '& .MuiBadge-badge': {
                    color: '#FFFFFF',
                    backgroundColor: '#5371ff',
                  }
                }}
              >
                <NotificationsNoneOutlinedIcon style={{ color: '#000' }} />
              </Badge>
            </IconButton>
          </IconSpan>
          <IconSpan>
            <Tooltip title="Need help? Contact support at help@parentswarm.com.">
              <IconButton href="mailto:help@parentswarm.com">
                <HelpOutlineOutlinedIcon style={{ color: '#000' }} />
              </IconButton>
            </Tooltip>
          </IconSpan>
          <IconSpan>
            <IconButton
              id="more"
              variant="text"
              aria-controls={openMore ? 'more-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMore ? 'true' : undefined}
              onClick={(e) => setOpenMore(e.currentTarget)}
            >
              <MoreVertOutlinedIcon style={{ color: '#000' }} />
            </IconButton>
            <Menu
              id="more-menu"
              anchorEl={openMore}
              open={Boolean(openMore)}
              onClose={closeMoreMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={closeMoreMenu}>
                <MenuLink
                  href="https://www.parentswarm.com/our-story"
                  target="_blank"
                >
                  About Us
                </MenuLink>
              </MenuItem>
              <MenuItem onClick={closeMoreMenu}>
                <MenuLink
                  href="https://assets-global.website-files.com/62b6328770c172ec59ea167d/6539e9a2f1b0c2065c190748_Nessle%20-%20Terms%20of%20Service%20and%20Consultant%20Agreement%20(TC%2010.24.23).docx.pdf"
                  target="_blank"
                >
                  Expert Terms of Use
                </MenuLink>
              </MenuItem>
              <MenuItem onClick={closeMoreMenu}>
                <MenuLink
                  href="https://assets-global.website-files.com/62b6328770c172ec59ea167d/6539e9328c4b038d166066aa_Nessle%20-%20Terms%20of%20Service%20and%20User%20Agreement%20(TC%2010.24.23).docx.pdf"
                  target="_blank"
                >
                  Parent Terms of Use
                </MenuLink>
              </MenuItem>
              <MenuItem onClick={closeMoreMenu}>
                <MenuLink
                  href="https://assets-global.website-files.com/62b6328770c172ec59ea167d/6539e88ab01b8c534a29ec16_Nessle%20-%20Privacy%20Policy%20-%20GP%2010.24.23.docx.pdf"
                  target="_blank"
                >
                  Privacy Policy
                </MenuLink>
              </MenuItem>
              <Divider />
              <MenuItem onClick={logout}>
                <MenuButton>
                  <span>Logout</span>
                  <img src={LogoutIcon} alt="logout" width={20} />
                </MenuButton>
              </MenuItem>
            </Menu>
          </IconSpan>
        </Box>
        <Box display="flex">
          {sidebarToggle && (
            <IconButton onClick={() => setOpenSidebar(!openSidebar)}>
              <MenuRoundedIcon />
            </IconButton>
          )}
          <HelloText>
            <b>{`Welcome${(user.profile.business_name || user.profile.first_name) ? ', ' : ''}`}</b>
            {`${user.profile.business_name || user.profile.first_name}!`}
          </HelloText>
        </Box>
        <LogoSm src={NessleLogoSm} alt="parentswarm logo" />

        <Drawer
          anchor="left"
          open={openSidebar}
          onClose={() => setOpenSidebar(false)}
        >
          <Sidebar />
        </Drawer>
        <Drawer
          anchor="right"
          open={openNotifications}
          onClose={() => setOpenNotifications(false)}
        >
          <Notifications onDismiss={() => setOpenNotifications(false)} />
        </Drawer>
      </Container>
    </>
  );
}

export default Header;
