import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Box, CircularProgress, IconButton, TextField } from '@mui/material';

import { uploadToS3 } from '../../utils/upload';
import { get, post } from '../../services/api.services';
import { API } from '../../constants';
import MiniProfile from '../session/mini-profile';
import Message from './message';
import Chip from '../common/chip';

import DismissIcon from '../../assets/icons/Dismiss.svg';
import AttachIcon from '../../assets/icons/Attach.svg';

function Thread({ parentProfile, expertProfile, onDismiss }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [thread, setThread] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [text, setText] = useState('');
    const [attachment, setAttachment] = useState();
    const [attachmentError, setAttachmentError] = useState(false);

    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };  

    const createThread = async () => {
        const result = await post(
            API.THREAD,
            {
                parent_profile_id: parentProfile.id,
                expert_profile_id: expertProfile.id,
            },
            token,
        );

        if (result.status === 201) {
            setThread(result.data);
            setLoading(false);
        }
        else {
            setError(true);
            setLoading(false);
        }
    };

    const fetchThread = async () => {
        const result = await get(
            `${API.THREAD}?parent_id=${parentProfile.id}&expert_id=${expertProfile.id}`,
            token,
        );

        if (result.status === 200) {
            if (result.data.length) {
                if (!thread || result.data[0].messages.length > thread.messages.length) {
                    setThread(result.data[0]);
                }
                setLoading(false);
            }
            else {
                createThread();
            }
        }
        else {
            setError(true);
            setLoading(false);
        }
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        
        const result = await post(
            API.MESSAGE,
            {
                thread_id: thread.id,
                sender_id: user.profile.id,
                text,
                attachment_file_key: attachment,
            },
            token,
        );

        if (result.status === 201) {
            setText('');
            setAttachment(null);
            fetchThread();
        }
        else {
            setError(true);
        }
    };

    const onNewFile = async (acceptedFiles) => {
        for (let i = 0; i < acceptedFiles.length; i++) {
          const path = `attachment/${thread.id}/${acceptedFiles[i].path}`;
          const successfulUpload = await uploadToS3(
              token,
              path,
              acceptedFiles[i],
          );
    
          if (successfulUpload) {
            setAttachment(path);
          }
          else {
            setAttachmentError(true);
          }
        }
      }

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': [],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
        },
        maxFiles: 1,
        maxSize: 5 * 1000 * 1000,
        onDrop: onNewFile,
    });

    useEffect(() => {
        fetchThread();

        const interval = setInterval(() => {
            fetchThread();
        }, 15000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [thread]);

  return (
    <Box sx={{ width: 400, maxWidth: '100%', height: '100%', overflowY: 'hidden' }}>
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #E8E8E8',
                height: 65,
                pt: 1,
                pb: 1,
                pl: 2,
                pr: 2,
            }}
        >
            <MiniProfile
                profile={user.profile.id === parentProfile.id ? expertProfile : parentProfile}
            />
            <IconButton onClick={onDismiss}>
                <img src={DismissIcon} alt="close" width={12} height={12} />
            </IconButton>
        </Box>
        <Box sx={{ pl: 2, pr: 2, overflowY: 'scroll', height: 'calc(100% - 140px);' }}>
            {(loading || !thread) ? (
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <CircularProgress style={{ color: '#5371ff' }} />
                </Box>
            ) : (
                <>
                    {error ? (
                        <span>Error</span>
                    ) : (
                        <>
                            {thread.messages && thread.messages.map((message, index) => (
                                <Message
                                    key={message.id}
                                    message={message}
                                    previousMessage={index > 0 ? thread.messages[index - 1] : null}
                                />
                            ))}
                            <Box
                                sx={{ height: 85, width: '100%' }}
                                ref={messagesEndRef}
                            />
                        </>
                    )}
                </>
            )}
        </Box>
        <Box sx={{
            background: '#FFF4F4',
            boxShadow: '0px -1.06224px 0px #FAE3E3',
            position: 'absolute',
            width: '100%',
            bottom: 0,
            padding: 2,
            height: 75,
        }}>
            {attachment && (
                <Box sx={{ mt: -2, mb: 1 }}>
                    <Chip color="secondary" label={attachment.split('/')[2]} />
                </Box>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <form onSubmit={sendMessage}>
                    <TextField
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{ width: 340, height: 40 }}
                        InputProps={{
                            sx: { borderRadius: 100, background: '#FFFFFF', height: 40 },
                        }}
                    />
                    <span {...getRootProps({ style: {} })}>
                        <input {...getInputProps()} />
                        <IconButton style={{ marginLeft: 5 }}>
                            <img src={AttachIcon} alt="attachment" width={18} />
                        </IconButton>
                    </span>
                </form>
            </Box>
        </Box>
    </Box>
  );
}

Thread.propTypes = {
  parentProfile: PropTypes.any, // TODO: Replace with proper proptype
  expertProfile: PropTypes.any,
  onDismiss: PropTypes.func,
};

export default Thread;