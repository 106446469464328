import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import { Box, Button, FormControl, Grid, IconButton, InputAdornment, Modal, OutlinedInput, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import {
    ModalContentContainer,
    ModalHeader,
    ModalHeaderLeft,
    ModalHeaderRight,
    ModalTitle,
    ModalBody,
  } from '../../components/profile/Profile.style';
import ConfirmModal from '../../components/common/modal/confirm';
import { number } from 'prop-types';
import { createPaymentPlan, updatePaymentPlan, deletePaymentPlan } from '../../clients/paymentPlan';
import { useAuth } from '../../hooks';
import { PaymentPlan } from '../../types';

interface PaymentScheduleFormProps {
    paymentPlan?: PaymentPlan;
    onClose: any;
}

const NUM_PAYMENT_OPTS = 8;

export default function PaymentScheduleForm({ paymentPlan, onClose }: PaymentScheduleFormProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const { token } = useAuth();

    const [numPayments, setNumPayments] = useState(paymentPlan ? paymentPlan.num_payments : -1);
    const [firstPaymentOccurrence, setFirstPaymentOccurrence] = useState(paymentPlan ? paymentPlan.first_payment_occurrence : '');
    const [firstPaymentValueType, setFirstPaymentValueType] = useState(paymentPlan ? paymentPlan.first_payment_value_type : '');
    const [firstPaymentAmount, setFirstPaymentAmount] = useState(paymentPlan ? paymentPlan.first_payment_amount : 0);
    const [remainingPaymentOccurrence, setRemainingPaymentOccurrence] = useState(paymentPlan ? paymentPlan.remaining_payment_occurrence : '');
    const [name, setName] = useState(paymentPlan ? paymentPlan.name : '');
    const [disableSave, setDisableSave] = useState(true);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

    const validate = () => {
        if (firstPaymentOccurrence.length && firstPaymentValueType.length) {
            if (numPayments === 1) {
                setDisableSave(false);
            } else if (remainingPaymentOccurrence.length) {
                if (numPayments === 2 && (remainingPaymentOccurrence === 'manual' || remainingPaymentOccurrence === 'end')) {
                    setDisableSave(false);
                } else if (remainingPaymentOccurrence !== 'end') {
                    setDisableSave(false);
                }
            }
        }
    };

    const onSave = async () => {
        if (!paymentPlan) {
            await createPaymentPlan(
                dispatcher,
                navigate,
                token,
                {
                    num_payments: numPayments,
                    first_payment_occurrence: firstPaymentOccurrence,
                    first_payment_value_type: firstPaymentValueType,
                    first_payment_amount: firstPaymentAmount,
                    remaining_payment_occurrence: remainingPaymentOccurrence,
                    name,
                }
            );
        } else {
            await updatePaymentPlan(
                dispatcher,
                navigate,
                token,
                paymentPlan,
                {
                    num_payments: numPayments,
                    first_payment_occurrence: firstPaymentOccurrence,
                    first_payment_value_type: firstPaymentValueType,
                    first_payment_amount: firstPaymentAmount,
                    remaining_payment_occurrence: remainingPaymentOccurrence,
                    name,
                }
            );
        }
        onClose();
    }

    const onDelete = async () => {
        await deletePaymentPlan(dispatcher, navigate, token, paymentPlan);
        onClose();
    }

    useEffect(() => {
        validate();
    }, [numPayments, firstPaymentOccurrence, firstPaymentValueType, remainingPaymentOccurrence]);

    useEffect(() => {
        if (numPayments === 1) {
            setFirstPaymentValueType('percentage');
            setFirstPaymentAmount(100);
            setRemainingPaymentOccurrence('');
        }
    }, [numPayments]);

    return (
        <ModalContentContainer>
            <ModalHeader>
                <ModalHeaderLeft>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                    <ModalTitle>
                        {paymentPlan ? 'Edit' : 'New'} Payment Plan
                    </ModalTitle>
                </ModalHeaderLeft>
                <ModalHeaderLeft>
                    {paymentPlan?.is_default && (
                        <Tooltip title="Please select a new default to remove this payment plan">
                            <IconButton onClick={onClose} disabled>
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    
                    {paymentPlan && !paymentPlan.is_default && (
                        <IconButton onClick={() => setOpenDeleteConfirm(true)}>
                            <DeleteOutlineOutlinedIcon />
                        </IconButton>
                    )}
                </ModalHeaderLeft>
            </ModalHeader>
            <ModalBody>
                <Box mb={2}>
                    <Typography variant="subtitle1">How many payments are in this schedule?</Typography>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        {Array(NUM_PAYMENT_OPTS).fill(0).map((_, i) => (
                            <Grid item xs={6} sm={3}>
                                <Button
                                    variant={numPayments === i + 1 ? 'contained' : 'outlined'}
                                    color={numPayments === i + 1 ? 'primary' : 'info'}
                                    sx={{ width: '100%' }}
                                    onClick={() => setNumPayments(i + 1)}
                                >
                                    {i + 1}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                {numPayments > -1 && (
                    <>
                        <Box mb={2} mt={2}>
                            <Typography variant="subtitle1">When should the <b>{numPayments > 1 ? 'first' : ''}</b> payment occur?</Typography>
                        </Box>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant={firstPaymentOccurrence === 'purchase' ? 'contained' : 'outlined'}
                                        color={firstPaymentOccurrence === 'purchase' ? 'primary' : 'info'}
                                        sx={{ width: '100%', height: '100px' }}
                                        onClick={() => setFirstPaymentOccurrence('purchase')}
                                    >
                                        <Stack spacing={1}>
                                            On Day of Purchase
                                            <Typography variant="caption">Client will be automatically charged as they book your service.</Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant={firstPaymentOccurrence === 'start' ? 'contained' : 'outlined'}
                                        sx={{ width: '100%', height: '100px' }}
                                        color={firstPaymentOccurrence === 'start' ? 'primary' : 'info'}
                                        onClick={() => setFirstPaymentOccurrence('start')}
                                    >
                                        <Stack spacing={1}>
                                            On First Day of Service
                                            <Typography variant="caption">Client will be automatically charged on the day of your first session with them.</Typography>
                                        </Stack>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        {firstPaymentOccurrence.length > 0 && numPayments > 1 && (
                            <>
                                <Box mb={2} mt={2}>
                                    <Typography variant="subtitle1">Is the first payment a percentage of the total price or a fixed amount?</Typography>
                                </Box>
                                <Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <Button
                                                variant={firstPaymentValueType === 'fixed' ? 'contained' : 'outlined'}
                                                color={firstPaymentValueType === 'fixed' ? 'primary' : 'info'}
                                                sx={{ width: '100%', height: '100px' }}
                                                onClick={() => setFirstPaymentValueType('fixed')}
                                            >
                                                <Stack spacing={1}>
                                                    Fixed
                                                    <Typography variant="caption">$</Typography>
                                                </Stack>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant={firstPaymentValueType === 'percentage' ? 'contained' : 'outlined'}
                                                color={firstPaymentValueType === 'percentage' ? 'primary' : 'info'}
                                                sx={{ width: '100%', height: '100px' }}
                                                onClick={() => setFirstPaymentValueType('percentage')}
                                            >
                                                <Stack spacing={1}>
                                                    Percentage
                                                    <Typography variant="caption">%</Typography>
                                                </Stack>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                                {firstPaymentValueType.length > 0 && (
                                    <>
                                        <Box mb={2} mt={2}>
                                            <Typography variant="subtitle1">First payment amount:</Typography>
                                        </Box>
                                        <Box>
                                            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                <OutlinedInput
                                                    id="outlined-adornment-weight"
                                                    startAdornment={firstPaymentValueType === 'percentage' ? null : (
                                                        <InputAdornment position="start">
                                                            $
                                                        </InputAdornment>
                                                    )}
                                                    endAdornment={firstPaymentValueType === 'fixed' ? null : (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    )}
                                                    value={firstPaymentAmount}
                                                    aria-describedby="outlined-weight-helper-text"
                                                    type="number"
                                                    inputProps={{
                                                        maxLength: 4,
                                                        min: 0,
                                                        max: firstPaymentValueType === 'fixed' ? 9999 : 100,
                                                        'aria-label': 'weight',
                                                    }}
                                                    onChange={(e) => setFirstPaymentAmount(parseInt(e.target.value))}
                                                />
                                            </FormControl>
                                        </Box>
                                        {numPayments === 2 && (
                                            <>
                                                <Box mb={2} mt={2}>
                                                    <Typography variant="subtitle1">When should the <b>second</b> payment occur?</Typography>
                                                </Box>
                                                <Box>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6}>
                                                            <Button
                                                                variant={remainingPaymentOccurrence === 'end' ? 'contained' : 'outlined'}
                                                                color={remainingPaymentOccurrence === 'end' ? 'primary' : 'info'}
                                                                sx={{ width: '100%', height: '100px' }}
                                                                onClick={() => setRemainingPaymentOccurrence('end')}
                                                            >
                                                                <Stack spacing={1}>
                                                                    On Last Day of Service
                                                                    <Typography variant="caption">
                                                                        Client will be automatically charged after their final session.
                                                                    </Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <Button
                                                                variant={remainingPaymentOccurrence === 'manual' ? 'contained' : 'outlined'}
                                                                color={remainingPaymentOccurrence === 'manual' ? 'primary' : 'info'}
                                                                sx={{ width: '100%', height: '100px' }}
                                                                onClick={() => setRemainingPaymentOccurrence('manual')}
                                                            >
                                                                <Stack spacing={1}>
                                                                    Manually
                                                                    <Typography variant="caption">
                                                                        One-click initiation of the charge at the time of your choosing or schedule them later.
                                                                    </Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        )}
                                        {numPayments > 2 && (
                                            <>
                                                <Box mb={2} mt={2}>
                                                    <Typography variant="subtitle1">Split the remainder over {numPayments - 1} payments that occur:</Typography>
                                                </Box>
                                                <Box>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={4}>
                                                            <Button
                                                                variant={remainingPaymentOccurrence === 'manual' ? 'contained' : 'outlined'}
                                                                color={remainingPaymentOccurrence === 'manual' ? 'primary' : 'info'}
                                                                sx={{ width: '100%', height: '100px' }}
                                                                onClick={() => setRemainingPaymentOccurrence('manual')}
                                                            >
                                                                <Stack spacing={1}>
                                                                    Manually
                                                                    <Typography variant="caption">One-click initiation of the charge at the time of your choosing or schedule them later.</Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Button
                                                                variant={remainingPaymentOccurrence === 'weekly' ? 'contained' : 'outlined'}
                                                                color={remainingPaymentOccurrence === 'weekly' ? 'primary' : 'info'}
                                                                sx={{ width: '100%', height: '100px' }}
                                                                onClick={() => setRemainingPaymentOccurrence('weekly')}
                                                            >
                                                                <Stack spacing={1}>
                                                                    Automatically each week
                                                                    <Typography variant="caption">Payments are automatically charged each week after the initial payment.</Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <Button
                                                                variant={remainingPaymentOccurrence === 'monthly' ? 'contained' : 'outlined'}
                                                                color={remainingPaymentOccurrence === 'monthly' ? 'primary' : 'info'}
                                                                sx={{ width: '100%', height: '100px' }}
                                                                onClick={() => setRemainingPaymentOccurrence('monthly')}
                                                            >
                                                                <Stack spacing={1}>
                                                                    Automatically each month
                                                                    <Typography variant="caption">Payments are automatically charged each month after the initial payment.</Typography>
                                                                </Stack>
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
                {!disableSave && (
                    <Box mt={2}>
                        <Box mb={2}>
                            <Typography variant="subtitle1">What do you want to call this plan? <Typography variant="caption">(this name is just for your reference and not public)</Typography></Typography>
                        </Box>
                        <TextField
                            id="name"
                            variant="outlined"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            inputProps={{ maxLength: 64 }}
                            sx={{ width: '100%' }}
                        />
                    </Box>
                )}
                <Box mt={2} display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        size="large"
                        disabled={disableSave}
                        onClick={onSave}
                    >
                        Save
                    </Button>
                </Box>
            </ModalBody>
            <Modal
                open={openDeleteConfirm}
                onClose={() => setOpenDeleteConfirm(false)}
            >
                <ConfirmModal
                    title="Are you sure you want to delete this payment plan?"
                    subtitle="To undo this action you will need to contact support."
                    cta="Yes, delete it."
                    ctaAction={onDelete}
                    cancelText="Cancel"
                    cancelAction={() => setOpenDeleteConfirm(false)}
                />
            </Modal>
        </ModalContentContainer>
    );
}