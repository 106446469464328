import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';

import { Box, TextField } from '@mui/material';

import { API } from '../../constants';
import { uploadToS3 } from '../../utils/upload';
import Chip from '../common/chip';
import Modal from '../common/modal';
import { post } from '../../services/api.services';
import Button from '../common/button';

const dropzoneStyle = {
    fontFamily: 'Cabin',
    background: 'none',
    height: 100,
    width: '100%',
    border: `2px dashed #e8e8e8`,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: 6,
}

function NoteForm({ folderId, sessionId, onClose }) {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState(false);

    const saveNote = async () => {
        const result = await post(
            API.NOTE,
            {
                folder_id: folderId || null,
                session_id: sessionId || null,
                title,
                body,
                attachments,
            },
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate(`/login/${user.profile.profile_type}/`);
        }
        else if (result.status === 201) {
            onClose();
        }
    };

    const onNewAttachment = async (acceptedFiles) => {
        setAttachmentError(false);
      
        for (let i = 0; i < acceptedFiles.length; i++) {
          const path = `attachment/${user.profile.id}/${folderId || '_'}/${sessionId || '_'}/${moment().unix()}-${acceptedFiles[i].path}`;
          const successfulUpload = await uploadToS3(
              token,
              path,
              acceptedFiles[i],
          );
    
          if (successfulUpload) {
            setAttachments([ ...attachments, path ]);
          }
          else {
            setAttachmentError('We were unable to save this file, please try again.');
          }
        }
    };
    
    const onDeleteFile = (path) => {
        setAttachments(attachments.filter(file => file !== path))
    };
    
    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': [],
          'application/pdf': [],
        },
        maxFiles: 10,
        maxSize: 5 * 1000 * 1000,
        onDrop: onNewAttachment,
    });

    return (
        <Modal title="New Note" onClose={onClose}>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Box mb={2}>
                    <TextField
                        id="note-title"
                        label="Title"
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ width: '100%' }}
                        inputProps={{ maxLength: 128 }}
                    />
                </Box>
                <Box>
                    <TextField
                        id="note-body"
                        label="Notes"
                        variant="outlined"
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        sx={{ width: '100%' }}
                        inputProps={{ maxLength: 1024 }}
                        multiline
                        minRows={4}
                    />
                </Box>
                `<Box sx={{ mb: 2 }}>
                    <Box>
                        {attachments.map((file) => (
                            <Box sx={{ mb: 1 }}>
                                <Chip key={file} color="secondaryLight" label={file.split('-')[1]} onDelete={() => onDeleteFile(file)} />
                            </Box>
                        ))}
                        {fileRejections.map(({ file, errors }) => (
                            <span key={file}>{errors.map(error => error.message)}</span>
                        ))}
                    </Box>
                
                    <div {...getRootProps({ style: dropzoneStyle })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Drop attachments here...</p>
                        ) : (
                            <p>Drag 'n' drop attachments here, or click to select files</p>
                        )}
                    </div>
                    {attachmentError && <span>{attachmentError}</span>}
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                    <Button shade="secondary" onClick={saveNote}>Save Note</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default NoteForm;
