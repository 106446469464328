/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Card = styled.div`
    background: #FFFFFF;
    border-radius: 8px;
    padding: 20px;
    font-family: 'Cabin';
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #E8E8E8;
`;

export const ExpertName = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 1.2em;
    line-height: 150%;
`;

export const DetailText = styled.div`
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 150%;
    overflow: hidden;
    line-height: 1.3em;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    height: 40px;
`;

export const BioText = styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    overflow: hidden;
    line-height: 1.3em;
    max-height: 8rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    padding-top: 5px;
`;