import React from 'react';
import PropTypes from 'prop-types';
import {
  InfoText, PinField, ResendText, SendOtp,
} from '../../pages/login/Login.style';

function RegisterOtp({
  otp1, otp2, otp3, otp4, handleOtp, timer, sendOtp,
  handleResend, setTimer, loggingIn,
}) {
  return (
    <>
      <h4>Enter 4-digit pin</h4>
      <InfoText>
        Enter the 4-digit pin sent to your email
      </InfoText>
      <PinField
        name="otp-1"
        id="otp-1"
        maxLength={1}
        value={otp1}
        onChange={handleOtp}
        pattern="\d*"
      />
      <PinField
        name="otp-2"
        id="otp-2"
        maxLength={1}
        value={otp2}
        onChange={handleOtp}
        pattern="\d*"
      />
      <PinField
        name="otp-3"
        maxLength={1}
        value={otp3}
        onChange={handleOtp}
        pattern="\d*"
      />
      <PinField
        name="otp-4"
        maxLength={1}
        value={otp4}
        onChange={handleOtp}
        pattern="\d*"
      />
      <div>
        <SendOtp
          onClick={() => sendOtp()}
          backgroundColor={otp1 && otp2 && otp3 && otp4}
        >
          {loggingIn ? 'Login' : 'Register'}
        </SendOtp>
      </div>
      <br />
      <br />
      <InfoText>
        {`${timer} minutes left`}
      </InfoText>
      <br />
      <ResendText
        onClick={(e) => {
          handleResend(e);
          setTimer(5);
        }}
      >
        Resend 4-digit pin
      </ResendText>
    </>
  );
}

RegisterOtp.propTypes = {
  handleOtp: PropTypes.func,
  sendOtp: PropTypes.func,
  setTimer: PropTypes.func,
  handleResend: PropTypes.func,
  otp1: PropTypes.number,
  otp2: PropTypes.number,
  otp3: PropTypes.number,
  otp4: PropTypes.number,
  timer: PropTypes.number,
  loggingIn: PropTypes.bool,
};

export default RegisterOtp;
