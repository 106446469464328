import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, IconButton, Modal, Skeleton, Snackbar } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkIcon from '@mui/icons-material/Link';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import config from '../../config';
import { get } from '../../services/api.services';
import { API } from '../../constants';
import { SET_PROFILE } from '../../store/actions';
import { fetchDisplayProfile } from '../../clients/profile';

import { ACCOUNT_TYPES } from '../../constants';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import {
  ModalContentContainer,
  ModalHeader,
  ModalHeaderLeft,
  ModalHeaderRight,
  ModalTitle,
} from './Profile.style';
import Parent from './parent';
import Expert from './expert';
import Button from '../common/button';

export default function Profile({ profileId, open, onClose, openProduct }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const profileMap = useSelector((state) => state.profiles.profilesByDisplayId);

    const [copied, setCopied] = useState(false);

    const profile = profileMap[profileId];

    const fetchProfile = async () => {
      fetchDisplayProfile(dispatcher, profileId, token);
    }

    useEffect(() => {
        if (!profileMap[profileId]) {
            fetchProfile();
        }
    }, [profileId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-profile-title"
      aria-describedby="modal-profile-description"
    >
      <ModalContentContainer>
        <ModalHeader>
          <ModalHeaderLeft>
            <IconButton onClick={onClose}>
              <img src={DismissIcon} alt="share" width={12} height={12} />
            </IconButton>
            <ModalTitle>
              {profile ? (
                <span>
                  {profile.profile_type === ACCOUNT_TYPES.EXPERT ? 'Expert profile' : 'Parent profile'}
                </span>
              ) : (
                <Box sx={{ pl: 2, pr: 2, pt: 2, pb: 2 }}>
                  <Skeleton variant="rectangular" width={200} height={40} />
                </Box>
              )}
            </ModalTitle>
          </ModalHeaderLeft>
          {profile?.profile_type === ACCOUNT_TYPES.EXPERT && (
            <ModalHeaderRight>
              <Button
                onClick={() => window.open(`${config.FE_BASE}/experts/${profileId}`, '_blank')}
                variant="text"
                shade="black"
              >
                  <OpenInNewIcon /> <Box ml={1} display="inline">View Full Profile</Box>
              </Button>
              <CopyToClipboard
                text={`${config.FE_BASE}/experts/${profileId}`}
                onCopy={() => setCopied(true)}
              >
                <Button onClick={() => {}} variant="text" shade="black">
                  <LinkIcon /> <Box ml={1} display="inline">Share</Box>
                </Button>
              </CopyToClipboard>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={copied}
                autoHideDuration={4000}
                onClose={() => setCopied(false)}
                message="Share link has been copied to your clipboard"
              />
            </ModalHeaderRight>
          )}
        </ModalHeader>
        {profile ? (
          <>
            {profile.profile_type === ACCOUNT_TYPES.EXPERT ? (
              <Expert profile={profile} openProduct={openProduct} />
            ) : (
              <Parent profile={profile} />
            )}
          </>
        ) : (
          <Box sx={{ pl: 2, pt: 2, pr: 2, pb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={'100%'} height={200} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={'100%'} height={200} />
              </Grid>
            </Grid>
          </Box>
        )}
      </ModalContentContainer>
    </Modal>
  );
}
