import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

import { Alert, Avatar, Box, CircularProgress } from '@mui/material';

import { State } from '../../../types';
import { useAuth } from '../../../hooks';
import { uploadToS3 } from '../../../utils/upload';

interface FileUploaderProps {
    existingFile?: string,
    pathPrefix: string,
    onSuccess: Function,
}

export default function FileUploader({ existingFile, pathPrefix, onSuccess }: FileUploaderProps) {
    const { user, token } = useAuth();

    const [processing, setProcessing] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [fileError, setFileError] = useState('');

    const dropzoneStyle = {
        fontFamily: 'Nunito',
        background: 'none',
        minHeight: 100,
        width: '100%',
        border: `2px dashed #e8e8e8`,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 6,
        padding: '10px',
    };

    const onNewFile = async (acceptedFiles, fileRejections) => {
        setFileError('');
        setProcessing(true);
      
        for (let i = 0; i < acceptedFiles.length; i++) {
            const path = `${pathPrefix}/${user.profile.id}/${encodeURIComponent(acceptedFiles[i].path.replace(/ /g, "-"))}`;
            
            const successfulUpload = await uploadToS3(
                token,
                path,
                acceptedFiles[i],
            );
    
            if (successfulUpload) {
                setFilePath(path);
                setProcessing(false);

                onSuccess(
                    path,
                    URL.createObjectURL(acceptedFiles[acceptedFiles.length - 1]),
                )
            }
            else {
                setFileError('We were unable to save this file, please try again.');
                setProcessing(false);
            }
        }
    }

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/gif': [],
        },
        maxFiles: 1,
        multiple: false,
        maxSize: 5 * 1000 * 1000,
        onDrop: onNewFile,
    });

    return (
        <Box>
            <div {...getRootProps({ style: dropzoneStyle as any })}>
                <input {...getInputProps()} />
                {(!acceptedFiles.length && !existingFile) ? (
                    <>
                        {isDragActive ? (
                            <p>Drop image here...</p>
                        ) : (
                            <p>Drag 'n' drop an image here</p>
                        )}
                    </>
                ) : (
                    <>
                        {processing ? (
                            <CircularProgress />
                        ) : (
                            <Avatar
                                variant="square"
                                src={acceptedFiles.length ? URL.createObjectURL(acceptedFiles[acceptedFiles.length - 1]) : existingFile}
                                alt={acceptedFiles.length ? acceptedFiles[acceptedFiles.length - 1].toString() : existingFile}
                                sx={{ width: 'auto', height: 175, borderRadius: 2 }}
                            />
                        )}
                    </>
                )}
            </div>
            {fileError && <Box mt={1}><Alert color="error">{fileError}</Alert></Box>}
            {fileRejections.map(({ file, errors }) => (
              <Box mt={1}><Alert key={file.name} severity="error">{errors.map(error => error.message)}</Alert></Box>
          ))}
        </Box>
    );
}
