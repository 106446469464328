export const getFinalPrice = (price, coupon) => {
    if (!coupon) {
        return price;
    }
    
    if (coupon.percent_off) {
        return price - price * (coupon.percent_off / 100);
    } else if (coupon.amount_off) {
        return price - (coupon.amount_off / 100);
    }
};

export const getCouponDescription = (coupon) => {
    if (!coupon) {
        return null;
    }

    if (coupon.percent_off) {
        return `${coupon.percent_off}% off${coupon.duration_in_months ? ` for ${coupon.duration_in_months} months` : ''}`;
    }

    if (coupon.amount_off) {
        return `$${coupon.amount_off / 100} off${coupon.duration_in_months ? ` for ${coupon.duration_in_months} months` : ''}`;
    }
};

export const getPriceAfterDiscounts = (product, coupon, isMember) => {
    let price = product.price

    if (isMember && product.track_price) {
        price = product.track_price
    }

    if (coupon && coupon.metadata.in_app_purchases) {
        if (coupon.percent_off) {
            return price - price * (coupon.percent_off / 100);
        } else if (coupon.amount_off) {
            return price - (coupon.amount_off / 100);
        }
    }
    return price;
};

export const getQuestionPriceAfterDiscounts = (price, coupon) => {
    if (coupon && coupon.metadata.in_app_purchases) {
        if (coupon.percent_off) {
            return price - price * (coupon.percent_off / 100);
        } else if (coupon.amount_off) {
            return price - (coupon.amount_off / 100);
        }
    }
    return price;
};

