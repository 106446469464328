import { API } from '../constants';
import { get } from '../services/api.services';
import {
    VersionActionType
} from '../store/actions';

export const fetchVersion = async (dispatcher) => {
    const result = await get(
        API.VERSION,
    );

    if (result && result.data?.version) {
        dispatcher({
            type: VersionActionType.SET_LATEST_VERSION,
            payload: {
                version: result.data.version,
            },
        });
    }
};
