import React, { useState } from 'react';

import { Box, Rating, Stack, TextField } from '@mui/material';

import Button from '../common/button';
import RateIcon from '../../assets/icons/Rate.svg';

export default function DirectComment({ session, rating, directComment, setDirectComment, next, back }) {
    return (
        <Stack spacing={2}>
            <Box display="flex" justifyContent="center">
                <img src={RateIcon} alt="rating" width={50} />
            </Box>
            <Box display="flex" justifyContent="center">
                <Rating name="rating" value={rating} precision={1} readOnly />
            </Box>
            <TextField
                id="direct-comment"
                variant="outlined"
                placeholder={`Write any comments about your experience with ${session.expert_profile.first_name}.`}
                multiline
                minRows={4}
                value={directComment}
                onChange={(e) => setDirectComment(e.target.value)}
                sx={{ width: '100%' }}
            />
            <Box display="flex" justifyContent="space-around">
                <Button onClick={back} shade="secondaryLight">Back</Button>
                <Button onClick={next}>Next</Button>
            </Box>
        </Stack>
    );
}
