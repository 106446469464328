import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Avatar, Box, Grid, IconButton, Tooltip } from '@mui/material';
import Button from '../common/button';
import Chip from '../common/chip';
import { Card, BioText, DetailText, ExpertName } from './experts.style';
import { displayName, calculateAge } from '../../utils/profile';
import { stringToColor } from '../../utils/stringHelpers';
import Profile from '../profile';

function ExpertRow({ profile, xs, sm, md, lg, xl }) {
    const [openProfile, setOpenProfile] = useState(false);

    const rolesArray = profile.one_liner || profile.roles?.split(',');

  return (
    <Grid item xs={12} sm={sm || 6} md={md || 4} lg={lg || 3} xl={xl || 3}>
        <Card onClick={() => window.open(`/r/${profile.display_id}`, '_blank')}>
            <Box mb={1}>
                <Avatar
                    alt={profile.first_name}
                    src={profile.profile_image_url}
                    sx={{ width: 100, height: 100, margin: '0 auto', bgcolor: profile.profile_image_url ? '#FFFFFF' : stringToColor(profile.business_name || profile.first_name) }}
                >
                    {!profile.profile_image_url ? profile.business_name?.charAt(0) || profile.first_name?.charAt(0) : null}
                </Avatar>
                <Box mt={1}>
                    <ExpertName>{profile.business_name || displayName(profile.first_name, profile.last_name)}</ExpertName>
                </Box>
            </Box>
            {profile.one_liner && (
                <Box>
                    <DetailText>
                        {profile.one_liner}
                    </DetailText>
                </Box>
            )}
            {!profile.one_liner && rolesArray && (
                <Box>
                    <DetailText>
                        {rolesArray.join(', ')}
                    </DetailText>
                </Box>
            )}
            <Box>
                <BioText>{profile.bio}</BioText>
            </Box>
            <Box mt={2}>
                <Button shade="secondary">View</Button>
            </Box>
        </Card>
        {openProfile && (
            <Profile open={openProfile} profileId={profile.display_id} onClose={() => setOpenProfile(false)} />
        )}
    </Grid>
  );
}

ExpertRow.propTypes = {
  profile: PropTypes.any,
  onClick: PropTypes.func,
};

export default ExpertRow;