import { useState } from "react";
import { Checkbox, Link, FormControlLabel, Stack, TextField, Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";

const Field = ({ question, value, onChange }) => {
    const [showConditionalInput, setShowConditionalInput] = useState(false);
    const handleCheckboxChange = (event) => {
        onChange(question.id, event.target.checked);
    };
    
    const handleMultiCheckboxChange = (option) => {
        // For multiple checkboxes, toggle the presence of option in the array
        const newValue = Array.isArray(value) ? [...value] : [];
        if (newValue.includes(option)) {
        onChange(question.id, newValue.filter((item) => item !== option));
        } else {
        onChange(question.id, [...newValue, option]);
        }
    };
    
    switch (question.type) {
        case 'text':
            return (
                <>
                    <Typography variant="subtitle1" component="div" gutterBottom>{question.label}</Typography>
                    <TextField
                        fullWidth
                        required={question.required}
                        onChange={(e) => onChange(question.id, e.target.value)}
                        value={value || ''}
                    />
                </>
            );
        case 'password':
            return (
                <>
                    <Typography variant="subtitle1" component="div" gutterBottom>{question.label}</Typography>
                    <TextField
                        fullWidth
                        type="password"
                        required={question.required}
                        onChange={(e) => onChange(question.id, e.target.value)}
                        value={value || ''}
                    />
                </>
            );
        case 'select':
            return (
                <Stack spacing={2}>
                    <div>{question.label}</div>
                    <ToggleButtonGroup
                        value={value || (question.multiple ? [] : '')}
                        onChange={(e, newValue) => {
                            onChange(question.id, newValue);
                            setShowConditionalInput(newValue === question.conditionalInput?.triggerValue);
                        }}
                        exclusive={!question.multiple}
                        aria-label={question.label}
                        orientation="vertical"
                    >
                        {question.options.map((option, index) => (
                            <ToggleButton key={index} value={option} aria-label={option}>
                                {option}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                    {question.conditionalInput && showConditionalInput && (
                        <TextField
                            label={question.conditionalInput.label}
                            onChange={(e) => {
                                onChange(question.id, e.target.value);
                            }}
                            value={value !== question.conditionalInput?.triggerValue ? value : ''}
                        />
                    )}
                </Stack>
            );
        case 'checkbox':
            if (question.options) {
                // For multiple checkboxes (assuming multiple options are provided)
                return (
                    <div>
                        <div>{question.label}</div>
                        {question.options.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                    checked={value.includes(option)}
                                    onChange={() => handleMultiCheckboxChange(option)}
                                    name={option}
                                    />
                                }
                                label={option}
                            />
                        ))}
                    </div>
                  );
                } else {
                    // For a single checkbox
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!value}
                                    onChange={handleCheckboxChange}
                                />
                            }
                            label={
                                <span>
                                    {question.label.split(question.linkText)[0]}
                                        <Link href={question.linkHref} target="_blank" rel="noopener">{question.linkText}</Link>
                                    {question.label.split(question.linkText)[1]}
                                </span>
                            }
                        />
                    );
                }
        default:
            return null;
    }
}

export default Field;
