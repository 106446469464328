export const buildSearchQuery = (filterSet) => {
  const query = [];

  const {
    keyTerm,
    languages,
    minorityGroups,
    topics,
    productTypes,
    pricing,
    virtual,
    inPerson,
    radius,
    zip,
    offset,
    limit,
    insurances,
    locationsServed,
  } = filterSet;

  if (keyTerm.length) {
    query.push(
      encodeURIComponent('key_term') + '=' + encodeURIComponent(keyTerm),
    );
  }

  if (languages.length) {
    query.push(
      encodeURIComponent('languages_spoken') +
        '=' +
        encodeURIComponent(languages),
    );
  }

  if (minorityGroups.length) {
    query.push(
      encodeURIComponent('minority_identification') +
        '=' +
        encodeURIComponent(minorityGroups),
    );
  }

  if (topics.length) {
    query.push(encodeURIComponent('topics') + '=' + encodeURIComponent(topics));
  }

  if (virtual !== undefined) {
    query.push(
      encodeURIComponent('offers_virtual_services') +
        '=' +
        encodeURIComponent(virtual),
    );
  }

  if (inPerson !== undefined) {
    query.push(
      encodeURIComponent('offers_in_person_services') +
        '=' +
        encodeURIComponent(inPerson),
    );
  }

  if (inPerson && radius) {
    query.push(encodeURIComponent('radius') + '=' + encodeURIComponent(radius));
  }

  if (inPerson && zip) {
    query.push(encodeURIComponent('zip_code') + '=' + encodeURIComponent(zip));
  }

  if (pricing) {
    query.push(
      encodeURIComponent('pricing') + '=' + encodeURIComponent(pricing),
    );
  }

  if (productTypes) {
    query.push(
      encodeURIComponent('product_types') +
        '=' +
        encodeURIComponent(productTypes),
    );
  }

  if (insurances.length) {
    query.push(
      encodeURIComponent('insurance_accepted') +
        '=' +
        encodeURIComponent(insurances),
    );
  }

  if (locationsServed.length) {
    query.push(
      encodeURIComponent('locations_served') +
        '=' +
        encodeURIComponent(locationsServed.join(';'))
    )
  }

  if (offset) {
    query.push(encodeURIComponent('offset') + '=' + encodeURIComponent(offset));
  }

  if (limit) {
    query.push(encodeURIComponent('limit') + '=' + encodeURIComponent(limit));
  }

  return query.length ? '?' + query.join('&') : '';
};
