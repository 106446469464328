import styled from 'styled-components';

export const InputWrapperContainer = styled.div`
  width: 100%;
  height: 300px;
`;

export const ServiceCategoriesWrapper = styled.div`
  display: flex;
  input {
    width: 20px;
    height: 20px;
    accent-color: #5371ff;
  }
  label {
    padding-left: 10px;
    font-size: 16px;
    margin-top: 4px;
    color: #210b2b;
  }
`;

export const Button = styled.button`
  width: ${(props) => props.width || '142px'};
  height: ${(props) => props.height || '55px'};
  background: ${(props) => props.background || '#5371ff'};
  border-radius: ${(props) => props.br || '0px'};
  text-align: ${(props) => props.textAlign || 'center'};
  color: ${(props) => props.color || 'white'};
  cursor: pointer;
  position: ${(props) => props.position || 'relative'};
  top: ${(props) => props.top || '0'};
  left: ${(props) => props.left || '0'};
  bottom: ${(props) => props.bottom || '0'};
  right: ${(props) => props.right || '0'};
  outline: none;
  border: none;
  font-size: ${(props) => props.fontSize || '20px'};
  font-weight: bold;
  white-space: nowrap;
`;

export const NextButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const OtherBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    accent-color: #5371ff;
  }
  label {
    padding-left: 10px;
    font-size: 16px;
    margin-top: 4px;
    color: #210b2b;
  }
`;
export const OtherBoxInputContainer = styled.div`
  position: absolute;
  left: 100px;
  top: -10px;
  span {
    display: block;
    color: red;
    font-size: 13px;
  }
`;

export const Input = styled.input`
  border-radius: 5px;
  padding: 10px;
  height: 50%;
  width: 90%;
  font-size: 16px;
  border: 1px solid #bcb6c1;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #bcb6c1;
    opacity: 1; /* Firefox */
  }
`;

export const InputTextarea = styled.textarea`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  height: 70%;
  border: 1px solid #bcb6c1;
  resize: none;
  font-family: 'Cabin';
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #bcb6c1;
    opacity: 1; /* Firefox */;
    top: 0px;
    // border: 1px solid red;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  margin: 10px;
  font-size: 13px;
`;

export const CameraImageLabel = styled.label`
`;

export const CameraImage = styled.img`
  overflow: hidden;
  border-radius: 50%;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background-repeat: no-repeat;
  background-position: center center;
  padding-top: ${(props) => props.paddingTop || '0'};
`;

export const Circle = styled.div`
  border-radius: 50%;
  position: relative;
  width: 200px;
  height: 200px;
  cursor: pointer;
  border: 1px solid #fce2e3;
  background: #fff3f2;
  input {
    display: none;
  }
`;
