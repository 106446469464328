/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const MatchingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const LinearProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffe2e2;
`;

export const Title = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-size: 53px;
  line-height: 120%;
  color: #2f1160;
`;

export const DetailText = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #2f1160;
`;

export const QuizQuestion = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #2f1160;
  text-align: center;
`;

export const QuizQuestionDetail = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #2f1160;
`;

export const Answer = styled.div`
  height: 35px;
  max-width: 320px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 40px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  letter-spacing: 1px;
  text-align: center;
  color: #000000;
  padding: 20px 10px;
  cursor: pointer;
`;

export const SelectedAnswer = styled(Answer)`
  border: 2px solid #5371ff;
  color: #000;
  background: #fceffe;
`;

export const ModalLink = styled.div`
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  text-decoration-line: underline;
  color: #2f1160;
  cursor: pointer;
`;

export const ModalText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.019em;
  color: rgba(0, 0, 0, 0.8);
`;
