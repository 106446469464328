import { useNavigate } from 'react-router';

import { Box, Button, Grid, Typography } from '@mui/material';

import ListingPageImg from '../../assets/images/listing-page.png';

interface ListingPageProps {}

export default function ListingPage({}: ListingPageProps) {
    const navigate = useNavigate();

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <Box display="flex" flexDirection="column" justifyContent="center" height="100%" mb={2}>
                    <Typography variant="h5">Build Your Listing Page</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>
                                Add a bio, profile picture, and more to display on your listing.{' '} 
                                Get started<Box display="inline" ml={-1}><Button size="large" onClick={() => navigate('/more')}>here</Button></Box>
                            </li>
                            <li>
                                Watch the Training Module on building your bio<Box display="inline" ml={-1}><Button size="large" onClick={() => window.open('https://www.veed.io/view/f583d047-8710-486d-999f-ff8cc2b5366e?panel=share', '_blank')}>here</Button></Box>
                            </li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box textAlign="center">
                    <img style={{ width: '350px', maxWidth: '90%' }} src={ListingPageImg} />
                </Box>
            </Grid>
        </Grid>
    );
}
