import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';

import Logo from '../../assets/images/parentswarm-logo.svg';
import Button from '../../components/common/button';
import { post } from '../../services/api.services';
import { API } from '../../constants';
import ExpertCaraousalStill from '../../assets/images/expert-caraousal-still.png';
import { Container, ErrorMessage, ImageDiv, InfoText, PasswordField, RegisterBody, Send, SubmitButton } from '../login/Login.style';

function PasswordReset({}) {
    const navigate = useNavigate();
    const location = useLocation();

    const [password, setPassword] = useState('');
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);

    const params = new URLSearchParams(location.search);

    const resetPassword = async () => {
        setError(undefined);
        const result = await post(
            `${API.PASSWORD_RESET}confirm/`,
            {
                token: params.get('token'),
                password,
            },
            null,
        );

        if (result.status === 200) {
            setSuccess(true);
        }
        else {
            if ('token' in result.data) {
                setError(result.data.token[0]);
            }
            else if ('password' in result.data) {
                setError(result.data.password[0]);
            }
            else {
                setError('We were unable to reset your password. Please request a new link.')
            }
        }
    };

  return (
    <Container>
        <Grid container spacing={0}>
            <Grid item xs={12} md={8}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, height: '100%' }}>
                    <ImageDiv><img src={ExpertCaraousalStill} alt="parenting" width="100%" height="40%" /></ImageDiv>
                </Box>
            </Grid>
            <Grid item xs={12} md={4}>
                {success ? (
                    <RegisterBody>
                        <img src={Logo} alt="parenting" width={90} height={90} />
                        <h4 data-testid="page-title">Success!</h4>
                        <InfoText>
                            Your password has been reset.
                        </InfoText><br />
                        <Send
                            onClick={() => navigate('/login/experts?login=1')}
                            backgroundColor
                        >
                            Login
                        </Send>
                    </RegisterBody>
                ) : (
                    <RegisterBody>
                        <img src={Logo} alt="parenting" width={90} height={90} />
                        <h4 data-testid="page-title">Reset Password</h4>
                        {error && (
                            <ErrorMessage>{error}</ErrorMessage>
                        )}
                        <InfoText>
                            Select a new password.
                        </InfoText><br />
                        <PasswordField
                            type="password"
                            placeholder="New Password"
                            value={password}
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                        /><br />
                        <Send
                            onClick={resetPassword}
                            backgroundColor
                        >
                            Reset password
                        </Send><br />
                        <SubmitButton
                            onClick={() => navigate('/login/experts?login=1')}
                            backgroundColor
                        >
                            Back to login
                        </SubmitButton>
                    </RegisterBody>
                )}
            </Grid>
        </Grid>
    </Container>
  );
}

PasswordReset.propTypes = {};

export default PasswordReset;
