import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { Box, TextField, Checkbox, FormControlLabel } from "@mui/material";

import Button from "../common/button";
import Modal from "../common/modal";
import Schedule from "../product/schedule";
import { useAuth } from "../../hooks";
import { createOrSave } from "../../services";
import { Event } from "../../types";
import { fetchEvents } from "../../clients/event";
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";
const TIME_FORMAT = "hh:mm A";

interface AddOrEditEventProps {
  event?: Event;
  onClose: () => void;
  folderId: string;
}

export default function AddOrEditEvent({
  event,
  onClose,
  folderId,
}: AddOrEditEventProps) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const { token } = useAuth();

  const [title, setTitle] = useState(event ? event.title : "");
  const [description, setDescription] = useState(
    event ? event.description : ""
  );
  const [eventTime, setEventTime] = useState(event ? event.event_time : null);
  const [notificationType, setNotificationType] = useState(
    event ? event.notification_type : []
  );
  const [notificationTime, setNotificationTime] = useState(
    event?.notification_time ? moment.utc(event.notification_time).format(TIME_FORMAT): null
  );

  const modalTitle = event?.id ? `Edit ${event.title}` : "New Event";

  const save = useCallback(async () => {
    if (!title || !eventTime) {
      return;
    }

    // until we implement a way to set notification time, fallback to using event time so we can still store a full datetime
    const notificationDateTime = notificationTime ? moment.utc(`${eventTime} ${notificationTime}`).format() : null;

    const data: Partial<Event> = {
      title,
      description,
      event_time: eventTime,
      notification_type: notificationType,
      notification_time: notificationDateTime,
      folder_id: folderId,
    };

    const result = await createOrSave(event, data, token);

    if (result.status === 403 || result.status === 401) {
      navigate("/login/experts");
    } else if (result.status === 200 || result.status === 201) {
      fetchEvents(dispatcher, navigate, token, folderId);
      onClose();
    } else {
      // TODO: Handle errors
    }
  }, [
    description,
    event,
    eventTime,
    folderId,
    navigate,
    notificationTime,
    notificationType,
    onClose,
    title,
    token,
  ]);

  const onCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id } = e.target;
      if (notificationType.includes(id)) {
        setNotificationType(notificationType.filter((type) => type !== id));
      } else {
        setNotificationType([...notificationType, id]);
      }
    },
    [notificationType]
  );

  const scheduleCallback = useCallback((scheduledAt: string) => {
    if (!scheduledAt) {
      return;
    }
    const scheduledMoment = moment(scheduledAt);

    const newEventTime = scheduledMoment.format(DATE_FORMAT);
    setEventTime(newEventTime);

    if (scheduledMoment.hour() !== 0 || scheduledMoment.minute() !== 0) {
      const notificationTime = scheduledMoment.format(TIME_FORMAT);
      setNotificationTime(notificationTime);
    }
  }, []);

  return (
    <Modal title={modalTitle} onClose={onClose}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        display="flex"
        flexDirection="row"
        flexGrow={1}
      >
        <Schedule
          scheduledAt={event?.notification_time ? event.notification_time : eventTime}
          setScheduledAt={scheduleCallback}
          timeFieldLabel="Reminder Time"
          showTimezone={true}
        />
        <Box flexGrow={1}>
          <Box mb={2}>
            <TextField
              id="note-title"
              label="Name of event"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{ width: "100%" }}
              inputProps={{ maxLength: 128 }}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              id="note-title"
              label="(Optional) Description of event"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{ width: "100%" }}
              multiline
              minRows={4}
              inputProps={{ maxLength: 1024 }}
            />
          </Box>
          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationType.includes("email")}
                  id="email"
                  title="email"
                  onChange={onCheckboxChange}
                />
              }
              label="Get notified via email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationType.includes("sms")}
                  id="sms"
                  title="sms"
                  onChange={onCheckboxChange}
                />
              }
              label="Get notified via text"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-around">
            <Button shade="secondaryLight" onClick={onClose}>
              Cancel
            </Button>
            <Button shade="secondary" onClick={save}>
              {event?.id ? "Save" : "Create"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
