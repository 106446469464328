import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';
import {
    Alert,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    Modal as MUIModal,
    TextField,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { uploadToS3 } from '../../utils/upload';
import { API } from '../../constants';
import Modal from '../../components/common/modal';
import { patch, post } from '../../services/api.services';
import Button from '../../components/common/button';
import Chip from '../../components/common/chip';
import FilePreview from './file-preview';
import { ErrorMessage } from '../../pages/login/Login.style';
import ConfirmModal from '../../components/common/modal/confirm';
import { deleteResource } from '../../clients/resource';

function ResourceForm({ resource, onClose, onSuccess, startFileKey, startFileKeyError }) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [title, setTitle] = useState(resource ? resource.title : '');
    const [titleError, setTitleError] = useState();
    const [description, setDescription] = useState(resource ? resource.description : '');
    const [descriptionError, setDescriptionError] = useState();
    const [fileKey, setFileKey] = useState(resource ? resource.file_key : startFileKey);
    const [fileKeyError, setFileKeyError] = useState(startFileKeyError);
    const [requiresSignature, setRequiresSignature] = useState(resource ? resource.requires_signature : false);
    const [requiresSignatureError, setRequiresSignatureError] = useState();
    const [hasErrors, setHasErrors] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const resetErrors = () => {
        setTitleError(null);
        setDescriptionError(null);
        setFileKeyError(null);
        setRequiresSignatureError(null);
        setHasErrors(false);
    };

    const onSave = async () => {
        resetErrors();
        let result;

        if (fileKey.indexOf('docx') !== -1 && requiresSignature) {
            setHasErrors(true);
            setRequiresSignatureError('Please convert your document to PDF and reupload it');
            return;
        }

        if (!resource) {
            result = await post(
                `${API.RESOURCE}`,
                {
                    title,
                    description,
                    file_key: fileKey,
                    requires_signature: requiresSignature,
                },
                token,
            );
        } else {
            result = await patch(
                `${API.RESOURCE}${resource.id}/`,
                {
                    title,
                    description,
                    file_key: fileKey,
                    requires_signature: requiresSignature,
                },
                token,
            );
        }

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 400) {
            setHasErrors(true);
            if (result.data.title) {
                setTitleError(result.data.title);
            }

            if (result.data.description) {
                setDescriptionError(result.data.description);
            }

            if (result.data.file_key) {
                setFileKeyError(result.data.file_key);
            }

            if (result.data.requires_signature) {
                setRequiresSignatureError(result.data.requires_signature);
            }
        }
        else if (result.status === 201 || result.status === 200) {
            onSuccess(result.data);
            onClose();
        }
        else {
        }
    };

    const onNewFile = async (acceptedFiles) => {
        for (let i = 0; i < acceptedFiles.length; i++) {
          const path = `resources/${user.profile.id}/${moment().unix()}-${acceptedFiles[i].path}`;
          const successfulUpload = await uploadToS3(
              token,
              path,
              acceptedFiles[i],
          );
    
          if (successfulUpload) {
            setFileKey(path);
          }
          else {
            setFileKeyError(true);
          }
        }
    };

    const onDeleteFile = () => {
        setFileKey(null);
    };

    const onDeleteResource = async () => {
        await deleteResource(dispatcher, navigate, token, resource);
        closeDeleteResourceModal();
        onClose();
    }

    const closeDeleteResourceModal = () => {
        setShowDeleteModal(false);
    };

    const {acceptedFiles, fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
          'image/jpeg': [],
          'image/png': [],
          'application/pdf': [],
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
          'video/mp4': [],
        },
        maxFiles: 1,
        maxSize: 5 * 1000 * 1000 * 1000,
        onDrop: onNewFile,
    });

    const dropzoneStyle = {
        fontFamily: 'Cabin',
        background: 'none',
        height: 100,
        width: '100%',
        border: `2px solid #000000`,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        borderRadius: 8,
    };

    return (
        <Modal
            title={resource ? 'Edit Resource' : 'New Resource'}
            onClose={onClose}
            headerRight={resource?.archived ? <Chip label="Deleted" /> : (
                <IconButton color="info" onClick={() => setShowDeleteModal(true)}>
                    <DeleteOutlineIcon />
                </IconButton>
            )}
        >
            {hasErrors && (
                <Box mb={2}>
                    <Alert color="error">Please review the errors below and try again.</Alert>
                </Box>
            )}
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="resource-title"
                            label="Title"
                            variant="outlined"
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 64 }}
                            error={titleError}
                            helperText={titleError || null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="resource-description"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 512 }}
                            error={descriptionError}
                            helperText={descriptionError || null}
                        />
                    </Grid>
                    {!resource ? (
                        <Grid item xs={12}>
                            <Box>
                                {fileKey && (
                                    <Box sx={{ mb: 1 }}>
                                        <Chip key={fileKey} color="secondaryLight" label={fileKey.split('-')[1]} onDelete={() => onDeleteFile()} />
                                    </Box>
                                )}
                                {fileRejections.map(({ file, errors }) => (
                                    <span key={file}>{errors.map(error => error.message)}</span>
                                ))}
                            </Box>
                            {!fileKey && (
                                <>
                                    <div {...getRootProps({ style: dropzoneStyle })}>
                                        <input {...getInputProps()} />
                                        {isDragActive ? (
                                            <p>Drop attachments here...</p>
                                        ) : (
                                            <Box>
                                                <CloudUploadOutlinedIcon sx={{ fontSize: '48px' }}/><br />
                                                Supported file types: PNG, JPG, PDF, DOCX, MP4 up to 5GB
                                            </Box>
                                        )}
                                    </div>
                                    {fileKeyError && <span>{fileKeyError}</span>}
                                </>
                            )}
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <FilePreview url={resource.file_url} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={requiresSignature}
                                        onChange={(e) => setRequiresSignature(e.target.checked)}
                                    />
                                }
                                label="Requires signature"
                            />
                            {requiresSignatureError && (
                                <ErrorMessage>{requiresSignatureError}</ErrorMessage>
                            )}
                        </FormControl>
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                    <Button shade="secondary" onClick={onSave}>Save Resource</Button>
                </Box>
            </Box>
            <MUIModal open={showDeleteModal} onClose={closeDeleteResourceModal}>
                <ConfirmModal
                    title="Are you sure you want to delete this resource?"
                    subtitle="To undo this action you'll need to contact the Parentswarm team. If this resource has been shared with a client, they will still be able to access it."
                    cta="Confirm Delete"
                    ctaAction={onDeleteResource}
                    cancelAction={closeDeleteResourceModal}
                />
            </MUIModal>
        </Modal>
    );
}

export default ResourceForm;
