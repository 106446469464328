// action - state management
import {
  ACCOUNT_INITIALIZE,
  LOGIN,
  LOGOUT,
  SET_USER,
  SET_VERIFIED,
  SET_PAYWALL,
} from './actions';

export const initialState = {
  token: '',
  isLoggedIn: false,
  isVerified: false,
  isInitialized: false,
  user: null,
  paywall: false,
};

const accountReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, isVerified, user, token } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isVerified,
        isInitialized: true,
        paywall: false,
        token,
        user,
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isVerified: false,
        paywall: false,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
        isVerified: false,
        token: '',
        user: null,
        paywall: false,
      };
    }
    case SET_VERIFIED: {
      return {
        ...state,
        isVerified: true,
      };
    }
    case SET_USER: {
      const { user } = action.payload;
      return {
        ...state,
        user,
      };
    }
    case SET_PAYWALL: {
      const { paywall, paywall_type } = action.payload;
      return {
        ...state,
        paywall,
        paywall_type,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
