/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const LeftSectionDiv = styled.div`
padding-right: 2em;
border-right: 1px solid #d3d3d3;
`;

export const RightSectionDiv = styled.div`
padding-left: 2em;
padding-top: .5em;
border-left: 1px solid #d3d3d3;
@media (max-width: 768px) {
   border-left: none;
   padding-left: 0;
}
`;

export const ProfileDetailsWrapper = styled.div`
padding: 1em 1.5em;
display: flex;
justify-content: flex-start;
background-color: #5371ff;
border-radius: 10px;
border-right: 1px solid #d3d3d3;
`;

export const ProfileDetails = styled.div`
margin-top: .5em;
margin-left: 1em;
`;

export const ProfileName = styled.div`
color: #FFFFFF;
font-weight: 13px;
`;

export const ProfileRatings = styled.div`
margin-top: .3em;
color: #FFFFFF;
`;

export const RatingsText = styled.text`
margin-top: .4em;
font-size: 11px;
color: #FFFFFF;
margin-left: .4em;
`;

export const DisabledText = styled.text`
font-size: 11px;
color: #808080;
margin-left: ${({ margin }) => (margin ? '.4em' : null)};
`;

export const BalanceWrapper = styled.div`
border: 1px solid #d3d3d3;
margin-top: 1em;
border-radius: 10px;
padding: .6em 1.3em;
`;

export const BalanceText = styled.text`
font-size: 10px;
color: #5371ff;
letter-spacing: 4px;
`;

export const AmountDiv = styled.div`
display: flex;
justify-content: space-between;
margin-top: .3em;
`;

export const Amount = styled.text`
font-size: 18px;
font-weight: 700;
color: #5371ff;
letter-spacing: 2px;
`;

export const BankText = styled.text`
font-size: 12px;
color: #5371ff;
font-weight: 700;
margin-top: .4em;
`;

export const ProfileInfo = styled.div`
margin-top: 2em;
`;

export const ProfileHeading = styled.div`
margin-bottom: .5em;
font-size: 18px;
font-weight: 700;
font-family: 'Cabin';
`;

export const ImageContainer = styled.div`
display: flex;
justify-content: end;
`;

export const ImageIcon = styled.div`
margin-top: ${({ margin }) => (margin ? '.5em' : '')};
margin-bottom: .5em;
text-align: left;
margin-left: 1em;
`;

export const ProfileEditIcon = styled.div`
top: 50px;
left: 50px;
position: absolute;
background: #FFFFFF;
width: 25px;
height: 22px;
padding-top: 3px;
border-radius: 50px;
opacity: 0.7;
`;

export const ProfilePicSaveIcon = styled.div`
top: -25px;
left: 50px;
position: absolute;
background: #FFFFFF;
width: 25px;
height: 22px;
padding-top: 3px;
border-radius: 50px;
opacity: 0.7;
cursor: pointer;
`;

export const ProfilePicCancelIcon = styled.div`
top: -25px;
left: 0;
position: absolute;
background: #FFFFFF;
width: 25px;
height: 23px;
padding-top: 2px;
border-radius: 50px;
opacity: 0.7;
cursor: pointer;
`;

export const FieldRow = styled.div`
border-bottom: 1px solid #d3d3d3;
display: flex;
align-items: center;
padding-bottom: 5px;
font-family: 'Cabin';
`;

export const ProfileFields = styled.div`
font-size: 12px;
color: #808080;
padding-top: 10px;
`;

export const ProfileValue = styled.div`
font-size: 12px;
text-align: right;
font-weight: 700;
padding-top: 10px;
`;

export const AboutMeTextArea = styled.textarea`
width: 100%;
border: ${({ theme, border }) => (!border ? `1px solid #d3d3d3` : 'none')};
padding-bottom: 2em;
margin-bottom: 2em;
background-color: #FFFFFF;
line-height: 18px;
font-size: 12px;
text-align: left;
border-bottom: 1px solid #d3d3d3;
font-family: 'Cabin';
white-space: pre-line;
`;

export const AboutMe = styled.div`
padding-bottom: 2em;
margin-bottom: 2em;
background-color: #FFFFFF;
line-height: 18px;
font-size: 12px;
text-align: left;
border-bottom: 1px solid #d3d3d3;
font-family: 'Cabin';
white-space: pre-line;
`;

export const Listing = styled.li`
font-size: 12px;
margin-top: .9em;
width: 100%
`;

export const CategoriesDiv = styled.div`
width: 100%;
padding-bottom: 1.7em;
margin-bottom: 1.6em;
border-bottom: 1px solid #d3d3d3;
`;

export const CertificationDiv = styled.div`
width: 100%;
padding-bottom: 1.7em;
`;

export const ProfileInfoField = styled.input`
border: ${({ theme, border }) => (!border ? `1px solid #d3d3d3` : 'none')};
border-radius: 10px;
padding: ${({ border }) => (!border ? '.5em' : 'none')};
text-align: right;
width: 90%;
background-color: #FFFFFF;
font-weight: ${({ fontWeight }) => (fontWeight ? 700 : '')};
font-family: 'Cabin';
`;

export const ReadMoreText = styled.text`
color: #5371ff;
cursor: pointer;
`;

export const ScrollableAboutMe = styled.div`
height: 26vh;
overflow-y: auto;
margin-bottom: 2em;
background-color: #FFFFFF;
line-height: 18px;
font-size: 12px;
text-align: left;
border-bottom: 1px solid #d3d3d3;
`;

export const CategoryCheckboxes = styled.input`
font-size: 12px;
margin-top: .9em;
accent-color: #5371ff; 
`;

export const CategoryLabels = styled.label`
font-size: 12px;
margin-top: .9em;
padding-bottom: 1em;
`;

export const CertificateField = styled.input`
border: 1px solid #d3d3d3;
border-radius: 10px;
padding: .5em;
width: 100%;
background-color: #FFFFFF;
margin-bottom: 1em;
`;

export const OthersCategoryField = styled.input`
border: 1px solid #d3d3d3;
border-radius: 10px;
padding: .5em;
text-align: right;
background-color: #FFFFFF;
margin: .5em 0em 0em;
`;

export const AddMore = styled.button`
background-color: #5371ff;
color: #FFFFFF;
border-radius: 5px;
font-size: 12px;
border: none;
padding: .5em;
`;

export const ErrorMessage = styled.p`
color: red;
margin: 0px;
font-size: 13px;
`;

export const ProfileImage = styled.img`
border-radius: 50%;
`;

export const ProfileImageEdit = styled.img`
position: absolute;
top: 4.2em;
left: 4.3em;
border-radius: 30%;
background-color: #621158;
padding: 5px;
`;

export const ProfileLeft = styled.div`
  border-radius: 10px;
  background: #5371ff;
  color: #FFFFFF;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;