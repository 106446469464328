import React, { useState, ChangeEvent } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

import { Alert, Button, TextField, Box, Grid, Popover } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import customColorImg from '../../../assets/images/color-picker.png';

const DEFAULT_COLORS = ['#FFFFFF', '#F67F7F', '#FFEEEE', '#5371FF', '#FCEFFE'];

interface ColorPickerProps {
    predefinedColors?: string[];
    selectedColor?: string;
    setSelectedColor: (color: string) => void;
}

const ColorPicker = ({ predefinedColors = DEFAULT_COLORS, selectedColor, setSelectedColor }: ColorPickerProps) => {
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [hexError, setHexError] = useState(false);

    const handleHexInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setHexError(false);

        const hexValue = e.target.value;
        setSelectedColor(hexValue);

        if (!(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexValue))) {
            setHexError(true);
        }
    };

    const handleColorChangeComplete = (color: ColorResult) => {
        setSelectedColor(color.hex);
        handleCloseColorPicker();
    };

    const handleOpenColorPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setShowPicker(true);
    };

    const handleCloseColorPicker = () => {
        setAnchorEl(null);
        setShowPicker(false);
    };

    const isCustomColor = !predefinedColors.includes(selectedColor);

    return (
        <Box>
            {/* Display predefined colors */}
            <Grid container spacing={2}>
                {predefinedColors.map((color) => (
                    <Grid item xs={6} sm={4} md={2}>
                        <Button
                            key={color}
                            variant="contained"
                            style={{
                                backgroundColor: color,
                                color: '#210B2B',
                                height: 60,
                                border: `${color === selectedColor ? '2' : '1'}px solid ${color === selectedColor ? '#210B2B' : '#949494'}`
                            }}
                            onClick={() => setSelectedColor(color)}
                            fullWidth
                        >
                            {color === selectedColor ? <CheckRoundedIcon /> : null}
                        </Button>
                    </Grid>
                ))}
                {/* Custom color option */}
                <Grid item xs={6} sm={4} md={2}>
                    <Button
                        variant="contained"
                        onClick={handleOpenColorPicker}
                        style={{
                            height: 60,
                            color: '#210B2B',
                            backgroundImage: `url(${customColorImg})`,
                            backgroundSize: 'cover',
                            border: `${isCustomColor ? '2' : '1'}px solid ${isCustomColor ? '#210B2B' : '#949494'}`,
                        }}
                        aria-describedby="color-picker"
                        fullWidth
                    >
                        {isCustomColor ? <CheckRoundedIcon /> : null}
                    </Button>
                </Grid>

                {/* Show color picker if needed */}
                <Popover
                    id="color-picker"
                    open={showPicker}
                    anchorEl={anchorEl}
                    onClose={handleCloseColorPicker}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <SketchPicker
                        color={selectedColor}
                        onChangeComplete={handleColorChangeComplete}
                    />
                </Popover>
            </Grid>

            {/* Hex input */}
            <Box mt={2}>
                <TextField
                    label="Hex Color"
                    variant="outlined"
                    value={selectedColor}
                    onChange={handleHexInputChange}
                />
            </Box>

            {/* Error handling */}
            {hexError && (
                <Box mt={2}>
                    <Alert color="error">Please input a valid hex color code</Alert>
                </Box>
            )}
        </Box>
    );
};

export default ColorPicker;
