import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, CircularProgress, Modal, Stack } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { fetchFolderNotes } from '../../clients/note';
import Button from '../common/button';
import Note from './index';
import NoteForm from './note-form';
import DrawerContainer from '../common/drawer';
import { NoResults } from '../../pages/questions/questions.style';

function NotesList({ folder, onClose }) {
    const token = useSelector((state) => state.account.token);

    const [notes, setNotes] = useState([]);
    const [openNewNote, setOpenNewNote] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchAndSetNotes = async () => {
        setLoading(true);

        const fetchedNotes = await fetchFolderNotes(token, folder.id);
    
        if (fetchedNotes) {
          setNotes(fetchedNotes);
        }

        setLoading(false);
    };

    const newNoteClose = () => {
        setOpenNewNote(false);
        fetchAndSetNotes();
    };

    useEffect(() => {
        fetchAndSetNotes();
    }, []);
    
    return (
        <>
            <DrawerContainer title="Notes" onDismiss={onClose}>
                <Stack mt={-2} mb={2}>
                    <Button shade="secondary" onClick={() => setOpenNewNote(true)}>
                        <AddRoundedIcon />
                        Add Notes
                    </Button>
                </Stack>
                {loading && (
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <CircularProgress style={{ color: '#5371ff' }} />
                    </Box>
                )}
                {notes.length === 0 && !loading && (
                    <Box>
                        <NoResults>
                            Get started with client notes by adding your first one.
                        </NoResults>
                    </Box>
                )}
                {notes.map((note) => (
                    <Note note={note} />
                ))}
            </DrawerContainer>
            <Modal
                open={openNewNote}
                onClose={newNoteClose}
            >
                <NoteForm
                    folderId={folder.id}
                    onClose={newNoteClose}
                />
            </Modal>
        </>
    );
}

export default NotesList;
