import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Box, Button, ButtonGroup, Grid, Stack, TextField, Typography } from '@mui/material';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import DynamicFeedOutlinedIcon from '@mui/icons-material/DynamicFeedOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import DuoOutlinedIcon from '@mui/icons-material/DuoOutlined';
import AllInclusiveOutlinedIcon from '@mui/icons-material/AllInclusiveOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import ForkRightOutlinedIcon from '@mui/icons-material/ForkRightOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import LinkIcon from '@mui/icons-material/Link';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CampaignIcon from '@mui/icons-material/Campaign';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import Lottie from 'react-lottie-player';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import config from '../../config';
import { ACCOUNT_TYPES, PaywallTypes } from '../../constants';
import Subscription from './subscription';
import CheckoutForm from './checkout-form';
import { getSubscriptionBaseProduct, getSubscriptionBasePrice } from '../../utils/profile';
import { capitalizeFirstLetter } from '../../utils/stringHelpers';
import { fetchPaymentMethods, setSubscription, applyCoupon } from '../../clients/profile';
import {
    SelectedPaymentContainer,
    PaymentContainer,
} from './payment.style';
import {
    ProductTitle,
} from '../product/product.style';
import { SET_PAYWALL } from '../../store/actions';
import SuccessAnimation from '../../assets/animations/success-animation.json';

const stripePromise = loadStripe(
    config.currentEnv === 'production' ?
    'pk_live_51LPUdOHf16Ud2OOxIA2TxiTEluSTEgonownCZgfuFp1TPeQVRWTL9nyRyhwTUukLBFxOkDKCZ1fCTxXegZqPVB6g00UWCQeR8N' :
    'pk_test_51LPUdOHf16Ud2OOxGrqOrYIX4jQX7gOjT3Xop4J8jSQPTG2gb1qqXdCMPm7T3gpZ9zPub7s28XYnjFgAIjOBox2200IoGhQhWk'
);

const CADENCES = {
    MONTHLY: 'month',
    ANNUALLY: 'year',
};

function Subscribe({ paywall = false, cancelInProgress = false, paywall_type = PaywallTypes.BASE, displayPlans = true }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const planInterval = user.profile.subscription?.plan.interval;

    const currentProduct = getSubscriptionBaseProduct(user);
    const currentPrice = getSubscriptionBasePrice(user);

    const [showPlans, setShowPlans] = useState(displayPlans);
    const [selectedSubscription, setSelectedSubscription] = useState();
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(user.profile.default_payment_method);
    const [addNewMethod, setAddNewMethod] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [enterCoupon, setEnterCoupon] = useState(false);
    const [couponCode, setCouponCode] = useState();
    const [couponError, setCouponError] = useState(false);
    const [addOnServices, setAddOnServices] = useState(currentPrice === null && user.profile.subscription?.plan.id ? user.profile.subscription.quantity : 0);
    const [showSuccess, setShowSuccess] = useState(false);
    const [error, setError] = useState();
    const [selectedCadence, setSelectedCadence] = useState(user.profile.profile_type === 'expert' ? (planInterval || CADENCES.ANNUALLY) : 'default');

    const appearance = {
        theme: 'stripe',
    };

    const options = {
        appearance,
    };

    const getPaymentMethods = async () => {
        const cards = await fetchPaymentMethods(navigate, user, token);
        setPaymentMethods(cards);

        if (!selectedPaymentMethod && cards.length) {
            setSelectedPaymentMethod(cards[0].id);
        }
    };

    const submitCouponCode = async () => {
        setCouponError(false);
    
        const success = await applyCoupon(
            dispatcher,
            navigate,
            user,
            token,
            couponCode,
        );

        if (success) {
            setEnterCoupon(false);
            setCouponCode(undefined);
        } else {
            setCouponError(true);
        }
    };

    useEffect(() => {
        getPaymentMethods();
    }, []);

    useEffect(() => {
        setError(undefined);
    }, [selectedSubscription]);

    const PARENT_SUBSCRIPTIONS = [
        {
            priceMap: {
                default: {
                    id: null,
                    amount: 0,
                },
            },
            productId: null,
            title: 'Member',
            ctaText: 'Select Plan',
            oneTimeCharge: 0,
            parentFeatures: [
                {
                    title: '24/7 digital concierge',
                    renderIcon: () => <RoomServiceOutlinedIcon />,
                },
                {
                    title: 'Browse and search partner experts, resources, products, and services',
                    renderIcon: () => <SearchRoundedIcon />,
                },
                {
                    title: 'Secure personal dashboard with saved favorites and recommendations',
                    renderIcon: () => <BookmarksOutlinedIcon />,
                },
                {
                    title: 'Insider perks and deals',
                    renderIcon: () => <LocalOfferOutlinedIcon />,
                },
            ],
        },
        {
            priceMap: {
                default: {
                    id: config.currentEnv === 'production' ? 'price_1PDfjaHf16Ud2OOxxeuyVMhr' : 'price_1P9g9CHf16Ud2OOxFRrHpN25',
                    amount: 99,
                },
            },
            productId: config.currentEnv === 'production' ? 'prod_P4DtBzz7th2Fmr' : 'prod_P4A14dEASQ2Jol',
            title: 'VIP Member',
            ctaText: 'Select Plan',
            oneTimeCharge: 9900,
            parentFeatures: [
                {
                    title: 'Everything in Member, plus...',
                    renderIcon: () => <AddBoxOutlinedIcon />,
                },
                {
                    title: 'Digital bonus kit with 20+ of our most-loved webinars, workbooks, checklists, and audio resources',
                    renderIcon: () => <AutoAwesomeMotionOutlinedIcon />,
                },
                {
                    title: 'Personalized parenting dream team: 💎 A curated PDF report hand-picking the perfect prenatal and postpartum experts and resources tailored just for you based on your unique questionnaire responses.',
                    renderIcon: () => <Diversity1OutlinedIcon />,
                },
                {
                    title: '$50 reimbursement for purchases from approved supplier partners on services and/or products',
                    renderIcon: () => <CardGiftcardOutlinedIcon />,
                },
                {
                    title: 'Free welcome gift mailed to your house (US only)',
                    renderIcon: () => <DiscountOutlinedIcon />,
                },
            ],
        },
        {
            priceMap: {
                default: {
                    id: config.currentEnv === 'production' ? 'price_1PfDLZHf16Ud2OOxL1TW5nfe' : 'price_1PfDQJHf16Ud2OOxgsUJgebo',
                    amount: 299,
                },
            },
            productId: config.currentEnv === 'production' ? 'prod_QWFrZx971l4o38' : 'prod_QWFwYWeV5svJwg',
            title: 'Parentswarm Pathfinder',
            ctaText: 'Select Plan',
            oneTimeCharge: 29900,
            parentFeatures: [
                {
                    title: 'Everything in VIP Member, plus...',
                    renderIcon: () => <AddBoxOutlinedIcon />,
                },
                {
                    title: 'In-Depth Consultation: In a 1-hour private virtual meeting, Parentswarm CEO, Carly, will guide you through a detailed assessment, gaining a deep understanding of your family\'s parenting style, concerns, preferences, and specific requirements.',
                    renderIcon: () => <ForkRightOutlinedIcon />,
                },
            ],
        },
    ];

    const EXPERT_BASE_SUBSCRIPTIONS = [
        {
            priceMap: {
                month: {
                    id: null,
                    amount: 0,
                },
                year: {
                    id: null,
                    amount: 0,
                },
            },
            productId: null,
            title: 'Free',
            ctaText: 'Select Plan',
            listingFeatures: [
                {
                    title: 'Build your listing page',
                    renderIcon: () => <ContactPageOutlinedIcon />,
                },
                {
                    title: 'Share your listing page with your network',
                    renderIcon: () => <ShareRoundedIcon />,
                },
            ],
        },
        {
            priceMap: {
                year: {
                    id: config.currentEnv === 'production' ? 'price_1PikPVHf16Ud2OOxrKWaxYTP' : 'price_1PikOpHf16Ud2OOxo2VqgqYs',
                    amount: 10,
                },
            },
            productId: config.currentEnv === 'production' ? 'prod_QZuDiukwpEu9v8' : 'prod_QZuDK6cUE2Gqmf',
            title: 'Resource',
            ctaText: 'Select Plan',
            listingFeatures: [
                {
                    title: 'Publish your listing page to be included in our search and recommendations for Parents',
                    renderIcon: () => <SearchRoundedIcon />,
                },
                {
                    title: 'Digital bonus kit of resources to help you grow your business',
                    renderIcon: () => <DynamicFeedOutlinedIcon />,
                },
                {
                    title: 'Gain exposure by contributing to our Digital Bonus Kit for Parents',
                    renderIcon: () => <PermMediaOutlinedIcon />,
                },
                {
                    title: 'Get your product in Parents hands by contributing Product Samples we will distribute',
                    renderIcon: () => <CategoryRoundedIcon />,
                },
            ],
        },
    ];

    const subscribe = async () => {
        setProcessing(true);
        setError(undefined);
    
        const result = await setSubscription(
            dispatcher,
            navigate,
            user,
            token,
            selectedSubscription.priceMap[selectedCadence].id,
            selectedPaymentMethod,
            selectedSubscription.quantity,
            selectedSubscription.oneTimeCharge,
        );
        
        if (result.success) {
            setSelectedSubscription(undefined);
            setShowSuccess(true);
        } else {
            setError(result.message);
        }

        setProcessing(false);
    };

    const closePaywall = () => {
        dispatcher({
            type: SET_PAYWALL,
            payload: {
                paywall: false,
            },
        });
    };

    return (
        <>
            {selectedSubscription !== undefined ? (
                <Grid container>
                    <Grid item xs={12}>
                        <Box mb={2} textAlign="center">
                            <ProductTitle>
                                {selectedSubscription.priceMap[selectedCadence].amount > 0 ? `Subscribe to ${selectedSubscription.title}` : 'Cancel Plan'}
                            </ProductTitle>
                        </Box>
                        <Box sx={{ width: 300, textAlign: 'center', margin: '0 auto' }}>
                            {selectedSubscription.priceMap[selectedCadence].amount > 0 ? (
                                <Stack spacing={2}>
                                    {paymentMethods.map((paymentMethod) => (
                                        <Box key={paymentMethod.id}>
                                            {selectedPaymentMethod === paymentMethod.id ? (
                                                <SelectedPaymentContainer onClick={() => setSelectedPaymentMethod(paymentMethod.id)}>
                                                    {capitalizeFirstLetter(paymentMethod.card.brand) || 'Card'} ending in &bull;&bull;&bull;&bull; {paymentMethod.card.last4}
                                                </SelectedPaymentContainer>
                                            ) : (
                                                <PaymentContainer onClick={() => setSelectedPaymentMethod(paymentMethod.id)}>
                                                    {capitalizeFirstLetter(paymentMethod.card.brand) || 'Card'} ending in &bull;&bull;&bull;&bull; {paymentMethod.card.last4}
                                                </PaymentContainer>
                                            )}
                                        </Box>
                                    ))}
                                    {paymentMethods.length > 0 && !addNewMethod && (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setAddNewMethod(true)}
                                        >
                                            + Add Payment Method
                                        </Button>
                                    )}
                                    {(paymentMethods.length === 0 || addNewMethod) && (
                                        <Elements
                                            stripe={stripePromise}
                                            options={options}
                                        >   
                                            <CheckoutForm
                                                onSuccess={() => {
                                                    setAddNewMethod(false);
                                                    getPaymentMethods();
                                                }}
                                            />
                                        </Elements>
                                    )}
                                    {user.profile.discount?.coupon && (
                                        <Box sx={{ fontFamily: 'Open Sans', fontSize: '13px' }} mb={2}>
                                            Applied Coupon: {user.profile.discount.coupon.id}
                                        </Box>
                                    )}
                                    {couponError && (
                                        <Box sx={{ fontFamily: 'Open Sans', fontSize: '13px' }} mb={2}>
                                            Invalid coupon code
                                        </Box>
                                    )}
                                    {!user.profile.discount?.coupon && !enterCoupon && (
                                        <Button
                                            color="primary"
                                            variant="text"
                                            onClick={() => setEnterCoupon(true)}
                                        >
                                            Enter a Coupon Code
                                        </Button>
                                    )}
                                    {!user.profile.discount?.coupon && enterCoupon && (
                                        <>
                                            <TextField
                                                label="Coupon code"
                                                id="coupon-code"
                                                variant="outlined"
                                                value={couponCode}
                                                onChange={(e) => setCouponCode(e.target.value)}
                                                sx={{ width: '100%' }}
                                            />
                                            <Button
                                                color="secondary"
                                                variant="contained"
                                                onClick={submitCouponCode}
                                            >
                                                Apply Coupon
                                            </Button>
                                        </>
                                    )}
                                </Stack>
                            ) : (
                                <Box sx={{ fontFamily: 'Open Sans' }}>
                                    Are you sure you want to discontinue your current plan?
                                </Box>
                            )}
                            <Box sx={{ mt: 2, mb: 2 }}>
                                <Stack spacing={2}>
                                    {error && (
                                        <Alert color="error">{error}</Alert>
                                    )}
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => setSelectedSubscription(undefined)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={(!selectedPaymentMethod || processing) && selectedSubscription.priceMap[selectedCadence].amount > 0}
                                            onClick={subscribe}
                                        >
                                            Confirm
                                        </Button>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <>
                    {!showSuccess && (
                        <Grid container spacing={2}>
                            {paywall && (
                                <Grid item xs={12}>
                                    {paywall_type === PaywallTypes.BASE && (
                                        <Box sx={{ textAlign: 'center', fontFamily: 'Open Sans' }}>
                                            This feature requires a paid subscription, please upgrade to continue.
                                        </Box>
                                    )}
                                    {paywall_type === PaywallTypes.ADD_ON && (
                                        <Box sx={{ textAlign: 'center', fontFamily: 'Open Sans' }}>
                                            <Typography variant="subtitle1">You've reached your plan limit on number of active services.</Typography>

                                            <Box mt={1}>
                                                <Typography variant="h6">
                                                    To continue, please upgrade to the Business plan for unlimited services or add á-la-carte Storefront Tokens.
                                                </Typography>
                                            </Box>

                                            <Box mt={2} mb={4}>
                                                <Typography variant="subtitle1">
                                                    What is a Storefront Token? Our Basic plan allows for 1 active service on your profile.{' '}
                                                    You can add more by subscribing to Storefront Tokens for $5 / mo each.{' '}
                                                    Each token lets you include another service on your profile.
                                                </Typography>
                                            </Box>

                                            {!showPlans && <Button size="large" variant="contained" onClick={() => setShowPlans(true)}>Continue</Button>}
                                        </Box>
                                    )}
                                </Grid>
                            )}
                            {(user.profile.profile_type === ACCOUNT_TYPES.EXPERT  && showPlans) ? (
                                <>
                                    {!currentPrice && paywall_type !== PaywallTypes.BASE && (
                                        <Grid item xs={12}>
                                            <Box mb={4} mt={2} p={2} borderRadius={1} sx={{ border: '1px solid #5371ff'}}>
                                                <Typography sx={{ fontSize: 16, fontFamily: 'Nunito' }} color="text.secondary" gutterBottom>
                                                    Add-Ons
                                                </Typography>
                                                <Box>
                                                    <Typography variant="h6" component="div">
                                                        Storefront Tokens
                                                    </Typography>
                                                    <Box mt={2} display="flex" alignItems="center" justifyContent="space-evenly">
                                                        <Typography variant="body1" component="div">
                                                            $5 / month
                                                        </Typography>
                                                        <Typography>x</Typography>
                                                        <Box>
                                                            <TextField
                                                                type="number"
                                                                value={addOnServices}
                                                                onChange={(e) => setAddOnServices(e.target.value)}
                                                                inputProps={{ maxLength: 2, min: 0, sx: { width: 50 } }}
                                                            />
                                                        </Box>
                                                        <Typography>=</Typography>
                                                        <Typography variant="body1" component="div">
                                                            ${5 * addOnServices} / mo
                                                        </Typography>
                                                        <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => setSelectedSubscription({
                                                                    priceId: config.currentEnv === 'production' ? 'price_1Nb4X8Hf16Ud2OOxDmPZ2L7d' : 'price_1NaMdJHf16Ud2OOx7icgYpJh',
                                                                    productId: config.currentEnv === 'production' ? 'prod_ONqD4BBLBdLASP' : 'prod_OBcbMipF0RXisf',
                                                                    title: `${addOnServices} Storefront Token${addOnServices == 1 ? '' : 's'}`,
                                                                    price: 5 * addOnServices,
                                                                    quantity: addOnServices,
                                                                })}
                                                            >
                                                                Update
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                    <Box mt={1} flexDirection="row-reverse" sx={{ display: { xs: 'flex', sm: 'none' }}}>
                                                        <Button
                                                                variant="contained"
                                                                onClick={() => setSelectedSubscription({
                                                                    priceId: config.currentEnv === 'production' ? 'price_1Nb4X8Hf16Ud2OOxDmPZ2L7d' : 'price_1NaMdJHf16Ud2OOx7icgYpJh',
                                                                    productId: config.currentEnv === 'production' ? 'prod_ONqD4BBLBdLASP' : 'prod_OBcbMipF0RXisf',
                                                                    title: `${addOnServices} Storefront Token${addOnServices == 1 ? '' : 's'}`,
                                                                    price: 5 * addOnServices,
                                                                    quantity: addOnServices,
                                                                })}
                                                            >
                                                                Update
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}
                                    {/*<Grid item xs={12}>
                                        <Box textAlign="center">
                                            <ButtonGroup variant="contained" aria-label="outlined secondary button group">
                                                <Button
                                                    onClick={() => setSelectedCadence(CADENCES.MONTHLY)}
                                                    variant={selectedCadence === CADENCES.MONTHLY ? 'contained' : 'outlined'}
                                                >
                                                    Paid Monthly
                                                </Button>
                                                <Button
                                                    onClick={() => setSelectedCadence(CADENCES.ANNUALLY)}
                                                    variant={selectedCadence === CADENCES.ANNUALLY ? 'contained' : 'outlined'}
                                                >
                                                    Paid Annually
                                                </Button>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>*/}
                                    {EXPERT_BASE_SUBSCRIPTIONS.map((sub) => (
                                        <Grid item xs={12} md={6}>
                                            <Subscription
                                                subscription={sub}
                                                isCurrentSubscription={currentPrice === sub.priceMap[selectedCadence].id}
                                                onSelectPlan={() => setSelectedSubscription(sub)}
                                                onCancelPlan={() => setSelectedSubscription({
                                                    priceId: null,
                                                    productId: null,
                                                    title: 'Free',
                                                    price: 0,
                                                })}
                                                cancelInProgress={cancelInProgress}
                                                selectedCadence={selectedCadence}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {PARENT_SUBSCRIPTIONS.map((sub) => (
                                        <Grid item xs={12} sm={6}>
                                            <Subscription
                                                subscription={sub}
                                                isCurrentSubscription={currentPrice === sub.priceMap['default'].id}
                                                onSelectPlan={() => setSelectedSubscription(sub)}
                                                onCancelPlan={() => setSelectedSubscription({
                                                    priceId: null,
                                                    productId: null,
                                                    title: 'Free',
                                                    price: 0,
                                                })}
                                                cancelInProgress={cancelInProgress}
                                                selectedCadence={'default'}
                                            />
                                        </Grid>
                                    ))}
                                </>
                            )}
                        </Grid>
                    )}
                </>
            )}
            {showSuccess && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Box mb={-4}><Typography variant="h6">You're plan has been updated!</Typography></Box>
                    <Box mb={-6}>
                        <Lottie
                            loop={false}
                            animationData={SuccessAnimation}
                            play
                            style={{ width: 250, height: 250 }}
                        />
                    </Box>
                    {paywall_type === PaywallTypes.ADD_ON && paywall && (
                        <Box mb={4}>
                            <Typography variant="subtitle1">
                                You can now continue with managing the active services in your storefront.
                            </Typography>
                        </Box>
                    )}
                    {paywall ? (
                        <Button variant="contained" onClick={closePaywall}>Close</Button>
                    ) : (
                        <Button variant="contained" onClick={() => setShowSuccess(false)}>View Plan</Button>
                    )}
                </Box>
            )}
        </>
    );
};

export default Subscribe;
