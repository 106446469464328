import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Box } from '@mui/material';

import Chip from '../../components/common/chip';
import ResourceThumbnail from '../../components/resource/resource-thumbnail';

export default function ResourceCard({ resource, selected, onSelect, onOpen, selectMode = false, share }) {
    return (
        <Box 
            borderRadius={2}
            p={2}
            textAlign="center"
            sx={{
                cursor: 'pointer',
                background: (selectMode && share) ? '#D6D6D6' : '#FFFFFF',
                fontFamily: 'Nunito',
                fontWeight: 600,
                fontSize: '20px',
                border: selected ? '3px solid #5371ff' : `3px solid ${(selectMode && share) ? '#D6D6D6' : '#FFFFFF'}`,
                boxShadow: (selectMode && !selected && !share) ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : 'none',
                position: 'relative',
            }}
            onClick={(selectMode && !share) ? onSelect : onOpen}
        >
            <>
                {selectMode && share && (
                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <Chip color="white" label={`Sent on ${moment(share.shared_at).format('M/D/YYYY')}`} />
                    </Box>
                )}
                <Box height={200}><ResourceThumbnail url={resource.file_url} height="100%" /></Box>
                <Box mt={2}>
                    {resource.title}
                </Box>
            </>
        </Box>
    );
}
