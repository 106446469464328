import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Avatar, Box, Divider, Modal, Tooltip } from '@mui/material';
import MiniProfile from '../session/mini-profile';
import ParticipantCount from './participant-count';
import {
  BottomDetail,
  Card,
  Question,
  SessionDate,
} from '../session/Session.style';
import SessionType from '../session/session-type';
import ProductPurchase from '../product/purchase';
import { ACCOUNT_TYPES, PRODUCT_TYPES } from '../../constants';

function GroupSessionCard({ product, variant, onEdit = null, purchased = false }) {
  const user = useSelector((state) => state.account.user);

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <Card onClick={() => setOpenDetails(true)} style={{ border: '1px solid #E8E8E8'}}>
        <Box sx={{ display: 'flex', pb: 2 }}>
          <Avatar
            variant="square"
            sx={{ width: 100, height: 100, borderRadius: 1 }}
            alt={product.title}
            src={product.image_url}
          />
          <Box ml={2}>
            <Question>
              {product.title}
            </Question>
            {variant === ACCOUNT_TYPES.EXPERT ? (
              <ParticipantCount product={product} />
            ) : (
              <Box onClick={(e) => e.stopPropagation()}>
                <MiniProfile profile={product.seller} />
              </Box>
            )}
          </Box>
        </Box>
        <Divider />
        <BottomDetail>
          <SessionDate>
            <Tooltip title={moment(product.event_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}>
              {moment(product.event_date).tz(moment.tz.guess()).calendar().includes('at') ? (
                <span>{moment(product.event_date).tz(moment.tz.guess()).calendar()}</span>
              ) : (
                <span>{moment(product.event_date).tz(moment.tz.guess()).format('MMM D, h:mm a z')}</span>
              )}
            </Tooltip>
          </SessionDate>
          <SessionType productType={PRODUCT_TYPES.GROUP} variant={variant} />
        </BottomDetail>
      </Card>
      <Modal
        open={openDetails}
        onClose={() => setOpenDetails(false)}
      >
        <ProductPurchase
          product={product}
          onClose={() => setOpenDetails(false)}
          onEdit={onEdit}
          preview={user ? user.profile.id === product.seller.id : false}
          purchased={purchased}
        />
      </Modal>
    </>
  );
}

GroupSessionCard.propTypes = {
  product: PropTypes.any, // TODO: Replace with proper proptype
  variant: PropTypes.string,
};

export default GroupSessionCard;