import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchVersion } from '../../clients/version';

const VersionChecker = () => {
    const dispatcher = useDispatch();

    useEffect(() => {
        fetchVersion(dispatcher);

        // Periodically check if there is a new version available
        const intervalId = setInterval(() => {
            fetchVersion(dispatcher);
        }, 60000);  // Check every 60 seconds

        return () => clearInterval(intervalId);
    }, []);

    return null;
};

export default VersionChecker;