import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Alert, Autocomplete, Box, Button, TextField } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from '../../styled_components/grid/Grid.style';
import { API } from '../../constants';
import { post, get, patch } from '../../services/api.services';
import {
  InputWrapperContainer, ServiceCategoriesWrapper,
  OtherBoxWrapper, NextButtonWrapper, ErrorMessage,
  OtherBoxInputContainer, InputTextarea
} from './service-categories.style';
import {
  Input,
  InputWrapper,
} from './parent-details.style';
import { SET_USER } from '../../store/actions';
import Chip from '../common/chip';
import { PARENT_CATEGORIES, PARENT_SPECIAL_INTEREST_GROUPS } from '../../utils/groups';

export default function ParentDetails({
  page,
  setPage,
  handleCrossSetupLater,
  handleBackBtn,
  selectedGroups,
  setSelectedGroups,
  parentJoinedReason,
  setParentJoinedReason,
  selectedSpecialInterests,
  setSelectedSpecialInterests,
}) {
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.account.user);
  const token = useSelector((state) => state.account.token);

  const handleSubmitNext = async () => {
    const result = await patch(
      `${API.PROFILE}${user.profile.id}/`,
      {
        parent_categories: selectedGroups.length ? selectedGroups.join(',') : null,
        parent_joined_reason: parentJoinedReason,
        parent_special_interest_groups: selectedSpecialInterests.length ? selectedSpecialInterests.join(',') : null,
      },
      token,
    );
    if (result.status === 403 || result.status === 401) {
      navigate('/login/parents');
    }
    else if (result.status === 200) {
      dispatcher({
        type: SET_USER,
        payload: {
          user: {
            ...user,
            profile: {
              ...result.data,
            },
          },
        },
      });

      navigate('/');
    }
    else {
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <ToastContainer />
      <Box sx={{ mt: 2, mb: 2, width: '100%' }}>
        <TextField
            id="parentJoinedReason"
            label="What brings you to Parentswarm?"
            variant="outlined"
            value={parentJoinedReason}
            onChange={(e) => setParentJoinedReason(e.target.value)}
            sx={{ width: "100%" }}
            multiline
            minRows={4}
            inputProps={{ maxLength: 1024 }}
        />
        <Autocomplete
            id="tags-filled"
            multiple
            value={selectedGroups}
            options={[
                ...PARENT_CATEGORIES,
            ].map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={option} color="secondaryLight" label={option} {...getTagProps({ index })} />
              ))
            }
            sx={{
              width: '100%',
              mt: 2,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="What stage of your parenting journey are you in?"
              />
            )}
            onChange={(event, newValue) => {
              setSelectedGroups(newValue);
            }}
          />
          <Autocomplete
            multiple
            id="special-interest"
            value={selectedSpecialInterests}
            options={[
                ...PARENT_SPECIAL_INTEREST_GROUPS,
            ].map((option) => option)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={option} color="secondaryLight" label={option} {...getTagProps({ index })} />
              ))
            }
            sx={{
              width: '100%',
              mt: 2,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="What special interest groups are you interested in?"
              />
            )}
            onChange={(event, newValue) => {
              setSelectedSpecialInterests(newValue);
            }}
          />
      </Box>

      <NextButtonWrapper height="auto" justifyContent="center">
        <Button variant="contained" color="primary" onClick={() => handleSubmitNext()} data-testid="nextBtn">Next</Button>
      </NextButtonWrapper>
      <Row justifyContent="space-between" width="100%">
        <Col>
          <Button onClick={() => handleBackBtn()} variant="text" color="primary">
            Back
          </Button>
        </Col>
        <Col>
          <Button onClick={() => handleCrossSetupLater()} variant="text" color="primary">
            Setup later
          </Button>
        </Col>
      </Row>
    </>
  );
}

ParentDetails.propTypes = {
  setPage: PropTypes.any,
  page: PropTypes.number,
  handleCrossSetupLater: PropTypes.any,
  handleBackBtn: PropTypes.any,
  selectedGroups: PropTypes.any,
  setSelectedGroups: PropTypes.any,
  selectedSpecialInterests: PropTypes.any,
  setSelectedSpecialInterests: PropTypes.any,
  parentJoinedReason: PropTypes.any,
  setParentJoinedReasons: PropTypes.any,
};
