import { patch } from '../services/api.services';
import { API } from '../constants';
import {
    SET_NOTIFICATION,
} from '../store/actions';

export const markNotificationRead = async (dispatcher, token, notification) => {
    const result = await patch(
      `${API.NOTIFICATION}${notification.id}/read/`,
      {
        read: true,
      },
      token,
    );

    if (result.status === 200) {
        if (result.data.length) {
            dispatcher({
              type: SET_NOTIFICATION,
              payload: {
                notification: result.data,
              },
            });
        }
    }
}
