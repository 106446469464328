import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';

import { API } from '../../constants';
import { SET_SESSION } from '../../store/actions';
import { post } from '../../services/api.services';
import DismissIcon from '../../assets/icons/Dismiss.svg';
import {
    ModalSmallContainer,
    ModalBody,
} from '../profile/Profile.style';
import Rate from './rate';
import DirectComment from './direct-comment';
import PlatformComment from './platform-comment';
import PrivateComment from './private-comment';
import ContactForm from './contact-form';
import Confirm from './confirm';

export default function Review({
    session,
    onClose,
}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [step, setStep] = useState(1);
    const [rating, setRating] = useState(session.review?.rating || 0);
    const [directComment, setDirectComment] = useState(session.review?.direct_comments || '');
    const [platformComment, setPlatformComment] = useState('');
    const [privateComment, setPrivateComment] = useState('');
    const [contactMe, setContactMe] = useState(null);

    const next = () => {
        submit();
        if (step === 1 && rating && rating < 3) {
            setStep(4);
        }
        else if (step === 3) {
            setStep(6);
        }
        else {
            setStep((prev) => prev + 1);
        }
    };

    const back = () => {
        if (step === 4) {
            setStep(1);
        }
        else if (step === 6 && rating && rating > 3) {
            setStep(3);
        }
        else {
            setStep((prev) => prev - 1);
        }
    };

    const submit = async () => {
        const result = await post(
            `${API.SESSION}${session.id}/review/`,
            {
              rating,
              direct_comments: directComment,
              private_comments: privateComment,
              platform_comments: platformComment,
              contact_me: contactMe,
            },
            token,
        );
    
        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            dispatcher({
              type: SET_SESSION,
              payload: {
                  session: result.data,
              },
            });
        }
        else {
          // TODO: Error states
        }
    };

    return (
        <ModalSmallContainer>
            <ModalBody>
                <Box sx={{ textAlign: 'right' }}>
                    <IconButton onClick={onClose}>
                        <img src={DismissIcon} alt="close" width={15} />
                    </IconButton>
                </Box>
                {step === 1 && (
                    <Rate
                        session={session}
                        next={next}
                        rating={rating}
                        setRating={setRating}
                    />
                )}
                {step === 2 && (
                    <DirectComment
                        session={session}
                        rating={rating}
                        directComment={directComment}
                        setDirectComment={setDirectComment}
                        next={next}
                        back={back}
                    />
                )}
                {step === 3 && (
                    <PlatformComment
                        platformComment={platformComment}
                        setPlatformComment={setPlatformComment}
                        next={next}
                        back={back}
                    />
                )}
                {step === 4 && (
                    <PrivateComment
                        privateComment={privateComment}
                        setPrivateComment={setPrivateComment}
                        next={next}
                        back={back}
                    />
                )}
                {step === 5 && (
                    <ContactForm
                        contactMe={contactMe}
                        setContactMe={setContactMe}
                        next={next}
                        back={back}
                    />
                )}
                {step === 6 && (
                    <Confirm
                        rating={rating}
                        onClose={onClose}
                        contactMe={contactMe}
                    />
                )}
            </ModalBody>
        </ModalSmallContainer>
    );
}
