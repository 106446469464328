// action - state management
import * as actionTypes from './actions';

export const initialState = {
  filters: {
    keyTerm: '',
    languages: [],
    minorityGroups: [],
    topics: [],
    productTypes: [],
    pricing: [],
    virtual: undefined,
    inPerson: undefined,
    radius: undefined,
    zip: undefined,
    numFilters: 0,
    insurances: [],
    locationsServed: [],
  },
  offset: 0,
  limit: 24,
  hasNextPage: true,
};

const searchReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.BUMP_PAGE: {
      return {
        ...state,
        offset: state.offset + state.limit,
      };
    }
    case actionTypes.SET_HAS_NEXT_PAGE: {
      return {
        ...state,
        hasNextPage: action.payload.hasNextPage,
      };
    }
    case actionTypes.SET_FILTERS: {
      let filtersCount = 0;

      const newState = {
        ...state,
        filters: { ...state.filters, ...action.payload },
        offset: 0,
        hasNextPage: true,
      };

      Object.keys(state.filters).forEach((key) => {
        if (
          initialState.filters[key] !== newState.filters[key] &&
          key !== 'numFilters'
        ) {
          filtersCount += 1;
        }
      });

      return {
        ...newState,
        filters: {
          ...newState.filters,
          numFilters: filtersCount,
        },
      };
    }
    case actionTypes.RESET_FILTERS:
      return {
        ...initialState,
        filters: {
          ...initialState.filters,
        },
      };
    default:
      return state;
  }
};

export default searchReducer;
