import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Container,
    Grid,
    Modal,
    Snackbar,
} from '@mui/material';
import { get } from '../../services/api.services';
import { API } from '../../constants';
import {
    PageTitle,
} from '../sessions/sessions.style';
import FolderCard, { FOLDER_VARIANTS } from '../../components/folder/folder-card';
import Button from '../../components/common/button';
import Invite from '../../components/folder/invite';
import { NoResults } from '../sessions/sessions.style';

function Clients() {
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);

    const [folders, setFolders] = useState([]);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [copied, setCopied] = useState(false);
    const [sent, setSent] = useState(false);

    const fetchFolders = async () => {
        const result = await get(
            API.FOLDER,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setFolders(result.data);
        }
        else {
            // TODO: Handle errors
        }
    }

    const closeInviteModal = () => {
        setOpenInviteModal(false);
        fetchFolders();
    }

    useEffect(() => {
        fetchFolders();
    }, []);

    return (
        <Container maxWidth="lg">
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={copied}
                autoHideDuration={4000}
                onClose={() => setCopied(false)}
                message="Invite link has been copied to your clipboard"
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={sent}
                autoHideDuration={4000}
                onClose={() => setSent(false)}
                message="Invite sent!"
            />
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <PageTitle>
                    My Clients
                </PageTitle>
                <Button onClick={() => setOpenInviteModal(true)} shade="secondary">Invite Client</Button>
            </Box>
            {folders.length > 0 ? (
                <Grid container spacing={2}>
                    {folders.filter((folder) => folder.folder_type === 'CLIENT').map((folder) => (
                        <Grid key={folder.id} item xs={12} md={6}>
                            <FolderCard
                                folder={folder}
                                variant={folder.owner.id === user.profile.id ? FOLDER_VARIANTS.OWNER : FOLDER_VARIANTS.MEMBER}
                            />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <NoResults>
                    Your clients will show up here. Get started by inviting your first one.
                </NoResults>
            )}
            <Modal
                open={openInviteModal}
                onClose={closeInviteModal}
            >   
                <Invite onClose={closeInviteModal} />
            </Modal>
        </Container>
    );
}

export default Clients;