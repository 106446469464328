import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Box, Button, Divider, Drawer, IconButton, Modal, Tooltip } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ScheduleIcon from '@mui/icons-material/Schedule';

import AddMeetingLink from './add-meeting-link';
import MiniProfile from './mini-profile';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PickNewTimeRanges from '../questions/schedule';
import SessionType from './session-type';
import SessionDetails from './session-details';
import Schedule from './schedule';
import Thread from '../thread';
import NoteForm from '../note/note-form';
import {
  BottomDetail,
  Card,
  Question,
  SessionDate,
  TopDetail,
} from './Session.style';
import { ACCOUNT_TYPES, PRODUCT_TYPES } from '../../constants';

function SessionCard({ session, variant }) {
  const [openSessionDetails, setOpenSessionDetails] = useState(false);
  const [openAddMeetingLink, setOpenAddMeetingLink] = useState(false);
  const [openThread, setOpenThread] = useState(false);
  const [openScheduler, setOpenScheduler] = useState(false);
  const [openNewNote, setOpenNewNote] = useState(false);
  const [suggestNewTimes, setSuggestNewTimes] = useState(false);

  const onClickSchedule = (e) => {
    e.stopPropagation();
    setOpenScheduler(true);
  };

  const onClickChat = (e) => {
    e.stopPropagation();
    setOpenThread(true);
  };

  const onClickMeetingLink = (e) => {
    e.stopPropagation();
    window.open(session.meeting_link, '_blank');
  };

  const onClickAddMeetingLink = (e) => {
    e.stopPropagation();
    setOpenAddMeetingLink(true);
  };

  const onClickAddNotes = (e) => {
    e.stopPropagation();
    setOpenNewNote(true);
  }

  return (
    <>
      <Card onClick={() => setOpenSessionDetails(true)} style={{ border: '1px solid #E8E8E8'}}>
        <Question>
          {session.title}
        </Question>
        <TopDetail>
          <Box onClick={(e) => e.stopPropagation()}>
            {variant === ACCOUNT_TYPES.EXPERT ? (
                <MiniProfile profile={session.parent_profile} />
            ) : (
                <MiniProfile profile={session.expert_profile} />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1}}>
            {!session.canceled && (
              <>
                {session.meeting_link && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={(e) => onClickMeetingLink(e)}>
                      Open Meeting Link
                  </Button>
                )}
                {variant === ACCOUNT_TYPES.EXPERT && !session.meeting_link && (
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={(e) => onClickAddMeetingLink(e)}
                  >
                    Add Meeting Link
                  </Button>
                )}
                {!session.session_date && variant === ACCOUNT_TYPES.EXPERT && (
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={(e) => onClickSchedule(e)}
                  >
                    <ScheduleIcon />&nbsp;Schedule
                  </Button>
                )}
                {!session.date_ranges && variant === ACCOUNT_TYPES.PARENT && (
                  <Button
                    color="secondary"
                    variant="contained"
                    size="small"
                    onClick={() => setSuggestNewTimes(true)}
                  >
                    <ScheduleIcon />&nbsp;Schedule
                  </Button>
                )}
              </>
            )}
            <Box />
            <IconButton
              onClick={(e) => onClickChat(e)}
            >
              <MessageOutlinedIcon />
            </IconButton>
          </Box>
        </TopDetail>
        <Divider />
        <BottomDetail>
          <SessionDate>
            {session.canceled ? (
              <span>Canceled</span>
            ) : (
              <>
                {session.session_date ? (
                  <Tooltip title={moment(session.session_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}>
                    <span>{moment(session.session_date).tz(moment.tz.guess()).calendar()}</span>
                  </Tooltip>
                ) : (
                  <span>{variant === ACCOUNT_TYPES.EXPERT ? 'Session Request' : 'Scheduling'}</span>
                )}
              </>
            )}
          </SessionDate>
          <SessionType productType={PRODUCT_TYPES.SINGLE} variant={variant} />
        </BottomDetail>
        {variant === ACCOUNT_TYPES.EXPERT && (
          <Box ml={-1} mt={1}>
            {/* More Actions */}
            <Button
              variant="text"
              color="info"
              size="small"
              onClick={onClickAddNotes}
            >
                <AddRoundedIcon fontSize="small" />
                <span style={{ fontWeight: 300 }}>Add Notes</span>
            </Button>
          </Box>
        )}
      </Card>
      <Drawer
        anchor="right"
        open={openSessionDetails}
        onClose={() => setOpenSessionDetails(false)}
      >
        <SessionDetails
          session={session}
          variant={variant}
          onDismiss={() => setOpenSessionDetails(false)}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={openThread}
        onClose={() => setOpenThread(false)}
      >
        <Thread
          parentProfile={session.parent_profile}
          expertProfile={session.expert_profile}
          onDismiss={() => setOpenThread(false)}
        />
      </Drawer>
      <Modal
        open={openScheduler}
        onClose={() => setOpenScheduler(false)}
      >
        <Schedule
          session={session}
          onClose={() => setOpenScheduler(false)}
        />
      </Modal>
      <Modal
        open={openAddMeetingLink}
        onClose={() => setOpenAddMeetingLink(false)}
      >
        <AddMeetingLink
          session={session}
          onDismiss={() => setOpenAddMeetingLink(false)}
        />
      </Modal>
      <Modal
        open={suggestNewTimes}
        onClose={() => setSuggestNewTimes(false)}
      >
          <PickNewTimeRanges session={session} onClose={() => setSuggestNewTimes(false)} showConfirm={true} />
      </Modal>
      <Modal
        open={openNewNote}
        onClose={() => setOpenNewNote(false)}
      >
        <NoteForm
          folderId={session.folder_id}
          sessionId={session.id}
          onClose={() => setOpenNewNote(false)}
        />
      </Modal>
    </>
  );
}

SessionCard.propTypes = {
  session: PropTypes.any, // TODO: Replace with proper proptype
  variant: PropTypes.string,
};

export default SessionCard;