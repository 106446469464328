import React, { useState } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';

import Button from '../common/button';

export default function AddToCalendar({ calendarLinks }) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleOpen = (e) => {
        setOpen(true);
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        setOpen(false);
        setAnchorEl(null);
    };

    const openLink = (key) => {
        window.open(calendarLinks[key], '_blank');
    };

    if (Object.keys(calendarLinks).length === 0) {
        return null;
    }

    return (
        <Box>
            <Button
                id="add-to-calendar-btn"
                aria-controls={open ? 'add-to-calendar-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpen}
                shade="secondaryLight"
                size="xsmall"
            >
                Add to Calendar
            </Button>
            <Menu
                id="add-to-calendar-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'add-to-calendar-btn',
                }}
            >   
                {Object.keys(calendarLinks).map((cal) => (
                    <MenuItem key={cal} onClick={() => openLink(cal)}>
                        <Typography variant="body2">{cal}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}
