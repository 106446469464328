import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Drawer, Grid, Modal, Rating, Tooltip } from '@mui/material';

import Button from '../common/button';
import MiniProfile from './mini-profile';
import SessionDetails from './session-details';
import KeyTakeaways from './key-takeaways';
import Review from '../review';
import { Card, Question, SessionDate } from './Session.style';
import { ACCOUNT_TYPES } from '../../constants';

function SessionRow({ session, variant }) {
  const [openSessionDetails, setOpenSessionDetails] = useState(false);
  const [openKeyTakeaways, setOpenKeyTakeaways] = useState(false);
  const [openReview, setOpenReview] = useState(false);

  const onClickWriteTakeaways = (e) => {
    e.stopPropagation();
    setOpenKeyTakeaways(true);
  };

  const onClickRateSession = (e) => {
    e.stopPropagation();
    setOpenReview(true);
  };

  return (
    <>
      <Card onClick={() => setOpenSessionDetails(true)}>
        <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Grid key={session.id} item xs={12} sm={6} md={4} lg={4}>
            <Question>
              {session.title}
            </Question>
          </Grid>
          <Grid key={session.id} item xs={12} sm={6} md={3} lg={3}>
            <Box onClick={(e) => e.stopPropagation()}>
              <MiniProfile
                profile={variant === ACCOUNT_TYPES.EXPERT ? session.parent_profile : session.expert_profile}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={2}>
            {session.canceled ? (
              <SessionDate>Canceled</SessionDate>
            ): (
              <Tooltip title={moment(session.session_date).tz(moment.tz.guess()).format('MMM D, YYYY h:mm a z')}>
                <SessionDate>{moment(session.session_date).local().calendar()}</SessionDate>
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
              {!session.canceled && (
                <>
                  {variant === ACCOUNT_TYPES.EXPERT ? (
                      <>
                          {(session.review && session.key_takeaway_1) ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title={session.review.direct_comments || `${session.review.rating} stars`}>
                                  <Box sx={{ display: 'inline', ml: 1 }}>
                                    <Rating name="rating" value={session.review.rating} precision={1} readOnly />
                                  </Box>
                              </Tooltip>
                            </Box>
                          ) : (
                            <>
                              {session.key_takeaway_1 ? (
                                  <Button
                                    shade="secondaryLight"
                                    onClick={() => setOpenSessionDetails(true)}
                                  >
                                    View Key Takeaways
                                  </Button>
                              ) : (
                                  <Button
                                    shade="secondaryLight"
                                    onClick={(e) => onClickWriteTakeaways(e)}
                                  >
                                    Write Key Takeaways
                                  </Button>
                              )}
                            </>
                          )}
                      </>
                  ) : (
                      <>
                          {session.key_takeaway_1 && session.review && (
                              <Button
                                shade="secondaryLight"
                                onClick={() => setOpenSessionDetails(true)}
                              >
                                Key Takeaways
                              </Button>
                          )}
                          {!session.review && (
                            <Button
                              shade="secondaryLight"
                              onClick={(e) => onClickRateSession(e)}
                            >
                              Rate your session 
                            </Button>
                          )}  
                      </>
                  )}
                </>
              )}
          </Grid>
        </Grid>
      </Card>
      <Drawer
        anchor="right"
        open={openSessionDetails}
        onClose={() => setOpenSessionDetails(false)}
      >
        <SessionDetails
          session={session}
          variant={variant}
          onDismiss={() => setOpenSessionDetails(false)}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={openKeyTakeaways}
        onClose={() => setOpenKeyTakeaways(false)}
      >
        <KeyTakeaways
          session={session}
          onDismiss={() => setOpenKeyTakeaways(false)}
        />
      </Drawer>
      <Modal
        open={openReview}
        onClose={() => setOpenReview(false)}
      >
        <Review session={session} onClose={() => setOpenReview(false)} />
      </Modal>
    </>
  );
}

SessionRow.propTypes = {
  session: PropTypes.any, // TODO: Replace with proper proptype
  variant: PropTypes.string,
};

export default SessionRow;