import { Box, Button, Chip, Paper, Typography } from '@mui/material';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../types';
import { patch } from '../../services/api.services';
import { API } from '../../constants';
import { fetchUser } from '../../clients/profile';
import React from 'react';

interface ModuleProps {
    title: string,
    description: string | React.ReactNode,
    category: string,
    moduleKey: string,
    modulePath: string,
    moduleLink: string,
}

export default function Module({ title, description, category, moduleKey, modulePath, moduleLink }: ModuleProps) {
    const navigate = useNavigate();
    const dispatcher = useDispatch();

    const user = useSelector((state: State) => state.account.user);
    const token = useSelector((state: State) => state.account.token);

    const openModule = (moduleKey, modulePath, moduleLink) => {
        const moduleMap = {
            'build': 'https://www.parentswarm.com/module/build-your-nessle-storefront-and-listing-page',
            'testimonials': 'https://www.parentswarm.com/module/showcase-testimonials-from-happy-clients',
            'share': 'https://www.parentswarm.com/module/kickstart-your-client-growth-by-telling-the-world-youre-on-nessle',
            'prepare': 'https://www.parentswarm.com/module/prepare-for-your-first-session-on-the-nessle-platform',
            'bank': 'https://www.parentswarm.com/module/upload-bank-information-when-youre-ready-to-accept-transactions',
            'ideas': 'https://www.parentswarm.com/module/get-ideas-about-how-to-maximize-your-use-of-the-nessle-platform',
        };

        if (moduleKey && !modulePath && !moduleLink) {
            window.open(moduleMap[moduleKey], '_blank');
        } else if (modulePath) {
            navigate(modulePath);
        } else if (moduleLink) {
            window.open(moduleLink, '_blank');
        }
    };

    const markComplete = async (e: React.MouseEvent, moduleKey: string) => {
        e.preventDefault();
        e.stopPropagation();

        const newChecklist = { ...user.profile.onboarding_checklist };
        newChecklist[`module-${moduleKey}`] = true;
    
        await patch(
            `${API.PROFILE}${user.profile.id}/`,
            {
              onboarding_checklist: newChecklist,
            },
            token,
        );

        await fetchUser(dispatcher, user, token);
    };

    return (
        <Box borderRadius={4} sx={{ cursor: 'pointer' }} onClick={() => openModule(moduleKey, modulePath, moduleLink)}>
            <Paper elevation={0}>
                <Box p={2} display="flex" flexDirection="column" justifyContent="space-between">
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <Typography variant="body1">{description}</Typography>
                    <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="contained"
                        >
                            <PlayCircleFilledWhiteIcon /><span style={{ marginLeft: 5 }}>Start Now</span>
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => markComplete(e, moduleKey)}
                            style={{ marginLeft: 10 }}
                        >
                            Mark Complete
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
