import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import {
    Box,
    Container,
    Grid,
    Modal,
    Stack,
} from '@mui/material';

import {
    SET_SESSION,
    ADD_SESSION_ID,
    RESET_SESSION_IDS,
} from '../../store/actions';
import Button from '../../components/common/button';
import SessionCard from '../../components/session/session-card';
import SessionRow from '../../components/session/session-row';
import GroupSessionCard from '../../components/group-session/group-session-card';
import GroupSessionRow from '../../components/group-session/group-session-row';
import { get } from '../../services/api.services';
import { API } from '../../constants';
import {
    NoResults,
    SuccessModal,
    SuccessModalTitle,
    SuccessModalText,
    PageTitle,
} from './sessions.style';
import Posted from '../../assets/images/Posted.png';
import { sessionIdsReadyToSchedule } from '../../utils/sessions';

function ParentSessions() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const sessionIds = useSelector((state) => state.sessions.sessionIds);
    const sessionsById = useSelector((state) => state.sessions.sessionsById);

    const [grabSuccess, setGrabSuccess] = useState(false);
    const [groupSessions, setGroupSessions] = useState([]);
    const [packageGroupSessions, setPackageGroupSessions] = useState([]);

    const fetchPackageGroupSessions = async () => {
        const result = await get(
            `${API.ATTENDEE}?product_type=package`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            let gs = [];
            result.data.forEach((p) => {
                if (p.group_sessions) {
                    gs = [
                        ...gs,
                        ...p.group_sessions.filter((product) => moment(product.event_date) > moment().subtract(15, 'minutes')),
                    ];
                }
            });

            setPackageGroupSessions([ ...packageGroupSessions, ...gs ]);
        }
        else {
        }
    }

    const fetchGroupSessions = async () => {
        const result = await get(
            `${API.ATTENDEE}?product_type=group`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            const groupSessionProducts = result.data.map((purchase) => purchase.product);

            setGroupSessions([ ...groupSessions, ...groupSessionProducts ]);
            fetchPackageGroupSessions();
        }
        else {
        }
    }

    const fetchSessions = async () => {
        const result = await get(
            `${API.SESSION}`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            dispatcher({
                type: RESET_SESSION_IDS,
                payload: {},
            });
            
            result.data.forEach((session) => {
                dispatcher({
                    type: SET_SESSION,
                    payload: {
                        session,
                    },
                });
                dispatcher({
                    type: ADD_SESSION_ID,
                    payload: {
                        session,
                    },
                });
            });
        }
        else {
        }
    }

    useEffect(() => {
        fetchSessions();
        fetchGroupSessions();
        fetchPackageGroupSessions();
    }, []);

    const filteredSessionIds = sessionIdsReadyToSchedule(sessionIds, sessionsById);
    const pendingSessions = filteredSessionIds.filter((id) => !sessionsById[id].canceled && (!sessionsById[id].session_date || moment(sessionsById[id].session_date) > moment.now()));
    const finishedSessions = sessionIds.filter((id) => sessionsById[id].canceled || (sessionsById[id].session_date && moment(sessionsById[id].session_date) < moment.now()));
    const upcomingGroupSessions = [ ...groupSessions, ...packageGroupSessions ].filter((product) => moment(product.event_date) >= moment().subtract(product.duration || 30, 'minutes'));
    const finishedGroupSessions = [ ...groupSessions, ...packageGroupSessions ].filter((product) => moment(product.event_date) < moment().subtract(product.duration || 30, 'minutes'));

    return (
        <Container maxWidth="lg">
            <PageTitle>
                Upcoming Sessions
            </PageTitle>
            <Grid container spacing={2}>
                {pendingSessions.length === 0 && upcomingGroupSessions.length === 0 && (
                    <Grid item xs={12}>
                        <NoResults>You don't have any upcoming sessions yet</NoResults>
                    </Grid>
                )}
                {pendingSessions.map((id) => (
                    <Grid key={id} item xs={12} sm={6}>
                        <SessionCard
                            session={sessionsById[id]}
                            variant={user.profile.profile_type}
                        />
                    </Grid>
                ))}
                {upcomingGroupSessions.map((product) => (
                    <Grid item xs={12} sm={6}>
                        <GroupSessionCard product={product} variant="parent" />
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ mb: 2 }}>
                <PageTitle>
                    Finished Sessions
                </PageTitle>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            {finishedSessions.length === 0 && finishedGroupSessions.length === 0 && (
                                <Grid item xs={12}>
                                    <NoResults>You don't have any finished sessions yet</NoResults>
                                </Grid>
                            )}
                            {finishedSessions.map((id) => (
                                <SessionRow
                                    key={id}
                                    session={sessionsById[id]}
                                    variant={user.profile.profile_type}
                                />
                            ))}
                            {finishedGroupSessions.map((product) => (
                                <Grid item xs={12}>
                                    <GroupSessionRow product={product} variant={user.profile_type} />
                                </Grid>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Modal
              open={grabSuccess}
              onClose={() => setGrabSuccess(false)}
            >
                <SuccessModal>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                        <img src={Posted} alt="success" width={75} />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <SuccessModalTitle>Grabbed!</SuccessModalTitle>
                    </Box>
                    <Box sx={{ mb: 4 }}>
                        <SuccessModalText>
                            We'll notify you when the parent confirms their session.
                        </SuccessModalText>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                        shade="secondaryLight"
                        size="large"
                        onClick={() => navigate('/dashboard')}
                        >
                            View in dashboard
                        </Button>
                    </Box>
                </SuccessModal>
            </Modal>
        </Container>
    );
}

export default ParentSessions;