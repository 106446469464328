import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import moment from 'moment';
import {
    Alert,
    Box,
    Container,
    Modal,
    Grid,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import {
    PageTitle,
    NoResults,
} from '../../pages/sessions/sessions.style';
import Button from '../../components/common/button';
import ShareCard from './share-card';

import { get, destroy } from '../../services/api.services';
import { API } from '../../constants';
import { uploadToS3 } from '../../utils/upload';
import { displayName } from '../../utils/profile';

function ShareList({}) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const shareToSign = useSelector((state) => state.shares.shareToSign);

    const [shares, setShares] = useState([]);
    const [signaturesByShareId, setSignaturesByShareId] = useState({});

    const fetchShares = async () => {
        const url = params.folder_id ? `${API.SHARE}?folder_id=${params.folder_id}&share_type=resource` : `${API.SHARE}?member_id=${user.profile.id}&share_type=resource`;

        const result = await get(
            url,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            setShares(result.data);
        }
        else {
        }
    };

    const fetchSignatures = async () => {
        const result = await get(
            `${API.SIGNATURE}?share_ids=${[shares.map((share) => share.id)]}`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            const newSignaturesByShareId = {};

            result.data.forEach((signature) => {
                if (newSignaturesByShareId[signature.share.id]) {
                    newSignaturesByShareId[signature.share.id] = [...newSignaturesByShareId[signature.share.id], signature];
                } else {
                    newSignaturesByShareId[signature.share.id] = [signature];
                }
            });

            setSignaturesByShareId(newSignaturesByShareId);
        }
    }

    useEffect(() => {
        fetchShares();
    }, []);

    useEffect(() => {
        fetchSignatures();
    }, [shares, shareToSign]);

    return (
        <Container maxWidth="lg">
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <PageTitle>
                    Resources
                </PageTitle>
                {params.folder_id && (
                    <Box display="flex" flexDirection="row">
                        <Button shade="secondaryLight" onClick={() => {
                            navigate(`/clients/${params.folder_id}`);
                        }}>
                            Back
                        </Button>
                    </Box>
                )}
            </Box>
            <Grid container spacing={2}>
                {shares.length === 0 && (
                    <Grid item xs={12}>
                        <NoResults>
                            Your expert{params.folder_id ? '' : 's'} will share resources with you here.
                        </NoResults>
                    </Grid>
                )}
                {shares.map((share) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <ShareCard
                            key={share.id}
                            share={share}
                            signatures={signaturesByShareId[share.id]}
                        />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default ShareList;