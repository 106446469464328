import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Card, CardContent, Divider, IconButton, Menu, MenuItem, Modal, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';

import { fetchResources } from "../../clients/resource";
import { useAuth } from "../../hooks";
import { State } from "../../types";
import ResourceForm from "../../pages/documents/document-form";
import ResourcePreview from "../resource/resource-preview";

interface ResourceSelectorProps {
  selectedResourceIds: string[],
  setSelectedResourceIds: Function,
}

export default function ResourceSelector({ selectedResourceIds, setSelectedResourceIds}: ResourceSelectorProps) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const { token } = useAuth();
  const resourcesById = useSelector((state: State) => state.resources.resourcesById);
  const resourceIds = useSelector((state: State) => state.resources.resourceIds);

  const [anchorEl, setAnchorEl] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const open = Boolean(anchorEl);

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('itemIndex', index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedItemIndex = parseInt(e.dataTransfer.getData('itemIndex'));
    const newList = [...selectedResourceIds];

    if (draggedItemIndex !== index) {
      // Remove the dragged item from its original position
      const draggedItem = newList.splice(draggedItemIndex, 1)[0];

      // Insert the dragged item at the new position
      newList.splice(index, 0, draggedItem);
      setSelectedResourceIds(newList);
    }
  };

  const onClickAdd = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (id) => {
    onClose();
    setSelectedResourceIds([ ...selectedResourceIds, id ])
  };

  const onDelete = (index) => {
    const copySelectedIds = [ ...selectedResourceIds ];
    copySelectedIds.splice(index, 1);
    setSelectedResourceIds(copySelectedIds);
  };

  useEffect(() => {
    fetchResources(dispatcher, navigate, token);
  }, []);

  return (
    <Box textAlign="center">
      <Stack spacing={1} mb={2}>
        {resourceIds.length > 0 && selectedResourceIds.map((resourceId, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="center"
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
          >
            <Box>
              <IconButton onClick={() => {}}>
                <DragHandleRoundedIcon />
              </IconButton>
            </Box>
            <ResourcePreview resource={resourcesById[resourceId]} showThumbnail={true} />
            <Box ml={2}>
              <IconButton onClick={() => onDelete(index)}>
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Stack>
      <Button
        color="secondary"
        variant="contained"
        aria-label="add"
        id="add-resource"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClickAdd}
      >
        <AddIcon /> Add a Resource
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'add-resource',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: 175,
            width: '20ch',
          },
        }}
      >
        <MenuItem key="create-new" selected={false} onClick={() => setCreateNew(true)}>
          Create New
        </MenuItem>
        <Divider />
        {resourceIds.map((resourceId) => (
          <MenuItem key={resourceId} selected={false} onClick={() => onSelect(resourceId)}>
            {resourcesById[resourceId].title}
          </MenuItem>
        ))}
      </Menu>
      <Modal open={createNew} onClose={() => setCreateNew(false)}>
          <ResourceForm
              onClose={() => setCreateNew(false)}
              onSuccess={async (resource) => {
                await fetchResources(dispatcher, navigate, token);
                onSelect(resource.id);
              }}
              startFileKey=""
              startFileKeyError={undefined}
          />
      </Modal>
    </Box>
  );
}
