import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import { Avatar, Badge, Box, Drawer, IconButton, Snackbar, Stack, Typography } from '@mui/material';

import { post } from '../../services/api.services';
import { API } from '../../constants';
import config from '../../config';
import Button from '../common/button';
import Chip from '../common/chip';
import { CardTitle, CardDate } from '../product/product.style';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import Thread from '../thread';
import { displayName } from '../../utils/profile';
import { stringToColor } from '../../utils/stringHelpers';

export const FOLDER_VARIANTS = {
    OWNER: 'owner',
    MEMBER: 'member',
};

export default function FolderCard({
    folder,
    variant,
}) {
    const navigate = useNavigate();
    const token = useSelector((state) => state.account.token);
    const unreadNotificationCount = useSelector((state) => state.notifications.unreadCountByFolderId[folder.id]);

    const [openThread, setOpenThread] = useState(false);
    const [sent, setSent] = useState(false);
    const [copied, setCopied] = useState(false);

    const resendInvite = async (invite) => {
        const result = await post(
            `${API.INVITE}${invite.id}/send/`,
            {},
            token,
        );
            
        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }

        setSent(true);
    };

    const copyInviteLink = async (invite) => {
        await navigator.clipboard.writeText(`${config.FE_BASE}/invite/${invite.code}`);
        setCopied(true);
    };

    const openFolder = () => {
        navigate(`/clients/${folder.id}`);
    };

    const hasMembers = folder.members.length > 0;

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={copied}
                autoHideDuration={4000}
                onClose={() => setCopied(false)}
                message="Invite link has been copied to your clipboard"
            />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={sent}
                autoHideDuration={4000}
                onClose={() => setSent(false)}
                message="Invite sent!"
            />
            <Box
                onClick={hasMembers ? openFolder : () => {}}
                sx={{
                    borderRadius: 2,
                    background: '#FFFFFF',
                    overflow: 'hidden',
                    position: 'relative',
                    p: 2,
                    border: '1px solid #f8f8f8',
                    cursor: hasMembers ? 'pointer' : 'default',
                }}
            >
                {!hasMembers && (
                    <Box sx={{ position: 'absolute', top: 1, right: 0 }}>
                        <Chip label="Invite Pending" color="primaryLight" size="small" />
                    </Box>
                )}
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: '100%' }}>
                        <Box display="flex" alignItems="center">
                            {variant === FOLDER_VARIANTS.OWNER ? (
                                <>
                                    {hasMembers ? (
                                        <>
                                            <Avatar
                                                alt={folder.members[0].first_name}
                                                src={folder.members[0].profile_image_url}
                                                sx={{ width: 35, height: 35, bgcolor: stringToColor(folder.members[0].first_name) }}
                                            >
                                                {!folder.members[0].profile_image_url ? folder.members[0].first_name?.charAt(0) : null}
                                            </Avatar>
                                            <Box ml={1}>
                                                <Typography variant="h6">
                                                    {displayName(folder.members[0].first_name, folder.members[0].last_name)}
                                                </Typography>
                                            </Box>
                                        </>
                                    ) : (
                                        <Box ml={1}>
                                            <Typography variant="h6" style={{ height: '35px' }}>{folder.invites[0].invitee_name}</Typography>
                                            <CardDate>Invited on {moment(folder.invites[0].created_at).format('M/D/YYYY')}</CardDate>
                                        </Box>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Avatar
                                        alt={folder.owner.first_name}
                                        src={folder.owner.profile_image_url}
                                        sx={{ width: 35, height: 35, bgcolor: stringToColor(folder.owner.first_name) }}
                                    >
                                        {!folder.owner.profile_image_url ? folder.owner.first_name?.charAt(0) : null}
                                    </Avatar>
                                    <Box ml={1}>
                                        <Typography variant="h6">
                                            {displayName(folder.owner.first_name, folder.owner.last_name)}
                                        </Typography>
                                    </Box>
                                </>
                            )}
                            
                        </Box>
                        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" sx={{ width: '100%'}}>
                            <Stack spacing={1} direction="row" alignItems="center">
                                {hasMembers ? (
                                    <>
                                        {unreadNotificationCount && (
                                            <IconButton onClick={() => navigate(`/clients/${folder.id}`)}>
                                                <Badge
                                                    variant="dot"
                                                    badgeContent={unreadNotificationCount}
                                                    max={99}
                                                    sx={{
                                                    '& .MuiBadge-badge': {
                                                        color: '#FFFFFF',
                                                        backgroundColor: '#5371ff',
                                                    }
                                                    }}
                                                >
                                                    <NotificationsNoneOutlinedIcon />
                                                </Badge>
                                            </IconButton>
                                        )}
                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            setOpenThread(true);
                                        }}>
                                            <MessageOutlinedIcon />
                                        </IconButton>
                                        <Button shade="secondaryLight" onClick={() => navigate(`/clients/${folder.id}`)} size="small">
                                            {variant === FOLDER_VARIANTS.OWNER ? 'Manage' : 'Open'}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button shade="secondaryLight" onClick={() => copyInviteLink(folder.invites[0])} size="small">Copy Invite Link</Button>
                                        <Button shade="secondary" onClick={() => resendInvite(folder.invites[0])} size="small">Resend Invite</Button>
                                    </>
                                )}
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Drawer
                anchor="right"
                open={openThread}
                onClose={() => setOpenThread(false)}
            >
                <Thread
                    parentProfile={folder.members[0]}
                    expertProfile={folder.owner}
                    onDismiss={() => setOpenThread(false)}
                />
            </Drawer>
        </>
    );
}
