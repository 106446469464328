import { useNavigate } from 'react-router';

import moment from 'moment';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useAuth } from '../../hooks';
import config from '../../config';
import CheckoutForm from '../../components/payment/checkout-form';
import { getFinalPrice } from '../../utils/coupons';

interface CheckoutProps {
    handleBackBtn: Function,
}

const stripePromise = loadStripe(
    config.currentEnv === 'production' ?
    'pk_live_51LPUdOHf16Ud2OOxIA2TxiTEluSTEgonownCZgfuFp1TPeQVRWTL9nyRyhwTUukLBFxOkDKCZ1fCTxXegZqPVB6g00UWCQeR8N' :
    'pk_test_51LPUdOHf16Ud2OOxGrqOrYIX4jQX7gOjT3Xop4J8jSQPTG2gb1qqXdCMPm7T3gpZ9zPub7s28XYnjFgAIjOBox2200IoGhQhWk'
);

export default function Checkout({ handleBackBtn }: CheckoutProps ) {
    const navigate = useNavigate();

    const { user, token } = useAuth();

    const options = {
        appearance: {
            theme: 'stripe',
        },
    };

    const productNames = {
        'prod_NWieJmAUSBnTSS': 'Business',
        'prod_N9rja1oC6pPuR1': 'Business',
        'prod_OaEAlmpo6sJg60' : 'Pro',
        'prod_OVlOA7C07oTyh0': 'Pro',
    };

    const PRICE = user.profile.subscription.plan.amount / 100;
    const finalPrice = getFinalPrice(PRICE, user.profile.discount?.coupon);
    const hasDiscount = finalPrice !== PRICE;
    const planName = productNames[user.profile.subscription.plan.product];
    const planInterval = user.profile.subscription.plan.interval;

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Box p={2} width="100%">
            <Stack spacing={2}>
                <Typography variant="h6">
                    {user.profile.organization ? user.profile.organization.trial_description : '14-Day'} Free Trial of Parentswarm {planName} then ${PRICE} / {planInterval}
                </Typography>

                <Box>
                    <Typography variant="caption">
                        It’s free to get started on Parentswarm. We’ll collect your credit card information here,{' '}
                        but we won’t charge you until your free trial ends, and we’ll give you plenty of warning{' '}
                        if you’d like to change your plan before that happens.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption">Today's charge: $0.00</Typography><br />
                    {hasDiscount && (
                        <Typography variant="caption">
                            Coupon Applied: {user.profile.discount.coupon.id}<br />
                        </Typography>
                    )}
                    <Typography variant="caption">
                        First charge: {moment.unix(user.profile.subscription.trial_end).format('MMM D, YYYY')} |{' '}
                        <span style={{ textDecoration: hasDiscount ? 'line-through' : 'none' }}>
                            ${PRICE}
                        </span>
                        {hasDiscount && (
                            <span>{' '}{USDollar.format(finalPrice)}</span>
                        )}
                    </Typography>
                </Box>
                
                <Box>
                    <Elements
                        stripe={stripePromise}
                        options={options as any}
                    >   
                        <CheckoutForm
                            onSuccess={() => {
                                navigate('/');
                            }}
                            saveText="Start Your Free Trial"
                            saveColor="primary"
                        />
                    </Elements>
                </Box>
            </Stack>
            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button variant="contained" color="secondary" onClick={() => handleBackBtn()}>
                    Back
                </Button>
                <Button variant="text" color="primary" onClick={() => navigate('/')}>
                    Add card later
                </Button>
            </Box>
        </Box>
    );
}