/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const TabTitle = styled.div`
font-family: 'Cabin';
color: ${({ active }) => (!active ? '#808080' : null)};
font-size: 18px;
font-weight: 700;
`;

export const TabContentContainer = styled.div`
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 30px;
`;