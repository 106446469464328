/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const EarningsRow = styled.div`
    border-bottom: 1px solid #E8E8E8;
    font-family: 'Cabin';
    padding-top: 10px;
    padding-bottom: 10px;
`;

export const PayoutContainer = styled.div`
    background: #FFFFFF;
    border: 0.5px solid #9D9D9D;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 10px;
    font-family: 'Cabin';
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const PayoutHeader = styled.div`
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #210B2B;
`;

export const Balance = styled.div`
    font-weight: 700;
    font-size: 22px;
    line-height: 29px;
    color: #5371ff;
`;