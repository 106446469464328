import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Grid, IconButton, Modal } from '@mui/material';

import Chip from '../common/chip';
import { displayName } from '../../utils/profile';
import { stringToColor } from '../../utils/stringHelpers';
import {
    ModalBody,
    ProfileLeft,
    ProfileName,
    Pronouns,
    SectionTitle,
    Section,
    SectionContent,
} from './Profile.style';
import ProductCard from '../product/product-card';
import { PRODUCT_TYPES } from '../../constants';
import NextIcon from '../../assets/icons/next.svg';
import PrevIcon from '../../assets/icons/prev.svg';
import moment from 'moment';
import { OPEN_PRODUCT } from '../../store/actions';
import { fetchProductsBySeller, fetchProduct } from '../../clients/product';
import Badge from '../badges/badge';

export default function Expert({ profile, openProduct }) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.account.token);
    const productIdsBySellerId = useSelector((state) => state.products.productIdsBySellerId);
    const productsById = useSelector((state) => state.products.productsById);
  
    const [productIndex, setProductIndex] = useState(0);

    const products = productIdsBySellerId[profile.id] ? productIdsBySellerId[profile.id].filter(
      (productId) => (!productsById[productId].archived && (productsById[productId].product_type !== PRODUCT_TYPES.GROUP || (productsById[productId].product_type === PRODUCT_TYPES.GROUP && moment(productsById[productId].event_date) >= moment())))
    ) : [];

    const nextProduct = () => {
      if (productIndex < products.length - 1) {
        setProductIndex((prev) => prev + 1);
      }
      else {
        setProductIndex(0);
      }
    };

    const prevProduct = () => {
      if (productIndex === 0) {
        setProductIndex(products.length - 1);
      } else {
        setProductIndex((prev) => prev - 1);
      }
    };

    const displayProduct = (product, bookNow) => {
      dispatcher({
          type: OPEN_PRODUCT,
          payload: {
              product,
              bookNow,
          },
        });
    };

    useEffect(() => {
      fetchProductsBySeller(dispatcher, navigate, token, profile.id);

      if (openProduct) {
        fetchProduct(dispatcher, navigate, token, openProduct);
      }
    }, []);

    useEffect(() => {
      if (openProduct && productsById[openProduct] && !productsById[openProduct].archived) {
        displayProduct(productsById[openProduct], false);
        navigate(`/experts/${profile.display_id}`);
      }
    }, [productIdsBySellerId]);

    return (
      <ModalBody>
          <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <ProfileLeft>
                  <Avatar
                      alt={profile.first_name}
                      src={profile.profile_image_url}
                      sx={{ width: 75, height: 75, bgcolor: profile.profile_image_url ? '#FFFFFF' : stringToColor(profile.business_name || profile.first_name) }}
                  >
                      {!profile.profile_image_url ? profile.business_name?.charAt(0) || profile.first_name?.charAt(0) : null}
                  </Avatar>
                  <ProfileName>
                      {profile.business_name || displayName(profile.first_name, profile.last_name)}
                      {profile.pronouns && (<Pronouns>({profile.pronouns})</Pronouns>)}
                  </ProfileName>
                </ProfileLeft>
                {products.length > 0 && (
                  <Section>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <SectionTitle style={{ marginBottom: 0 }}>Services</SectionTitle>
                      {products.length > 1 && (
                        <Box>
                          <IconButton onClick={prevProduct}><img src={PrevIcon} alt="previous" width={24} /></IconButton>
                          <IconButton onClick={nextProduct}><img src={NextIcon} alt="next" width={24} /></IconButton>
                        </Box>
                      )}
                    </Box>
                    <ProductCard
                      variant="normal"
                      product={productsById[products[productIndex]]}
                      key={products[productIndex]}
                      secondaryCtaAction={() => displayProduct(productsById[products[productIndex]], false)}
                      primaryCtaAction={() => displayProduct(productsById[products[productIndex]], true)}
                      miniProfile={false}
                    />
                  </Section>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <Section>
                  <SectionTitle>About Me</SectionTitle>
                  <SectionContent>{profile.bio}</SectionContent>
                </Section>
                {profile.badges && profile.badges.length > 0 && (
                    <Section>
                        <SectionTitle>Badges Earned</SectionTitle>
                        <SectionContent>
                          <Grid container spacing={2}>
                            {profile.badges.map((badge) => (
                                <Grid item xs={3}>
                                  <Badge badgeKey={badge} size="small" key={badge} />
                                </Grid>
                            ))}
                          </Grid>
                        </SectionContent>
                    </Section>
                )}
                {profile.roles && (
                  <Section>
                    <SectionTitle>I am Trained as a</SectionTitle>
                    <SectionContent>
                        {profile.roles.split(',').map((role) => (
                          <Chip key={role} color="primaryLight" label={role} key={role} />
                        ))}
                    </SectionContent>
                  </Section>
                )}
                {profile.clients_say_i_am && (
                  <Section>
                    <SectionTitle>Clients say I am</SectionTitle>
                    <SectionContent>
                        {profile.clients_say_i_am.split(',').map((adj) => (
                          <Chip key={adj} color="primaryLight" label={adj} />
                        ))}
                    </SectionContent>
                  </Section>
                )}
                {profile.minority_identification && (
                  <Section>
                    <SectionTitle>Experienced with</SectionTitle>
                    <SectionContent>
                        {profile.minority_identification.split(',').map((group) => (
                          <Chip color="primaryLight" label={group} key={group} />
                        ))}
                    </SectionContent>
                  </Section>
                )}
                {profile.languages_spoken && (
                  <Section>
                    <SectionTitle>I speak</SectionTitle>
                    <SectionContent>
                        {profile.languages_spoken.split(',').map((language) => (
                          <Chip color="primaryLight" label={language} key={language} />
                        ))}
                    </SectionContent>
                  </Section>
                )}
              </Grid>
          </Grid>
      </ModalBody>
    );
}
