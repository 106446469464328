import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ImageCont, SetUpButton, ThankText } from '../../pages/login/Login.style';
import PositiveVote from '../../assets/images/positive-vote.png';
import { ACCOUNT_TYPES } from '../../constants';

function RegisterSuccess({ accountType }) {
  const navigate = useNavigate();

  const navigateToAccountSetup = () => {
    if (accountType === ACCOUNT_TYPES.EXPERT) {
      navigate('/setup-account');
    } else {
      navigate('/setup-parent-account');
    }
  };

  return (
    <>
      <ImageCont>
        <img src={PositiveVote} alt="positive-vote" width={100} height={100} />
      </ImageCont>
      <ThankText>
        Thank you for signing up
        <br />
        with Parentswarm.
      </ThankText>
      <SetUpButton
        onClick={navigateToAccountSetup}
      >
        Set up my account
      </SetUpButton>
    </>
  );
}

RegisterSuccess.propTypes = {
  accountType: PropTypes.string,
};

export default RegisterSuccess;
