/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  border-radius: 10px;
  background: #FFFFFF;
  width: 60vw;
  max-width: 800px;
  margin: 5vh auto;
  outline: 0;
  max-height: 90vh;
  overflow: scroll;
  @media (max-width: 768px) {
    width: 90vw;
    max-height: 80vh;
  }
  &:active {
    border: none;
  }
`;

export const ModalSmallContainer = styled.div`
  border-radius: 10px;
  background: #FFFFFF;
  width: 60%;
  max-width: 300px;
  margin: 5% auto;
  outline: 0;
  @media (max-width: 768px) {
    width: 90%;
  }
  &:active {
    border: none;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #C4C4C4;
  color: #210B2B; 
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  padding: 15px;
`;

export const ModalHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalHeaderRight = styled.div`
  display: flex;
`;

export const ModalTitle = styled.span`
  margin-left: 0.5em;
`;

export const ModalBody = styled.div`
  padding: 20px;
  overflow: scroll;
  max-width: 100%;
  white-space: pre-line;
`;

export const ProfileLeft = styled.div`
  border-radius: 10px;
  background: #5371ff;
  color: #FFFFFF;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProfileName = styled.span`
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
`;

export const Pronouns = styled.span`
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 13px;
  font-size: 10px;
  line-height: 19px;
`;

export const Age = styled.span`
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 13px;
  font-size: 11px;
  line-height: 19px;
`;

export const SessionCountContainer = styled.div`
  background: #92558B;
  border-radius: 10px;
  font-family: 'Cabin';
  font-weight: 13px;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 1em 3em;
  margin: 1em;
`;

export const SessionCount = styled.div`
  font-family: 'Cabin';
  color: #FFFFFF;
  font-weight: 700;
  font-size: 22px;
  line-height: 40px;
`;

export const Section = styled.div`
  padding: 1em 0;
  border-bottom: 1px solid #C4C4C4;
  &:last-child {
    border: none;
  }
`;

export const SectionTitle = styled.div`
  font-family: 'Cabin';
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  color: #210B2B;
  margin-bottom: 1em;
`;

export const SectionContent = styled.div`
  font-family: 'Cabin';
  font-weight: 13px;
  font-size: 11px;
  line-height: 150%;
  color: #210B2B;
  white-space: pre-line;
`;

export const SectionList = styled.ul`
  margin-left: -26px;
  line-height: 190%;
`;