import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
    Alert,
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import { API } from '../../../constants';
import Modal from '../../../components/common/modal';
import { get, patch, post } from '../../../services/api.services';
import Button from '../../../components/common/button';
import { displayName } from '../../../utils/profile';

function ExpenseForm({ ledger, onClose, onSuccess }) {
    const navigate = useNavigate();

    const token = useSelector((state) => state.account.token);

    const [folders, setFolders] = useState([]);
    const [transactionDate, setTransactionDate] = useState(ledger ? moment(ledger.transaction_dt) : moment());
    const [transactionDateError, setTransactionDateError] = useState();
    const [description, setDescription] = useState(ledger ? ledger.description : '');
    const [descriptionError, setDescriptionError] = useState();
    const [client, setClient] = useState(ledger && ledger.folder ? ledger.folder.id : '');
    const [clientError, setClientError] = useState();
    const [paymentForm, setPaymentForm] = useState(ledger ? ledger.payment_form : '');
    const [paymentFormError, setPaymentFormError] = useState();
    const [amount, setAmount] = useState((ledger && ledger?.ledger_details.length) ? ledger.ledger_details[0].amount : undefined);
    const [amountError, setAmountError] = useState();
    const [notes, setNotes] = useState(ledger ? ledger.extra_notes : '');
    const [notesError, setNotesError] = useState();
    const [hasErrors, setHasErrors] = useState(false);

    const resetErrors = () => {
        setTransactionDateError(null);
        setDescriptionError(null);
        setClientError(null);
        setPaymentFormError(null);
        setAmountError(null);
        setNotesError(null);
        setHasErrors(false);
    };

    const onSave = async () => {
        resetErrors();
        let result;
    
        if (!ledger) {
            result = await post(
                `${API.LEDGER}`,
                {
                    ledger_entry_type: 20,
                    transaction_dt: transactionDate?.format('YYYY-MM-DD'),
                    description,
                    folder_id: client !== '' ? client : null,
                    payment_form: paymentForm,
                    amount,
                    extra_notes: notes,
                },
                token,
            );
        } else {
            result = await patch(
                `${API.LEDGER}${ledger.id}/`,
                {
                    ledger_entry_type: 20,
                    transaction_dt: transactionDate?.format('YYYY-MM-DD'),
                    description,
                    folder_id: client !== '' ? client : null,
                    payment_form: paymentForm,
                    amount,
                    extra_notes: notes,
                },
                token,
            );
        }

        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 400) {
            setHasErrors(true);
            if (result.data.transaction_dt) {
                setTransactionDateError(result.data.transaction_dt);
            }

            if (result.data.description) {
                setDescriptionError(result.data.description);
            }

            if (result.data.payment_form) {
                setPaymentFormError(result.data.payment_form);
            }

            if (result.data.amount) {
                setAmountError(result.data.amount);
            }

            if (result.data.extra_notes) {
                setNotesError(result.data.extra_notes);
            }
        }
        else if (result.status === 201 || result.status === 200) {
            onSuccess();
            onClose();
        }
        else {
        }
    };

    const fetchFolders = async () => {
        const result = await get(
            API.FOLDER,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate('/login/experts');
        }
        else if (result.status === 200) {
            setFolders(result.data);
        }
    };

    useEffect(() => {
        fetchFolders();
    }, []);

    return (
        <Modal title={ledger ? 'Edit Expense' : 'New Expense'} onClose={onClose}>
            {hasErrors && (
                <Box mb={2}>
                    <Alert color="error">Please review the errors below and try again.</Alert>
                </Box>
            )}
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="expense-amount"
                            label="Amount"
                            variant="outlined"
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 128 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span>$</span>
                                    </InputAdornment>
                                ),
                            }}
                            error={amountError}
                            helperText={amountError || null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DesktopDatePicker
                                label="Date"
                                inputFormat="MM/DD/YYYY"
                                value={transactionDate}
                                onChange={(newValue) => {
                                    setTransactionDate(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{ width: '100%' }}
                                        {...params}
                                        error={transactionDateError}
                                        helperText={transactionDateError || null}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel id="client-label">Client (optional)</InputLabel>
                            <Select
                                id="client"
                                labelId="client-label"
                                label="Client (optional)"
                                value={client}
                                onChange={(e) => setClient(e.target.value)}
                                error={clientError}
                                helperText={clientError || null}
                                sx={{ width: '100%', color: 'black' }}
                            >
                                {folders.filter((folder) => folder.members.length > 0).map((folder) => (
                                    <MenuItem value={folder.id}>
                                        {displayName(folder.members[0].first_name, folder.members[0].last_name)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="expense-payment-form"
                            label="Form of Payment"
                            variant="outlined"
                            value={paymentForm}
                            onChange={(e) => setPaymentForm(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 128 }}
                            error={paymentFormError}
                            helperText={paymentFormError || null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="expense-description"
                            label="Description"
                            variant="outlined"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 128 }}
                            error={descriptionError}
                            helperText={descriptionError || null}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="expense-notes"
                            label="Notes"
                            variant="outlined"
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            sx={{ width: '100%' }}
                            inputProps={{ maxLength: 1024 }}
                            multiline
                            minRows={4}
                            error={notesError}
                            helperText={notesError || null}
                        />
                    </Grid>
                </Grid>
                <Box mt={2} display="flex" alignItems="center" justifyContent="space-around">
                    <Button shade="secondaryLight" onClick={onClose}>Cancel</Button>
                    <Button shade="secondary" onClick={onSave}>Save Expense</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default ExpenseForm;
