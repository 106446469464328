import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import moment from 'moment';

import {
    SET_SESSION,
    ADD_SESSION_ID,
    RESET_SESSION_IDS,
    OPEN_PRODUCT,
    SET_PAYWALL,
} from '../../store/actions';
import Hero from '../../components/common/hero';
import SessionCard from '../../components/session/session-card';
import GroupSessionCard from '../../components/group-session/group-session-card';
import {
    Avatar,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Stack,
    Typography,
    Modal as MUIModal,
} from '@mui/material';
import { get, post } from '../../services/api.services';
import { API } from '../../constants';
import {
    PageTitle,
} from '../sessions/sessions.style';
import {
    Card,
    CardDetail,
    CardImg,
    CardSubtitle,
    CardTitle,
    Subtitle,
    CardLink,
} from './dashboard.style';
import NewTabIcon from '../../assets/icons/NewTab.svg';
import RightChevronIcon from '../../assets/icons/RightChevron.svg';
import SleepImg from '../../assets/images/sleep.png';
import PostpartumImg from '../../assets/images/postpartum.png';
import LactationImg from '../../assets/images/lactation.png';
import LaborImg from '../../assets/images/labor.png';
import HeroImg from '../../assets/images/hero.png';
import Topic from './topic';
import Track from './track';
import { fetchProduct } from '../../clients/product';
import ProductCard from '../../components/product/product-card';
import FolderCard, { FOLDER_VARIANTS } from '../../components/folder/folder-card';
import { sessionIdsReadyToSchedule } from '../../utils/sessions';
import { TRACKS, PaywallTypes } from '../../constants';
import Modal from '../../components/common/modal';

const SUGGESTED_GROUP_PRODUCTS = [480, 425];

function ParentDashboard() {
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const user = useSelector((state) => state.account.user);
    const token = useSelector((state) => state.account.token);
    const sessionIds = useSelector((state) => state.sessions.sessionIds);
    const sessionsById = useSelector((state) => state.sessions.sessionsById);
    const productMap = useSelector((state) => state.products.productsById);
    const featureFlags = useSelector((state) => state.account.user?.profile?.feature_flags);

    const [nearbyExperts, setNearbyExperts] = useState([]);
    const [stageExperts, setStageExperts] = useState([]);
    const [blogPosts, setBlogPosts] = useState([]);
    const [authorMap, setAuthorMap] = useState({});
    const [groupSessions, setGroupSessions] = useState([]);
    const [packageGroupSessions, setPackageGroupSessions] = useState([]);
    const [folders, setFolders] = useState([]);
    const [showPDFModal, setShowPDFModal] = useState(false);

    const openPaywall = () => {
        dispatcher({
            type: SET_PAYWALL,
            payload: {
                paywall: true,
                paywall_type: PaywallTypes.BASE,
            },
        });
    };

    const fetchBlogPosts = async () => {
        const result = await get(
            API.BLOG,
            token,
        )

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            if (result.data.items) {
                setBlogPosts(result.data.items);
            }
        }
        else {
        }
    }

    const fetchBlogAuthors = async () => {
        const result = await get(
            API.AUTHOR,
            token,
        );

        if (result.status === 403) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            const authors = result.data.items;
            const authorsById = {};

            if (authors) {
            
                authors.forEach((author) => {
                    authorsById[author._id] = author;
                });

                setAuthorMap(authorsById);
            }
        }
        else {
        }
    }

    const fetchSessions = async () => {
        const result = await get(
            `${API.SESSION}`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            dispatcher({
                type: RESET_SESSION_IDS,
                payload: {},
            });
            
            result.data.forEach((session) => {
                dispatcher({
                    type: SET_SESSION,
                    payload: {
                        session,
                    },
                });
                dispatcher({
                    type: ADD_SESSION_ID,
                    payload: {
                        session,
                    },
                });
            });
        }
        else {
        }
    }

    const fetchPackageGroupSessions = async () => {
        const result = await get(
            `${API.ATTENDEE}?product_type=package`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            let gs = [];
            result.data.forEach((p) => {
                if (p.group_sessions) {
                    gs = [
                        ...gs,
                        ...p.group_sessions.filter((product) => moment(product.event_date) > moment().subtract(15, 'minutes')),
                    ];
                }
            });

            setPackageGroupSessions([ ...packageGroupSessions, ...gs ]);
        }
        else {
        }
    }

    const fetchGroupSessions = async () => {
        const result = await get(
            `${API.ATTENDEE}?upcoming=1&product_type=group`,
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            const groupSessionProducts = result.data.map((purchase) => purchase.product);
            setGroupSessions([ ...groupSessions, ...groupSessionProducts ]);
        }
        else {
        }
    }

    const fetchSuggestedProducts = async () => {
        SUGGESTED_GROUP_PRODUCTS.forEach((id) => {
            fetchProduct(dispatcher, navigate, token, id);
        });
    };

    const navigateToExperts = () => {
        navigate('/experts');
    };

    const openProduct = (product, bookNow) => {
        dispatcher({
            type: OPEN_PRODUCT,
            payload: {
                product,
                bookNow,
            },
          });
    };

    const acceptInvite = async (code) => {
        const result = await post(
            `${API.INVITE}${code}/accept/`,
            {},
            token,
        );

        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            fetchFolders();
        }
        else {
            // TODO: Handle errors
        }
    };

    const fetchFolders = async () => {
        const result = await get(
            API.FOLDER,
            token,
        );
        
        if (result.status === 403 || result.status === 401) {
            navigate('/login/parents');
        }
        else if (result.status === 200) {
            setFolders(result.data);
        }
        else {
            // TODO: Handle errors
        }
    };

    useEffect(() => {
        fetchSessions();
        fetchBlogPosts();
        fetchBlogAuthors();
        fetchGroupSessions();
        fetchPackageGroupSessions();
        fetchSuggestedProducts();
        fetchFolders();

        if (queryParams.get('invite')) {
            acceptInvite(queryParams.get('invite'));
        }
    }, []);

    const filteredSessionIds = sessionIdsReadyToSchedule(sessionIds, sessionsById);

    const pendingSessions = filteredSessionIds.filter((id) => !sessionsById[id].canceled && (!sessionsById[id].session_date || moment(sessionsById[id].session_date) > moment().subtract(30, 'minutes')));
    const sortedBlogPosts = blogPosts.filter((post) => post._draft === false).sort((a, b) => moment(b['published-on']) - moment(a['published-on']));

    return (
        <Container maxWidth="md">
            <Box display="flex" alignItems="center">
                <PageTitle>
                    My Resources
                </PageTitle>
            </Box>
            <Grid container spacing={2}>
                {folders.filter((folder) => folder.folder_type === 'CLIENT').map((folder) => (
                    <Grid key={folder.id} item xs={12} md={6}>
                        <FolderCard
                            folder={folder}
                            variant={folder.owner.id === user.profile.id ? FOLDER_VARIANTS.OWNER : FOLDER_VARIANTS.MEMBER}
                        />
                    </Grid>
                ))}
                <Grid key="match" item xs={12} md={6} onClick={() => navigate('/match')}>
                    <Paper elevation={0}>
                        <Box p={2}>
                            <Typography variant="h6">
                                Need help finding the right option for you?
                            </Typography>
                            <Box mt={2}>
                                <Button variant="contained">Take the Matching Quiz</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid key="digital-bonus-kit" item xs={12} md={6} onClick={() => featureFlags?.digital_bonus_kit ? navigate('/digital-bonus-kit') : openPaywall()}>
                    <Paper elevation={0}>
                        <Box p={2}>
                            <Typography variant="h6">
                                Check out the Digital Bonus Kit
                            </Typography>
                            <Box mt={2}>
                                <Button variant="contained">See What's Inside</Button>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                {featureFlags?.welcome_gift && (
                    <Grid key="digital-bonus-kit" item xs={12} md={6} onClick={() => window.open('https://tkn1shk0jgv.typeform.com/to/XWm2CtkG', '_blank')}>
                        <Paper elevation={0}>
                            <Box p={2}>
                                <Typography variant="h6">
                                    Request your welcome gift!
                                </Typography>
                                <Box mt={2}>
                                    <Button variant="contained">Share Your Address to Get Your Gift</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                {featureFlags?.tailored_report && (
                    <Grid key="digital-bonus-kit" item xs={12} md={6} onClick={() => setShowPDFModal(true)}>
                        <Paper elevation={0}>
                            <Box p={2}>
                                <Typography variant="h6">
                                    Build your support dream team!
                                </Typography>
                                <Box mt={2}>
                                    <Button variant="contained">Get Your Tailored Suggestions</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                )}
                {featureFlags?.consultation && (
                    <Grid key="digital-bonus-kit" item xs={12} md={6} onClick={() => window.open('https://calendar.app.google/ndHJ89UpYhdWKAeB7', '_blank')}>
                        <Paper elevation={0}>
                            <Box p={2}>
                                <Typography variant="h6">
                                    Book your in-depth consultation with Parentswarm CEO, Carly!
                                </Typography>
                                <Box mt={2}>
                                    <Button variant="contained">Schedule Now</Button>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    {pendingSessions.length > 0 && (
                        <>
                            <PageTitle>
                                Upcoming Sessions
                            </PageTitle>
                            <Grid container spacing={2}>
                                {pendingSessions.map((id) => (
                                    <Grid key={id} item xs={12} sm={6}>
                                        <SessionCard
                                            session={sessionsById[id]}
                                            variant={user.profile.profile_type}
                                        />
                                    </Grid>
                                ))}
                                {[ ...groupSessions, ...packageGroupSessions ].map((product, index) => (
                                    <Grid key={index} item xs={12} sm={6}>
                                        <GroupSessionCard
                                            product={product}
                                            variant={user.profile.profile_type}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                    <PageTitle>
                        Popular Topics
                    </PageTitle>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <Topic img={LaborImg} displayText="Birth" topic="Labor and Birth" />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Topic img={LactationImg} displayText="Feeding" topic="Lactation and Feeding" />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Topic img={SleepImg} topic="Sleep" />
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Topic img={PostpartumImg} topic="Postpartum" />
                            </Grid>
                        </Grid>
                    </Box>
                    <PageTitle>
                        Recommended for You
                    </PageTitle>
                    <Stack spacing={2}>
                        {user.profile.tracks?.map((track) => (
                            <Box>
                                <Subtitle>{TRACKS.filter((t) => t.value === track)[0].title}</Subtitle>
                                <Track track={track} />
                            </Box>
                        ))}
                        {/*
                        <Box>
                            <Subtitle>Popular Group Sessions</Subtitle>
                            <Grid container spacing={2}>
                                {SUGGESTED_GROUP_PRODUCTS.map((id) => (
                                    <>
                                        {productMap[id] && (
                                            <Grid key={id} item xs={12} sm={12} md={6}>
                                                <ProductCard
                                                    variant="small"
                                                    product={productMap[id]}
                                                    secondaryCtaAction={() => openProduct(productMap[id], false)}
                                                    primaryCtaAction={() => openProduct(productMap[id], true)}
                                                    key={id}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                ))}
                            </Grid>
                        </Box>
                        */}
                        {user.profile.zip_code && nearbyExperts.length > 0 && (
                            <Box>
                                <Subtitle>Experts near you</Subtitle>
                                <Grid container spacing={2}>
                                    {nearbyExperts.map((expert) => (
                                        <Grid key={expert.id} item xs={12} sm={6} md={3}>
                                            <Card
                                                style={{ textAlign: 'center' }}
                                                onClick={() => navigate(`/experts/${expert.display_id}/`)}
                                            >
                                                <Avatar
                                                    src={expert.profile_image_url}
                                                    alt={expert.first_name}
                                                    sx={{ width: 62, height: 62, margin: '0 auto' }}
                                                />
                                                <CardTitle style={{ fontSize: 22, marginTop: 10 }}>
                                                    {expert.first_name}
                                                </CardTitle>
                                                <CardDetail>
                                                    {expert.roles.split(',').slice(0, 1).join(', ')}
                                                </CardDetail>
                                                <CardLink>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Box sx={{ mr: 0.5 }}>Learn More</Box>
                                                        <img src={RightChevronIcon} alt="arrow" />
                                                    </Box>
                                                </CardLink>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        {stageExperts.length > 0 && (
                            <Box sx={{ mb: 2 }}>
                                <Subtitle>Experts for your stage of parenthood</Subtitle>
                                <Grid container spacing={2}>
                                    {stageExperts.map((expert, index) => (
                                        <React.Fragment key={expert.id}>
                                            {index < 4 && (
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Card
                                                        style={{ textAlign: 'center' }}
                                                        onClick={() => navigate(`/experts/${expert.display_id}/`)}
                                                    >
                                                        <Avatar
                                                            src={expert.profile_image_url}
                                                            alt={expert.first_name}
                                                            sx={{ width: 62, height: 62, margin: '0 auto' }}
                                                        />
                                                        <CardTitle style={{ fontSize: 22, marginTop: 10 }}>
                                                            {expert.first_name}
                                                        </CardTitle>
                                                        <CardDetail>
                                                            {expert.roles.split(',').slice(0, 1).join(', ')}
                                                        </CardDetail>
                                                        <CardLink>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Box sx={{ mr: 0.5 }}>Learn More</Box>
                                                                <img src={RightChevronIcon} alt="arrow" />
                                                            </Box>
                                                        </CardLink>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                        {sortedBlogPosts.length > 0 && (
                            <Box>
                                <Subtitle>Blog posts</Subtitle>
                                <Grid container spacing={2}>
                                    {sortedBlogPosts.map((post, index) => (
                                        <React.Fragment key={post.slug}>
                                            {index < 4 && (
                                                <Grid item xs={12} sm={6} md={3}>
                                                    <Card onClick={() => window.open(`https://parentswarm.com/blog/${post.slug}`, '_blank')}>
                                                        <CardImg src={post['post-main-image'].url} alt="blog image" />
                                                        <CardTitle>{post.name}</CardTitle>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <CardSubtitle>By: {authorMap[post['post-author']]?.name}</CardSubtitle>
                                                            <img src={NewTabIcon} alt="link" width={16} />
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                    </Stack>
                </Grid>
            </Grid>
            <MUIModal
                open={showPDFModal}
                onClose={() => setShowPDFModal(false)}
            >
                <Modal title="Your Personalized Parenting Dream Team 💎" onClose={() => setShowPDFModal(false)}>
                    <Stack spacing={2}>
                        <Typography variant="body1">
                            Get your curated PDF report hand-picking the perfect prenatal and postpartum experts and resources tailored{' '}
                            just for you based on your unique questionnaire responses.
                        </Typography>
                        <Typography variant="body1">
                            <b>Fill out the Matching Quiz</b> with your latest parenting challenge. You'll receive your hand-picked report{' '}
                            in your inbox within 24 business hours.
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                onClick={() => navigate('/match')}
                            >
                                Get My Report
                            </Button>
                        </Box>
                    </Stack>
                </Modal>
            </MUIModal>
        </Container>
    );
}

export default ParentDashboard;
