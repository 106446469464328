/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Container = styled.div`
background: #5371FF;
`;

export const AccountTypePrompt = styled.span`
    font-family: 'Cabin';
    color: #d36f7e;
    text-transform: lowercase;
`;

export const SubPrompt = styled.span`
    font-family: 'Cabin';
    font-size: 15px;
`;

export const RegisterBody = styled.div`
text-align: center;
margin: 5em 0;
max-width: 400px;
margin-left: auto;
margin-right: auto;
background-color: #FFFFFF;
`;

export const EmailField = styled.input`
border-radius: 2em;
text-align: center;
padding: 1em 1.7em;
margin: 1.6em 0em 1em;
color: #808080;
border: 1px solid #d3d3d3;
font-size: 15px;
`;

export const PasswordField = styled.input`
border-radius: 2em;
text-align: center;
padding: 1em 1.7em;
margin-bottom: 1em;
color: #808080;
border: 1px solid #d3d3d3;
font-size: 15px;
`;

export const PhoneField = styled.input`
border-radius: 2em;
margin: 1em 0em 1em;
text-align: center;
padding: 1em 1.7em;
border: 1px solid #d3d3d3;
font-size: 15px;
`;

export const PinField = styled.input`
width: 1.3vw;
min-width: 25px;
border-radius: 0.3em;
text-align: center;
padding: 1em;
margin: 3em 0.3em 0.5em;
color: #808080;
border: 1px solid #d3d3d3;
font-size: 15px;
`;

export const SubmitButton = styled.button`
margin: 1em 0em 0em;
border-radius: 2em;
text-align: center;
padding: 1.2em 2.5em;
border: 1px solid #d3d3d3;
background-color: ${({ backgroundColor }) => (backgroundColor ? '#5371ff' : '#d3d3d3')};
color: #fff;
font-weight: 700;
cursor: pointer;
`;

export const Send = styled.button`
margin: 2em 0em 0em;
border-radius: 2em;
text-align: center;
padding: 1.2em 2.5em;
border: 1px solid #d3d3d3;
background-color: ${({ backgroundColor }) => (backgroundColor ? '#5371ff' : '#d3d3d3')};
color: #fff;
font-weight: 700;
cursor: pointer;
`;

export const SendOtp = styled.button`
margin: 2em 0em 0em;
border-radius: 2em;
text-align: center;
padding: 1.2em 2.5em;
border: 1px solid #808080;
background-color: ${({ backgroundColor }) => (backgroundColor ? '#5371ff' : '#d3d3d3')};
color: #fff;
font-weight: 700;
cursor: pointer;
`;

export const InfoText = styled.p`
font-size: 13px;
color: #808080;
letter-spacing: 0.4px;
margin: 0px;
`;

export const ErrorMessage = styled.p`
color: red;
margin: 0px;
font-size: 13px;
`;

export const ResendText = styled.p`
color: #5371ff;
font-weight: 700;
cursor: pointer;
`;

export const ImageDiv = styled.div`
    background-color: #F08181;
    min-height: 150vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const ImageCont = styled.div`
margin: 5em 0em 2em;
`;

export const ThankText = styled.p`
font-weight: 700;
`;

export const SetUpButton = styled.button`
margin: 1em 0em 0em;
border-radius: 2em;
text-align: center;
padding: 1.1em 2em;
border: 1px solid #808080;
background-color: #5371ff;
color: #fff;
font-weight: 700;
cursor: pointer;
`;

export const TopDot = styled.div`
    height: 300px;
    width: 300px;
    background-color: rgb(255, 255, 255);
    opacity: 0.1;
    border-radius: 100%;
    position: absolute;
    top: -100px;
    left: -50px;
    z-index: 0;
`;

export const BottomDot = styled.div`
    height: 300px;
    width: 300px;
    background-color: rgb(255, 255, 255);
    opacity: 0.1;
    border-radius: 100%;
    position: absolute;
    bottom: -100px;
    right: 20px;
    z-index: 0;
`;
