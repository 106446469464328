import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

import { Box, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";

import TimeField from "../common/time-field";

interface ScheduleProps {
  scheduledAt: string | null;
  setScheduledAt: (scheduledAt: string) => void;
  timeFieldLabel?: React.ReactNode;
  showTimezone?: boolean;
}

export default function Schedule({
  scheduledAt,
  setScheduledAt,
  timeFieldLabel,
  showTimezone,
}: ScheduleProps) {
  const [hour, setHour] = useState(
    scheduledAt ? moment(scheduledAt).format("hh") : "12"
  );
  const [minute, setMinute] = useState(
    scheduledAt ? moment(scheduledAt).format("mm") : "00"
  );
  const [period, setPeriod] = useState(
    scheduledAt ? moment(scheduledAt).format("a") : "pm"
  );
  const [dateSelected, setDateSelected] = useState(
    scheduledAt
      ? moment(scheduledAt).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  );

  const timezone = moment.tz.guess();

  const updateScheduledAt = useCallback(() => {
    let militaryHour;

    if (period === "am") {
      if (hour === "12") {
        militaryHour = "00";
      } else {
        militaryHour = parseInt(hour);
      }
    } else {
      if (hour === "12") {
        militaryHour = parseInt(hour);
      } else {
        militaryHour = parseInt(hour) + 12;
      }
    }

    const hourStr = militaryHour < 10 ? `0${militaryHour}` : `${militaryHour}`;
    const sessionDate = moment
      .tz(`${dateSelected} ${hourStr}:${minute}:00`, timezone)
      .tz("UTC")
      .format("YYYY-MM-DD HH:mm");

    setScheduledAt(sessionDate);
  }, [dateSelected, hour, minute, period, setScheduledAt, timezone]);

  useEffect(() => {
    updateScheduledAt();
  }, [hour, minute, period, dateSelected, updateScheduledAt]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          views={["day"]}
          minDate={moment.now()}
          disableHighlightToday
          value={dateSelected}
          onChange={(selection: any) => {
            const dateStr = selection.format("YYYY-MM-DD");
            setDateSelected(dateStr);
          }}
          showDaysOutsideCurrentMonth
          renderInput={(params) => {
            return <TextField {...params} />;
          }}
          renderDay={(day, _value, DayComponentProps) => {
            const dateStr = day.format("YYYY-MM-DD");

            if (dateStr === dateSelected) {
              return (
                <PickersDay
                  {...DayComponentProps}
                  style={{ background: "#5371ff", color: "#FFFFFF" }}
                />
              );
            } else if (dateStr === moment().format("YYYY-MM-DD")) {
              return (
                <PickersDay
                  {...DayComponentProps}
                  style={{ background: "none", color: "#333333" }}
                />
              );
            } else {
              return <PickersDay {...DayComponentProps} />;
            }
          }}
        />
      </LocalizationProvider>
      <Box mt={-3}>
        <TimeField
          prompt={timeFieldLabel ?? 'Select a time'}
          timezone={showTimezone ? timezone : ''}
          hour={hour}
          setHour={setHour}
          minute={minute}
          setMinute={setMinute}
          period={period}
          setPeriod={setPeriod}
        />
      </Box>
    </Box>
  );
}
