import React from 'react';
import PropTypes from 'prop-types';
import { Chip as MUIChip } from '@mui/material';

function Chip({ label, tagProps, color, onDelete, styleOverride = {} }) {
  const colorMap = {
    primary: {
      background: '#5371ff',
      color: '#FFF',
    },
    primaryLight: {
      background: '#FFEEFF',
      color: '#5371ff',
    },
    secondary: {
      background: '#5371ff',
      color: '#FFF',
    },
    secondaryLight: {
      background: '#FFE7E7',
      color: '#5371ff',
    },
    white: {
      background: '#FFFFFF',
      color: '#000000',
    },
  };

  const style = {
    borderRadius: 35,
    border: 'none',
    fontFamily: 'Cabin',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '150%',
    textTransform: 'none',
    marginRight: 5,
    marginTop: 5,
    ...colorMap[color],
    ...styleOverride,
  };

  if (onDelete) {
    return (
        <MUIChip
            variant="outlined"
            style={style}
            label={label}
            {...tagProps}
            onDelete={onDelete}
            sx={{
                '& .MuiChip-deleteIcon': {
                    color: colorMap[color].color,
                },
            }}
        />
    );
  }

  return (
    <MUIChip
        variant="outlined"
        style={style}
        label={label}
        {...tagProps}
        sx={{
            '& .MuiChip-deleteIcon': {
                color: colorMap[color].color,
            },
        }}
    />
  );
}

Chip.propTypes = {
  label: PropTypes.string,
  tagProps: PropTypes.any,
  color: PropTypes.string,
  onDelete: PropTypes.func,
};

export default Chip;