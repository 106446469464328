import React from 'react';
import { Box, Rating, Stack } from '@mui/material';

import Button from '../common/button';
import RateIcon from '../../assets/icons/Rate.svg';
import { ScreenTitle } from './review.style';

export default function Rate({ session, next, rating, setRating }) {
    return (
        <Stack spacing={2} sx={{ textAlign: 'center' }}>
            <img src={RateIcon} alt="rate" width={75} style={{ margin: '0 auto' }} />
            <ScreenTitle
                sx={{
                    fontFamily: 'Nunito',
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '24px',
                    color: '#0E172C',
                }}
            >
                How would you rate your session with {session.expert_profile.first_name}?
            </ScreenTitle>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Rating
                    name="rating"
                    value={rating}
                    precision={1}
                    onChange={(event, newValue) => {
                        setRating(newValue);
                    }}
                />
            </Box>
            <Box>
                <Button onClick={next}>Next</Button>
            </Box>
        </Stack>
    );
}
