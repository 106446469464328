import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';

import Button from '../../components/common/button';
import { SET_FILTERS, OPEN_PRODUCT } from '../../store/actions';
import { fetchTrackProducts } from '../../clients/product';
import { State } from '../../types';
import ProductCard from '../../components/product/product-card';

interface TrackProps {
    track: string;
}

function Track({ track }: TrackProps) {
    const dispatcher = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state: State) => state.account.user);

    const [trackProducts, setTrackProducts] = useState([]);

    const fetchTrack = async () => {
        const result = await fetchTrackProducts(dispatcher, navigate, user.token, track);
        setTrackProducts(result);
    };

    const openProduct = (product, bookNow) => {
        dispatcher({
            type: OPEN_PRODUCT,
            payload: {
                product,
                bookNow,
            },
          });
    };

    useEffect(() => {
        fetchTrack();
    }, []);

    return (
        <Grid container spacing={2}>
            {trackProducts.map((product) => (
                <Grid key={product.id} item xs={12} sm={12} md={6}>
                    <ProductCard
                        variant="small"
                        product={product}
                        secondaryCtaAction={() => openProduct(product, false)}
                        primaryCtaAction={() => openProduct(product, true)}
                        key={product.id}
                    />
                </Grid>
            ))}
        </Grid>
    );
}

export default Track;