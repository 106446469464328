import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Box, Button, Divider, IconButton, Menu, MenuItem, Modal, Select, Stack, TextField, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DragHandleRoundedIcon from '@mui/icons-material/DragHandleRounded';

import { fetchProductsBySeller } from "../../clients/product";
import { useAuth } from "../../hooks";
import { State } from "../../types";
import ProductPreview from "./product-preview";
import AddService from './index.jsx';
import { PRODUCT_TYPES } from "../../constants";

interface ProductSelectorProps {
  selectedProductIds: string[],
  setSelectedProductIds: Function,
  workflow: any,
  setWorkflow: Function,
}

export default function ProductSelector({
  selectedProductIds,
  setSelectedProductIds,
  workflow,
  setWorkflow,
}: ProductSelectorProps) {
  const navigate = useNavigate();
  const dispatcher = useDispatch();

  const { user, token } = useAuth();
  const productsById = useSelector((state: State) => state.products.productsById);

  const [productIds, setProductIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [createNew, setCreateNew] = useState(false);
  const open = Boolean(anchorEl);

  const onDragStart = (e, index) => {
    e.dataTransfer.setData('itemIndex', index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedItemIndex = parseInt(e.dataTransfer.getData('itemIndex'));
    const newList = [...selectedProductIds];

    if (draggedItemIndex !== index) {
      // Remove the dragged item from its original position
      const draggedItem = newList.splice(draggedItemIndex, 1)[0];

      // Insert the dragged item at the new position
      newList.splice(index, 0, draggedItem);
      setSelectedProductIds(newList);
    }

    // Get the old index of each id in list1
    let idToOldIndex = {};
    selectedProductIds.forEach((id, index) => {
        if (idToOldIndex[id]) {
            idToOldIndex[id].push(index);
        } else {
            idToOldIndex[id] = [index];
        }
    });

    // Create a new dictionary with the updated order
    let dict2 = {};
    newList.forEach((id, new_index) => {
        // Get and remove an old index from the list of old indexes
        let old_index = idToOldIndex[id].shift();
        dict2[new_index] = workflow[old_index];
    });

    setWorkflow(dict2);
  };

  const onClickAdd = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (id) => {
    onClose();
    setSelectedProductIds([ ...selectedProductIds, id ]);

    const workflowCopy = { ...workflow };
    workflowCopy[selectedProductIds.length] = {
      value: 0,
      timeframe: 'minutes',
      relative_to: 'purchase',
      relative_index: null,
    };

    setWorkflow(workflowCopy);
  };

  const onDelete = (index) => {
    const copySelectedIds = [ ...selectedProductIds ];
    copySelectedIds.splice(index, 1);
    setSelectedProductIds(copySelectedIds);

    const workflowCopy = { ...workflow };
    delete workflowCopy[index];

    setWorkflow(workflowCopy);
  };

  const onChangeTimeframe = (index, timeframe) => {
    const workflowCopy = { ...workflow };
    workflowCopy[index] = {
      ...workflowCopy[index],
      timeframe,
    };

    setWorkflow(workflowCopy);
  };

  const onChangeTimeframeValue = (index, value) => {
    const workflowCopy = { ...workflow };
    workflowCopy[index] = {
      ...workflowCopy[index],
      value,
    };

    setWorkflow(workflowCopy);
  };

  const onChangeRelativeTo = (index, relativeTo, relativeIndex) => {
    const workflowCopy = { ...workflow };
    workflowCopy[index] = {
      ...workflowCopy[index],
      relative_to: relativeTo,
      relative_index: relativeTo !== 'purchase' ? relativeIndex : null,
    };

    setWorkflow(workflowCopy);
  };

  const fetchAndSetProductsBySeller = async () => {
    const products = await fetchProductsBySeller(dispatcher, navigate, token, user.profile.id);
    setProductIds(products.map(product => product.id));
  }

  useEffect(() => {
    fetchAndSetProductsBySeller();
  }, []);

  const getMostPrecedingSingleProduct = (index) => {
    for (let i = index - 1; i >= 0; i--) {
        let product = productsById[selectedProductIds[i]];
        if (product.product_type === 'single') {
            return i;
        }
    }
    return null;
  };

  return (
    <Box textAlign="center">
      <Stack spacing={2} mb={2}>
        {productIds.length > 0 && selectedProductIds.map((productId, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            justifyContent="center"
            draggable
            onDragStart={(e) => onDragStart(e, index)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
          >
            <Box>
              <IconButton onClick={() => {}}>
                <DragHandleRoundedIcon />
              </IconButton>
            </Box>
            <ProductPreview product={productsById[productId]} />
            <Box ml={2}>
              {(productsById[productId].product_type === PRODUCT_TYPES.RESOURCE || productsById[productId].product_type === PRODUCT_TYPES.SINGLE) && (
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Typography variant="body1">{productsById[productId].product_type === PRODUCT_TYPES.RESOURCE ? 'Deliver' : 'Prompt Scheduling'}</Typography> 
                  <Box display="flex">
                    <TextField
                        id={`value-${index}`}
                        variant="outlined"
                        value={workflow[index]?.value || 0}
                        type="number"
                        onChange={(e) => onChangeTimeframeValue(index, e.target.value)}
                        sx={{ width: '65px', mr: 1 }}
                        inputProps={{ min: 0, max: 100 }}
                    />
                    <Select
                      id={`timeframe-${index}`}
                      value={workflow[index]?.timeframe || 'minutes'}
                      onChange={(e) => onChangeTimeframe(index, e.target.value)}
                      sx={{ width: '100px', mr: 1 }}
                    >
                      <MenuItem value='minutes'>Minutes</MenuItem>
                      <MenuItem value='hours'>Hours</MenuItem>
                      <MenuItem value='days'>Days</MenuItem>
                      <MenuItem value='weeks'>Weeks</MenuItem>
                      <MenuItem value='months'>Months</MenuItem>
                    </Select>
                  </Box>
                  <Box mt={1}>
                    <Select
                      id={`relative-to-${index}`}
                      value={workflow[index]?.relative_to || 'minutes'}
                      onChange={(e) => onChangeRelativeTo(index, e.target.value, getMostPrecedingSingleProduct(index))}
                      sx={{ width: '173px' }}
                    >
                      <MenuItem value='purchase'>After Purchase</MenuItem>
                      {getMostPrecedingSingleProduct(index) !== null && (
                        <MenuItem value='completion'>After {productsById[selectedProductIds[getMostPrecedingSingleProduct(index)]].title} Completes</MenuItem>
                      )}
                    </Select>
                  </Box>
                </Box>
              )}
            </Box>
            <Box ml={2}>
              <IconButton onClick={() => onDelete(index)}>
                <DeleteOutlineRoundedIcon />
              </IconButton>
            </Box>
          </Box>
        ))}
      </Stack>
      <Button
        color="secondary"
        variant="contained"
        aria-label="add"
        id="add-resource"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={onClickAdd}
      >
        <AddIcon /> Add a Service
      </Button>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'add-resource',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: 175,
            width: '20ch',
          },
        }}
      >
        <MenuItem key="create-new" selected={false} onClick={() => setCreateNew(true)}>
          Create New
        </MenuItem>
        <Divider />
        {productIds.map((productId) => (
          <>
            {productsById[productId].product_type !== PRODUCT_TYPES.PACKAGE && (
              <MenuItem key={productId} selected={false} onClick={() => onSelect(productId)}>
                {productsById[productId].title}
              </MenuItem>
            )}
          </>
        ))}
      </Menu>
      <Box mt={2}>
        <Alert color="warning">
          Services and resources included in a package will be displayed in the package even if they have{' '}
          General Access marked "Private" or become archived.
        </Alert>
      </Box>
      <Modal
        open={createNew}
        onClose={() => setCreateNew(false)}
      >
        <AddService
          productId={null}
          onDismiss={() => {
            fetchAndSetProductsBySeller();
            setCreateNew(false);
          }}
        />
      </Modal>
    </Box>
  );
}
