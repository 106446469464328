import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

import { InfoText, Send } from '../../pages/login/Login.style';

function ForgotPasswordSuccess({ onBack }) {
  return (
    <Box mt={2}>
      <Typography variant="h6">Your request has been sent!</Typography>
      <Box mt={2} mb={3}>
        <Typography variant="subtitle1">
          Please check your email for a link to reset your password.
        </Typography>
      </Box>
      <Button onClick={onBack} variant="contained" color="secondary">Back</Button>
    </Box>
  );
}

ForgotPasswordSuccess.propTypes = {
  onBack: PropTypes.func,
};

export default ForgotPasswordSuccess;
